const { apiAxios } = require("common/services/httpService");

export const adminGetAssistant = (assistantId, params) => {
    return apiAxios.get(`/admin/assistant/${assistantId}`, { params });
};

export const adminUpdateAssistant = (assistantId, params) => {
    return apiAxios.patch(`/admin/assistant/${assistantId}`, params);
};

export const adminDeleteAssistant = (assistantId) => {
    return apiAxios.delete(`/admin/assistant/${assistantId}`);
};
