import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, Grid, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import parsePhoneNumber from "libphonenumber-js";
import MuiPhoneNumber from "material-ui-phone-number";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

const roles = [
    {
        label: "Member",
        value: "member",
    },
    {
        label: "Manager",
        value: "manager",
    },
];

function TeamMembers({ formData }) {
    const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);

    const [selectedStep, setSelectedStep] = useState(null);
    const [memberData, setMemberData] = useState({
        name: "",
        phoneNumber: "",
        role: "member",
    });

    const { formField, errors, touched } = formData;
    const { teamMembers } = formField;

    const { values, setFieldValue } = useFormikContext();

    const { teamMembers: teamMembersValue } = values;

    useEffect(() => {
        if (selectedStep === null) {
            setMemberData({
                name: "",
                phoneNumber: "",
                role: "member",
            });
        } else {
            setMemberData({
                name: teamMembersValue[selectedStep].name,
                phoneNumber: teamMembersValue[selectedStep].phoneNumber,
                role: teamMembersValue[selectedStep].role,
            });
        }
    }, [isMemberModalOpen]);

    const toggleMemberModal = () => {
        setIsMemberModalOpen(!isMemberModalOpen);
    };

    const handleCreateMember = () => {
        if (!memberData.name || !memberData.phoneNumber || !memberData.role) {
            return toast.error("Name / Phone Number / Role cannot be empty.");
        }

        if (teamMembersValue.find((_) => _.name === memberData.name)) {
            return toast.error("Member with same name already exists.");
        }

        if (
            teamMembersValue.find(
                (_) => _.phoneNumber === memberData.phoneNumber
            )
        ) {
            return toast.error("Member with same phone number already exists.");
        }

        if (
            teamMembersValue.filter((_) => _.role === "manager").length > 0 &&
            memberData.role === "manager"
        ) {
            return toast.error("Only one manager is allowed.");
        }

        setFieldValue(teamMembers.name, [
            ...teamMembersValue,
            {
                name: memberData.name,
                phoneNumber: memberData.phoneNumber,
                role: memberData.role,
            },
        ]);

        setIsMemberModalOpen(false);
    };

    const handleUpdateMember = () => {
        if (!memberData.name || !memberData.phoneNumber || !memberData.role) {
            return toast.error("Name / Phone Number / Role cannot be empty.");
        }

        if (
            teamMembersValue.find((_) => _.name === memberData.name) &&
            teamMembersValue.findIndex((_) => _.name === memberData.name) !==
                selectedStep
        ) {
            return toast.error("Member with same name already exists.");
        }

        if (
            teamMembersValue.find(
                (_) => _.phoneNumber === memberData.phoneNumber
            ) &&
            teamMembersValue.findIndex(
                (_) => _.phoneNumber === memberData.phoneNumber
            ) !== selectedStep
        ) {
            return toast.error("Member with same phone number already exists.");
        }

        if (
            teamMembersValue.filter((_) => _.role === "manager").length > 0 &&
            memberData.role === "manager" &&
            teamMembersValue[selectedStep].role !== "manager"
        ) {
            return toast.error("Only one manager is allowed.");
        }

        setFieldValue(teamMembers.name, [
            ...teamMembersValue.slice(0, selectedStep),
            {
                name: memberData.name,
                phoneNumber: memberData.phoneNumber,
                role: memberData.role,
            },
            ...teamMembersValue.slice(selectedStep + 1),
        ]);

        setIsMemberModalOpen(false);

        setSelectedStep(null);
    };

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Team Members
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Add team members to your assistant. They can update the
                    assistant's notes, and help assistant resolve some
                    conversations.
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid item xs={12}>
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Team Members
                        </SoftTypography>
                    </SoftBox>
                    {teamMembersValue
                        .sort((a, b) => {
                            if (a.role === "manager" && b.role !== "manager") {
                                return -1;
                            }

                            if (a.role !== "manager" && b.role === "manager") {
                                return 1;
                            }

                            return 0;
                        })
                        .map((teamMember, index) => (
                            <SoftBox
                                key={`teamMembers-${index}`}
                                sx={{
                                    border: "1px solid #E0E0E0",
                                    px: 1.5,
                                    py: 1.5,
                                    borderRadius: "0.375rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mb: 1,
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: 1,
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: 1,
                                        }}
                                    >
                                        <Chip
                                            label={`${teamMember.role[0].toUpperCase()}${teamMember.role.slice(
                                                1
                                            )}`}
                                            size="small"
                                        />
                                        <SoftTypography
                                            variant="body2"
                                            fontWeight="regular"
                                            color="black"
                                        >
                                            {teamMember.name}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: 1,
                                        }}
                                    >
                                        <CallIcon
                                            sx={{
                                                fontSize: "1rem",
                                            }}
                                        />

                                        <SoftTypography
                                            variant="caption"
                                            color="black"
                                        >
                                            {parsePhoneNumber(
                                                teamMember.phoneNumber
                                            )?.formatNational()}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>

                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    <EditIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                            ml: 1,
                                        }}
                                        onClick={() => {
                                            setSelectedStep(index);

                                            toggleMemberModal();
                                        }}
                                    />
                                    <CloseIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                            ml: 1,
                                        }}
                                        onClick={() => {
                                            setFieldValue(teamMembers.name, [
                                                ...teamMembersValue.slice(
                                                    0,
                                                    index
                                                ),
                                                ...teamMembersValue.slice(
                                                    index + 1
                                                ),
                                            ]);
                                        }}
                                    />
                                </SoftBox>
                            </SoftBox>
                        ))}
                    <SoftBox
                        sx={{
                            py: 1,
                            borderRadius: "0.375rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#1C4C73",
                            cursor: "pointer",
                        }}
                        onClick={toggleMemberModal}
                    >
                        <SoftTypography
                            variant="body2"
                            fontWeight="regular"
                            color="white"
                        >
                            Add Team Member
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Modal open={isMemberModalOpen} onClose={toggleMemberModal}>
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Name
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            type="text"
                            label="Name"
                            placeholder="Enter Name"
                            value={memberData.name}
                            onChange={(event) => {
                                setMemberData({
                                    ...memberData,
                                    name: event.target.value,
                                });
                            }}
                        />

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Phone Number
                            </SoftTypography>
                        </SoftBox>
                        <MuiPhoneNumber
                            sx={{
                                width: "100%",
                                outline: "none",
                                border: "none",
                                "& .MuiInputBase-root::before": {
                                    display: "none",
                                },
                                "& .MuiInputBase-root::after": {
                                    display: "none",
                                },
                            }}
                            defaultCountry={"us"}
                            value={memberData.phoneNumber}
                            onChange={(value) => {
                                setMemberData({
                                    ...memberData,
                                    phoneNumber: value.replace(
                                        /[^0-9+]|(?!^)\+/g,
                                        ""
                                    ),
                                });
                            }}
                        />

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Role
                            </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                            options={roles}
                            value={roles.filter(
                                (_) => _.value === memberData.role
                            )}
                            onChange={({ value }) => {
                                setMemberData({
                                    ...memberData,
                                    role: value,
                                });
                            }}
                        />

                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (selectedStep === null) {
                                        handleCreateMember();
                                    } else {
                                        handleUpdateMember();
                                    }
                                }}
                            >
                                {selectedStep !== null ? "Update" : "Create"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleMemberModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </SoftBox>
        </SoftBox>
    );
}

TeamMembers.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default TeamMembers;
