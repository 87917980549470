import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Card, Stack } from "@mui/material";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import AdminLayout from "examples/LayoutContainers/AdminLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminActions } from "../../../redux/adminSlice";
import useAdmin from "../../../redux/hooks/useAdmin";
import SelectUser from "../Users/components/SelectUser";
import {
    adminBusinessColumns,
    createTableRows,
} from "./AdminBusinesses.helpers";

const AdminBusinesses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { businesses, businessesError, isBusinessesLoading } = useAdmin();

    useEffect(() => {
        dispatch(adminActions.fetchBusinesses());
    }, []);

    const tableData = useMemo(() => {
        return {
            columns: adminBusinessColumns,
            rows: createTableRows(businesses),
        };
    }, [businesses]);

    return (
        <AdminLayout>
            <DashboardNavbar />
            <SoftBox pt={3} pb={3}>
                <SoftBox pt={3} pb={3}>
                    <SoftBox pt={3} pb={3}>
                        <Card>
                            <SoftBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                p={3}
                            >
                                <SoftBox lineHeight={1}>
                                    <SoftTypography
                                        variant="h5"
                                        fontWeight="medium"
                                    >
                                        All Businesses
                                    </SoftTypography>
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                    >
                                        List of all businesses.
                                    </SoftTypography>
                                </SoftBox>
                                <Stack spacing={1} direction="row">
                                    <SoftBox
                                        onClick={() => {
                                            if (isBusinessesLoading) return;

                                            dispatch(
                                                adminActions.fetchBusinesses()
                                            );
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            px: 1,
                                            pr: 2,
                                            border: "1px solid #fff",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <RefreshIcon fontSize="medium" />
                                    </SoftBox>
                                    <SelectUser />
                                    <SoftBox
                                        onClick={() => {
                                            navigate(
                                                "/admin/businesses/create"
                                            );
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            px: 1,
                                            border: "1px solid #fff",
                                            borderRadius: "8px",
                                            backgroundColor: "#1C4C73",
                                            color: "#fff",
                                        }}
                                    >
                                        <AddIcon fontSize="medium" />
                                    </SoftBox>
                                </Stack>
                            </SoftBox>
                            <DataTable table={tableData} canSearch />
                        </Card>
                    </SoftBox>
                    <Loader open={isBusinessesLoading} />
                    <Footer />
                </SoftBox>
            </SoftBox>
        </AdminLayout>
    );
};

export default AdminBusinesses;
