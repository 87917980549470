import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {
    adminGetBusiness,
    adminUpdateBusiness,
} from "common/api/admin/business";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import AdminLayout from "examples/LayoutContainers/AdminLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AssignNumbers from "./components/AssignNumbers";
import BusinessDetails from "./components/BusinessDetails";
import TeamMembers from "./components/TeamMembers";
import form from "./schemas/form";
import _initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

const { formId, formField } = form;

function getSteps() {
    return ["Business Details", "Team Members", "Assign Phone Numbers"];
}

function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
        case 0:
            return <BusinessDetails formData={formData} />;
        case 1:
            return <TeamMembers formData={formData} />;
        case 2:
            return <AssignNumbers formData={formData} />;
        default:
            return null;
    }
}

function UpdateBusinessPage() {
    const [activeStep, setActiveStep] = useState(0);

    const { businessId } = useParams();
    const [business, setBusiness] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const steps = getSteps();

    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const navigate = useNavigate();

    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        try {
            const {
                businessHoursEnabled,
                businessHoursStartTime,
                businessHoursEndTime,
                ...rest
            } = values;
            await adminUpdateBusiness(businessId, {
                ...rest,
                businessHours: {
                    isEnabled: businessHoursEnabled,
                    startTime:
                        businessHoursStartTime &&
                        parseInt(businessHoursStartTime),
                    endTime:
                        businessHoursEndTime && parseInt(businessHoursEndTime),
                },
            });
            actions.setSubmitting(false);
            actions.resetForm();

            toast.success("Business updated successfully.");
            navigate("/admin/businesses");
        } catch (error) {
            actions.setSubmitting(false);
            toast.error(error?.response?.data?.error?.message || error.message);
        }
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchBusiness();
    }, [businessId]);

    const fetchBusiness = async () => {
        setIsLoading(true);
        try {
            const response = await adminGetBusiness(businessId);

            setBusiness(response.data.payload);
            setInitialValues({
                ..._initialValues,
                ...response.data.payload,
                userId: response.data.payload.userSetting,
                phoneNumbers: response.data.payload.phoneNumbers.map(
                    (phoneNumber) => phoneNumber._id
                ),
                businessHoursEnabled:
                    response.data.payload?.businessHours?.enabled,
                businessHoursStartTime: String(
                    response.data.payload?.businessHours?.startTime
                ),
                businessHoursEndTime: String(
                    response.data.payload?.businessHours?.endTime
                ),
            });
            setIsLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            setIsLoading(false);
        }
    };

    return (
        <AdminLayout>
            <DashboardNavbar />
            <SoftBox py={2} mb={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {!isLoading && (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                }) => (
                                    <Form id={formId} autoComplete="off">
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={2}>
                                                <SoftBox>
                                                    {getStepContent(
                                                        activeStep,
                                                        {
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                        },
                                                        business
                                                    )}
                                                    <SoftBox
                                                        mt={2}
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        {activeStep === 0 ? (
                                                            <SoftBox />
                                                        ) : (
                                                            <SoftButton
                                                                variant="gradient"
                                                                color="light"
                                                                onClick={
                                                                    handleBack
                                                                }
                                                            >
                                                                Back
                                                            </SoftButton>
                                                        )}
                                                        <SoftButton
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            type="submit"
                                                            variant="gradient"
                                                            color="dark"
                                                        >
                                                            {isLastStep
                                                                ? "Update"
                                                                : "Next"}
                                                        </SoftButton>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </AdminLayout>
    );
}

export default UpdateBusinessPage;
