import { Card, Grid } from "@mui/material";
import curved0 from "assets/images/curved-images/curved0.jpg";
import { getContact } from "common/api/contacts";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../../redux/appSlice";
import useApp from "../../redux/hooks/useApp";
import CustomFields from "./components/CustomFields";
import MessageContact from "./components/MessageContact";

function ContactsProfilePage() {
    const { contactId } = useParams();

    const [contact, setContact] = useState();
    const [conversation, setConversation] = useState();
    const [isLoading, setLoading] = useState(false);

    const { assistants, phoneNumbers } = useApp();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (!contact) return;

        dispatch(appActions.selectBusiness(contact.business));
        dispatch(appActions.fetchPhoneNumbers());
        dispatch(appActions.fetchAssistants());
    }, [contact]);

    const refresh = async () => {
        setLoading(true);

        try {
            setLoading(true);

            const response = await getContact(contactId);

            setContact(response.data.payload.contact);
            setConversation(response.data.payload.conversation);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
        }

        setLoading(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox>
                <SoftBox
                    display="flex"
                    alignItems="center"
                    minHeight="18.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({
                            functions: { rgba, linearGradient },
                            palette: { gradients },
                        }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.6),
                                rgba(gradients.info.state, 0.6)
                            )}, url(${curved0})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                        mb: -5,
                    }}
                />
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: ({
                            functions: { rgba },
                            palette: { white },
                        }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) =>
                            navbarBoxShadow,
                        position: "relative",
                        mt: -8,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography
                                    variant="h4"
                                    sx={{
                                        color: "#000",
                                        fontWeight: 700,
                                    }}
                                >
                                    {contact?.name ? contact.name : ""}
                                </SoftTypography>
                                <SoftTypography
                                    variant="body1"
                                    sx={{
                                        color: "#000",
                                        fontWeight: 400,
                                    }}
                                >
                                    {(contact?.phone &&
                                        parsePhoneNumber(
                                            contact.phone
                                        )?.formatNational()) ||
                                        contact?.phone}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <SoftTypography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 3,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                    width: "fit-content",
                                    ml: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                                onClick={() => {
                                    if (conversation) {
                                        dispatch(
                                            appActions.selectConversation(
                                                conversation._id
                                            )
                                        );
                                    }

                                    navigate(`/conversations`);
                                }}
                            >
                                <MessageIcon fontSize="small" />
                                Send Message
                            </SoftTypography>
                        </Grid> */}
                    </Grid>
                </Card>
            </SoftBox>
            <Grid
                container
                sx={{
                    my: 2,
                }}
                columnSpacing={2}
            >
                <Grid item xs={12} lg={8}>
                    <CustomFields contact={contact} refresh={refresh} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MessageContact contact={contact} />
                </Grid>
            </Grid>
            <Loader open={isLoading} />
            <Footer />
        </DashboardLayout>
    );
}

export default ContactsProfilePage;
