import { Grid, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import FormField from "../FormField";

function FirstStep({ formData }) {
    const { formField, errors, touched } = formData;
    const {
        name,
        displayName,
        state,
        city,
        description,
        businessHoursEnabled,
        businessHoursStartTime,
        businessHoursEndTime,
    } = formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        name: nameValue,
        displayName: displayNameValue,
        state: stateValue,
        city: cityValue,
        description: descriptionValue,
        businessHoursEnabled: businessHoursEnabledValue,
        businessHoursStartTime: businessHoursStartTimeValue,
        businessHoursEndTime: businessHoursEndTimeValue,
    } = values;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Business Details
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Let's get to know your business better! Please provide a
                    unique name and a concise, engaging description for your
                    business.
                </SoftTypography>
            </SoftBox>
            <SoftBox py={6}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={name.type}
                                    label={name.label}
                                    name={name.name}
                                    placeholder={name.placeholder}
                                    error={errors?.name && touched?.name}
                                    success={
                                        nameValue.length > 0 && !errors?.name
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={displayName.type}
                                    label={displayName.label}
                                    name={displayName.name}
                                    placeholder={displayName.placeholder}
                                    error={
                                        errors?.displayName &&
                                        touched?.displayName
                                    }
                                    success={
                                        displayNameValue.length > 0 &&
                                        !errors?.displayName
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={state.type}
                                    label={state.label}
                                    name={state.name}
                                    placeholder={state.placeholder}
                                    error={errors?.state && touched?.state}
                                    success={
                                        stateValue.length > 0 && !errors?.state
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={city.type}
                                    label={city.label}
                                    name={city.name}
                                    placeholder={city.placeholder}
                                    error={errors?.city && touched?.city}
                                    success={
                                        cityValue.length > 0 && !errors?.city
                                    }
                                />
                            </Grid>
                        </Grid>
                        <FormField
                            multiline
                            rows={5}
                            type={description.type}
                            label={description.label}
                            name={description.name}
                            placeholder={description.placeholder}
                            error={errors?.description && touched?.description}
                            success={
                                descriptionValue.length > 0 &&
                                !errors?.description
                            }
                        />

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Business Hours
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mt: 1,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Switch
                                        color="default"
                                        checked={businessHoursEnabledValue}
                                        onChange={(e) => {
                                            setFieldValue(
                                                businessHoursEnabled.name,
                                                e.target.checked
                                            );
                                        }}
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "green",
                                            },
                                            "&.Mui-checked + .MuiSwitch-track":
                                                {
                                                    backgroundColor: "green",
                                                },
                                        }}
                                    />
                                </SoftBox>
                            </Grid>
                        </Grid>

                        {businessHoursEnabledValue && (
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormField
                                        type={businessHoursStartTime.type}
                                        label={businessHoursStartTime.label}
                                        name={businessHoursStartTime.name}
                                        placeholder={
                                            businessHoursStartTime.placeholder
                                        }
                                        error={
                                            errors?.businessHoursStartTime &&
                                            touched?.businessHoursStartTime
                                        }
                                        success={
                                            businessHoursStartTimeValue.length >
                                                0 &&
                                            !errors?.businessHoursStartTime
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormField
                                        type={businessHoursEndTime.type}
                                        label={businessHoursEndTime.label}
                                        name={businessHoursEndTime.name}
                                        placeholder={
                                            businessHoursEndTime.placeholder
                                        }
                                        error={
                                            errors?.businessHoursEndTime &&
                                            touched?.businessHoursEndTime
                                        }
                                        success={
                                            businessHoursEndTimeValue.length >
                                                0 &&
                                            !errors?.businessHoursEndTime
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

FirstStep.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default FirstStep;
