import { Card } from "@mui/material";
import { adminGetUsersByUsage } from "common/api/admin/dashboard";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminActions } from "../../../../../redux/adminSlice";
import useAdmin from "../../../../../redux/hooks/useAdmin";

const UsersByUsage = () => {
    const [selectedModel, setSelectedModel] = useState("");
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { usedModels } = useAdmin();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.fetchUsedModels());
    }, []);

    useEffect(() => {
        setSelectedModel(usedModels[0]);
    }, [usedModels]);

    useEffect(() => {
        if (selectedModel) {
            fetchUsersByUsage();
        }
    }, [selectedModel]);

    const fetchUsersByUsage = async () => {
        try {
            const response = await adminGetUsersByUsage({
                model: selectedModel,
            });

            setUsers(response.data.payload);
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response?.data?.error?.message || error.message);
            setIsLoading(false);
        }
    };

    const table = useMemo(
        () => ({
            columns: [
                { Header: "First Name", accessor: "firstName", width: "20%" },
                { Header: "Last Name", accessor: "lastName", width: "20%" },
                {
                    Header: "Email",
                    accessor: "userEmail",
                    width: "40%",
                    Cell: ({ value: [email, id] }) => (
                        <Link to={`/admin/users/${id}`}>
                            <SoftTypography
                                sx={{
                                    textDecoration: "underline",
                                    fontSize: ".875rem",
                                }}
                            >
                                {email}
                            </SoftTypography>
                        </Link>
                    ),
                },
                { Header: "Total Usage", accessor: "totalUsage", width: "20%" },
            ],
            rows: users.map((user) => ({
                firstName: user.firstName || "--",
                lastName: user.lastName || "--",
                userEmail: [user.userEmail, user._id],
                totalUsage: user.totalUsage.totalTokens,
            })),
        }),
        [users]
    );

    return (
        <Card sx={{ height: "100%", py: 2 }}>
            <SoftBox
                px={2}
                lineHeight={1}
                mb={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <SoftBox>
                    <SoftTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                        color="text"
                    >
                        Users by Usage
                    </SoftTypography>
                </SoftBox>

                <SoftSelect
                    options={usedModels?.map((_) => ({
                        label: _.toUpperCase(),
                        value: _,
                    }))}
                    value={usedModels
                        ?.map((_) => ({
                            label: _.toUpperCase(),
                            value: _,
                        }))
                        ?.filter((_) => _.value === selectedModel)}
                    onChange={({ value }) => {
                        setSelectedModel(value);
                    }}
                />
            </SoftBox>
            <DataTable entriesPerPage={false} table={table} />
        </Card>
    );
};

export default UsersByUsage;
