import SaveIcon from "@mui/icons-material/Save";
import { Card, Grid } from "@mui/material";
import { updateUser } from "common/api/user";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { toast } from "react-toastify";
import { authActions } from "../../../redux/authSlice";
import useAuth from "../../../redux/hooks/useAuth";
import SelectField from "./SelectField";

function PreferredTimezone() {
    const { user } = useAuth();

    const { options, parseTimezone } = useTimezoneSelect({
        labelStyle: "original",
        timezones: allTimezones,
    });

    const [formData, setFormData] = useState({
        preferredTimezone: user?.preferredTimezone,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({
            preferredTimezone: user?.preferredTimezone,
        });
    }, [user]);

    const saveChanges = async () => {
        try {
            console.log(
                options.filter(
                    (item) => item.value === formData?.preferredTimezone
                )
            );

            const response = await updateUser(formData);

            dispatch(authActions.updateUser(response.data.payload));

            toast.success("Timezone changed successfully.");
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);

            setFormData({
                preferredTimezone: user?.preferredTimezone,
            });
        }
    };

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Preferred Timezone
                </SoftTypography>
                <SoftBox mt={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectField
                                label="Preferred Timezone"
                                value={
                                    options.filter(
                                        (item) =>
                                            item.value ===
                                            formData?.preferredTimezone
                                    )[0]
                                }
                                options={options}
                                onChange={({ value }) =>
                                    setFormData({
                                        ...formData,
                                        preferredTimezone: value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SoftTypography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                    width: "fit-content",
                                    ml: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                                onClick={saveChanges}
                            >
                                <SaveIcon fontSize="small" />
                                Save Changes
                            </SoftTypography>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default PreferredTimezone;
