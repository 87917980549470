import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function SelectBusiness({
    withoutAny = false,
    defaultValue = undefined,
    callbackFn = () => {},
}) {
    const {
        businesses,
        selectedBusinessId,
        businessesError,
        isBusinessesLoading,
    } = useApp();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appActions.fetchBusinesses());

        if (
            withoutAny &&
            defaultValue !== undefined &&
            selectedBusinessId === undefined
        ) {
            dispatch(appActions.selectBusiness(businesses?.[0]?._id));
            dispatch(appActions.selectBusiness(defaultValue));
            dispatch(appActions.fetchAssistants());
            dispatch(appActions.fetchContacts());
            dispatch(appActions.fetchBusinesses());
        }

        if (defaultValue !== undefined) {
            dispatch(appActions.selectBusiness(defaultValue));
            dispatch(appActions.fetchAssistants());
            dispatch(appActions.fetchContacts());
            dispatch(appActions.fetchBusinesses());
        }
    }, []);

    useEffect(() => {
        callbackFn(selectedBusinessId);
    }, [selectedBusinessId]);

    const businessesValues = useMemo(() => {
        return [
            !withoutAny
                ? {
                      label: "Any Business",
                      value: undefined,
                  }
                : null,
            ...businesses?.map((business) => ({
                label: business.displayName,
                value: business._id,
            })),
        ].filter(Boolean);
    }, [businesses]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={businessesValues}
                value={businessesValues.filter(
                    (_) => _.value === selectedBusinessId
                )}
                onChange={({ value }) => {
                    dispatch(appActions.selectBusiness(value));
                    dispatch(appActions.fetchAssistants());
                    dispatch(appActions.fetchContacts());
                    dispatch(appActions.fetchBusinesses());
                }}
            />
        </div>
    );
}

export default SelectBusiness;
