import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid, Icon, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import { parsePhoneNumber } from "libphonenumber-js";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function ThirdStep({ formData }) {
    const { formField, errors, touched } = formData;

    const { values, setFieldValue } = useFormikContext();

    const { phoneNumbers } = useApp();

    const dispatch = useDispatch();

    const { businessId } = useParams();

    useEffect(() => {
        dispatch(appActions.selectBusiness(undefined));
        dispatch(appActions.fetchPhoneNumbers());
    }, []);

    const { phoneNumbers: numbersValue } = values;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Assign Phone Number/s
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Assign one or more unassigned phone numbers to your
                    business. If you require additional numbers or don't have
                    any, please reach out to our support team.
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        py: 1,
                    }}
                >
                    <SoftBox
                        sx={{
                            width: "100%",
                            border: "1px solid",
                            borderRadius: "5px",
                            borderColor: "#E5E5E5",
                            ...(phoneNumbers.length === 0 && {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }),
                            mt: 1,
                        }}
                    >
                        {phoneNumbers.filter((number) =>
                            businessId
                                ? number.business?._id === businessId ||
                                  number.business === null
                                : number.business === null
                        ).length === 0 && (
                            <SoftTypography
                                variant="body2"
                                fontWeight="regular"
                                color="text"
                                sx={{
                                    textAlign: "center",
                                    py: 2,
                                }}
                            >
                                No phone numbers available. <br /> Please
                                contact our support for new number.
                            </SoftTypography>
                        )}

                        {phoneNumbers
                            .filter((number) =>
                                businessId
                                    ? number.business?._id === businessId ||
                                      number.business === null
                                    : number.business === null
                            )
                            .map((number) => {
                                const isSelected = numbersValue.includes(
                                    number._id
                                );

                                return (
                                    <SoftBox
                                        key={`number-${number._id}`}
                                        onClick={() => {
                                            if (isSelected) {
                                                setFieldValue(
                                                    "phoneNumbers",
                                                    numbersValue.filter(
                                                        (n) => n !== number._id
                                                    )
                                                );
                                            } else {
                                                setFieldValue("phoneNumbers", [
                                                    ...numbersValue,
                                                    number._id,
                                                ]);
                                            }
                                        }}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: 1,
                                            py: 1,
                                            px: 2,
                                            cursor: "pointer",
                                            borderRadius: 1,
                                            width: "100%",
                                            bgcolor: isSelected
                                                ? "#1C4C7320"
                                                : "",
                                            "&:hover": {
                                                bgcolor: "#E5E5E5",
                                            },
                                            borderBottom: "1px solid",
                                            borderColor: "#E5E5E5",
                                        }}
                                    >
                                        <SoftBox
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography
                                                color={"#000000"}
                                                fontWeight={700}
                                                variant="h6"
                                            >
                                                {number.senderName ||
                                                    parsePhoneNumber(
                                                        number.senderNumber
                                                    )?.formatNational() ||
                                                    number.senderNumber}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                fontWeight={400}
                                            >
                                                Created at:{" "}
                                                <ReactTimeago
                                                    date={number.createdAt}
                                                />
                                            </Typography>
                                        </SoftBox>
                                        <SoftBox>
                                            <SoftBox
                                                sx={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    p: 0.6,
                                                    border: "1px solid #fff",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#1C4C73",
                                                    color: "#fff",
                                                }}
                                            >
                                                {isSelected ? (
                                                    <RemoveIcon fontSize="medium" />
                                                ) : (
                                                    <AddIcon fontSize="medium" />
                                                )}
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                );
                            })}

                        <SoftBox
                            sx={{
                                ml: "auto",
                                lineHeight: 0,
                                p: 1,
                                textAlign: "right",
                            }}
                        >
                            {numbersValue.length > 0 ? (
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                >
                                    {numbersValue.length} number/s selected.
                                </SoftTypography>
                            ) : (
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                >
                                    No numbers selected.
                                </SoftTypography>
                            )}
                        </SoftBox>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: 1,
                        }}
                    >
                        <Icon color="action">info_outline</Icon>
                        <SoftTypography
                            variant="body2"
                            sx={{ fontSize: ".9rem" }}
                        >
                            The default assistant can be set up on the numbers
                            page after the business has been created.
                        </SoftTypography>
                    </SoftBox>
                </Grid>

                {errors.numbers && touched.numbers && (
                    <SoftTypography
                        variant="caption"
                        fontWeight="regular"
                        color="error.main"
                    >
                        {errors.numbers}
                    </SoftTypography>
                )}
            </SoftBox>
        </SoftBox>
    );
}

ThirdStep.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default ThirdStep;
