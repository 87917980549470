import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";

export const attemptsColumns = [
    // {
    //     Header: "ATTEMPT",
    //     accessor: "index",
    //     width: "15%",
    //     Cell: ({ value: [index] }) => (
    //         <SoftBox
    //             sx={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //             }}
    //         >
    //             {index + 1}.
    //         </SoftBox>
    //     ),
    // },
    {
        Header: "MESSAGE",
        accessor: "message",
        width: "35%",
        Cell: ({ value: [message] }) => (
            <SoftBox
                sx={{
                    width: "300px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {message}
            </SoftBox>
        ),
    },
    {
        Header: "SENT AT",
        accessor: "sentAt",
        width: "25%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    {
        Header: "RESULT",
        accessor: "result",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <SoftBadge
                    variant="gradient"
                    size="md"
                    color={name === "replied" ? "success" : "warning"}
                    badgeContent={name.toUpperCase()}
                    container
                    border
                    circular
                />
            </SoftBox>
        ),
    },
    {
        Header: "REPLIED AT",
        accessor: "repliedAt",
        width: "25%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(attempts) {
    return attempts.map((attempt, index) => ({
        index: [index],
        message: [attempt.message || "--"],
        sentAt: [
            attempt.sentAt ? new Date(attempt.sentAt).getTime() : null,
            attempt.sentAt ? dayjs(attempt.sentAt).format("D MMM, YYYY") : "--",
        ],
        result: [attempt.result || "--", attempt],
        repliedAt: [
            attempt.repliedAt ? new Date(attempt.repliedAt).getTime() : null,
            attempt.repliedAt
                ? dayjs(attempt.repliedAt).format("D MMM, YYYY")
                : "--",
        ],
    }));
}
