import SaveIcon from "@mui/icons-material/Save";
import {
    Card,
    CircularProgress,
    Grid,
    Icon,
    Switch,
    Typography,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useDebounce } from "core/hooks/useDebounce";
import { parsePhoneNumber } from "libphonenumber-js";
import SelectBusiness from "pages/Contacts/components/SelectBusiness";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import ReactTimeago from "react-timeago";
import { appActions } from "../../../redux/appSlice";
import useApp from "../../../redux/hooks/useApp";
import useAuth from "../../../redux/hooks/useAuth";
import classes from "../Settings.module.css";
import FormField from "./FormField";
import SelectField from "./SelectField";

const notificationPreferenceOptions = [
    {
        label: "Notify me about all conversations",
        value: "all",
    },
    {
        label: "Notify me about specific conversations",
        value: "specified",
    },
];

function NotificationPreferences() {
    const [formData, setFormData] = useState();

    const [searchQueryInput, setSearchQueryInput] = useState("");
    const searchQueryDebounced = useDebounce(searchQueryInput, 500);

    const { user, isLoading } = useAuth();
    const {
        conversations,
        selectedBusinessId,
        totalConversations,
        isConversationsLoading,
        notificationPreferences,
    } = useApp();

    const dispatch = useDispatch();

    useEffect(() => {
        if (formData?.preference === "specified") {
            dispatch(appActions.fetchConversations());
        }
    }, [formData?.preference, user]);

    useEffect(() => {
        if (!isLoading) {
            dispatch(appActions.fetchNotificationPreferences());
        }
    }, [user]);

    useEffect(() => {
        dispatch(
            appActions.setSearchQuery(searchQueryDebounced.replace(/ /g, ""))
        );
        dispatch(appActions.fetchConversations());
    }, [searchQueryDebounced]);

    useEffect(() => {
        dispatch(appActions.fetchConversations());
    }, [selectedBusinessId]);

    useEffect(() => {
        if (notificationPreferences) {
            setFormData({
                preference: notificationPreferences.preference,
                specifiedConversations:
                    notificationPreferences.specifiedConversations,
                notifyNewConversations:
                    notificationPreferences.notifyNewConversations,
            });
        }
    }, [notificationPreferences]);

    const saveChanges = async () => {
        dispatch(
            appActions.updateNotificationPreferences({
                preference: formData.preference,
                specifiedConversations: formData.specifiedConversations,
                notifyNewConversations: formData.notifyNewConversations,
            })
        );
    };

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Notification Preferences
                </SoftTypography>
                <SoftBox mt={1}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: -2,
                            }}
                        >
                            <SelectField
                                label="Your notification preference"
                                value={notificationPreferenceOptions.filter(
                                    (item) =>
                                        item.value === formData?.preference
                                )}
                                options={notificationPreferenceOptions}
                                onChange={({ value }) =>
                                    setFormData({
                                        ...formData,
                                        preference: value,
                                    })
                                }
                            />
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    mt: 1,
                                }}
                            >
                                <Icon color="action">info_outline</Icon>
                                <SoftTypography
                                    variant="body2"
                                    sx={{ fontSize: ".9rem" }}
                                >
                                    This preference will apply to all your
                                    devices.
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                                sx={{ mt: 2 }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Select Business
                                </SoftTypography>
                            </SoftBox>
                            <SelectBusiness />
                        </Grid>

                        {formData?.preference === "specified" && (
                            <Grid item xs={12}>
                                <FormField
                                    label="Select conversations to notify you about"
                                    placeholder="Search for conversations"
                                    value={searchQueryInput}
                                    onChange={(e) => {
                                        setSearchQueryInput(e.target.value);
                                    }}
                                />

                                <SoftBox
                                    sx={{
                                        overflowY: "auto",
                                        width: "100%",
                                        height: "300px",
                                        border: "1px solid",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                        borderBottomLeftRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                        borderColor: "#E5E5E5",
                                        ...(conversations.length === 0 && {
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }),
                                        mt: 1,
                                    }}
                                    className={classes.specifiedConversations}
                                    id="scrollableDiv"
                                >
                                    {isConversationsLoading &&
                                        conversations.length === 0 && (
                                            <CircularProgress
                                                color="inherit"
                                                disableShrink
                                                style={{
                                                    color: "#1C4C73",
                                                }}
                                            />
                                        )}

                                    {!isConversationsLoading &&
                                        conversations.length === 0 && (
                                            <SoftTypography
                                                variant="body2"
                                                sx={{ textAlign: "center" }}
                                            >
                                                No conversations found.
                                            </SoftTypography>
                                        )}

                                    <InfiniteScroll
                                        dataLength={totalConversations}
                                        next={() => {
                                            dispatch(
                                                appActions.fetchNextPageConversations()
                                            );
                                        }}
                                        hasMore={
                                            conversations.length <
                                            totalConversations
                                        }
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {conversations.map((conversation) => (
                                            <SoftBox
                                                key={`conversation-notification-preferences-${conversation._id}`}
                                                onClick={() => {
                                                    if (
                                                        formData.specifiedConversations.includes(
                                                            conversation._id
                                                        )
                                                    ) {
                                                        setFormData({
                                                            ...formData,
                                                            specifiedConversations:
                                                                formData.specifiedConversations.filter(
                                                                    (item) =>
                                                                        item !==
                                                                        conversation._id
                                                                ),
                                                        });

                                                        return;
                                                    }

                                                    setFormData({
                                                        ...formData,
                                                        specifiedConversations:
                                                            [
                                                                ...formData.specifiedConversations,
                                                                conversation._id,
                                                            ],
                                                    });
                                                }}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    gap: 1,
                                                    py: 1,
                                                    px: 2,
                                                    cursor: "pointer",
                                                    borderRadius: 1,
                                                    width: "100%",
                                                    "&:hover": {
                                                        bgcolor: "#E5E5E5",
                                                    },
                                                }}
                                            >
                                                <SoftBox
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography
                                                        color={"#000000"}
                                                        fontWeight={700}
                                                        variant="h6"
                                                    >
                                                        {conversation.contact
                                                            .name ||
                                                            parsePhoneNumber(
                                                                conversation
                                                                    .contact
                                                                    .phone
                                                            )?.formatNational() ||
                                                            conversation.contact
                                                                .phone}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontWeight={400}
                                                    >
                                                        {conversation?.lastMessage
                                                            ? "Last message: "
                                                            : "Created at: "}
                                                        <ReactTimeago
                                                            date={
                                                                conversation.lastMessage
                                                                    ? conversation
                                                                          .lastMessage
                                                                          .createdAt
                                                                    : conversation.createdAt
                                                            }
                                                        />
                                                    </Typography>
                                                </SoftBox>
                                                <Switch
                                                    checked={formData.specifiedConversations.includes(
                                                        conversation._id
                                                    )}
                                                />
                                            </SoftBox>
                                        ))}
                                    </InfiniteScroll>
                                </SoftBox>

                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: 1,
                                        py: 1,
                                        px: 2,
                                        cursor: "pointer",
                                        border: "1px solid",
                                        borderTopLeftRadius: "0px",
                                        borderTopRightRadius: "0px",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        borderColor: "#E5E5E5",
                                        width: "100%",
                                        "&:hover": {
                                            bgcolor: "#E5E5E5",
                                        },
                                    }}
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            notifyNewConversations:
                                                !formData.notifyNewConversations,
                                        });
                                    }}
                                >
                                    <Typography
                                        color={"#000000"}
                                        fontWeight={700}
                                        variant="h6"
                                    >
                                        Notify me about new conversations
                                    </Typography>
                                    <Switch
                                        checked={
                                            formData?.notifyNewConversations
                                        }
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                notifyNewConversations:
                                                    e.target.checked,
                                            });
                                        }}
                                    />
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mt: 1,
                                    }}
                                >
                                    <Icon color="action">info_outline</Icon>
                                    <SoftTypography
                                        variant="body2"
                                        sx={{ fontSize: ".9rem" }}
                                    >
                                        You can toggle notifications for each
                                        conversation from the conversation page.
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        )}

                        <Grid item xs={6} lg={6} mt={1}></Grid>
                        <Grid item xs={6} lg={6} mt={1}>
                            <SoftTypography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                    width: "fit-content",
                                    ml: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                                onClick={saveChanges}
                            >
                                <SaveIcon fontSize="small" />
                                Save Changes
                            </SoftTypography>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default NotificationPreferences;
