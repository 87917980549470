import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Card, Grid, Modal } from "@mui/material";
import calendlyLogo from "assets/images/assistant-skills/calendly.png";
import outboundLogo from "assets/images/assistant-skills/outbound.png";
import shopifyLogo from "assets/images/assistant-skills/shopify.png";
import voiceNoteLogo from "assets/images/assistant-skills/voice-note.png";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";

const allExtensions = [
    {
        name: "Outbound",
        value: "outbound",
        logo: outboundLogo,
    },
    {
        name: "Voice Note",
        value: "voiceNote",
        logo: voiceNoteLogo,
    },
    {
        name: "Calendly",
        value: "calendly",
        logo: calendlyLogo,
    },
    {
        name: "Shopify",
        value: "shopify",
        logo: shopifyLogo,
    },
];

const extensionSettingsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function Extensions({ formData }) {
    const { formField, errors, touched } = formData;
    const { extensions } = formField;

    const { values, setFieldValue } = useFormikContext();

    const { extensions: extensionsValue } = values;

    const [selectedExtension, setSelectedExtension] = useState(
        extensionsValue.filter((_) => _.name === "outbound")[0] || {}
    );
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const toggleSettingsModal = () => {
        setIsSettingsModalOpen(!isSettingsModalOpen);
    };

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Extensions
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Configure the extensions of the user.
                </SoftTypography>
            </SoftBox>
            <SoftBox
                py={2}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                {allExtensions
                    // .sort((a, b) => {
                    //     const aEnabled = extensionsValue.some(
                    //         (ext) => ext.name === a.value
                    //     );
                    //     const bEnabled = extensionsValue.some(
                    //         (ext) => ext.name === b.value
                    //     );

                    //     return bEnabled - aEnabled;
                    // })
                    .map((extension) => (
                        <Card
                            sx={{
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                borderRadius: "0.375rem",
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                p: 0,
                            }}
                        >
                            <SoftBox
                                p={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <SoftBox display="flex" alignItems="center">
                                    <SoftAvatar
                                        src={extension.logo}
                                        alt={`${extension.name} Logo`}
                                        size="lg"
                                        variant="rounded"
                                        sx={{
                                            width: (theme) =>
                                                extension.value === "shopify"
                                                    ? theme.spacing(6)
                                                    : theme.spacing(7),
                                            height: (theme) =>
                                                extension.value === "shopify"
                                                    ? theme.spacing(7)
                                                    : theme.spacing(7),
                                            backgroundSize: "cover",
                                        }}
                                    />
                                    <SoftBox
                                        ml={
                                            extension.value === "shopify"
                                                ? 3
                                                : 2
                                        }
                                        lineHeight={0}
                                    >
                                        <SoftBox lineHeight={0}>
                                            <SoftTypography
                                                variant="h5"
                                                textTransform="capitalize"
                                                fontWeight="medium"
                                            >
                                                {extension.name}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap="0.5rem"
                                >
                                    {extension.value === "outbound" &&
                                        extensionsValue.filter(
                                            (_) => _.name === extension.value
                                        )[0] && (
                                            <>
                                                <SoftBox
                                                    lineHeight={0}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        gap: 1,
                                                        px: 2,
                                                        py: 1,
                                                        cursor: "pointer",
                                                        backgroundColor:
                                                            "#1c4c73",
                                                        borderRadius: "4px",
                                                    }}
                                                    onClick={() => {
                                                        setSelectedExtension(
                                                            extensionsValue.filter(
                                                                (_) =>
                                                                    _.name ===
                                                                    extension.value
                                                            )[0]
                                                        );
                                                        toggleSettingsModal();
                                                    }}
                                                >
                                                    <SoftTypography
                                                        variant="button"
                                                        fontWeight="medium"
                                                        color="white"
                                                    >
                                                        Configure
                                                    </SoftTypography>
                                                    <SettingsIcon color="white" />
                                                </SoftBox>

                                                <Modal
                                                    open={isSettingsModalOpen}
                                                    onClose={
                                                        toggleSettingsModal
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            ...extensionSettingsModalStyle,
                                                        }}
                                                    >
                                                        <SoftBox
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                        >
                                                            <SoftBox
                                                                lineHeight={0}
                                                            >
                                                                <SoftTypography
                                                                    variant="h5"
                                                                    fontWeight="bold"
                                                                >
                                                                    Configure
                                                                    Outbound
                                                                </SoftTypography>
                                                                <SoftTypography
                                                                    variant="button"
                                                                    fontWeight="regular"
                                                                    color="text"
                                                                >
                                                                    Configure
                                                                    the Outbound
                                                                    extension.
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox mt={1.625}>
                                                            <Grid
                                                                mt={1.625}
                                                                item
                                                                xs={12}
                                                            >
                                                                <SoftBox
                                                                    mb={1}
                                                                    ml={0.5}
                                                                    lineHeight={
                                                                        0
                                                                    }
                                                                    display="inline-block"
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                        alignItems:
                                                                            "flex-end",
                                                                    }}
                                                                >
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize"
                                                                    >
                                                                        API Key
                                                                    </SoftTypography>
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize"
                                                                        cursor="pointer"
                                                                        onClick={() => {
                                                                            const generatedApiKey =
                                                                                Math.random()
                                                                                    .toString(
                                                                                        36
                                                                                    )
                                                                                    .substring(
                                                                                        2
                                                                                    );

                                                                            setSelectedExtension(
                                                                                {
                                                                                    ...selectedExtension,
                                                                                    apiKey: generatedApiKey,
                                                                                }
                                                                            );

                                                                            navigator.clipboard.writeText(
                                                                                generatedApiKey
                                                                            );
                                                                        }}
                                                                    >
                                                                        Generate
                                                                        and Copy
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                                <SoftInput
                                                                    label="API Key"
                                                                    placeholder="Enter API Key"
                                                                    type="password"
                                                                    value={
                                                                        selectedExtension.apiKey
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedExtension(
                                                                            {
                                                                                ...selectedExtension,
                                                                                apiKey: e
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                    }}
                                                                />

                                                                <SoftBox
                                                                    sx={{
                                                                        mt: 3,
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <SoftBox
                                                                        sx={{
                                                                            width: "100%",
                                                                            py: 1,
                                                                            borderRadius:
                                                                                "0.375rem",
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "center",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            toggleSettingsModal();
                                                                        }}
                                                                    >
                                                                        <SoftTypography
                                                                            variant="body2"
                                                                            fontWeight="regular"
                                                                            color="black"
                                                                        >
                                                                            Cancel
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                    <SoftBox
                                                                        sx={{
                                                                            width: "100%",
                                                                            py: 1,
                                                                            borderRadius:
                                                                                "0.375rem",
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "center",
                                                                            backgroundColor:
                                                                                "#1C4C73",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                !selectedExtension.apiKey
                                                                            ) {
                                                                                return toast.error(
                                                                                    "Please provide a API Key."
                                                                                );
                                                                            }

                                                                            setFieldValue(
                                                                                extensions.name,
                                                                                extensionsValue.map(
                                                                                    (
                                                                                        _
                                                                                    ) =>
                                                                                        _.name ===
                                                                                        extension.value
                                                                                            ? selectedExtension
                                                                                            : _
                                                                                )
                                                                            );

                                                                            toast.success(
                                                                                "Outbound extension configured."
                                                                            );
                                                                            toggleSettingsModal();
                                                                        }}
                                                                    >
                                                                        <SoftTypography
                                                                            variant="body2"
                                                                            fontWeight="regular"
                                                                            color="white"
                                                                        >
                                                                            Configure
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                </SoftBox>
                                                            </Grid>
                                                        </SoftBox>
                                                    </Box>
                                                </Modal>
                                            </>
                                        )}
                                    <SoftBox
                                        lineHeight={0}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 1,
                                            px: 2,
                                            py: 1,
                                            cursor: "pointer",
                                            backgroundColor:
                                                !extensionsValue.filter(
                                                    (_) =>
                                                        _.name ===
                                                        extension.value
                                                )[0]
                                                    ? "#1c4c73"
                                                    : "red",
                                            borderRadius: "4px",
                                        }}
                                        onClick={() => {
                                            if (
                                                !extensionsValue.filter(
                                                    (_) =>
                                                        _.name ===
                                                        extension.value
                                                )[0]
                                            ) {
                                                setFieldValue(extensions.name, [
                                                    ...extensionsValue,
                                                    {
                                                        name: extension.value,
                                                    },
                                                ]);
                                            } else {
                                                setFieldValue(
                                                    extensions.name,
                                                    extensionsValue.filter(
                                                        (_) =>
                                                            _.name !==
                                                            extension.value
                                                    )
                                                );
                                            }
                                        }}
                                    >
                                        <SoftTypography
                                            variant="button"
                                            fontWeight="medium"
                                            color="white"
                                        >
                                            {!extensionsValue.filter(
                                                (_) =>
                                                    _.name === extension.value
                                            )[0]
                                                ? "Activate"
                                                : "Deactivate"}
                                        </SoftTypography>
                                        {!extensionsValue.filter(
                                            (_) => _.name === extension.value
                                        )[0] ? (
                                            <AddIcon color="white" />
                                        ) : (
                                            <RemoveIcon color="white" />
                                        )}
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    ))}
            </SoftBox>
        </SoftBox>
    );
}

Extensions.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default Extensions;
