const form = {
    formId: "create-number-group",
    formField: {
        relayServerPoolId: {
            name: "relayServerPoolId",
            label: "Relay Server Pool ID",
            type: "text",
            placeholder: "Enter Relay Server Pool ID",
            errorMsg: "Relay Server Pool ID is required.",
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "Enter Name",
            errorMsg: "Name is required.",
        },
        userSetting: {
            name: "userSetting",
            label: "User Setting",
            type: "text",
            placeholder: "Enter User Setting",
            errorMsg: "User Setting is required.",
        },
        defaultAssistant: {
            name: "defaultAssistant",
            label: "Default Assistant",
            type: "text",
            placeholder: "Enter Default Assistant",
            errorMsg: "Default Assistant is required.",
        },
        business: {
            name: "business",
            label: "Business",
            type: "text",
            placeholder: "Enter Business",
            errorMsg: "Business is required.",
        },
    },
};

export default form;
