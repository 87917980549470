import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { ErrorMessage, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import FormField from "../FormField";

function AdditionalDetails({ formData }) {
    const { formField, errors, touched } = formData;
    const { background, jobDescription, role, style, additional, teamNotes } =
        formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        role: roleValue,
        style: styleValue,
        background: backgroundValue,
        jobDescription: jobDescriptionValue,
        additional: additionalValue,
        teamNotes: teamNotesValue,
    } = values;

    const { assistantId } = useParams();

    const roles = [
        {
            label: "Sales Assistant",
            value: "assistant",
        },
        {
            label: "Coach",
            value: "coach",
        },
        {
            label: "Customer Satisfaction",
            value: "customer satisfaction",
        },
        {
            label: "Affiliate Manager",
            value: "affiliate manager",
        },
        {
            label: "Support",
            value: "support",
        },
    ];

    const styles = [
        {
            label: "calm, casual and suggestive",
            value: "You are very calm and casual. You are not in a rush. You care more about learning about them, you are curious about their goals and after you learn a decent amount only then do you casually suggest the offer",
        },
        {
            label: "direct and assertive guidance",
            value: "You are confident and persuasive, your goal is to identify their problem, create credibility, then offer direct solutions and guidance",
        },
    ];

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Personal & Professional Details
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Share more about yourself, both personally and
                    professionally, defining your identity.
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Role
                            </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                            options={roles}
                            value={roles.filter((_) => _.value === roleValue)}
                            onChange={({ value }) => {
                                setFieldValue(role.name, value);
                            }}
                            error={errors?.role && touched?.role}
                            success={roleValue.length > 2 && !errors?.role}
                        />
                        <SoftBox mt={0.75}>
                            <SoftTypography
                                component="div"
                                variant="caption"
                                color="error"
                            >
                                <ErrorMessage name={role.name} />
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Style
                            </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                            options={styles}
                            value={styles.filter((_) => _.value === styleValue)}
                            onChange={({ value }) => {
                                setFieldValue(style.name, value);
                            }}
                            error={errors?.role && touched?.role}
                            success={styleValue.length > 2 && !errors?.style}
                        />
                        <SoftBox mt={0.75}>
                            <SoftTypography
                                component="div"
                                variant="caption"
                                color="error"
                            >
                                <ErrorMessage name={style.name} />
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            multiline
                            rows={3}
                            type={background.type}
                            label={background.label}
                            name={background.name}
                            placeholder={background.placeholder}
                            error={errors?.background && touched?.background}
                            success={
                                backgroundValue.length > 0 &&
                                !errors?.background
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            multiline
                            rows={3}
                            type={jobDescription.type}
                            label={jobDescription.label}
                            name={jobDescription.name}
                            placeholder={jobDescription.placeholder}
                            error={
                                errors?.jobDescription &&
                                touched?.jobDescription
                            }
                            success={
                                jobDescriptionValue.length > 0 &&
                                !errors?.jobDescription
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            multiline
                            rows={5}
                            type={additional.type}
                            label={additional.label}
                            name={additional.name}
                            placeholder={additional.placeholder}
                            error={errors?.additional && touched?.additional}
                            success={
                                additionalValue.length > 0 &&
                                !errors?.additional
                            }
                        />
                    </Grid>
                </Grid>
                {assistantId && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormField
                                multiline
                                rows={5}
                                readOnly
                                type={teamNotes.type}
                                label={teamNotes.label}
                                name={teamNotes.name}
                                placeholder={
                                    "No team notes found for this assistant."
                                }
                                error={false}
                                success={true}
                            />
                            <SoftBox mt={0.75}>
                                <SoftTypography
                                    component="div"
                                    variant="caption"
                                >
                                    This is a read-only field. Team notes can be
                                    added by the team.
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                )}
            </SoftBox>
        </SoftBox>
    );
}

AdditionalDetails.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default AdditionalDetails;
