import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        name,
        displayName,
        state,
        city,
        description,
        businessHoursEnabled,
        businessHoursStartTime,
        businessHoursEndTime,
        phoneNumbers,
    },
} = checkout;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [displayName.name]: Yup.string().required(displayName.errorMsg),
        [state.name]: Yup.string().required(state.errorMsg),
        [city.name]: Yup.string().required(city.errorMsg),
        [description.name]: Yup.string().required(description.errorMsg),
        [businessHoursEnabled.name]: Yup.boolean(),
        [businessHoursStartTime.name]: Yup.string().when(
            businessHoursEnabled.name,
            {
                is: (value) => value === true,
                then: (schema) =>
                    schema.required(businessHoursStartTime.errorMsg),
            }
        ),
        [businessHoursEndTime.name]: Yup.string().when(
            businessHoursEnabled.name,
            {
                is: (value) => value === true,
                then: (schema) =>
                    schema.required(businessHoursEndTime.errorMsg),
            }
        ),
    }),
    Yup.object().shape({}),
    Yup.object().shape({}),
];

export default validations;
