import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Card, Divider, Grid, Modal, Switch } from "@mui/material";
import shopifyLogo from "assets/images/assistant-skills/shopify.png";
import { getShopDetails } from "common/api/shopify";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../../../../redux/hooks/useAuth";

const extensionSettingsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

const intervalUnits = [
    {
        label: "minute/s",
        value: "minute",
    },
    {
        label: "hour/s",
        value: "hour",
    },
    {
        label: "day/s",
        value: "day",
    },
];

function ShopifySkill({
    formField,
    conversationStepGroupsFormField,
    followUpGroupsFormField,
}) {
    const { values, setFieldValue } = useFormikContext();

    const {
        skills: skillsValue,
        conversationStepGroups: conversationStepGroupsValue,
        followUpGroups: followUpGroupsValue,
    } = values;

    const [extension, setExtension] = useState(
        skillsValue.filter((_) => _.name === "shopify")[0]
            ? skillsValue.filter((_) => _.name === "shopify")[0]
            : undefined
    );
    const [shopDetails, setShopDetails] = useState(null);
    const [continueWithoutAccessToken, setContinueWithoutAccessToken] =
        useState(!extension?.config.accessToken);

    const { user } = useAuth();

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const toggleSettingsModal = () => {
        setIsSettingsModalOpen(!isSettingsModalOpen);
    };

    useEffect(() => {
        setExtension(
            skillsValue.filter((_) => _.name === "shopify")[0]
                ? skillsValue.filter((_) => _.name === "shopify")[0]
                : {
                      name: "shopify",
                      config: {
                          shopUrl: "",
                          shopName: "",
                          accessToken: "",
                          enableAbandonedCartTracking: false,
                          cartAbandonedTime: null,
                          cartAbandonedTimeUnit: null,
                          enableOrderTracking: false,
                          enableDeliveryTracking: false,
                      },
                  }
        );
    }, [isSettingsModalOpen]);

    useEffect(() => {
        if (skillsValue) {
            setExtension(
                skillsValue.filter((_) => _.name === "shopify")[0]
                    ? skillsValue.filter((_) => _.name === "shopify")[0]
                    : undefined
            );
        }
    }, [skillsValue]);

    useEffect(() => {
        setShopDetails(null);
    }, [extension?.config?.shopUrl, extension?.config?.accessToken]);

    const fetchShopDetails = async () => {
        if (
            !extension?.config.shopUrl.startsWith("http://") &&
            !extension?.config.shopUrl.startsWith("https://")
        ) {
            return toast.error(
                "Shop URL should start with http:// or https://"
            );
        }

        if (!extension.config.accessToken) {
            return toast.error("Please fill in the Access Token field.");
        }

        try {
            const response = await getShopDetails({
                shopUrl: extension.config.shopUrl.split("//")[1],
                accessToken: extension.config.accessToken,
            });

            if (response.data) {
                if (response.data.isSuccessful) {
                    setShopDetails(response.data.payload);

                    setExtension((prevExtension) => ({
                        ...prevExtension,
                        config: {
                            ...prevExtension.config,
                            shopName:
                                prevExtension.config.shopName ||
                                response.data.payload.name, // Use new value if shopName is missing
                            myshopify_domain:
                                prevExtension.config.myshopify_domain ||
                                response.data.payload.myshopify_domain, // Use new value if missing
                            shopUrl:
                                prevExtension.config.shopUrl ||
                                `https://${response.data.payload.domain}`, // Prepend https:// if missing
                        },
                    }));

                    toast.success(
                        "Access Token is valid and has all required scopes."
                    );
                } else {
                    toast.error(response.data.error.message);
                }
            } else {
                const originalResponse = response.response;

                toast.error(
                    originalResponse.data.error.message ||
                        "Something went wrong."
                );
            }
        } catch (error) {
            const originalResponse = error.response;

            toast.error(
                originalResponse.data.error.message || "Something went wrong."
            );
        }
    };

    useEffect(() => {
        if (!continueWithoutAccessToken) {
            setExtension((_) => ({
                ..._,
                config: {
                    ..._.config,
                    accessToken: "",
                    enableAbandonedCartTracking: false,
                    cartAbandonedTime: null,
                    cartAbandonedTimeUnit: null,
                    enableOrderTracking: false,
                    enableDeliveryTracking: false,
                },
            }));
        }
    }, [continueWithoutAccessToken]);

    useEffect(() => {
        if (extension?.config?.shopUrl && extension?.config?.accessToken) {
            setShopDetails({});
        }
    }, []);

    return (
        <>
            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "0.375rem",
                    border: "1px solid #E0E0E0",
                    px: 1.5,
                    py: 1.5,
                    p: 0,
                }}
            >
                <SoftBox p={2}>
                    <SoftBox display="flex" alignItems="center">
                        <SoftAvatar
                            src={shopifyLogo}
                            alt={`Shopify Logo`}
                            size="lg"
                            variant="rounded"
                            sx={{
                                width: (theme) => theme.spacing(6),
                                height: (theme) => theme.spacing(7),
                                backgroundSize: "cover",
                            }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography
                                    variant="h5"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                >
                                    Shopify
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox my={2} lineHeight={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            With Shopify, your assistant will be able use Shop
                            details to help your contacts.
                        </SoftTypography>
                    </SoftBox>
                    <Divider />
                    <SoftBox
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {skillsValue?.filter((_) => _.name === "shopify")[0] ===
                            undefined && (
                            <SoftBox
                                lineHeight={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    px: 2,
                                    py: 1,
                                    cursor: "pointer",
                                    backgroundColor: !user.extensions.filter(
                                        (_) => _.name === "shopify"
                                    )[0]
                                        ? "#787878"
                                        : "#1c4c73",
                                    borderRadius: "4px",
                                }}
                                onClick={() => {
                                    if (
                                        !user.extensions.filter(
                                            (_) => _.name === "shopify"
                                        )[0]
                                    ) {
                                        toast.error(
                                            "Please upgrade your account to use this feature."
                                        );
                                    } else {
                                        toggleSettingsModal();
                                    }
                                }}
                            >
                                <SoftTypography
                                    variant="button"
                                    fontWeight="medium"
                                    color="white"
                                >
                                    {!user.extensions.filter(
                                        (_) => _.name === "shopify"
                                    )[0]
                                        ? "Locked"
                                        : "Activate"}
                                </SoftTypography>
                                {!user.extensions.filter(
                                    (_) => _.name === "shopify"
                                )[0] ? (
                                    <LockIcon color="white" />
                                ) : (
                                    <AddIcon color="white" />
                                )}
                            </SoftBox>
                        )}

                        {skillsValue?.filter(
                            (_) => _.name === "shopify"
                        )[0] && (
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "red",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        setFieldValue(formField, [
                                            ...skillsValue.filter(
                                                (_) => _.name !== "shopify"
                                            ),
                                        ]);

                                        setFieldValue(followUpGroupsFormField, [
                                            ...followUpGroupsValue.filter(
                                                (_) => _.category !== "Shopify"
                                            ),
                                        ]);

                                        setFieldValue(
                                            conversationStepGroupsFormField,
                                            [
                                                ...conversationStepGroupsValue.filter(
                                                    (_) =>
                                                        _.category !== "Shopify"
                                                ),
                                            ]
                                        );

                                        toast.success("Shopify skill removed.");
                                    }}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Remove
                                    </SoftTypography>
                                    <DeleteIcon color="white" />
                                </SoftBox>
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "#1c4c73",
                                        borderRadius: "4px",
                                    }}
                                    onClick={toggleSettingsModal}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                    <SettingsIcon color="white" />
                                </SoftBox>
                            </SoftBox>
                        )}
                    </SoftBox>
                </SoftBox>
            </Card>
            <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
                <Box
                    sx={{
                        ...extensionSettingsModalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Configure Shopify
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Configure your Shopify settings.
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox mt={1.625}>
                        <Grid mt={1.625} item xs={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Shop URL
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Shop URL"
                                placeholder="Enter Shop URL"
                                value={extension?.config?.shopUrl}
                                onChange={(event) => {
                                    setExtension((_) => ({
                                        ..._,
                                        config: {
                                            ..._.config,
                                            shopUrl: event.target.value,
                                        },
                                    }));
                                }}
                            />

                            {(shopDetails || continueWithoutAccessToken) && (
                                <>
                                    <SoftBox
                                        mb={1}
                                        ml={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                    >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Shop Name
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput
                                        label="Shop Name"
                                        placeholder="Enter Shop Name"
                                        value={extension?.config?.shopName}
                                        onChange={(event) => {
                                            setExtension((_) => ({
                                                ..._,
                                                config: {
                                                    ..._.config,
                                                    shopName:
                                                        event.target.value,
                                                },
                                            }));
                                        }}
                                    />
                                </>
                            )}

                            {!continueWithoutAccessToken && (
                                <>
                                    <SoftBox
                                        mt={1}
                                        mb={1}
                                        ml={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Access Token
                                        </SoftTypography>
                                        <a href="">
                                            <HelpIcon
                                                fontSize="small"
                                                color="black"
                                            />
                                        </a>
                                    </SoftBox>
                                    <SoftInput
                                        label="Access Token"
                                        placeholder="Enter Access Token"
                                        value={extension?.config?.accessToken}
                                        type="password"
                                        onChange={(event) => {
                                            setExtension((_) => ({
                                                ..._,
                                                config: {
                                                    ..._.config,
                                                    accessToken:
                                                        event.target.value,
                                                },
                                            }));
                                        }}
                                    />

                                    <SoftBox
                                        sx={{
                                            mt: 0.7,
                                            py: 1,
                                            borderRadius: "0.375rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#1C4C73",
                                            cursor: "pointer",
                                        }}
                                        onClick={fetchShopDetails}
                                    >
                                        <SoftTypography
                                            variant="body2"
                                            fontWeight="regular"
                                            color="white"
                                        >
                                            {shopDetails ? "Refresh" : "Fetch"}{" "}
                                            Shop Details
                                        </SoftTypography>
                                    </SoftBox>
                                </>
                            )}

                            {(!shopDetails || !continueWithoutAccessToken) && (
                                <SoftBox ml={0.5} mt={0.5} lineHeight={0}>
                                    <SoftTypography
                                        variant="body2"
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {continueWithoutAccessToken ? (
                                            <>
                                                or{" "}
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                    onClick={() => {
                                                        setContinueWithoutAccessToken(
                                                            !continueWithoutAccessToken
                                                        );

                                                        setShopDetails(null);

                                                        setExtension((_) => ({
                                                            ..._,
                                                            config: {
                                                                ..._.config,
                                                                accessToken: "",
                                                            },
                                                        }));
                                                    }}
                                                >
                                                    continue with access token
                                                </span>
                                                .
                                            </>
                                        ) : (
                                            <>
                                                or{" "}
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                    onClick={() => {
                                                        setContinueWithoutAccessToken(
                                                            !continueWithoutAccessToken
                                                        );

                                                        setShopDetails(null);

                                                        setExtension((_) => ({
                                                            ..._,
                                                            config: {
                                                                ..._.config,
                                                                accessToken: "",
                                                            },
                                                        }));
                                                    }}
                                                >
                                                    continue without access
                                                    token
                                                </span>
                                                .
                                            </>
                                        )}
                                    </SoftTypography>
                                </SoftBox>
                            )}

                            {!continueWithoutAccessToken && shopDetails && (
                                <>
                                    <SoftBox
                                        mt={2}
                                        mb={1}
                                        mx={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Advanced Tracking
                                        </SoftTypography>
                                    </SoftBox>

                                    <Card
                                        sx={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                            borderRadius: "0.375rem",
                                            border: "1px solid #E0E0E0",
                                            px: 1.5,
                                            py: 1.5,
                                        }}
                                    >
                                        <SoftBox
                                            mx={0.5}
                                            lineHeight={0}
                                            display="inline-block"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Abandoned Cart
                                            </SoftTypography>
                                            <Switch
                                                checked={
                                                    extension?.config
                                                        ?.enableAbandonedCartTracking
                                                }
                                                onChange={() => {
                                                    setExtension((_) => ({
                                                        ..._,
                                                        config: {
                                                            ..._.config,
                                                            enableAbandonedCartTracking:
                                                                !_.config
                                                                    .enableAbandonedCartTracking,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </SoftBox>

                                        {extension?.config
                                            ?.enableAbandonedCartTracking && (
                                            <>
                                                <SoftBox
                                                    mt={1}
                                                    mb={1}
                                                    ml={0.5}
                                                    lineHeight={0}
                                                    display="inline-block"
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "flex-end",
                                                    }}
                                                >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        Cart Abandonment Time
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftBox
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "end",
                                                        justifyContent:
                                                            "space-between",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <SoftInput
                                                        label="Cart Abandonment Time"
                                                        placeholder="Enter Cart Abandonment Time"
                                                        value={
                                                            extension?.config
                                                                .cartAbandonedTime
                                                        }
                                                        type="number"
                                                        onChange={(event) => {
                                                            setExtension(
                                                                (_) => ({
                                                                    ..._,
                                                                    config: {
                                                                        ..._.config,
                                                                        cartAbandonedTime:
                                                                            parseInt(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            ),
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    <SoftSelect
                                                        options={intervalUnits}
                                                        value={intervalUnits.filter(
                                                            (_) =>
                                                                _.value ===
                                                                extension
                                                                    ?.config
                                                                    .cartAbandonedTimeUnit
                                                        )}
                                                        onChange={({
                                                            value,
                                                        }) => {
                                                            setExtension(
                                                                (_) => ({
                                                                    ..._,
                                                                    config: {
                                                                        ..._.config,
                                                                        cartAbandonedTimeUnit:
                                                                            value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </SoftBox>
                                                <SoftBox
                                                    ml={0.5}
                                                    mt={0.5}
                                                    lineHeight={0}
                                                    display="inline-block"
                                                >
                                                    <SoftTypography variant="caption">
                                                        This will add a delay
                                                        before the cart is
                                                        considered abandoned.
                                                    </SoftTypography>
                                                </SoftBox>
                                            </>
                                        )}
                                    </Card>
                                    <Card
                                        sx={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                            borderRadius: "0.375rem",
                                            border: "1px solid #E0E0E0",
                                            px: 1.5,
                                            py: 1.5,
                                            mt: 1,
                                        }}
                                    >
                                        <SoftBox
                                            mx={0.5}
                                            lineHeight={0}
                                            display="inline-block"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Order
                                            </SoftTypography>
                                            <Switch
                                                checked={
                                                    extension?.config
                                                        ?.enableOrderTracking
                                                }
                                                onChange={() => {
                                                    setExtension((_) => ({
                                                        ..._,
                                                        config: {
                                                            ..._.config,
                                                            enableOrderTracking:
                                                                !_.config
                                                                    .enableOrderTracking,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </SoftBox>
                                    </Card>
                                    <Card
                                        sx={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                            borderRadius: "0.375rem",
                                            border: "1px solid #E0E0E0",
                                            px: 1.5,
                                            py: 1.5,
                                            mt: 1,
                                        }}
                                    >
                                        <SoftBox
                                            mx={0.5}
                                            lineHeight={0}
                                            display="inline-block"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Delivery
                                            </SoftTypography>
                                            <Switch
                                                checked={
                                                    extension?.config
                                                        ?.enableDeliveryTracking
                                                }
                                                onChange={() => {
                                                    setExtension((_) => ({
                                                        ..._,
                                                        config: {
                                                            ..._.config,
                                                            enableDeliveryTracking:
                                                                !_.config
                                                                    .enableDeliveryTracking,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </SoftBox>
                                    </Card>

                                    <SoftBox
                                        ml={0.5}
                                        mt={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                    >
                                        <SoftTypography variant="caption">
                                            Each Advanced Tracking feature adds
                                            a special Follow-Up Group/s to
                                            handle each case separately.
                                        </SoftTypography>
                                    </SoftBox>
                                </>
                            )}

                            <SoftBox
                                sx={{
                                    mt: 3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        toggleSettingsModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="black"
                                    >
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1C4C73",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (
                                            !extension?.config.shopUrl ||
                                            !extension?.config.shopName
                                        ) {
                                            return toast.error(
                                                "Please fill in all the fields."
                                            );
                                        }

                                        if (
                                            extension?.config
                                                ?.enableAbandonedCartTracking
                                        ) {
                                            if (
                                                !extension?.config
                                                    ?.cartAbandonedTime
                                            ) {
                                                return toast.error(
                                                    "Please fill in the Cart Abandonment Time field."
                                                );
                                            }

                                            if (
                                                typeof extension?.config
                                                    ?.cartAbandonedTime !==
                                                "number"
                                            ) {
                                                return toast.error(
                                                    "Cart Abandonment Time should be a number."
                                                );
                                            }

                                            if (
                                                !extension?.config
                                                    ?.cartAbandonedTimeUnit
                                            ) {
                                                return toast.error(
                                                    "Please select a Cart Abandonment Time Unit."
                                                );
                                            }
                                        } else {
                                            setExtension((_) => ({
                                                ..._,
                                                config: {
                                                    ..._.config,
                                                    cartAbandonedTime: null,
                                                    cartAbandonedTimeUnit: null,
                                                },
                                            }));
                                        }

                                        if (
                                            !extension?.config.shopUrl.startsWith(
                                                "http://"
                                            ) &&
                                            !extension?.config.shopUrl.startsWith(
                                                "https://"
                                            )
                                        ) {
                                            return toast.error(
                                                "Shop URL should start with http:// or https://"
                                            );
                                        }

                                        setFieldValue(formField, [
                                            ...skillsValue.filter(
                                                (_) => _.name !== "shopify"
                                            ),
                                            extension,
                                        ]);

                                        let groupsToBeAdded = [];
                                        let groupsToBeRemoved = [];

                                        if (
                                            extension?.config
                                                .enableAbandonedCartTracking
                                        ) {
                                            if (
                                                followUpGroupsValue.filter(
                                                    (_) =>
                                                        _.name ===
                                                            "Abandoned Cart" &&
                                                        _.category === "Shopify"
                                                ).length === 0
                                            ) {
                                                groupsToBeAdded.push({
                                                    identifier:
                                                        crypto.randomUUID(),
                                                    name: "Abandoned Cart",
                                                    category: "Shopify",
                                                    steps: [],
                                                });
                                            }
                                        } else {
                                            groupsToBeRemoved.push(
                                                "Abandoned Cart"
                                            );
                                        }

                                        if (
                                            extension?.config
                                                .enableOrderTracking
                                        ) {
                                            if (
                                                followUpGroupsValue.filter(
                                                    (_) =>
                                                        _.name ===
                                                            "Order Created" &&
                                                        _.category === "Shopify"
                                                ).length === 0
                                            ) {
                                                groupsToBeAdded.push({
                                                    identifier:
                                                        crypto.randomUUID(),
                                                    name: "Order Created",
                                                    category: "Shopify",
                                                    steps: [],
                                                });
                                            }
                                        } else {
                                            groupsToBeRemoved.push(
                                                "Order Created"
                                            );
                                        }

                                        if (
                                            extension?.config
                                                .enableDeliveryTracking
                                        ) {
                                            const deliveryStatusGroups = [
                                                "Order In Transit",
                                                "Order Out for Delivery",
                                                "Order Delivered",
                                            ];

                                            for (const deliveryStatusGroup of deliveryStatusGroups) {
                                                if (
                                                    followUpGroupsValue.filter(
                                                        (_) =>
                                                            _.name ===
                                                                deliveryStatusGroup &&
                                                            _.category ===
                                                                "Shopify"
                                                    ).length === 0
                                                ) {
                                                    groupsToBeAdded.push({
                                                        identifier:
                                                            crypto.randomUUID(),
                                                        name: deliveryStatusGroup,
                                                        category: "Shopify",
                                                        steps: [],
                                                    });
                                                }
                                            }
                                        } else {
                                            const deliveryStatusGroups = [
                                                "Order In Transit",
                                                "Order Out for Delivery",
                                                "Order Delivered",
                                            ];

                                            groupsToBeRemoved.push(
                                                ...deliveryStatusGroups
                                            );
                                        }

                                        // There is a weird issue with Formik field update, looks like it can't be updated in if statement, it only works in top level, so this is a workaround for that.
                                        setFieldValue(
                                            conversationStepGroupsFormField,
                                            [
                                                ...conversationStepGroupsValue,
                                                ...groupsToBeAdded,
                                            ].filter(
                                                (_) =>
                                                    !groupsToBeRemoved.includes(
                                                        _.name
                                                    )
                                            )
                                        );

                                        setFieldValue(
                                            followUpGroupsFormField,
                                            [
                                                ...followUpGroupsValue,
                                                ...groupsToBeAdded,
                                            ].filter(
                                                (_) =>
                                                    !groupsToBeRemoved.includes(
                                                        _.name
                                                    )
                                            )
                                        );

                                        toast.success(
                                            "Shopify skill configured."
                                        );
                                        toggleSettingsModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </SoftBox>
                </Box>
            </Modal>
        </>
    );
}

export default ShopifySkill;
