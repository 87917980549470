import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useMemo } from "react";

function RegularStep({
    key,
    index,
    step,
    onChange,
    onDelete,
    voiceNotes = [],
    conversationStepGroups = [],
    followUpGroups = [],
    isNested,
}) {
    const predefinedSteps = useMemo(
        () => ({
            custom:
                step?.type === "conditional"
                    ? step?.step?.content
                    : step?.content,
            voice_note: `Send a Voice Note: ${
                voiceNotes.find(
                    ({ id }) =>
                        id ===
                        (step?.type === "voice_note"
                            ? step?.content
                            : step?.step?.content)
                )?.title
            }`,
            save_data: `Save the following data: ${
                step?.type === "conditional"
                    ? step?.step?.keys?.join(", ")
                    : step?.keys?.join(", ")
            }`,
            change_conversation_step_group: `Change Conversation Group to: ${
                conversationStepGroups.find(
                    ({ identifier }) =>
                        identifier ===
                        (step?.type === "conditional"
                            ? step?.step?.content
                            : step?.content)
                )?.name
            } (${
                conversationStepGroups.find(
                    ({ identifier }) =>
                        identifier ===
                        (step?.type === "conditional"
                            ? step?.step?.content
                            : step?.content)
                )?.steps?.length
            } step/s)`,
            change_follow_up_group: `Change Follow-Up Group to: ${
                followUpGroups.find(
                    ({ identifier }) =>
                        identifier ===
                        (step?.type === "conditional"
                            ? step?.step?.content
                            : step?.content)
                )?.name
            } (${
                followUpGroups.find(
                    ({ identifier }) =>
                        identifier ===
                        (step?.type === "conditional"
                            ? step?.step?.content
                            : step?.content)
                )?.steps?.length
            } step/s)`,
            book_appointment: "Confirm and Book Appointment",
            turn_off_assistant: "Turn Off Assistant",
            cancel_follow_ups: "Cancel existing follow ups",
        }),
        [step]
    );

    return (
        <SoftBox
            key={key}
            sx={{
                border: "1px solid #E0E0E0",
                px: 1.5,
                py: 1.5,
                borderRadius: "0.375rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
                background: "white",
                cursor: "grab",
                ml: isNested ? 5 : 0,
            }}
            className={isNested ? "children-drag-handle" : "drag-handle"}
        >
            <SoftBox
                sx={{
                    display: "inline-flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                }}
            >
                <SoftTypography variant="body2" color="black">
                    {index + 1}.
                </SoftTypography>
                <SoftTypography variant="body2" color="text">
                    {predefinedSteps[step?.type]}
                </SoftTypography>
            </SoftBox>
            {![
                "book_appointment",
                "turn_off_assistant",
                "cancel_follow_ups",
            ].includes(step?.type) && (
                <EditIcon
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        ml: 1.5,
                    }}
                    onClick={onChange}
                />
            )}
            <CloseIcon
                fontSize="medium"
                sx={{
                    cursor: "pointer",
                    ml: 1.5,
                }}
                onClick={onDelete}
            />
        </SoftBox>
    );
}

export default RegularStep;
