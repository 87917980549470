import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        relayServerPoolId,
        name,
        business,
        defaultAssistant,
        userSetting,
    },
} = checkout;

const validations = [
    Yup.object().shape({
        [relayServerPoolId.name]: Yup.string().required(
            relayServerPoolId.errorMsg
        ),
        [name.name]: Yup.string().required(name.errorMsg),
        [defaultAssistant.name]: Yup.string(),
        [business.name]: Yup.string(),
        [userSetting.name]: Yup.string().required(userSetting.errorMsg),
    }),
];

export default validations;
