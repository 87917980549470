/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
    const { palette, boxShadows, transitions, breakpoints, functions } = theme;
    const { transparentSidenav, miniSidenav } = ownerState;

    const sidebarWidth = 250;
    const { white, transparent } = palette;
    const { xxl } = boxShadows;
    const { pxToRem } = functions;

    // styles for the sidenav when miniSidenav={false}
    const drawerOpenStyles = () => ({
        transform: "translateX(0)",
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            backgroundColor: transparentSidenav ? transparent.main : white.main,
            height: "100%",
            borderRadius: "0",
            boxShadow: transparentSidenav ? "none" : xxl,
            marginBottom: transparentSidenav ? 0 : "inherit",
            left: "0",
            width: sidebarWidth,
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
        },
    });

    // styles for the sidenav when miniSidenav={true}
    const drawerCloseStyles = () => ({
        transform: `translateX(${pxToRem(-320)})`,
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            backgroundColor: transparentSidenav ? transparent.main : white.main,
            height: "100%",
            boxShadow: transparentSidenav ? "none" : xxl,
            marginBottom: transparentSidenav ? 0 : "inherit",
            left: "0",
            width: pxToRem(96),
            overflowX: "hidden",
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.shorter,
            }),
        },
    });

    return {
        "& .MuiDrawer-paper": {
            boxShadow: xxl,
            border: "none",
            zIndex: 10000,

            [breakpoints.up("lg")]: {
                zIndex: 1,
            },

            ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
        },
    };
});
