/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import AccountBox from "@mui/icons-material/AccountBoxOutlined";
import PrivacyPolicy from "layouts/pages/privacy-policy";
import TermsConditions from "layouts/pages/terms-conditions";

// Soft UI Dashboard PRO React icons
import AlarmIcon from "@mui/icons-material/Alarm";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActiveOutlined";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswerOutlined";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunchOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import Document from "examples/Icons/Document";

// Pages
import { Conversations } from "layouts/dashboards/conversations/Conversations";
import AssistantsPage from "pages/Assistants/Assistants";
import CreateAssistantPage from "pages/Assistants/CreateAssistant";
import UpdateAssistantPage from "pages/Assistants/UpdateAssistant";
import SignInPage from "pages/Authentication";
import ForgotPasswordPage from "pages/Authentication/ForgotPassword";
import ResetPasswordPage from "pages/Authentication/ResetPassword";
import BusinessesPage from "pages/Bussinesses";
import CreateBusinessPage from "pages/Bussinesses/CreateBusiness";
import UpdateBusinessPage from "pages/Bussinesses/UpdateBusiness";
import ContactsPage from "pages/Contacts";
import ContactsProfilePage from "pages/Contacts/ContactProfile";
import DashboardPage from "pages/Dashboard";
import FollowUpsPage from "pages/FollowUps";
import NumberGroupsPage from "pages/NumberGroups";
import NumbersPage from "pages/Numbers";
import ScheduledMessagesPage from "pages/ScheduledMessages";
import SettingsPage from "pages/Settings";
import ShopifyCallbackPage from "pages/Shopify";

const routes = [
    { type: "title", title: "OVERVIEW", key: "title-pages" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <DashboardIcon />,
        noCollapse: true,
        route: "/dashboard",
        component: <DashboardPage />,
        // collapse: [
        //   {
        //     name: "Default",
        //     key: "default",
        //     route: "/dashboards/default",
        //     component: <Default />,
        //   },
        //   {
        //     name: "Automotive",
        //     key: "automotive",
        //     route: "/dashboards/automotive",
        //     component: <Automotive />,
        //   },
        //   {
        //     name: "Smart Home",
        //     key: "smart-home",
        //     route: "/dashboards/smart-home",
        //     component: <SmartHome />,
        //   },
        //   {
        //     name: "Virtual Reality",
        //     key: "virtual-reality",
        //     collapse: [
        //       {
        //         name: "VR Default",
        //         key: "vr-default",
        //         route: "/dashboards/virtual-reality/default",
        //         component: <VRDefault />,
        //       },
        //       {
        //         name: "VR Info",
        //         key: "vr-info",
        //         route: "/dashboards/virtual-reality/info",
        //         component: <VRInfo />,
        //       },
        //     ],
        //   },
        //   { name: "CRM", key: "crm", route: "/dashboards/crm", component: <CRM /> },
        // ],
    },
    {
        type: "collapse",
        name: "Conversations",
        key: "conversations",
        route: "/conversations",
        icon: <QuestionAnswerIcon />,
    },
    {
        name: "Business Conversations",
        key: "conversations",
        noCollapse: true,
        route: "/conversations/:businessId",
        component: <Conversations />,
    },
    {
        type: "collapse",
        name: "Follow Ups",
        key: "follow-ups",
        icon: <NotificationsActiveIcon />,
        noCollapse: true,
        route: "/follow-ups",
        component: <FollowUpsPage />,
    },
    {
        type: "collapse",
        name: "Scheduled Messages",
        key: "scheduled-messages",
        icon: <AlarmIcon />,
        noCollapse: true,
        route: "/scheduled-messages",
        component: <ScheduledMessagesPage />,
    },
    {
        type: "collapse",
        name: "Businesses",
        key: "businesses",
        route: "/businesses",
        noCollapse: true,
        icon: <BusinessIcon />,
        component: <BusinessesPage />,
    },
    // {
    //     type: "collapse",
    //     name: "Businesses",
    //     key: "businesses",
    //     route: "/businesses",
    //     icon: <BusinessIcon />,
    //     collapse: [
    //         {
    //             name: "Businesses",
    //             key: "businesses",
    //             route: "/businesses",
    //             component: <BusinessesPage />,
    //         },
    //         {
    //             name: "Create Business",
    //             key: "create-business",
    //             route: "/businesses/create",
    //             component: <CreateBusinessPage />,
    //         },
    //     ],
    // },
    {
        name: "Create Business",
        key: "create-business",
        noCollapse: true,
        route: "/businesses/create",
        component: <CreateBusinessPage />,
    },
    {
        name: "Update Business",
        key: "update-business",
        noCollapse: true,
        route: "/businesses/:businessId",
        component: <UpdateBusinessPage />,
    },
    {
        type: "collapse",
        name: "Numbers",
        key: "numbers",
        icon: <PhoneIcon />,
        noCollapse: true,
        route: "/numbers",
        component: <NumbersPage />,
    },
    {
        type: "collapse",
        name: "Number Groups",
        key: "number-groups",
        icon: <PhoneForwardedIcon />,
        noCollapse: true,
        route: "/number-groups",
        component: <NumberGroupsPage />,
    },
    // {
    //     type: "collapse",
    //     name: "Assistants",
    //     key: "assistants",
    //     route: "/assistants",
    //     icon: <SpaceShip />,
    //     noCollapse: true,
    //     component: <AssistantsPage />,
    // },
    {
        type: "collapse",
        name: "Assistants",
        key: "assistants",
        route: "/assistants",
        noCollapse: true,
        icon: <RocketLaunchIcon />,
        collapse: [
            {
                name: "Assistants",
                key: "assistants",
                route: "/assistants",
                component: <AssistantsPage />,
            },
            {
                name: "Create Assistant",
                key: "create-assistant",
                route: "/assistants/create",
                component: <CreateAssistantPage />,
            },
            {
                name: "Update Assistant",
                key: "update-assistant",
                noCollapse: true,
                route: "/assistants/:assistantId",
                component: <UpdateAssistantPage />,
            },
        ],
    },
    {
        name: "Shopify Callback",
        key: "shopify-callback",
        route: "/shopify/callback",
        component: <ShopifyCallbackPage />,
    },
    // {
    //     name: "Create Assistant",
    //     key: "create-assistant",
    //     noCollapse: true,
    //     route: "/assistants/:assistantId",
    //     component: <CreateAssistantPage />,
    // },
    // {
    //     name: "Update Assistant",
    //     key: "update-assistant",
    //     noCollapse: true,
    //     route: "/assistants/:assistantId",
    //     component: <UpdateAssistantPage />,
    // },
    // {
    //     type: "collapse",
    //     name: "Wizard",
    //     key: "pages",
    //     icon: <Office />,
    //     noCollapse: true,
    //     route: "/assistant-wizard",
    //     component: <NewUser />,
    //     // collapse: [
    //     //   {
    //     //     name: "Profile",
    //     //     key: "profile",
    //     //     collapse: [
    //     //       {
    //     //         name: "Profile Overview",
    //     //         key: "profile-overview",
    //     //         route: "/pages/profile/profile-overview",
    //     //         component: <ProfileOverview />,
    //     //       },
    //     //       {
    //     //         name: "Teams",
    //     //         key: "teams",
    //     //         route: "/pages/profile/teams",
    //     //         component: <Teams />,
    //     //       },
    //     //       {
    //     //         name: "All Projects",
    //     //         key: "all-projects",
    //     //         route: "/pages/profile/all-projects",
    //     //         component: <AllProjects />,
    //     //       },
    //     //     ],
    //     //   },
    //     //   {
    //     //     name: "Users",
    //     //     key: "users",
    //     //     collapse: [
    //     //       {
    //     //         name: "Reports",
    //     //         key: "reports",
    //     //         route: "/pages/users/reports",
    //     //         component: <Reports />,
    //     //       },
    //     //       {
    //     //         name: "New User",
    //     //         key: "new-user",
    //     //         route: "/pages/users/new-user",
    //     //         component: <NewUser />,
    //     //       },
    //     //     ],
    //     //   },
    //     //   {
    //     //     name: "Account",
    //     //     key: "account",
    //     //     collapse: [
    //     //       {
    //     //         name: "Settings",
    //     //         key: "settings",
    //     //         route: "/pages/account/settings",
    //     //         component: <Settings />,
    //     //       },
    //     //       {
    //     //         name: "Billing",
    //     //         key: "billing",
    //     //         route: "/pages/account/billing",
    //     //         component: <Billing />,
    //     //       },
    //     //       {
    //     //         name: "Invoice",
    //     //         key: "invoice",
    //     //         route: "/pages/account/invoice",
    //     //         component: <Invoice />,
    //     //       },
    //     //       {
    //     //         name: "Security",
    //     //         key: "security",
    //     //         route: "/pages/account/security",
    //     //         component: <Security />,
    //     //       },
    //     //     ],
    //     //   },
    //     //   {
    //     //     name: "Projects",
    //     //     key: "projects",
    //     //     collapse: [
    //     //       {
    //     //         name: "General",
    //     //         key: "general",
    //     //         route: "/pages/projects/general",
    //     //         component: <General />,
    //     //       },
    //     //       {
    //     //         name: "Timeline",
    //     //         key: "timeline",
    //     //         route: "/pages/projects/timeline",
    //     //         component: <Timeline />,
    //     //       },
    //     //       {
    //     //         name: "New Project",
    //     //         key: "new-project",
    //     //         route: "/pages/projects/new-project",
    //     //         component: <NewProject />,
    //     //       },
    //     //     ],
    //     //   },
    //     //   {
    //     //     name: "Pricing Page",
    //     //     key: "pricing-page",
    //     //     route: "/pages/pricing-page",
    //     //     component: <PricingPage />,
    //     //   },
    //     //   { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
    //     //   { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
    //     //   { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
    //     //   {
    //     //     name: "Sweet Alerts",
    //     //     key: "sweet-alerts",
    //     //     route: "/pages/sweet-alerts",
    //     //     component: <SweetAlerts />,
    //     //   },
    //     //   {
    //     //     name: "Notfications",
    //     //     key: "notifications",
    //     //     route: "/pages/notifications",
    //     //     component: <Notifications />,
    //     //   },
    //     // ],
    // },
    {
        type: "collapse",
        name: "Contacts",
        key: "contacts",
        icon: <AccountBox />,
        noCollapse: true,
        route: "/contacts",
        component: <ContactsPage />,
    },
    {
        name: "Contact Profile",
        key: "contact-profile",
        noCollapse: true,
        route: "/contacts/:contactId",
        component: <ContactsProfilePage />,
    },
    {
        type: "collapse",
        name: "Settings",
        key: "settings",
        icon: <SettingsIcon />,
        noCollapse: true,
        route: "/settings",
        component: <SettingsPage />,
    },
    {
        name: "Privacy Policy",
        key: "privacy-policy",
        noCollapse: true,
        route: "/privacy-policy",
        component: <PrivacyPolicy />,
    },
    {
        name: "Terms Conditions",
        key: "terms-conditions",
        noCollapse: true,
        route: "/terms-conditions",
        component: <TermsConditions />,
    },
    // {
    //   type: "collapse",
    //   name: "Applications",
    //   key: "applications",
    //   icon: <SettingsIcon />,
    //   collapse: [
    //     {
    //       name: "Kanban",
    //       key: "kanban",
    //       route: "/applications/kanban",
    //       component: <Kanban />,
    //     },
    //     {
    //       name: "Wizard",
    //       key: "wizard",
    //       route: "/applications/wizard",
    //       component: <Wizard />,
    //     },
    //     {
    //       name: "Data Tables",
    //       key: "data-tables",
    //       route: "/applications/data-tables",
    //       component: <DataTables />,
    //     },
    //     {
    //       name: "Calendar",
    //       key: "calendar",
    //       route: "/applications/calendar",
    //       component: <Calendar />,
    //     },
    //     {
    //       name: "Analytics",
    //       key: "analytics",
    //       route: "/applications/analytics",
    //       component: <Analytics />,
    //     },
    //   ],
    // },
    // {
    //   type: "collapse",
    //   name: "Ecommerce",
    //   key: "ecommerce",
    //   icon: <Basket size="12px" />,
    //   collapse: [
    //     {
    //       name: "Overview",
    //       key: "overview",
    //       route: "/ecommerce/overview",
    //       component: <Overview />,
    //     },
    //     {
    //       name: "Products",
    //       key: "products",
    //       collapse: [
    //         {
    //           name: "New Product",
    //           key: "new-product",
    //           route: "/ecommerce/products/new-product",
    //           component: <NewProduct />,
    //         },
    //         {
    //           name: "Edit Product",
    //           key: "edit-product",
    //           route: "/ecommerce/products/edit-product",
    //           component: <EditProduct />,
    //         },
    //         {
    //           name: "Product Page",
    //           key: "product-page",
    //           route: "/ecommerce/products/product-page",
    //           component: <ProductPage />,
    //         },
    //         {
    //           name: "Products List",
    //           key: "products-list",
    //           route: "/ecommerce/products/products-list",
    //           component: <ProductsList />,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Orders",
    //       key: "orders",
    //       collapse: [
    //         {
    //           name: "Order List",
    //           key: "order-list",
    //           route: "/ecommerce/orders/order-list",
    //           component: <OrderList />,
    //         },
    //         {
    //           name: "Order Details",
    //           key: "order-details",
    //           route: "/ecommerce/orders/order-details",
    //           component: <OrderDetails />,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Referral",
    //       key: "referral",
    //       route: "/ecommerce/referral",
    //       component: <Referral />,
    //     },
    //   ],
    // },
    {
        type: "collapse",
        name: "Authentication",
        key: "authentication",
        icon: <Document size="12px" />,
        collapse: [
            {
                name: "Sign In",
                key: "sign-in",
                collapse: [
                    // {
                    //   name: "Basic",
                    //   key: "basic",
                    //   route: "/authentication/sign-in/basic",
                    //   component: <SignInBasic />,
                    // },
                    // {
                    //   name: "Cover",
                    //   key: "cover",
                    //   route: "/authentication/sign-in/cover",
                    //   component: <SignInCover />,
                    // },
                    {
                        name: "Sign In",
                        key: "sign-in",
                        route: "/sign-in",
                        component: <SignInPage />,
                    },
                    {
                        name: "Forgot Password",
                        key: "forgot-password",
                        route: "/forgot-password",
                        component: <ForgotPasswordPage />,
                    },
                    {
                        name: "Reset Password",
                        key: "reset-password",
                        route: "/reset-password",
                        component: <ResetPasswordPage />,
                    },
                ],
            },
            // {
            //   name: "Sign Up",
            //   key: "sign-up",
            //   collapse: [
            //     {
            //       name: "Basic",
            //       key: "basic",
            //       route: "/authentication/sign-up/basic",
            //       component: <SignUpBasic />,
            //     },
            //     {
            //       name: "Cover",
            //       key: "cover",
            //       route: "/authentication/sign-up/cover",
            //       component: <SignUpCover />,
            //     },
            //     {
            //       name: "Illustration",
            //       key: "illustration",
            //       route: "/authentication/sign-up/illustration",
            //       component: <SignUpIllustration />,
            //     },
            //   ],
            // },
            // {
            //   name: "Reset Password",
            //   key: "reset-password",
            //   collapse: [
            //     {
            //       name: "Basic",
            //       key: "basic",
            //       route: "/authentication/reset-password/basic",
            //       component: <ResetBasic />,
            //     },
            //     {
            //       name: "Cover",
            //       key: "cover",
            //       route: "/authentication/reset-password/cover",
            //       component: <ResetCover />,
            //     },
            //     {
            //       name: "Illustration",
            //       key: "illustration",
            //       route: "/authentication/reset-password/illustration",
            //       component: <ResetIllustration />,
            //     },
            //   ],
            // },
            // {
            //   name: "Lock",
            //   key: "lock",
            //   collapse: [
            //     {
            //       name: "Basic",
            //       key: "basic",
            //       route: "/authentication/lock/basic",
            //       component: <LockBasic />,
            //     },
            //     {
            //       name: "Cover",
            //       key: "cover",
            //       route: "/authentication/lock/cover",
            //       component: <LockCover />,
            //     },
            //     {
            //       name: "Illustration",
            //       key: "illustration",
            //       route: "/authentication/lock/illustration",
            //       component: <LockIllustration />,
            //     },
            //   ],
            // },
            // {
            //   name: "2-Step Verification",
            //   key: "2-step-verification",
            //   collapse: [
            //     {
            //       name: "Basic",
            //       key: "basic",
            //       route: "/authentication/verification/basic",
            //       component: <VerificationBasic />,
            //     },
            //     {
            //       name: "Cover",
            //       key: "cover",
            //       route: "/authentication/verification/cover",
            //       component: <VerificationCover />,
            //     },
            //     {
            //       name: "Illustration",
            //       key: "illustration",
            //       route: "/authentication/verification/illustration",
            //       component: <VerificationIllustration />,
            //     },
            //   ],
            // },
            // {
            //   name: "Error",
            //   key: "error",
            //   collapse: [
            //     {
            //       name: "Error 404",
            //       key: "error-404",
            //       route: "/authentication/error/404",
            //       component: <Error404 />,
            //     },
            //     {
            //       name: "Error 500",
            //       key: "error-500",
            //       route: "/authentication/error/500",
            //       component: <Error500 />,
            //     },
            //   ],
            // },
        ],
    },
    // { type: "divider", key: "divider-1" },
    // { type: "title", title: "Docs", key: "title-docs" },
    // {
    //   type: "collapse",
    //   name: "Basic",
    //   key: "basic",
    //   icon: <SpaceShip size="12px" />,
    //   collapse: [
    //     {
    //       name: "Getting Started",
    //       key: "getting-started",
    //       collapse: [
    //         {
    //           name: "Overview",
    //           key: "overview",
    //           href: "https://www.creative-tim.com/learning-lab/react/overview/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "License",
    //           key: "license",
    //           href: "https://www.creative-tim.com/learning-lab/react/license/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Quick Start",
    //           key: "quick-start",
    //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Build Tools",
    //           key: "build-tools",
    //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/soft-ui-dashboard/",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Foundation",
    //       key: "foundation",
    //       collapse: [
    //         {
    //           name: "Colors",
    //           key: "colors",
    //           href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Grid",
    //           key: "grid",
    //           href: "https://www.creative-tim.com/learning-lab/react/grid/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Typography",
    //           key: "base-typography",
    //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Borders",
    //           key: "borders",
    //           href: "https://www.creative-tim.com/learning-lab/react/borders/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Box Shadows",
    //           key: "box-shadows",
    //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Functions",
    //           key: "functions",
    //           href: "https://www.creative-tim.com/learning-lab/react/functions/soft-ui-dashboard/",
    //         },
    //         {
    //           name: "Routing System",
    //           key: "routing-system",
    //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/soft-ui-dashboard/",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   type: "collapse",
    //   name: "Components",
    //   key: "components",
    //   icon: <CustomerSupport size="12px" />,
    //   collapse: [
    //     {
    //       name: "Alerts",
    //       key: "alerts",
    //       href: "https://www.creative-tim.com/learning-lab/react/alerts/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Avatar",
    //       key: "avatar",
    //       href: "https://www.creative-tim.com/learning-lab/react/avatar/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Badge",
    //       key: "badge",
    //       href: "https://www.creative-tim.com/learning-lab/react/badge/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Badge Dot",
    //       key: "badge-dot",
    //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Box",
    //       key: "box",
    //       href: "https://www.creative-tim.com/learning-lab/react/box/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Buttons",
    //       key: "buttons",
    //       href: "https://www.creative-tim.com/learning-lab/react/buttons/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Date Picker",
    //       key: "date-picker",
    //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Dropzone",
    //       key: "dropzone",
    //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Editor",
    //       key: "editor",
    //       href: "https://www.creative-tim.com/learning-lab/react/quill/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Input",
    //       key: "input",
    //       href: "https://www.creative-tim.com/learning-lab/react/input/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Pagination",
    //       key: "pagination",
    //       href: "https://www.creative-tim.com/learning-lab/react/pagination/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Progress",
    //       key: "progress",
    //       href: "https://www.creative-tim.com/learning-lab/react/progress/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Select",
    //       key: "select",
    //       href: "https://www.creative-tim.com/learning-lab/react/select/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Snackbar",
    //       key: "snackbar",
    //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Social Button",
    //       key: "social-button",
    //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Tag Input",
    //       key: "tag-input",
    //       href: "https://www.creative-tim.com/learning-lab/react/tag-input/soft-ui-dashboard/",
    //     },
    //     {
    //       name: "Typography",
    //       key: "typography",
    //       href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //     },
    //   ],
    // },
    // {
    //   type: "collapse",
    //   name: "Change Log",
    //   key: "changelog",
    //   href: "https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro-react/blob/main/CHANGELOG.md",
    //   icon: <CreditCard size="12px" />,
    //   noCollapse: true,
    // },
];

export default routes;
