const { apiAxios } = require("common/services/httpService");

export const adminGetPhoneNumberGroup = (phoneNumberGroupId, params) => {
    return apiAxios.get(`/admin/phone_number_group/${phoneNumberGroupId}`, {
        params,
    });
};

export const adminUpdatePhoneNumberGroup = (phoneNumberGroupId, params) => {
    return apiAxios.patch(
        `/admin/phone_number_group/${phoneNumberGroupId}`,
        params
    );
};
