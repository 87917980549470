/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import List from "@mui/material/List";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavList from "examples/Sidenav/SidenavList";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Soft UI Dashboard PRO React context
import { setMiniSidenav, useSoftUIController } from "context";
import { Conversations } from "layouts/dashboards/conversations/Conversations";
import { useDispatch } from "react-redux";
import { appActions } from "../../redux/appSlice";
import useApp from "../../redux/hooks/useApp";

function Sidenav({ color, brand, brandName, routes: _routes, ...rest }) {
    const [routes, setRoutes] = useState(_routes);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const itemName = pathname.split("/").slice(1)[1];

    const { businesses } = useApp();
    const reduxDispatch = useDispatch();

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse) => {
        const template = collapse.map(({ name, route, key, href }) =>
            href ? (
                <Link
                    key={key}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none" }}
                >
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
                    <SidenavItem
                        name={name}
                        active={route === pathname}
                        nested
                    />
                </NavLink>
            )
        );

        return template;
    };

    const conversationsCollapse = useMemo(() => {
        return businesses.map((business) => {
            return {
                name: business.displayName.toUpperCase(),
                key: `/conversations/${business._id}`,
                route: `/conversations/${business._id}`,
                component: <Conversations key={business._id} />,
            };
        });
    }, [businesses]);

    useEffect(() => {
        setRoutes(
            _routes.map((route) => {
                if (route.key === "conversations") {
                    return {
                        ...route,
                        collapse: conversationsCollapse,
                    };
                }
                return route;
            })
        );
    }, [_routes, businesses]);

    // Render the all the collapses from the routes.js
    const renderCollapse = (collapses) => {
        return collapses.map(({ name, collapse, route, href, key }) => {
            let returnValue;

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        name={name}
                        active={key === collapseName}
                        open={openNestedCollapse === name}
                        onClick={() =>
                            openNestedCollapse === name
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(name)
                        }
                    >
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link
                        href={href}
                        key={key}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                    >
                        <SidenavItem name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink
                        to={route}
                        key={key}
                        sx={{ textDecoration: "none" }}
                    >
                        <SidenavItem name={name} active={key === itemName} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });
    };

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = useMemo(() => {
        return routes.map(
            ({
                type,
                name,
                icon,
                title,
                collapse,
                noCollapse,
                key,
                href,
                route,
            }) => {
                let returnValue;

                if (type === "collapse") {
                    if (href) {
                        returnValue = (
                            <Link
                                href={href}
                                key={key}
                                target="_blank"
                                rel="noreferrer"
                                sx={{ textDecoration: "none" }}
                            >
                                <SidenavCollapse
                                    name={name}
                                    icon={icon}
                                    active={key === collapseName}
                                    noCollapse={noCollapse}
                                />
                            </Link>
                        );
                    } else if (noCollapse && route) {
                        returnValue = (
                            <NavLink to={route} key={key}>
                                <SidenavCollapse
                                    name={name}
                                    icon={icon}
                                    noCollapse={noCollapse}
                                    active={key === collapseName}
                                >
                                    {collapse ? renderCollapse(collapse) : null}
                                </SidenavCollapse>
                            </NavLink>
                        );
                    } else {
                        returnValue = (
                            <SidenavCollapse
                                key={key}
                                name={name}
                                icon={icon}
                                active={key === collapseName}
                                open={openCollapse === key}
                                onClick={() => {
                                    if (key === "conversations") {
                                        reduxDispatch(
                                            appActions.fetchBusinesses()
                                        );
                                    }

                                    openCollapse === key
                                        ? setOpenCollapse(false)
                                        : setOpenCollapse(key);
                                }}
                            >
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        );
                    }
                } else if (type === "title") {
                    returnValue = (
                        <SoftTypography
                            key={key}
                            display="block"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="uppercase"
                            opacity={0.6}
                            pl={3}
                            mt={2}
                            mb={1}
                            ml={0}
                        >
                            {title}
                        </SoftTypography>
                    );
                } else if (type === "divider") {
                    returnValue = <Divider key={key} />;
                }

                return returnValue;
            }
        );
    }, [routes]);

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, miniSidenav }}
        >
            <SoftBox
                sx={{
                    height: "100%",
                    borderRight: 1,
                    borderLeft: 0,
                    borderTop: 0,
                    borderBottom: 0,
                    borderColor: "lightgray",
                    borderStyle: "dashed",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                }}
            >
                <SoftBox pt={3} pb={1} px={4} textAlign="center">
                    <SoftBox
                        display={{ xs: "block", xl: "none" }}
                        position="absolute"
                        top={0}
                        right={0}
                        p={1.625}
                        onClick={closeSidenav}
                        sx={{ cursor: "pointer" }}
                    >
                        <SoftTypography variant="h6" color="secondary">
                            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox
                        component={NavLink}
                        to="/dashboard"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {brand && (
                            <SoftBox
                                component="img"
                                src={brand}
                                width={!miniSidenav ? "12rem" : "4rem"}
                                sx={{ transition: ".3s ease-in-out" }}
                                alt="SellBlue Logo"
                            />
                        )}
                        {/* <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium">
              {brandName}2222
            </SoftTypography>
          </SoftBox> */}
                    </SoftBox>
                </SoftBox>
                <Divider />
                <List>{renderRoutes}</List>
                {/* <SoftBox pt={2} my={2} mx={2}> */}
                {/* <SidenavCard /> */}
                {/* </SoftBox> */}
            </SoftBox>
        </SidenavRoot>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
