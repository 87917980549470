import checkout from "./form";

const {
    formField: {
        // Personal Info
        firstName,
        lastName,
        userEmail,
        password,
        isAdministrator,
        preferredTimezone,

        // Extensions
        extensions,
    },
} = checkout;

const initialValues = {
    // Personal Info
    [firstName.name]: "",
    [lastName.name]: "",
    [userEmail.name]: "",
    [password.name]: "",
    [isAdministrator.name]: false,
    [preferredTimezone.name]: undefined,

    // Extensions
    [extensions.name]: [],
};

export default initialValues;
