import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Avatar, Badge, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { ErrorMessage, useFormikContext } from "formik";
import PropTypes from "prop-types";
import FormField from "../FormField";

function BasicInfo({ formData }) {
    const { formField, errors, touched } = formData;
    const {
        displayAvatar,
        displayName,
        displayColor,
        name,
        age,
        gender,
        ethnicity,
        emojiSkinTone,
    } = formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        displayAvatar: displayAvatarValue,
        displayName: displayNameValue,
        displayColor: displayColorValue,
        name: nameValue,
        age: ageValue,
        gender: genderValue,
        ethnicity: ethnicityValue,
        emojiSkinTone: emojiSkinToneValue,
    } = values;

    const genders = [
        {
            label: "Male",
            value: "male",
        },
        {
            label: "Female",
            value: "female",
        },
    ];

    const emojiSkinTones = [
        {
            label: "👋🏻✋🏻🤚🏻🖐🏻", // Light Skin Tone - Waving Hand, Raised Hand, Raised Back of Hand, Hand with Fingers Splayed
            value: "light_skin_tone",
        },
        {
            label: "👋🏼✋🏼🤚🏼🖐🏼", // Medium-Light Skin Tone - Waving Hand, Raised Hand, Raised Back of Hand, Hand with Fingers Splayed
            value: "medium_light_skin_tone",
        },
        {
            label: "👋🏽✋🏽🤚🏽🖐🏽", // Medium Skin Tone - Waving Hand, Raised Hand, Raised Back of Hand, Hand with Fingers Splayed
            value: "medium_skin_tone",
        },
        {
            label: "👋🏾✋🏾🤚🏾🖐🏾", // Medium-Dark Skin Tone - Waving Hand, Raised Hand, Raised Back of Hand, Hand with Fingers Splayed
            value: "medium_dark_skin_tone",
        },
        {
            label: "👋🏿✋🏿🤚🏿🖐🏿", // Dark Skin Tone - Waving Hand, Raised Hand, Raised Back of Hand, Hand with Fingers Splayed
            value: "dark_skin_tone",
        },
    ];

    const handleAssistantAvatarChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setFieldValue(displayAvatar.name, reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Personalization & Basic Info
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Customize your AI assistant's look and feel, and provide key
                    personal details for a tailored experience.
                </SoftTypography>
            </SoftBox>
            <SoftBox py={6}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                py: 3,
                                height: "100%",
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <label htmlFor="assistant_display_avatar">
                                        <PhotoCameraIcon
                                            fontSize="small"
                                            style={{
                                                cursor: "pointer",
                                                color: "white",
                                            }}
                                        />
                                        <input
                                            id="assistant_display_avatar"
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={
                                                handleAssistantAvatarChange
                                            }
                                        />
                                    </label>
                                }
                                sx={{
                                    ".MuiBadge-badge": {
                                        backgroundColor: "#1C4C73",
                                        color: "white",
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "50%",
                                    },
                                }}
                            >
                                <Avatar
                                    src={
                                        displayAvatarValue
                                            ? displayAvatarValue
                                            : undefined
                                    }
                                    sx={{
                                        bgcolor: displayColorValue,
                                        width: 128,
                                        height: 128,
                                        fontSize: "3rem",
                                        objectFit: "cover",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "white",
                                            mixBlendMode: "difference",
                                        }}
                                    >
                                        {(displayNameValue || "A I")
                                            ?.split(" ")
                                            .map((word) =>
                                                word[0]?.toUpperCase()
                                            )
                                            .slice(0, 2)
                                            .join("")}
                                    </span>
                                </Avatar>
                            </Badge>
                            <SoftBox
                                mt={2}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Display Avatar
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormField
                            type={displayName.type}
                            label={displayName.label}
                            name={displayName.name}
                            placeholder={displayName.placeholder}
                            error={errors?.displayName && touched?.displayName}
                            success={
                                displayNameValue.length > 0 &&
                                !errors?.displayName
                            }
                        />
                        <FormField
                            type={displayColor.type}
                            label={displayColor.label}
                            name={displayColor.name}
                            placeholder={displayColor.placeholder}
                            error={
                                errors?.displayColor && touched?.displayColor
                            }
                            success={
                                displayColorValue.length > 0 &&
                                !errors?.displayColor
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            placeholder={name.placeholder}
                            error={errors?.name && touched?.name}
                            success={nameValue.length > 0 && !errors?.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={age.type}
                            label={age.label}
                            name={age.name}
                            placeholder={age.placeholder}
                            error={errors?.age && touched?.age}
                            success={ageValue && !errors?.age}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={ethnicity.type}
                            label={ethnicity.label}
                            name={ethnicity.name}
                            placeholder={ethnicity.placeholder}
                            error={errors?.ethnicity && touched?.ethnicity}
                            success={
                                ethnicityValue.length > 0 && !errors?.ethnicity
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Emoji Skin Tone
                            </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                            options={emojiSkinTones}
                            value={emojiSkinTones.filter(
                                (_) => _.value === emojiSkinToneValue
                            )}
                            onChange={({ value }) => {
                                setFieldValue(emojiSkinTone.name, value);
                            }}
                            error={
                                errors?.emojiSkinTone && touched?.emojiSkinTone
                            }
                            success={
                                emojiSkinToneValue.length > 2 &&
                                !errors?.emojiSkinTone
                            }
                        />
                        <SoftBox mt={0.75}>
                            <SoftTypography
                                component="div"
                                variant="caption"
                                color="error"
                            >
                                <ErrorMessage name={emojiSkinTone.name} />
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Gender
                        </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                        options={genders}
                        value={genders.filter((_) => _.value === genderValue)}
                        onChange={({ value }) => {
                            setFieldValue(gender.name, value);
                        }}
                        error={errors?.gender && touched?.gender}
                        success={genderValue.length > 2 && !errors?.gender}
                    />
                    <SoftBox mt={0.75}>
                        <SoftTypography
                            component="div"
                            variant="caption"
                            color="error"
                        >
                            <ErrorMessage name={gender.name} />
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

BasicInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default BasicInfo;
