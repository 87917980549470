import axios from "axios";
import { toast } from "react-toastify";
import { createSliceWithThunks } from "./createSliceWithThunks";

const initialState = {
    searchQuery: "",
    filters: {
        initialAssistant: "",
        activeAssistant: "",
    },

    businesses: [],
    businessesError: null,
    isBusinessesLoading: false,

    selectedBusinessId: undefined,
    selectedAssistantId: undefined,

    phoneNumbers: [],
    phoneNumbersError: null,
    isPhoneNumbersLoading: false,

    phoneNumberGroups: [],
    phoneNumberGroupsError: null,
    isPhoneNumberGroupsLoading: false,

    conversations: [],
    conversationsPage: null,
    totalConversations: null,
    conversationsError: null,
    isConversationsLoading: false,
    isSelectedConversationLoading: false,

    selectedConversation: null,
    selectedConversationMessages: null,
    selectedConversationOptimisticMessages: null,

    assistants: [],
    assistantsError: null,
    isAssistantsLoading: false,

    followUps: [],
    followUpsError: null,
    isFollowUpsLoading: false,

    scheduledMessages: [],
    scheduledMessagesError: null,
    isScheduledMessagesLoading: false,

    contacts: [],
    contactsError: null,
    isContactsLoading: false,

    notificationPreferences: null,
};

const appSlice = createSliceWithThunks({
    name: "app",
    initialState,
    reducers: (create) => ({
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        clearSelectedConversation: (state) => {
            state.selectedConversation = null;
            state.selectedConversationMessages = null;
        },
        updateConversation: (state, action) => {
            const conversation = action.payload;

            state.conversations = state.conversations.map((_) =>
                _._id === conversation._id ? conversation : _
            );
        },
        appendSelectedConversationMessage: (state, action) => {
            const message = action.payload;

            const indexOfFoundMessage =
                state.selectedConversationMessages.findIndex(
                    (_) => _._id === message._id
                );

            if (indexOfFoundMessage === -1) {
                state.selectedConversationMessages = [
                    ...state.selectedConversationMessages,
                    message,
                ];
            } else {
                state.selectedConversationMessages[indexOfFoundMessage] =
                    message;
            }
        },
        changeSelectedConversationStatus: (state, action) => {
            state.selectedConversation.conversationStatus = action.payload;
        },
        selectBusiness: (state, action) => {
            state.selectedBusinessId = action.payload;
        },
        selectAssistant: (state, action) => {
            state.selectedAssistantId = action.payload;
        },
        fetchBusinesses: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/business`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating businesses:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.businessesError = null;
                    state.isBusinessesLoading = true;
                },
                rejected: (state, action) => {
                    state.businessesError = action.error;
                    state.isBusinessesLoading = false;
                },
                fulfilled: (state, action) => {
                    state.businesses = action.payload;
                    state.isBusinessesLoading = false;
                },
            }
        ),
        fetchPhoneNumbers: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/phone_number${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating phone numbers:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.phoneNumbersError = null;
                    state.isPhoneNumbersLoading = true;
                },
                rejected: (state, action) => {
                    state.phoneNumbersError = action.error;
                    state.isPhoneNumbersLoading = false;
                },
                fulfilled: (state, action) => {
                    state.phoneNumbers = action.payload;
                    state.isPhoneNumbersLoading = false;
                },
            }
        ),
        fetchPhoneNumberGroups: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/phone_number_group${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error populating phone number groups:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.phoneNumberGroupsError = null;
                    state.isPhoneNumberGroupsLoading = true;
                },
                rejected: (state, action) => {
                    state.phoneNumberGroupsError = action.error;
                    state.isPhoneNumberGroupsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.phoneNumberGroups = action.payload;
                    state.isPhoneNumberGroupsLoading = false;
                },
            }
        ),
        fetchConversations: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/conversation?searchQuery=${
                                thunkApi.getState().app.searchQuery
                            }&business=${
                                thunkApi.getState().app.selectedBusinessId || ""
                            }&initialAssistant=${
                                thunkApi.getState().app.filters.initialAssistant
                            }&activeAssistant=${
                                thunkApi.getState().app.filters.activeAssistant
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating conversations:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.conversationsError = null;
                    state.isConversationsLoading = true;
                    state.conversationsPage = 1;
                },
                rejected: (state, action) => {
                    state.conversationsError = action.error;
                    state.isConversationsLoading = false;
                },
                fulfilled: (state, action) => {
                    const { conversations, total } = action.payload;

                    state.conversations = conversations;
                    state.totalConversations = total;
                    state.conversationsPage = 2;
                    state.isConversationsLoading = false;
                },
            }
        ),
        fetchNextPageConversations: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/conversation?page=${
                                thunkApi.getState().app.conversationsPage
                            }&searchQuery=${
                                thunkApi.getState().app.searchQuery
                            }&business=${
                                thunkApi.getState().app.selectedBusinessId
                            }&initialAssistant=${
                                thunkApi.getState().app.filters.initialAssistant
                            }&activeAssistant=${
                                thunkApi.getState().app.filters.activeAssistant
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            `Error fetching ${
                                thunkApi.getState().app.conversationsPage
                            }. page of conversations:`,
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.conversationsError = null;
                    state.isConversationsLoading = true;
                },
                rejected: (state, action) => {
                    state.conversationsError = action.error;
                    state.isConversationsLoading = false;
                },
                fulfilled: (state, action) => {
                    const { conversations, total } = action.payload;

                    state.conversations = [
                        ...state.conversations,
                        ...conversations,
                    ];
                    state.conversationsPage++;
                    state.totalConversations = total;
                    state.isConversationsLoading = false;
                },
            }
        ),
        selectConversation: create.asyncThunk(
            async (conversationId, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/conversation/${conversationId}`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error selecting conversation:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state, action) => {
                    state.selectedConversation = state.conversations.filter(
                        (_) => _._id === action.meta.arg
                    )[0];
                    state.conversationsError = null;
                    state.isSelectedConversationLoading = true;
                },
                rejected: (state, action) => {
                    state.selectedConversation = null;
                    state.conversationsError = action.error;
                    state.isSelectedConversationLoading = false;
                },
                fulfilled: (state, action) => {
                    const { conversation, conversationStatus, messages } =
                        action.payload;

                    state.selectedConversation = {
                        ...conversation,
                        conversationStatus,
                    };
                    state.selectedConversationMessages = messages;
                    state.selectedConversationOptimisticMessages = [];
                    state.isSelectedConversationLoading = false;
                },
            }
        ),
        sendMessageInSelectedConversation: create.asyncThunk(
            async ({ _, dataToBeSent }, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_URL}/message`,
                            dataToBeSent,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error sending message:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state, action) => {
                    const { optimisticMessage } = action.meta.arg;

                    state.selectedConversationOptimisticMessages = [
                        ...state.selectedConversationOptimisticMessages,
                        optimisticMessage,
                    ];
                },
                rejected: (state, action) => {
                    const { optimisticMessage } = action.meta.arg;

                    state.selectedConversationOptimisticMessages =
                        state.selectedConversationOptimisticMessages.filter(
                            (message) => message._id !== optimisticMessage._id
                        );

                    toast.error(action.error.message);
                },
                fulfilled: (state, action) => {
                    const { optimisticMessage } = action.meta.arg;

                    // Here is the message that was returned as response from the server
                    // const { message } = action.payload;

                    state.selectedConversationOptimisticMessages =
                        state.selectedConversationOptimisticMessages.filter(
                            (message) => message._id !== optimisticMessage._id
                        );

                    // Message will be pushed to user's messages array by socket.io
                    // state.selectedConversationMessages = [
                    //     ...state.selectedConversationMessages,
                    //     message,
                    // ];
                },
            }
        ),
        fetchAssistants: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/assistant${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating assistants:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.assistantsError = null;
                    state.isAssistantsLoading = true;
                },
                rejected: (state, action) => {
                    state.assistantsError = action.error;
                    state.isAssistantsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.assistants = action.payload;
                    state.isAssistantsLoading = false;
                },
            }
        ),
        fetchFollowUps: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/follow_up${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating follow ups:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.followUpsError = null;
                    state.isFollowUpsLoading = true;
                },
                rejected: (state, action) => {
                    state.followUpsError = action.error;
                    state.isFollowUpsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.followUps = action.payload;
                    state.isFollowUpsLoading = false;
                },
            }
        ),
        fetchScheduledMessages: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/scheduled_message${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error populating scheduled messages:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.scheduledMessagesError = null;
                    state.isScheduledMessagesLoading = true;
                },
                rejected: (state, action) => {
                    state.scheduledMessagesError = action.error;
                    state.isScheduledMessagesLoading = false;
                },
                fulfilled: (state, action) => {
                    state.scheduledMessages = action.payload;
                    state.isScheduledMessagesLoading = false;
                },
            }
        ),
        fetchContacts: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/contact${
                                thunkApi.getState().app.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().app
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error fetching contacts:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.contactsError = null;
                    state.isContactsLoading = true;
                },
                rejected: (state, action) => {
                    state.contactsError = action.error;
                    state.isContactsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.contacts = action.payload;
                    state.isContactsLoading = false;
                },
            }
        ),
        fetchNotificationPreferences: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/notification_preferences`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error fetching notification preferences:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.notificationPreferences = null;
                },
                rejected: (state, action) => {
                    state.notificationPreferences = null;
                },
                fulfilled: (state, action) => {
                    state.notificationPreferences = action.payload;
                },
            }
        ),
        updateNotificationPreferences: create.asyncThunk(
            async (params, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.patch(
                            `${process.env.REACT_APP_API_URL}/notification_preferences`,
                            params,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error updating notification preferences:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {},
                rejected: (state, action) => {
                    console.log(action.error);

                    toast.error(action.error);
                },
                fulfilled: (state, action) => {
                    state.notificationPreferences = action.payload;

                    toast.success(
                        "Notification preferences updated successfully."
                    );
                },
            }
        ),
        toggleNotificationPreferencesForConversation: create.asyncThunk(
            async (conversationId, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_URL}/notification_preferences/${conversationId}/toggle`,
                            {},
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error toggling notification preferences:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {},
                rejected: (state, action) => {
                    console.log(action.error);

                    toast.error(action.error);
                },
                fulfilled: (state, action) => {
                    state.notificationPreferences = action.payload;

                    toast.success(
                        "Notification preferences updated successfully."
                    );
                },
            }
        ),
    }),
});

export const appActions = {
    ...appSlice.actions,
};

export default appSlice.reducer;
