const form = {
    formId: "create-business",
    formField: {
        name: {
            name: "name",
            label: "Business Name",
            type: "text",
            placeholder: "Enter Business Name",
            errorMsg: "Business Name is required.",
        },
        displayName: {
            name: "displayName",
            label: "Display Name",
            type: "text",
            placeholder: "Enter Display Name",
            errorMsg: "Display Name is required.",
        },
        state: {
            name: "state",
            label: "State",
            type: "text",
            placeholder: "Enter State",
            errorMsg: "State is required.",
        },
        city: {
            name: "city",
            label: "City",
            type: "text",
            placeholder: "Enter City",
            errorMsg: "City is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "Enter Description",
            errorMsg: "Description is required.",
        },
        businessHoursEnabled: {
            name: "businessHoursEnabled",
            label: "Enable Business Hours",
            type: "checkbox",
        },
        businessHoursStartTime: {
            name: "businessHoursStartTime",
            label: "Start Time",
            type: "number",
            placeholder: "Enter Start Time",
            errorMsg: "Start Time is required.",
        },
        businessHoursEndTime: {
            name: "businessHoursEndTime",
            label: "End Time",
            type: "number",
            placeholder: "Enter End Time",
            errorMsg: "End Time is required.",
        },
        teamMembers: {
            name: "teamMembers",
            label: "Team Members",
            type: "text",
            placeholder: "Enter Team Members",
        },
        phoneNumbers: {
            name: "phoneNumbers",
            label: "Phone Numbers",
            type: "text",
            placeholder: "Enter Phone Numbers",
        },
        userId: {
            name: "userId",
            label: "User Id",
            type: "text",
            placeholder: "Enter User Id",
            errorMsg: "User Id is required.",
        },
    },
};

export default form;
