const { apiAxios } = require("common/services/httpService");

export const getConversations = () => {
    return apiAxios.get("conversation/list");
};

export const createConversation = (params) => {
    return apiAxios.post("conversation", params);
};

export const changeAssistantOfConversation = (id, params) => {
    return apiAxios.put(`conversation/${id}/set-assistant`, params);
};

export const retryConversation = (id) => {
    return apiAxios.post(`conversation/${id}/retry`);
};

export const deleteConversation = (id) => {
    return apiAxios.delete(`conversation/${id}`);
};

export const permanentlyDeleteConversation = (id) => {
    return apiAxios.delete(`conversation/${id}/permanently`);
};

export const addMessage = (params) => {
    return apiAxios.post("message", params);
};
