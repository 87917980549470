import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import AttemptsCell from "./AttemptsCell";
import StatusCell from "./StatusCell";

export const followUpsColumns = [
    {
        Header: "RECIPIENT",
        accessor: "recipient",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/contacts/${data.contact._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "businessName",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBadge
                variant="gradient"
                size="md"
                color="light"
                badgeContent={name}
                container
                border
                circular
            />
        ),
    },
    {
        Header: "ATTEMPTS",
        accessor: "attempts",
        Cell: ({ value: [data] }) => <AttemptsCell followUp={data} />,
    },
    {
        Header: "CURRENT STEP",
        accessor: "currentFollowUpStep",
        Cell: ({ value: [name] }) => (
            <SoftBox
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {name}
            </SoftBox>
        ),
    },
    {
        Header: "STATUS",
        accessor: "status",
        width: "15%",
        Cell: ({ value: [data] }) => <StatusCell followUp={data} />,
    },
    {
        Header: "LAST SENT AT",
        accessor: "lastFollowUpSentAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(followUps) {
    const formattedPhoneNumber = (phone) => {
        try {
            return parsePhoneNumber(phone).formatNational();
        } catch (error) {
            return phone;
        }
    };

    return followUps.map((followUp) => ({
        recipient: [
            followUp.contact.name ||
                formattedPhoneNumber(followUp.contact.phone),
            followUp,
        ],
        businessName: [followUp.business.name || "--"],
        attempts: [followUp],
        status: [followUp],
        currentFollowUpStep: [followUp.currentFollowUpStep],
        lastFollowUpSentAt: [
            followUp.lastFollowUpSentAt
                ? new Date(followUp.lastFollowUpSentAt).getTime()
                : null,
            followUp.lastFollowUpSentAt
                ? dayjs(followUp.lastFollowUpSentAt).format("D MMM, YYYY")
                : "--",
        ],
    }));
}
