import RefreshIcon from "@mui/icons-material/Refresh";
import { Hidden } from "@mui/material";
import Grid from "@mui/material/Grid";
import dashboardWidgetImage from "assets/images/dashboard-widget.png";
import breakpoints from "assets/theme/base/breakpoints";
import {
    getNewContactsStatistics,
    getScheduledMessagesStatistics,
    getSentMessagesStatistics,
    getStartedConversationsStatistics,
} from "common/api/dashboard";
import SoftBox from "components/SoftBox";
import SoftDatePicker from "components/SoftDatePicker";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SelectBusiness from "pages/Contacts/components/SelectBusiness";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useApp from "../../redux/hooks/useApp";
import useAuth from "../../redux/hooks/useAuth";
import ScheduledMessagesCalendar from "./components/ScheduledMessagesCalendar";

const dateOptions = [
    {
        label: "Today",
        value: "today",
    },
    {
        label: "Yesterday",
        value: "yesterday",
    },
    {
        label: "Last 7 Days",
        value: "last 7 days",
    },
    {
        label: "Last 30 Days",
        value: "last 30 days",
    },
    {
        label: "This Month",
        value: "this month",
    },
    {
        label: "Last Month",
        value: "last month",
    },
    {
        label: "This Year",
        value: "this year",
    },
    {
        label: "Last Year",
        value: "last year",
    },
    {
        label: "Custom",
        value: "custom",
    },
];

function DashboardPage() {
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [dashboard, setDashboard] = useState({
        newContactsStatistic: null,
        sentMessagesStatistic: null,
        // receivedMessagesStatistic: null,
        startedConversationsStatistic: null,
        scheduledMessagesStatistic: null,
    });
    const [selectedDateOption, setSelectedDateOption] = useState({
        option: dateOptions[0],
        additional: null,
    });

    const { selectedBusinessId } = useApp();

    const { values } = breakpoints;

    const { user } = useAuth();

    useEffect(() => {
        // setIsDashboardLoading(true);

        Promise.all([
            getScheduledMessagesStatistics(null, {
                businessId: selectedBusinessId,
            }).then((scheduledMessagesStatistic) => {
                setDashboard((prevState) => ({
                    ...prevState,
                    scheduledMessagesStatistic: scheduledMessagesStatistic.data,
                }));
            }),
        ])
            .then(() => {
                setIsDashboardLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsDashboardLoading(false);
            });
    }, [selectedBusinessId]);

    useEffect(() => {
        if (selectedDateOption.option.value) {
            if (selectedDateOption.option.value === "custom") {
                if (
                    selectedDateOption.additional?.from &&
                    selectedDateOption.additional?.to
                ) {
                    handleRefresh();
                }
            } else {
                handleRefresh();
            }
        }
    }, [selectedDateOption, selectedBusinessId]);

    const handleRefresh = async () => {
        try {
            // setIsDashboardLoading(true);

            await Promise.all([
                getNewContactsStatistics({
                    period: selectedDateOption.option.value,
                    startDate: selectedDateOption.additional?.from,
                    endDate: selectedDateOption.additional?.to,
                    businessId: selectedBusinessId,
                }).then((newContactsStatistic) => {
                    setDashboard((prevState) => ({
                        ...prevState,
                        newContactsStatistic: newContactsStatistic.data,
                    }));
                }),

                getSentMessagesStatistics({
                    period: selectedDateOption.option.value,
                    startDate: selectedDateOption.additional?.from,
                    endDate: selectedDateOption.additional?.to,
                    businessId: selectedBusinessId,
                }).then((sentMessagesStatistic) => {
                    setDashboard((prevState) => ({
                        ...prevState,
                        sentMessagesStatistic: sentMessagesStatistic.data,
                    }));
                }),

                getStartedConversationsStatistics({
                    period: selectedDateOption.option.value,
                    startDate: selectedDateOption.additional?.from,
                    endDate: selectedDateOption.additional?.to,
                    businessId: selectedBusinessId,
                }).then((startedConversationsStatistic) => {
                    setDashboard((prevState) => ({
                        ...prevState,
                        startedConversationsStatistic:
                            startedConversationsStatistic.data,
                    }));
                }),
            ])
                .then(() => {
                    setIsDashboardLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsDashboardLoading(false);
                });
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setIsDashboardLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox py={1}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform="capitalize"
                        fontWeight="bold"
                    >
                        Dashboard
                    </SoftTypography>
                </SoftBox>

                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                >
                    <SoftBox
                        display="flex"
                        alignItems="center"
                        sx={{
                            gap: "1rem",
                        }}
                    >
                        <Hidden xsUp>
                            <SoftTypography>
                                Showing results for:
                            </SoftTypography>
                        </Hidden>

                        <SoftSelect
                            value={selectedDateOption.option}
                            options={dateOptions}
                            onChange={(option) => {
                                setSelectedDateOption({
                                    option,
                                    additional:
                                        selectedDateOption.option.value ===
                                        "custom"
                                            ? {
                                                  from: null,
                                                  to: null,
                                              }
                                            : null,
                                });
                            }}
                        />

                        {selectedDateOption.option.value === "custom" && (
                            <>
                                <SoftBox
                                    sx={{
                                        width: "200px",
                                    }}
                                >
                                    <SoftDatePicker
                                        input={{
                                            placeholder: "From:",
                                            sx: {
                                                width: "100px",
                                            },
                                        }}
                                        options={{
                                            maxDate: selectedDateOption
                                                .additional?.to
                                                ? new Date(
                                                      selectedDateOption.additional?.to
                                                  )
                                                : undefined,
                                        }}
                                        value={
                                            selectedDateOption.additional?.from
                                        }
                                        onChange={(date) => {
                                            setSelectedDateOption(
                                                (prevState) => ({
                                                    ...prevState,
                                                    additional: {
                                                        ...prevState.additional,
                                                        from: new Date(
                                                            date
                                                        ).toISOString(),
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </SoftBox>

                                <SoftBox
                                    sx={{
                                        width: "200px",
                                    }}
                                >
                                    <SoftDatePicker
                                        input={{
                                            placeholder: "To:",
                                            sx: {
                                                width: "100px",
                                            },
                                        }}
                                        options={{
                                            minDate: selectedDateOption
                                                .additional?.from
                                                ? new Date(
                                                      selectedDateOption.additional?.from
                                                  )
                                                : undefined,
                                        }}
                                        value={
                                            selectedDateOption.additional?.to
                                        }
                                        onChange={(date) => {
                                            setSelectedDateOption(
                                                (prevState) => ({
                                                    ...prevState,
                                                    additional: {
                                                        ...prevState.additional,
                                                        to: new Date(
                                                            date
                                                        ).toISOString(),
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </SoftBox>
                            </>
                        )}

                        <SelectBusiness />
                    </SoftBox>

                    <SoftBox
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        px={3.6}
                        py={1}
                        onClick={handleRefresh}
                    >
                        <RefreshIcon
                            fontSize="medium"
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </SoftBox>
                </SoftBox>

                <Grid
                    container
                    spacing={3}
                    sx={{
                        mt: 1,
                    }}
                >
                    <Grid item xs={12} lg={7} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                {!isDashboardLoading && (
                                    <MiniGradientLineChart
                                        title="New Contacts"
                                        link="/contacts"
                                        description={
                                            <SoftTypography
                                                variant="h5"
                                                fontWeight="bold"
                                            >
                                                {dashboard.newContactsStatistic
                                                    ? dashboard
                                                          .newContactsStatistic
                                                          .value
                                                    : "--"}{" "}
                                                {dashboard.newContactsStatistic &&
                                                dashboard.newContactsStatistic
                                                    ?.vsPreviousPeriod > 0 ? (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="success"
                                                        fontWeight="bold"
                                                    >
                                                        +
                                                        {dashboard.newContactsStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                ) : (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="error"
                                                        fontWeight="bold"
                                                    >
                                                        {dashboard.newContactsStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                )}
                                            </SoftTypography>
                                        }
                                        chart={{
                                            labels: dashboard.newContactsStatistic
                                                ? Object.keys(
                                                      dashboard
                                                          .newContactsStatistic
                                                          ?.data
                                                  )
                                                : [],
                                            datasets: [
                                                {
                                                    label: "New Contacts",
                                                    color: "info",
                                                    data: dashboard.newContactsStatistic
                                                        ? Object.values(
                                                              dashboard
                                                                  .newContactsStatistic
                                                                  ?.data
                                                          )
                                                        : [],
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {!isDashboardLoading && (
                                    <MiniGradientLineChart
                                        title="Sent Messages"
                                        description={
                                            <SoftTypography
                                                variant="h5"
                                                fontWeight="bold"
                                            >
                                                {dashboard.sentMessagesStatistic
                                                    ? dashboard
                                                          .sentMessagesStatistic
                                                          .value
                                                    : "--"}{" "}
                                                {dashboard.sentMessagesStatistic &&
                                                dashboard.sentMessagesStatistic
                                                    ?.vsPreviousPeriod > 0 ? (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="success"
                                                        fontWeight="bold"
                                                    >
                                                        +
                                                        {dashboard.sentMessagesStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                ) : (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="error"
                                                        fontWeight="bold"
                                                    >
                                                        {dashboard.sentMessagesStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                )}
                                            </SoftTypography>
                                        }
                                        chart={{
                                            labels: dashboard.sentMessagesStatistic
                                                ? Object.keys(
                                                      dashboard
                                                          .sentMessagesStatistic
                                                          ?.data
                                                  )
                                                : [],
                                            datasets: [
                                                {
                                                    label: "Sent Messages",
                                                    color: "info",
                                                    data: dashboard.sentMessagesStatistic
                                                        ? Object.values(
                                                              dashboard
                                                                  .sentMessagesStatistic
                                                                  ?.data
                                                          )
                                                        : [],
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            </Grid>
                            {/* <Grid item xs={12} sm={4}>
                                {!isDashboardLoading && (
                                    <MiniGradientLineChart
                                        title="Received Messages"
                                        description={
                                            <SoftTypography
                                                variant="h5"
                                                fontWeight="bold"
                                            >
                                                {dashboard.receivedMessagesStatistic
                                                    ? dashboard
                                                          .receivedMessagesStatistic
                                                          .value
                                                    : "--"}{" "}
                                                {dashboard.receivedMessagesStatistic &&
                                                dashboard
                                                    .receivedMessagesStatistic
                                                    ?.vsPreviousPeriod > 0 ? (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="success"
                                                        fontWeight="bold"
                                                    >
                                                        +
                                                        {dashboard.receivedMessagesStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                ) : (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="error"
                                                        fontWeight="bold"
                                                    >
                                                        {dashboard.receivedMessagesStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                )}
                                            </SoftTypography>
                                        }
                                        chart={{
                                            labels: dashboard.receivedMessagesStatistic
                                                ? Object.keys(
                                                      dashboard
                                                          .receivedMessagesStatistic
                                                          ?.data
                                                  )
                                                : [],
                                            datasets: [
                                                {
                                                    label: "Received Messages",
                                                    color: "info",
                                                    data: dashboard.receivedMessagesStatistic
                                                        ? Object.values(
                                                              dashboard
                                                                  .receivedMessagesStatistic
                                                                  ?.data
                                                          )
                                                        : [],
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            </Grid> */}
                            <Grid item xs={12} sm={4}>
                                {!isDashboardLoading && (
                                    <MiniGradientLineChart
                                        title="Started Conversations Rate"
                                        description={
                                            <SoftTypography
                                                variant="h5"
                                                fontWeight="bold"
                                            >
                                                {dashboard.startedConversationsStatistic
                                                    ? `${dashboard.startedConversationsStatistic.value.toFixed(
                                                          2
                                                      )}%`
                                                    : "--"}{" "}
                                                {dashboard.startedConversationsStatistic &&
                                                dashboard
                                                    .startedConversationsStatistic
                                                    ?.vsPreviousPeriod > 0 ? (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="success"
                                                        fontWeight="bold"
                                                    >
                                                        +
                                                        {dashboard.startedConversationsStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                ) : (
                                                    <SoftTypography
                                                        variant="button"
                                                        color="error"
                                                        fontWeight="bold"
                                                    >
                                                        {dashboard.startedConversationsStatistic?.vsPreviousPeriod.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </SoftTypography>
                                                )}
                                            </SoftTypography>
                                        }
                                        chart={{
                                            labels: dashboard.startedConversationsStatistic
                                                ? Object.keys(
                                                      dashboard
                                                          .startedConversationsStatistic
                                                          ?.data
                                                  )
                                                : [],
                                            datasets: [
                                                {
                                                    label: "Started Conversations Rate (%)",
                                                    color: "info",
                                                    data: dashboard.startedConversationsStatistic
                                                        ? Object.values(
                                                              dashboard
                                                                  .startedConversationsStatistic
                                                                  ?.data
                                                          )
                                                        : [],
                                                },
                                            ],
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                mt: 1,
                            }}
                        >
                            <Grid item xs={12}>
                                {useMemo(
                                    () =>
                                        !isDashboardLoading ? (
                                            <ScheduledMessagesCalendar
                                                initialView="dayGridMonth"
                                                scheduledMessagesStatistic={
                                                    dashboard.scheduledMessagesStatistic
                                                }
                                            />
                                        ) : (
                                            <></>
                                        ),
                                    [
                                        isDashboardLoading,
                                        dashboard.scheduledMessagesStatistic,
                                    ]
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5} xl={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <BackgroundBlogCard
                                    image={dashboardWidgetImage}
                                    title={`Hey ${user?.firstName}!`}
                                    description={
                                        <>
                                            Here are some techniques you can use
                                            to
                                            <br />
                                            create and test your A.I. assistant.
                                            With a focus on link sharing and
                                            conversions.
                                        </>
                                    }
                                    buttonText="read more"
                                    action={{
                                        type: "internal",
                                        route: "/dashboard",
                                        label: "read more",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
            {/* <Loader open={isDashboardLoading} /> */}
            <Footer />
        </DashboardLayout>
    );
}

export default DashboardPage;
