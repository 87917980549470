import checkout from "./form";

const {
    formField: {
        relayServerId,
        senderName,
        senderNumber,
        userSetting,
        defaultAssistant,
        business,
    },
} = checkout;

const initialValues = {
    [relayServerId.name]: undefined,
    [senderName.name]: "",
    [senderNumber.name]: "",
    [userSetting.name]: undefined,
    [defaultAssistant.name]: undefined,
    [business.name]: undefined,
};

export default initialValues;
