const { apiAxios } = require("common/services/httpService");

export const getScheduledMessages = (params) => {
    return apiAxios.get(`scheduled_message/`, params);
};

export const getScheduledMessage = (scheduledMessageId, params) => {
    return apiAxios.get(`scheduled_message/${scheduledMessageId}`, params);
};

export const updateScheduledMessage = (scheduledMessageId, data) => {
    return apiAxios.patch(`scheduled_message/${scheduledMessageId}`, data);
};

export const deleteScheduledMessage = (scheduledMessageId, params) => {
    return apiAxios.delete(`scheduled_message/${scheduledMessageId}`, params);
};
