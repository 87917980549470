import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../redux/hooks/useAuth";

function APIKey() {
    const { user } = useAuth();

    const apiKey = useMemo(() => {
        if (!user) return null;

        const extensions = user?.extensions || [];

        const outboundExtension = extensions.find(
            (extension) => extension.name === "outbound"
        );

        return outboundExtension?.apiKey || null;
    }, [user]);

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant="h5" fontWeight="bold">
                    API Key
                </SoftTypography>
                <SoftBox mt={1}></SoftBox>
                <SoftBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "between",
                    }}
                >
                    <SoftTypography variant="body1">
                        {apiKey
                            ? apiKey.length <= 5
                                ? "*".repeat(apiKey.length)
                                : apiKey.slice(0, -5) + "*****"
                            : "No API Key generated yet. Contact support if you need one."}
                    </SoftTypography>
                </SoftBox>
                {apiKey && (
                    <SoftTypography
                        sx={{
                            fontSize: 16,
                            fontWeight: 400,
                            borderRadius: 1,
                            bgcolor: "#1C4C73",
                            color: "#fff",
                            px: 3,
                            py: 1,
                            cursor: "pointer",
                            width: "fit-content",
                            ml: "auto",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(apiKey);

                            toast.success("API Key copied to clipboard.");
                        }}
                    >
                        <ContentCopyIcon fontSize="small" />
                        Copy API Key
                    </SoftTypography>
                )}
            </SoftBox>
        </Card>
    );
}

export default APIKey;
