import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        // Personal Info
        firstName,
        lastName,
        userEmail,
        password,
        isAdministrator,

        // Extensions
        extensions,
    },
} = checkout;

const validations = [
    // Personal Info
    Yup.object().shape({
        [userEmail.name]: Yup.string()
            .email("Email is not valid.")
            .required(userEmail.errorMsg),
        [password.name]: Yup.string()
            .min(8, "Password must be at least 8 characters long.")
            .required(password.errorMsg),
        [isAdministrator.name]: Yup.boolean(
            "Is Administrator is not valid."
        ).required(isAdministrator.errorMsg),
    }),
    // Extensions
    Yup.object().shape({}),
];

export default validations;
