import ExtensionIcon from "@mui/icons-material/Extension";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import AdministratorCell from "./components/AdministratorCell";
import OutboundDisabledCell from "./components/OutboundDisabledCell";

export const adminUsersColumns = [
    {
        Header: "FULL NAME",
        accessor: "fullName",
        width: "20%",
    },
    {
        Header: "EMAIL",
        accessor: "userEmail",
        width: "23%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/users/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "EXTENSIONS",
        accessor: "extensions",
        width: "10%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: "0.9rem",
                }}
            >
                <ExtensionIcon fontSize="medium" />
                {name.length} / 4
            </SoftBox>
        ),
    },
    {
        Header: "ADMINISTRATOR",
        accessor: "isAdministrator",
        width: "10%",
        Cell: ({ value: [name, data] }) => (
            <AdministratorCell isAdministrator={name} userId={data._id} />
        ),
    },
    {
        Header: "OUTBOUND",
        accessor: "isOutboundDisabled",
        width: "10%",
        Cell: ({ value: [name, data] }) => (
            <OutboundDisabledCell
                isOutboundDisabled={!name}
                userId={data._id}
            />
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(users) {
    return users.map((user) => ({
        fullName: `${user.firstName || "--"} ${user.lastName || "--"}`,
        userEmail: [user.userEmail, user],
        extensions: [user.extensions, user],
        isAdministrator: [user.isAdministrator, user],
        isOutboundDisabled: [user.isOutboundDisabled, user],
        createdAt: [
            new Date(user.createdAt).getTime(),
            dayjs(user.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
