import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import APIKey from "./components/APIKey";
import NotificationPreferences from "./components/NotificationPreferences";
import PersonalDetails from "./components/PersonalDetails";
import PreferredTimezone from "./components/PreferredTimezone";

const {
    default: DashboardLayout,
} = require("examples/LayoutContainers/DashboardLayout");
const {
    default: DashboardNavbar,
} = require("examples/Navbars/DashboardNavbar");

function SettingsPage() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <PersonalDetails />
                        <SoftBox
                            sx={{
                                mt: 3,
                            }}
                        />
                        <PreferredTimezone />
                        <SoftBox
                            sx={{
                                mt: 3,
                            }}
                        />
                        <APIKey />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <NotificationPreferences />
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
}

export default SettingsPage;
