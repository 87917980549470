import LinkIcon from "@mui/icons-material/Link";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DirectLinksCell from "./components/DirectLinksCell";

export const adminPhoneNumbersColumns = [
    {
        Header: "PHONE NUMBER",
        accessor: "phoneNumber",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/numbers/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "Sender Name",
        accessor: "senderName",
        width: "20%",
        Cell: ({ value }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                {value}
            </SoftBox>
        ),
    },
    {
        Header: "Relay",
        accessor: "relayServerId",
        width: "10%",
        Cell: ({ value }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {value ? "Yes" : "No"}
            </SoftBox>
        ),
    },
    {
        Header: "USER EMAIL",
        accessor: "userEmail",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <>
                <SoftBox
                    sx={{
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/users/${data._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {name}
                        </Link>
                    </SoftBox>
                </SoftBox>
            </>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "business",
        width: "17%",
        Cell: ({ value: [phoneNumber] }) => (
            <>
                {phoneNumber.business ? (
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/businesses/${phoneNumber.business._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {phoneNumber.business.displayName}
                        </Link>
                    </SoftBox>
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "DEFAULT ASSISTANT",
        accessor: "defaultAssistant",
        width: "15%",
        Cell: ({ value: [phoneNumber] }) => (
            <>
                {phoneNumber.defaultAssistant ? (
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/assistants/${phoneNumber.defaultAssistant._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {phoneNumber.defaultAssistant.displayName}
                        </Link>
                    </SoftBox>
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "DIRECT LINKS",
        accessor: "directLinks",
        width: "15%",
        Cell: ({ value: [phoneNumber] }) => (
            <>
                {phoneNumber.senderNumber ? (
                    <SoftBox
                        sx={{
                            color: "black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <LinkIcon fontSize="medium" />
                        <DirectLinksCell phoneNumber={phoneNumber} />
                    </SoftBox>
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(phoneNumbers) {
    return phoneNumbers.map((phoneNumber) => ({
        relayServerId: phoneNumber.relayServerId,
        phoneNumber: [
            phoneNumber.senderNumber || phoneNumber.senderName,
            phoneNumber,
        ],
        senderName: phoneNumber.senderName,
        userEmail: [phoneNumber.userSetting.userEmail, phoneNumber.userSetting],
        defaultAssistant: [phoneNumber],
        business: [phoneNumber],
        directLinks: [phoneNumber],
        createdAt: [
            new Date(phoneNumber.createdAt).getTime(),
            dayjs(phoneNumber.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
