import { Box, Grid, Modal, Switch } from "@mui/material";
import { adminSendPushNotification } from "common/api/admin/user";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAdmin from "../../../../../redux/hooks/useAdmin";
import SelectUser from "../SelectUser";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

export default function SendPushNotification() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        body: "",
        icon: "",
        image: "",
        silent: false,
    });

    const { selectedUserId } = useAdmin();

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSendNotification = async () => {
        try {
            const response = await adminSendPushNotification(
                selectedUserId || null,
                formData
            );

            if (response?.data?.isSuccessful) {
                toast.success("Notification sent successfully.");
            } else {
                toast.success(response?.data?.error?.message);
            }

            toggleModal();
        } catch (error) {
            toast.error(error.response?.data?.error?.message || error.message);
        }
    };

    useEffect(() => {
        setFormData({
            title: "",
            body: "",
            icon: "",
            image: "",
            silent: false,
        });
    }, [isModalOpen]);

    return (
        <>
            <SoftBox
                onClick={() => {
                    toggleModal();
                }}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    border: "1px solid #fff",
                    borderRadius: "8px",
                    backgroundColor: "#1C4C73",
                    color: "#fff",
                    fontSize: "14px",
                }}
            >
                Send Push Notification
            </SoftBox>
            <Modal open={isModalOpen} onClose={toggleModal}>
                <Box
                    sx={{
                        ...modalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Send Push Notification
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Notify the user/s about the new update.
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox mt={1.625}>
                        <Grid mt={1.625} item xs={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    User
                                </SoftTypography>
                            </SoftBox>
                            <SelectUser />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                mt={2}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Title
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Title"
                                placeholder="Enter Title"
                                type="text"
                                value={formData.title}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        title: e.target.value,
                                    });
                                }}
                            />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                mt={2}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Body
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Body"
                                placeholder="Enter Body"
                                type="text"
                                value={formData.body}
                                rows={4}
                                multiline
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        body: e.target.value,
                                    });
                                }}
                            />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                mt={2}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Icon
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Icon"
                                placeholder="Enter Icon"
                                type="url"
                                value={formData.icon}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        icon: e.target.value,
                                    });
                                }}
                            />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                mt={2}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Image
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Image"
                                placeholder="Enter Image"
                                type="url"
                                value={formData.image}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        image: e.target.value,
                                    });
                                }}
                            />

                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mt: 2,
                                    gap: 2,
                                }}
                            >
                                <Switch
                                    checked={formData.silent}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            silent: e.target.checked,
                                        });
                                    }}
                                />
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            silent: !formData.silent,
                                        });
                                    }}
                                >
                                    Sent Silently
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                sx={{
                                    mt: 3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        toggleModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="black"
                                    >
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1C4C73",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleSendNotification}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                    >
                                        Send
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </SoftBox>
                </Box>
            </Modal>
        </>
    );
}
