const form = {
    formId: "create-assistant",
    formField: {
        relayServerId: {
            name: "relayServerId",
            label: "Relay Server ID (Optional)",
            type: "text",
            placeholder: "Enter Relay Server ID",
        },
        senderName: {
            name: "senderName",
            label: "Sender Name",
            type: "text",
            placeholder: "Enter Sender Name",
            errorMsg: "Sender Name is required.",
        },
        senderNumber: {
            name: "senderNumber",
            label: "Sender Number",
            type: "text",
            placeholder: "Enter Sender Number",
            errorMsg: "Sender Number is required.",
        },
        userSetting: {
            name: "userSetting",
            label: "User Setting",
            type: "text",
            placeholder: "Enter User Setting",
            errorMsg: "User Setting is required.",
        },
        defaultAssistant: {
            name: "defaultAssistant",
            label: "Default Assistant",
            type: "text",
            placeholder: "Enter Default Assistant",
            errorMsg: "Default Assistant is required.",
        },
        business: {
            name: "business",
            label: "Business",
            type: "text",
            placeholder: "Enter Business",
            errorMsg: "Business is required.",
        },
    },
};

export default form;
