import checkout from "./form";

const {
    formField: {
        relayServerPoolId,
        name,
        defaultAssistant,
        business,
        userSetting,
    },
} = checkout;

const initialValues = {
    [relayServerPoolId.name]: "",
    [name.name]: "",
    [defaultAssistant.name]: undefined,
    [business.name]: undefined,
    [userSetting.name]: undefined,
};

export default initialValues;
