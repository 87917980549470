import { Switch } from "@mui/material";
import { adminUpdateUser } from "common/api/admin/user";
import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AdministratorCell = ({ isAdministrator, userId }) => {
    const [isAdmin, setIsAdmin] = useState(isAdministrator);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsAdmin(isAdministrator);
    }, [isAdministrator]);

    useEffect(() => {
        if (isAdmin === isAdministrator) return;

        setIsLoading(true);
        adminUpdateUser(userId, { isAdministrator: isAdmin })
            .then(() => {
                toast.success("User updated successfully.");
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.error?.message || error.message
                );
                setIsAdmin(isAdministrator);
                setIsLoading(false);
            });
    }, [isAdmin]);

    return (
        <SoftBox>
            <Switch
                checked={isAdmin}
                disabled={isLoading}
                onChange={() => {
                    setIsAdmin(!isAdmin);
                }}
            />
        </SoftBox>
    );
};

export default AdministratorCell;
