import { Box, Grid } from "@mui/material";
import { useMessageReceived } from "core/hooks/useMessageReceived";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { appActions } from "../../../redux/appSlice";
import useApp from "../../../redux/hooks/useApp";
import classes from "./Conversations.module.css";
import { ContactInfo } from "./components/ContactInfo";
import { Conversation } from "./components/Conversation";
import { ConversationList } from "./components/ConversationList";

export function Conversations({ key, ...rest }) {
    const dispatch = useDispatch();

    const { selectedBusinessId, conversations, selectedConversationId } =
        useApp();

    const { businessId } = useParams();

    useMessageReceived(
        (_) => {
            dispatch(appActions.appendSelectedConversationMessage(_));

            if (_.role !== "system") {
                dispatch(appActions.fetchConversations());
                dispatch(appActions.fetchNotificationPreferences());
            }
        },
        (_) => {
            dispatch(appActions.changeSelectedConversationStatus(_));
        }
    );

    useEffect(() => {
        dispatch(appActions.selectBusiness(businessId));
        dispatch(appActions.clearSelectedConversation());
    }, [businessId]);

    useEffect(() => {
        dispatch(appActions.fetchAssistants());
        dispatch(appActions.fetchConversations());
        dispatch(appActions.fetchNotificationPreferences());
        dispatch(appActions.fetchPhoneNumbers());
    }, [selectedBusinessId]);

    return (
        <DashboardLayout {...rest}>
            <DashboardNavbar />
            <Box className={classes.messagesContainer}>
                <Grid container height="100%" key={key}>
                    <Grid item xs={12} md={2.6} height="100%">
                        <ConversationList />
                    </Grid>
                    <Grid item xs={12} md={4} height="100%">
                        <Conversation />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        height="100%"
                        mx={2}
                        sx={{ maxWidth: [1, 1, 0.33, "350px"] }}
                    >
                        <ContactInfo />
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </DashboardLayout>
    );
}
