import AdjustIcon from "@mui/icons-material/Adjust";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import MicNoneIcon from "@mui/icons-material/MicNone";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button, Menu, MenuItem, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { colors } from "assets/mui/theme";
import { imageFileToBase64 } from "common/helpers/fileHelper";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";
import Waveform from "./WaveForm";

function truncateMiddle(text, maxLength, separator = "...") {
    if (text.length > maxLength) {
        const charsToShow = maxLength - separator.length;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);
        return (
            text.substr(0, frontChars) +
            separator +
            text.substr(text.length - backChars)
        );
    }
    return text;
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 2,
    py: 2,
};

const StyledTextarea = styled((props) => (
    <TextField
        InputLabelProps={{
            color: `${colors.blue[100]} !important`,
            marginBottom: "10px !important",
            width: "100%",
        }}
        sx={{ "& .MuiInputBase-input": { flex: 1 } }}
        multiline
        maxRows={4}
        // InputProps={{
        //     disableUnderline: true,
        //     endAdornment: (
        //         <InputAdornment position="end">
        //             <MicNoneIcon
        //                 sx={{ fontSize: "30px !important", zIndex: "1" }}
        //             />
        //         </InputAdornment>
        //     ),
        // }}
        {...props}
        variant="standard"
    />
))(({ theme }) => ({
    "& .MuiFilledInput-root": {
        overflow: "hidden",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            borderColor: colors.blue[100],
        },
    },
    "& .MuiInputBase-root": {
        paddingRight: "0.5rem !important",
        borderRadius: "10rem !important",
    },
}));

const UploadButton = styled(Button)(({ theme }) => ({
    "& svg": {
        fontSize: `32px !important`,
        color: colors.gray[200],
    },
    "&:hover": {
        transform: "none",
    },
}));

export function AddMessagePanel() {
    const [content, setContent] = useState("");
    const [attachments, setAttachments] = useState([]);

    const [voiceNote, setVoiceNote] = useState(null);
    const [voiceNoteUrl, setVoiceNoteUrl] = useState(null);

    const [
        isMoreVoiceNoteOptionsMenuOpened,
        setIsMoreVoiceNoteOptionsMenuOpened,
    ] = useState(false);
    const [isVoiceNoteModalOpen, setIsVoiceNoteModalOpen] = useState(false);

    const audioInputRef = useRef();

    const { selectedConversation, isSelectedConversationLoading } = useApp();

    const dispatch = useDispatch();

    const toggleVoiceNoteModal = () => {
        setIsVoiceNoteModalOpen(!isVoiceNoteModalOpen);
    };

    const toggleMoreVoiceNoteOptionsMenu = (
        isMoreVoiceNoteOptionsMenuOpened
    ) => {
        setIsMoreVoiceNoteOptionsMenuOpened((_) =>
            !_ ? isMoreVoiceNoteOptionsMenuOpened : false
        );
    };

    const handleMessageInputChange = (event) => {
        setContent(event.target.value);
    };

    const handleAudioInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!file) return;

        reader.onloadend = () => {
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            console.log(base64String);

            setVoiceNote(base64String);
            setAttachments([]);
        };

        reader.readAsDataURL(file);
    };

    const appendFileToMessage = async (event) => {
        const file = event.target.files[0];

        if (!file) return;

        const allowedExt = ["image/png", "image/jpeg"];
        const isCorrectFileFormat = allowedExt.includes(file.type);

        if (!isCorrectFileFormat) {
            event.target.value = null;
            toast.error("File format not supported.");
        }

        const attachment = await imageFileToBase64(file);

        setAttachments((attachments) => [...attachments, attachment]);

        event.target.value = null;
    };

    const handleMessageInputKeyUp = (event) => {
        if ((event.key === "Enter") & !event.shiftKey) {
            dispatch(
                appActions.sendMessageInSelectedConversation({
                    optimisticMessage: {
                        _id: Math.random().toString(36).substring(7),
                        role: "assistant",
                        content:
                            voiceNoteUrl || voiceNote
                                ? "Uploading..."
                                : content,
                        attachments:
                            attachments?.map((attachment) => ({
                                type: "image",
                                url: attachment,
                                mimeType: "image/png",
                                fileName: "image.png",
                                fileSize: 0,
                            })) || [],
                        voicenote: voiceNoteUrl,
                        conversationId: selectedConversation._id,
                        isOptimistic: true,
                    },
                    dataToBeSent: {
                        text: content,
                        attachments: voiceNote ? [] : attachments,
                        voiceNote,
                        voiceNoteUrl,
                        conversationId: selectedConversation._id,
                    },
                })
            );

            setContent("");
            setVoiceNote(null);
            setVoiceNoteUrl(null);
            setAttachments([]);
            event.preventDefault();
        }
    };

    useEffect(() => {
        setContent("");
        setVoiceNote(null);
        setVoiceNoteUrl(null);
        setAttachments([]);
    }, [selectedConversation]);

    const assistantVoiceNotes = useMemo(
        () =>
            selectedConversation?.activeAssistant?.skills?.find(
                (_) => _.name === "voiceNote"
            )?.config?.voiceNotes || [],
        [selectedConversation]
    );

    return (
        !isSelectedConversationLoading && (
            <>
                {selectedConversation.activeAssistant && (
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            mt: "auto",
                            gap: 2,
                            pt: 1,
                        }}
                    >
                        <SoftTypography variant="body2">
                            {truncateMiddle(
                                selectedConversation?.conversationStatus
                                    ?.split(" $$ ")
                                    ?.at(0) ||
                                    `${selectedConversation.activeAssistant.displayName} is idle.`,
                                70
                            )}
                            {selectedConversation?.conversationStatus
                                ?.split(" $$ ")
                                ?.at(1) && (
                                <ReactTimeago
                                    date={
                                        new Date(
                                            new Date(
                                                selectedConversation?.conversationStatus
                                                    ?.split(" $$ ")
                                                    ?.at(1)
                                            )
                                        )
                                    }
                                    formatter={(value, unit, suffix) => {
                                        const units = {
                                            year: "y",
                                            month: "mo",
                                            week: "w",
                                            day: "d",
                                            hour: "h",
                                            minute: "m",
                                            second: "s",
                                        };

                                        return ` (${value}${units[unit]})`;
                                    }}
                                />
                            )}
                        </SoftTypography>
                        <AutoAwesomeIcon />
                    </SoftBox>
                )}

                <Box
                    display="flex"
                    padding={2}
                    alignItems="center"
                    sx={{
                        mt: !selectedConversation.activeAssistant
                            ? "auto"
                            : "0",
                    }}
                >
                    <UploadButton component="label">
                        <PhotoCameraIcon
                            sx={{
                                fontSize: "30px !important",
                            }}
                        />
                        <input
                            type="file"
                            hidden
                            onChange={appendFileToMessage}
                        />
                    </UploadButton>
                    <StyledTextarea
                        sx={{ width: "100%" }}
                        aria-label="maximum height"
                        placeholder="iMessage"
                        value={content}
                        onKeyDown={handleMessageInputKeyUp}
                        onChange={handleMessageInputChange}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <MicNoneIcon
                                        sx={{
                                            fontSize: "30px !important",
                                            zIndex: "1",
                                            cursor: "pointer",
                                            color: `${
                                                voiceNote || voiceNoteUrl
                                                    ? "#468DF5"
                                                    : colors.gray[200]
                                            }`,
                                        }}
                                        onClick={(e) => {
                                            if (voiceNote || voiceNoteUrl) {
                                                setVoiceNote(null);
                                                setVoiceNoteUrl(null);
                                            } else {
                                                if (
                                                    !selectedConversation.activeAssistant.skills.find(
                                                        (_) =>
                                                            _.name ===
                                                            "voiceNote"
                                                    )
                                                ) {
                                                    audioInputRef.current.click();
                                                } else {
                                                    toggleMoreVoiceNoteOptionsMenu(
                                                        e.currentTarget
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <input
                                        type="file"
                                        hidden
                                        ref={audioInputRef}
                                        accept="audio/*"
                                        onChange={handleAudioInputChange}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Menu
                    anchorEl={isMoreVoiceNoteOptionsMenuOpened}
                    anchorReference={null}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    open={Boolean(isMoreVoiceNoteOptionsMenuOpened)}
                    onClose={toggleMoreVoiceNoteOptionsMenu}
                    sx={{
                        mt: -1,
                        ml: 1,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            audioInputRef.current.click();
                            toggleMoreVoiceNoteOptionsMenu();
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0",
                                width: "100%",
                                gap: 2,
                            }}
                        >
                            <span>from Computer</span>
                            <FileUploadIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            toggleVoiceNoteModal();
                            toggleMoreVoiceNoteOptionsMenu();
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0",
                                width: "100%",
                                gap: 2,
                            }}
                        >
                            <span>from Voice Notes</span>
                            <LibraryMusicIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                </Menu>
                <Modal
                    open={isVoiceNoteModalOpen}
                    onClose={toggleVoiceNoteModal}
                >
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography component="label" variant="h4">
                                {
                                    selectedConversation?.activeAssistant
                                        ?.displayName
                                }
                                's Voice Notes
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography component="label" variant="body2">
                                Select a voice note to send.
                            </SoftTypography>
                        </SoftBox>
                        {!assistantVoiceNotes?.length > 0 && (
                            <SoftBox
                                lineHeight={0}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 2,
                                    py: 1,
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "0.375rem",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    No voice notes found. Upload it{" "}
                                    <Link
                                        to={`/assistants/${selectedConversation?.activeAssistant?._id}`}
                                    >
                                        here
                                    </Link>
                                    .
                                </SoftTypography>
                            </SoftBox>
                        )}
                        {assistantVoiceNotes?.map((voiceNote, index) => {
                            return (
                                <SoftBox
                                    key={`assistantVoiceNote-${index}`}
                                    sx={{
                                        border: "1px solid #E0E0E0",
                                        px: 1.5,
                                        py: 1.5,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mb: 1,
                                        bgcolor: `${
                                            voiceNoteUrl === voiceNote.url
                                                ? "#1C4C73"
                                                : "#fff"
                                        }`,
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            gap: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <SoftBox
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "column",
                                                bgcolor: "#1C4C73",
                                                px: 2,
                                                py: 1,
                                                borderRadius: "0.5rem",
                                                textAlign: "right",
                                            }}
                                        >
                                            <Waveform audio={voiceNote.url} />
                                        </SoftBox>
                                        <SoftBox
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setVoiceNoteUrl(
                                                    voiceNoteUrl ===
                                                        voiceNote.url
                                                        ? null
                                                        : voiceNote.url
                                                );
                                            }}
                                        >
                                            <SoftTypography
                                                variant="caption"
                                                color="text"
                                                sx={{
                                                    color: `${
                                                        voiceNoteUrl ===
                                                        voiceNote.url
                                                            ? "#fff"
                                                            : "#1C4C73"
                                                    }`,
                                                }}
                                            >
                                                {voiceNote.description}
                                            </SoftTypography>
                                            <AdjustIcon
                                                fontSize="medium"
                                                color="text"
                                                sx={{
                                                    color: `${
                                                        voiceNoteUrl ===
                                                        voiceNote.url
                                                            ? "#fff"
                                                            : ""
                                                    }`,
                                                }}
                                            />
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            );
                        })}
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    width: "50%",
                                    textAlign: "center",
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (!voiceNoteUrl) {
                                        toast.error(
                                            "Please select a voice note."
                                        );
                                        return;
                                    }

                                    toggleVoiceNoteModal();
                                }}
                            >
                                Select
                            </Typography>
                            <Typography
                                sx={{
                                    width: "50%",
                                    textAlign: "center",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setVoiceNoteUrl(null);
                                    toggleVoiceNoteModal();
                                }}
                            >
                                Cancel
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </>
        )
    );
}
