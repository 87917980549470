import { Backdrop, CircularProgress, styled } from "@mui/material"

const CustomBackdrop = styled(Backdrop, {name: 'CustomLoader', slot: 'root'})(({theme}) => {
  return {
    color: '#fff',
    zIndex: theme.zIndex.tooltip + 1
  }
})

export function Loader({ open }) {
  return (
    <CustomBackdrop
      open={open}
    >
      <CircularProgress color="inherit" />
    </CustomBackdrop>
  )
}