import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

import { STORAGE_TOKEN } from "../constants/constants";

const getJWTExpirationStatus = (accessToken) => {
    let decodedJWT;
    if (accessToken === null) {
        return { isExpired: true, shouldRefresh: false };
    }

    try {
        decodedJWT = jwt_decode(accessToken);
    } catch (error) {
        return {
            isExpired: true,
            // shouldRefresh: false,
        };
    }

    const currentUnixTime = dayjs().unix();
    const isExpired = currentUnixTime > decodedJWT.exp;

    // // we want to refresh the token only when it is within 15 minutes of expiring
    // const shouldRefresh = (decodedJWT.exp - currentUnixTime) / 60 < 15;

    return {
        isExpired,
        // shouldRefresh,
    };
};

const clearStorage = function () {
    localStorage.removeItem(STORAGE_TOKEN);
};

const tokenExpirationCheck = (token) => {
    if (token === null) {
        return;
    }
    const { isExpired } = getJWTExpirationStatus(token);
    if (isExpired) {
        clearStorage();
        // window.location.assign('/');
    }
};

export const defaultTransformers = () => {
    const { transformRequest } = axios.defaults;
    if (!transformRequest) return [];
    if (transformRequest instanceof Array) return transformRequest;
    return [transformRequest];
};

const authenticateTransformer = (data, headers) => {
    const token = localStorage.getItem(STORAGE_TOKEN);
    tokenExpirationCheck(token);
    if (headers) {
        headers.Authorization = `Bearer ${token}`;
    }
    return data;
};

export const apiAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    transformRequest: [...defaultTransformers(), authenticateTransformer],
});
