import { Avatar } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const assistantsColumns = [
    { Header: "DISPLAY AVATAR", accessor: "displayAvatar", width: "10%" },
    {
        Header: "DISPLAY NAME",
        accessor: "displayName",
        width: "25%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/assistants/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "NAME",
        accessor: "name",
        width: "25%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/assistants/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "businessName",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <SoftBadge
                variant="gradient"
                size="md"
                color="light"
                badgeContent={name}
                container
                border
                circular
            />
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    { Header: "ACTION", accessor: "action", width: "30%" },
];

export function createTableRows(assistants, actions) {
    return assistants.map((assistant) => ({
        displayAvatar: (
            <Avatar
                src={
                    assistant.displayAvatar
                        ? assistant.displayAvatar
                        : undefined
                }
                sx={{
                    bgcolor: assistant.displayColor,
                    width: 48,
                    height: 48,
                    objectFit: "cover",
                    mx: "auto",
                }}
            >
                <span
                    style={{
                        color: "white",
                        mixBlendMode: "difference",
                    }}
                >
                    {assistant.displayName
                        ?.split(" ")
                        .map((word) => word[0]?.toUpperCase())
                        .slice(0, 2)
                        .join("")}
                </span>
            </Avatar>
        ),
        displayName: [assistant.displayName, assistant],
        name: [assistant.name, assistant],
        businessName: [assistant.business.displayName || "--"],
        createdAt: [
            new Date(assistant.createdAt).getTime(),
            dayjs(assistant.createdAt).format("D MMM, YYYY"),
        ],
        action: actions(assistant),
    }));
}
