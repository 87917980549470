const form = {
    formId: "create-assistant",
    formField: {
        displayAvatar: {
            name: "displayAvatar",
        },
        displayName: {
            name: "displayName",
            label: "Display Name",
            type: "text",
            placeholder: "Enter Display Name",
            errorMsg: "Display Name is required.",
        },
        displayColor: {
            name: "displayColor",
            label: "Display Color",
            type: "color",
            placeholder: "Enter Display Color",
            errorMsg: "Display Color is required.",
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "Enter Name",
            errorMsg: "Name is required.",
        },
        age: {
            name: "age",
            label: "Age",
            type: "number",
            placeholder: "Enter Age",
            errorMsg: "Age is required.",
        },
        gender: {
            name: "gender",
            label: "Gender",
            type: "text",
            placeholder: "Enter Gender",
            errorMsg: "Gender is required.",
        },
        modelName: {
            name: "modelName",
            label: "Model Name",
            type: "text",
            placeholder: "Enter Model Name",
            errorMsg: "Model Name is required.",
        },
        ethnicity: {
            name: "ethnicity",
            label: "Ethnicity",
            type: "text",
            placeholder: "Enter Ethnicity",
            errorMsg: "Ethnicity is required.",
        },
        emojiSkinTone: {
            name: "emojiSkinTone",
            label: "Emoji Skin Tone",
            type: "text",
            placeholder: "Enter Emoji Skin Tone",
            errorMsg: "Emoji Skin Tone is required.",
        },
        background: {
            name: "background",
            label: "Background",
            type: "text",
            placeholder: "Enter Background",
            errorMsg: "Background is required.",
        },
        jobDescription: {
            name: "jobDescription",
            label: "Job Description",
            type: "text",
            placeholder: "Enter Job Description",
            errorMsg: "Job Description is required.",
        },
        role: {
            name: "role",
            label: "Role",
            type: "text",
            placeholder: "Enter Role",
            errorMsg: "Role is required.",
        },
        style: {
            name: "style",
            label: "Style",
            type: "text",
            placeholder: "Enter Style",
            errorMsg: "Style is required.",
        },
        conversationStepGroups: {
            name: "conversationStepGroups",
            label: "Conversation Steps Groups",
            type: "text",
            placeholder: "Enter Conversation Step Groups",
            errorMsg: "Conversation Step Groups is required.",
        },
        defaultConversationStepGroup: {
            name: "defaultConversationStepGroup",
            label: "Default Conversation Step Group",
            type: "text",
            placeholder: "Enter Default Conversation Step Group",
            errorMsg: "Default Conversation Step Group is required.",
        },
        followUpGroups: {
            name: "followUpGroups",
            label: "Follow Up Groups",
            type: "text",
            placeholder: "Enter Follow Up Groups",
            errorMsg: "Follow Up Groups is required.",
        },
        defaultFollowUpGroup: {
            name: "defaultFollowUpGroup",
            label: "Default Follow Up Group",
            type: "text",
            placeholder: "Enter Default Follow Up Group",
            errorMsg: "Default Follow Up Groups is required.",
        },
        additional: {
            name: "additional",
            label: "Additional",
            type: "text",
            placeholder: "Enter Additional",
            errorMsg: "Additional is required.",
        },
        teamNotes: {
            name: "teamNotes",
            label: "Team Notes",
            type: "text",
            placeholder: "Enter Team Notes",
            errorMsg: "Team Notes is required.",
        },
        business: {
            name: "business",
            label: "Business",
            type: "text",
            placeholder: "Enter Business",
            errorMsg: "Business is required.",
        },
        tokenLimit: {
            name: "tokenLimit",
            label: "Token Limit",
            type: "number",
            placeholder: "Enter Token Limit",
            errorMsg: "Token Limit is required.",
        },
        readTime: {
            name: "readTime",
            label: "Read Time",
            type: "number",
            placeholder: "Enter Read Time",
            errorMsg: "Read Time is required.",
        },
        assistantLinks: {
            name: "assistantLinks",
            label: "Assistant Links",
            type: "text",
            placeholder: "Enter Assistant Links",
            errorMsg: "Assistant Links is required.",
        },
        skills: {
            name: "skills",
            label: "Assistant Skills",
            type: "text",
            placeholder: "Enter Assistant Skills",
            errorMsg: "Assistant Skills is required.",
        },
        userId: {
            name: "userId",
            label: "User Id",
            type: "text",
            placeholder: "Enter User Id",
            errorMsg: "User Id is required.",
        },
    },
};

export default form;
