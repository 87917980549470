const { apiAxios } = require("common/services/httpService");

export const uploadVoiceNote = (data) => {
    return apiAxios.post(`assistant/voice-note`, data);
};

export const getAssistant = (assistantId, params) => {
    return apiAxios.get(`assistant/${assistantId}`, params);
};

export const updateAssistant = (assistantId, data) => {
    return apiAxios.patch(`assistant/${assistantId}`, data);
};

export const deleteAssistant = (assistantId) => {
    return apiAxios.delete(`assistant/${assistantId}`);
};
