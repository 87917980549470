import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Avatar, Box, Menu, MenuItem, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import parsePhoneNumber from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    changeAssistantOfConversation,
    deleteConversation,
    permanentlyDeleteConversation,
    retryConversation,
} from "../../../../common/api/conversation";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";
import useAuth from "../../../../redux/hooks/useAuth";

/* eslint-disable react/prop-types */

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

export function ConversationHeader() {
    const [isMoreActionsMenuOpened, setIsMoreActionsMenuOpened] =
        useState(false);

    const [isChangeAssistantModalOpen, setIsChangeAssistantModalOpen] =
        useState(false);
    const [assistantId, setAssistantId] = useState(undefined);

    const { user } = useAuth();
    const {
        selectedConversation,
        selectedBusinessId,
        assistants,
        notificationPreferences,
        selectedConversationMessages,
    } = useApp();

    const dispatch = useDispatch();

    const toggleMoreActionsMenu = (moreActionsMenuOpened) => {
        setIsMoreActionsMenuOpened((_) => (!_ ? moreActionsMenuOpened : false));
    };

    const toggleChangeAssistantModal = () => {
        setIsChangeAssistantModalOpen((_) => !_);
    };

    useEffect(() => {
        dispatch(appActions.fetchAssistants());
    }, [selectedBusinessId]);

    useEffect(() => {
        setIsMoreActionsMenuOpened(false);
    }, [selectedConversation]);

    const handleChangeAssistant = async () => {
        try {
            const response = await changeAssistantOfConversation(
                selectedConversation._id,
                {
                    assistantId,
                }
            );

            dispatch(appActions.updateConversation(response.data.payload));
            dispatch(appActions.selectConversation(selectedConversation._id));

            toggleChangeAssistantModal();

            toast.success("Assistant changed successfully.");
        } catch (error) {
            console.log("Error changing assistant: ", error?.response?.data);
            toast.error(error?.response?.data?.error?.message);
        }
    };

    const handleToggleNotificationPreference = async () => {
        dispatch(
            appActions.toggleNotificationPreferencesForConversation(
                selectedConversation._id
            )
        );
    };

    const handleRetryConversation = async () => {
        try {
            const response = await retryConversation(selectedConversation._id);

            dispatch(appActions.selectConversation(selectedConversation._id));

            toggleMoreActionsMenu();

            toast.success(response?.data?.payload?.message);
        } catch (error) {
            console.log("Error retrying conversation: ", error?.response?.data);
            toast.error(error?.response?.data?.error?.message);
        }
    };

    const handleDeleteConversation = async () => {
        try {
            await deleteConversation(selectedConversation._id);

            dispatch(appActions.clearSelectedConversation());

            dispatch(appActions.fetchConversations());

            toast.success("Conversation deleted successfully.");
        } catch (error) {
            console.log("Error deleting conversation: ", error?.response?.data);
            toast.error(error?.response?.data?.error?.message);
        }
    };

    const handlePermanentlyDeleteConversation = async () => {
        try {
            await permanentlyDeleteConversation(selectedConversation._id);

            dispatch(appActions.clearSelectedConversation());

            dispatch(appActions.fetchConversations());

            toast.success("Conversation permanently deleted successfully.");
        } catch (error) {
            console.log("Error deleting conversation: ", error?.response?.data);
            toast.error(error?.response?.data?.error?.message);
        }
    };

    return (
        <Box display={"flex"} justifyContent={"space-between"} mt="20px" px={2}>
            {selectedConversation ? (
                <>
                    <Box display={"flex"} alignItems={"center"}>
                        <Avatar
                            sx={{
                                bgcolor: "#1C4C73",
                                color: "white",
                            }}
                        >
                            {selectedConversation?.contact?.name
                                ? selectedConversation?.contact?.name[0]
                                : "#"}
                        </Avatar>
                        <Typography
                            fontSize={16}
                            fontWeight={800}
                            marginLeft={1}
                        >
                            {selectedConversation?.contact?.name ||
                                parsePhoneNumber(
                                    selectedConversation?.contact?.phone
                                )?.formatNational() ||
                                selectedConversation?.contact?.phone}
                        </Typography>
                    </Box>

                    <MoreVertIcon
                        sx={{ fontSize: "30px !important", cursor: "pointer" }}
                        MenuProps={{ style: { width: "600px" } }}
                        onClick={(e) => {
                            toggleMoreActionsMenu(e.currentTarget);
                        }}
                    />

                    <Menu
                        anchorEl={isMoreActionsMenuOpened}
                        anchorReference={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(isMoreActionsMenuOpened)}
                        onClose={toggleMoreActionsMenu}
                        sx={{
                            mt: "1rem",
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    selectedConversation._id
                                );
                                toast.success(
                                    "Conversation ID copied to clipboard."
                                );

                                toggleMoreActionsMenu();
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0",
                                    width: "180px",
                                }}
                            >
                                <span>Copy ID</span>
                                <ContentCopyIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                toggleChangeAssistantModal();
                                toggleMoreActionsMenu();
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0",
                                    width: "180px",
                                }}
                            >
                                <span>Change Assistant</span>
                                <SmartToyIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                        {notificationPreferences &&
                            notificationPreferences?.preference ===
                                "specified" && (
                                <MenuItem
                                    onClick={handleToggleNotificationPreference}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0",
                                            width: "180px",
                                        }}
                                    >
                                        <span>
                                            {notificationPreferences?.specifiedConversations?.includes(
                                                selectedConversation._id
                                            )
                                                ? "Disable Notifications"
                                                : "Enable Notifications"}
                                        </span>
                                        <EditNotificationsIcon fontSize="small" />
                                    </SoftBox>
                                </MenuItem>
                            )}
                        {(selectedConversationMessages?.filter(
                        (_) => _.role === "user"
                    ).length > 0 || selectedConversationMessages?.filter(
                        (_) => _.role === "team"
                    ).length > 0) && (
                            <>
                                <SoftBox
                                    component="div"
                                    bgColor="secondary"
                                    opacity={0.3}
                                    width="100%"
                                    height="1px"
                                    my={1}
                                />
                                <MenuItem
                                    onClick={() => {
                                        handleRetryConversation();
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0",
                                            width: "180px",
                                        }}
                                    >
                                        <span>Retry Conversation</span>
                                        <ReplayIcon fontSize="small" />
                                    </SoftBox>
                                </MenuItem>
                            </>
                        )}
                        <SoftBox
                            component="div"
                            bgColor="secondary"
                            opacity={0.3}
                            width="100%"
                            height="1px"
                            my={1}
                        />
                        <MenuItem
                            onClick={() => {
                                handleDeleteConversation();
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0",
                                    width: "180px",
                                }}
                            >
                                <span>Delete Conversation</span>
                                <DeleteIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                        {user.isAdministrator && (
                            <MenuItem
                                onClick={() => {
                                    handlePermanentlyDeleteConversation();
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0",
                                        width: "180px",
                                    }}
                                >
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "150px",
                                            color: "red",
                                        }}
                                    >
                                        Permanently Delete Conversation
                                    </span>
                                    <DeleteIcon
                                        fontSize="small"
                                        style={{ color: "red" }}
                                    />
                                </SoftBox>
                            </MenuItem>
                        )}
                    </Menu>

                    <Modal
                        open={isChangeAssistantModalOpen}
                        onClose={toggleChangeAssistantModal}
                    >
                        <Box
                            sx={{
                                ...style,
                            }}
                        >
                            <SoftSelect
                                placeholder="Change assistant"
                                defaultValue={{
                                    value: selectedConversation?.activeAssistant
                                        ? selectedConversation?.activeAssistant
                                              ?.id
                                        : null,
                                    label: selectedConversation?.activeAssistant
                                        ? selectedConversation?.activeAssistant
                                              ?.displayName
                                        : "Without assistant",
                                }}
                                options={[
                                    ...(assistants.map((a) => ({
                                        value: a._id.toString(),
                                        label: a.displayName,
                                    })) || []),
                                    {
                                        value: null,
                                        label: "Without assistant",
                                    },
                                ]}
                                onChange={({ value }) => setAssistantId(value)}
                            />
                            <Box
                                sx={{
                                    marginTop: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        borderRadius: 1,
                                        bgcolor: "#1C4C73",
                                        color: "#fff",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        handleChangeAssistant();
                                    }}
                                >
                                    Save
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#1C4C73",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={toggleChangeAssistantModal}
                                >
                                    Close
                                </Typography>
                            </Box>
                        </Box>
                    </Modal>
                </>
            ) : (
                <Box height={40} />
            )}
        </Box>
    );
}
