import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Card, Divider, Grid, Modal, Typography } from "@mui/material";
import voiceNoteLogo from "assets/images/assistant-skills/voice-note.png";
import { updateAssistant, uploadVoiceNote } from "common/api/assistant";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import Waveform from "layouts/dashboards/conversations/components/WaveForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../../../../../redux/hooks/useAuth";

const extensionSettingsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 100,
};

function VoiceNoteSkill({ formField }) {
    const { values, setFieldValue } = useFormikContext();

    const { skills: skillsValue } = values;

    const [extension, setExtension] = useState(
        skillsValue.filter((_) => _.name === "voiceNote")[0]
            ? skillsValue.filter((_) => _.name === "voiceNote")[0]
            : undefined
    );

    const { user } = useAuth();

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isCreateVoiceNoteModalOpen, setIsCreateVoiceNoteModalOpen] =
        useState(false);

    const [isVoiceNoteUploading, setIsVoiceNoteUploading] = useState(false);
    const [voiceNoteFormData, setVoiceNoteFormData] = useState({
        url: "",
        title: "",
        description: "",
        alternateText: "",
    });

    const { assistantId } = useParams();

    const toggleCreateVoiceNoteModal = () => {
        if (isVoiceNoteUploading) {
            return;
        }

        setIsCreateVoiceNoteModalOpen(!isCreateVoiceNoteModalOpen);
    };

    const toggleSettingsModal = () => {
        if (isVoiceNoteUploading) {
            return;
        }

        setIsSettingsModalOpen(!isSettingsModalOpen);
    };

    useEffect(() => {
        setExtension(
            skillsValue.filter((_) => _.name === "voiceNote")[0]
                ? skillsValue.filter((_) => _.name === "voiceNote")[0]
                : {
                      name: "voiceNote",
                      config: {
                          voiceNotes: [],
                      },
                  }
        );
    }, [isSettingsModalOpen]);

    useEffect(() => {
        if (skillsValue) {
            setExtension(
                skillsValue.filter((_) => _.name === "voiceNote")[0]
                    ? skillsValue.filter((_) => _.name === "voiceNote")[0]
                    : undefined
            );
        }
    }, [skillsValue]);

    useEffect(() => {
        setVoiceNoteFormData({
            url: "",
            title: "",
            description: "",
        });
    }, [isCreateVoiceNoteModalOpen]);

    const handleCreateVoiceNote = async () => {
        if (isVoiceNoteUploading) {
            toast.error("Please wait for the voice note to finish uploading.");
        }

        if (
            !voiceNoteFormData.url ||
            !voiceNoteFormData.title ||
            !voiceNoteFormData.description ||
            !voiceNoteFormData.alternateText
        ) {
            return toast.error(
                "Voice Note / Title / Description / Alternate Text cannot be empty."
            );
        }

        setExtension({
            name: "voiceNote",
            config: {
                voiceNotes: [
                    ...extension.config.voiceNotes,
                    {
                        url: voiceNoteFormData.url,
                        title: voiceNoteFormData.title,
                        description: voiceNoteFormData.description,
                        alternateText: voiceNoteFormData.alternateText,
                    },
                ],
            },
        });

        setIsCreateVoiceNoteModalOpen(false);
    };

    const handleAudioInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!file) {
            return;
        }

        reader.onloadend = () => {
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            setIsVoiceNoteUploading(true);
            toast.info("Uploading voice note. Please wait...");
            uploadVoiceNote({
                voiceNote: base64String,
                assistantId: assistantId || null,
            })
                .then((response) => {
                    setIsVoiceNoteUploading(false);
                    if (response.status === 200) {
                        setVoiceNoteFormData({
                            url: response.data.payload.cloudinarySecureUrl,
                            description: response.data.payload.transcription,
                        });
                    }
                })
                .catch((error) => {
                    setIsVoiceNoteUploading(false);
                    console.error(error);
                    toast.error(
                        "Error uploading voice note. Please try again."
                    );
                });
        };

        reader.readAsDataURL(file);
    };

    return (
        <>
            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "0.375rem",
                    border: "1px solid #E0E0E0",
                    px: 1.5,
                    py: 1.5,
                    p: 0,
                }}
            >
                <SoftBox p={2}>
                    <SoftBox display="flex" alignItems="center">
                        <SoftAvatar
                            src={voiceNoteLogo}
                            alt={`Voice Note Logo`}
                            size="lg"
                            variant="rounded"
                        />
                        <SoftBox ml={2} lineHeight={0}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography
                                    variant="h5"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                >
                                    Voice Note
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox my={2} lineHeight={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            With Voice Note, your assistant will be able to send
                            pre-recorded voice notes to your contacts.
                        </SoftTypography>
                    </SoftBox>
                    <Divider />
                    <SoftBox
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {skillsValue?.filter(
                            (_) => _.name === "voiceNote"
                        )[0] === undefined && (
                            <SoftBox
                                lineHeight={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    px: 2,
                                    py: 1,
                                    cursor: "pointer",
                                    backgroundColor: !user.extensions.filter(
                                        (_) => _.name === "voiceNote"
                                    )[0]
                                        ? "#787878"
                                        : "#1c4c73",
                                    borderRadius: "4px",
                                }}
                                onClick={() => {
                                    if (
                                        !user.extensions.filter(
                                            (_) => _.name === "voiceNote"
                                        )[0]
                                    ) {
                                        toast.error(
                                            "Please upgrade your account to use this feature."
                                        );
                                    } else {
                                        toggleSettingsModal();
                                    }
                                }}
                            >
                                <SoftTypography
                                    variant="button"
                                    fontWeight="medium"
                                    color="white"
                                >
                                    {!user.extensions.filter(
                                        (_) => _.name === "voiceNote"
                                    )[0]
                                        ? "Locked"
                                        : "Activate"}
                                </SoftTypography>
                                {!user.extensions.filter(
                                    (_) => _.name === "voiceNote"
                                )[0] ? (
                                    <LockIcon color="white" />
                                ) : (
                                    <AddIcon color="white" />
                                )}
                            </SoftBox>
                        )}

                        {skillsValue?.filter(
                            (_) => _.name === "voiceNote"
                        )[0] && (
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "red",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        setFieldValue(formField, [
                                            ...skillsValue.filter(
                                                (_) => _.name !== "voiceNote"
                                            ),
                                        ]);

                                        updateAssistant(assistantId, {
                                            skills: [
                                                ...skillsValue.filter(
                                                    (_) =>
                                                        _.name !== "voiceNote"
                                                ),
                                            ],
                                        });

                                        toast.success(
                                            "Voice Note skill removed."
                                        );
                                    }}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Remove
                                    </SoftTypography>
                                    <DeleteIcon color="white" />
                                </SoftBox>
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "#1c4c73",
                                        borderRadius: "4px",
                                    }}
                                    onClick={toggleSettingsModal}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                    <SettingsIcon color="white" />
                                </SoftBox>
                            </SoftBox>
                        )}
                    </SoftBox>
                </SoftBox>
            </Card>
            <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
                <Box
                    sx={{
                        ...extensionSettingsModalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Configure Voice Note
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Upload voice notes that assistant will use
                                during conversations with contacts.
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox mt={1.625}>
                        <Grid mt={1.625} item xs={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Voice Notes
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox
                                sx={{
                                    py: 1,
                                    borderRadius: "0.375rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#1C4C73",
                                    cursor: "pointer",
                                }}
                                onClick={toggleCreateVoiceNoteModal}
                            >
                                <SoftTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="white"
                                >
                                    Upload Voice Note
                                </SoftTypography>
                            </SoftBox>
                            {extension?.config?.voiceNotes?.map(
                                (voiceNote, index) => (
                                    <SoftBox
                                        key={`assistantVoiceNotes-${index}`}
                                        sx={{
                                            border: "1px solid #E0E0E0",
                                            px: 1.5,
                                            py: 1.5,
                                            borderRadius: "0.375rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            mt: 1,
                                        }}
                                    >
                                        <SoftBox
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: "100%",
                                            }}
                                        >
                                            <SoftBox
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    flexDirection: "column",
                                                    bgcolor: "#1C4C73",
                                                    px: 2,
                                                    py: 1,
                                                    borderRadius: "0.5rem",
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Waveform
                                                    audio={voiceNote.url}
                                                />
                                            </SoftBox>
                                            <SoftTypography
                                                variant="body2"
                                                color="text"
                                            >
                                                {voiceNote.title}
                                            </SoftTypography>
                                            <SoftTypography
                                                variant="caption"
                                                color="text"
                                            >
                                                {voiceNote.description}
                                            </SoftTypography>
                                        </SoftBox>
                                        <CloseIcon
                                            fontSize="medium"
                                            sx={{
                                                cursor: "pointer",
                                                ml: 1.5,
                                            }}
                                            onClick={() => {
                                                setExtension({
                                                    name: "voiceNote",
                                                    config: {
                                                        voiceNotes: [
                                                            ...extension.config.voiceNotes.slice(
                                                                0,
                                                                index
                                                            ),
                                                            ...extension.config.voiceNotes.slice(
                                                                index + 1
                                                            ),
                                                        ],
                                                    },
                                                });
                                            }}
                                        />
                                    </SoftBox>
                                )
                            )}

                            <SoftBox
                                sx={{
                                    mt: 3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        toggleSettingsModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="black"
                                    >
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1C4C73",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (
                                            extension.config.voiceNotes
                                                .length === 0
                                        ) {
                                            return toast.error(
                                                "Please add at least one voice note to continue."
                                            );
                                        }

                                        updateAssistant(assistantId, {
                                            skills: [
                                                ...skillsValue.filter(
                                                    (_) =>
                                                        _.name !== "voiceNote"
                                                ),
                                                extension,
                                            ],
                                        })
                                            .then((response) => {
                                                const voiceNoteExtension =
                                                    response.data.payload.skills.filter(
                                                        (_) =>
                                                            _.name ===
                                                            "voiceNote"
                                                    )[0];

                                                setFieldValue(formField, [
                                                    ...skillsValue.filter(
                                                        (_) =>
                                                            _.name !==
                                                            "voiceNote"
                                                    ),
                                                    voiceNoteExtension,
                                                ]);

                                                toast.success(
                                                    "Voice Note skill configured."
                                                );
                                                toggleSettingsModal();
                                            })
                                            .catch((error) => {
                                                toast.error(
                                                    "Error configuring Voice Note skill."
                                                );

                                                console.error(error);
                                            });
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                        <Modal
                            open={isCreateVoiceNoteModalOpen}
                            onClose={toggleCreateVoiceNoteModal}
                        >
                            <Box
                                sx={{
                                    ...style,
                                }}
                            >
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Voice Note
                                    </SoftTypography>
                                </SoftBox>

                                <SoftInput
                                    type="file"
                                    accept="audio/*"
                                    disabled={isVoiceNoteUploading}
                                    onChange={handleAudioInputChange}
                                />

                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Title"
                                    placeholder="Enter Title"
                                    value={voiceNoteFormData.title}
                                    disabled={isVoiceNoteUploading}
                                    onChange={(event) => {
                                        setVoiceNoteFormData((_) => ({
                                            ..._,
                                            title: event.target.value,
                                        }));
                                    }}
                                />

                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Description
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Description"
                                    placeholder="Enter Description"
                                    value={voiceNoteFormData.description}
                                    multiline
                                    rows={4}
                                    disabled={isVoiceNoteUploading}
                                    onChange={(event) => {
                                        setVoiceNoteFormData({
                                            ...voiceNoteFormData,
                                            description: event.target.value,
                                        });
                                    }}
                                />

                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Alternate Text
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Alternate Text"
                                    placeholder="Enter Alternate Text"
                                    value={voiceNoteFormData.alternateText}
                                    multiline
                                    rows={4}
                                    disabled={isVoiceNoteUploading}
                                    onChange={(event) => {
                                        setVoiceNoteFormData({
                                            ...voiceNoteFormData,
                                            alternateText: event.target.value,
                                        });
                                    }}
                                />

                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            borderRadius: 1,
                                            bgcolor: "#1C4C73",
                                            color: "#fff",
                                            px: 3,
                                            py: 0.6,
                                            cursor: "pointer",
                                        }}
                                        onClick={handleCreateVoiceNote}
                                    >
                                        {isVoiceNoteUploading
                                            ? "Uploading..."
                                            : "Create"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: "#1C4C73",
                                            px: 3,
                                            py: 0.6,
                                            cursor: "pointer",
                                        }}
                                        onClick={toggleCreateVoiceNoteModal}
                                    >
                                        Close
                                    </Typography>
                                </Box>
                            </Box>
                        </Modal>
                    </SoftBox>
                </Box>
            </Modal>
        </>
    );
}

export default VoiceNoteSkill;
