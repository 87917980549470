import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CalendarRoot from "examples/Calendar/CalendarRoot";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

function ScheduledMessagesCalendar({ scheduledMessagesStatistic, ...rest }) {
    const calendarRef = useRef(null);
    const [currentMonth, setCurrentMonth] = useState("");

    const handleDatesSet = (dateInfo) => {
        setCurrentMonth(dateInfo.startStr);
    };

    const events = scheduledMessagesStatistic?.data.map((scheduledMessage) => ({
        title: scheduledMessage.count,
        start: scheduledMessage.date,
        end: scheduledMessage.date,
        className: "event-info",
    }));

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox
                pt={2}
                px={2}
                lineHeight={1}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <SoftBox>
                    <Link to="/scheduled-messages">
                        <SoftTypography
                            variant="h6"
                            fontWeight="medium"
                            textTransform="capitalize"
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                        >
                            Scheduled Messages
                        </SoftTypography>
                    </Link>
                    <SoftTypography
                        component="p"
                        variant="button"
                        color="text"
                        fontWeight="medium"
                    >
                        {new Date(currentMonth).toLocaleDateString("en-US", {
                            month: "long",
                            year: "numeric",
                        })}
                    </SoftTypography>
                </SoftBox>
                <SoftBox
                    sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                    }}
                >
                    <Tooltip title="Sent Messages">
                        <SoftBox
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <SoftTypography
                                variant="body1"
                                color="black"
                                fontWeight="medium"
                                mr={1}
                                sx={{
                                    color: "#23731c",
                                }}
                            >
                                {(
                                    scheduledMessagesStatistic?.valueOfSent || 0
                                ).toLocaleString()}
                            </SoftTypography>
                            <AlarmOnIcon
                                fontSize="medium"
                                sx={{
                                    color: "#23731c",
                                }}
                            />
                        </SoftBox>
                    </Tooltip>

                    <Tooltip title="Pending Messages">
                        <SoftBox
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <SoftTypography
                                variant="body1"
                                color="black"
                                fontWeight="medium"
                                mr={1}
                                sx={{
                                    color: "#959595",
                                }}
                            >
                                {(
                                    scheduledMessagesStatistic?.valueOfPending ||
                                    0
                                ).toLocaleString()}
                            </SoftTypography>
                            <AlarmIcon
                                fontSize="medium"
                                sx={{
                                    color: "#959595",
                                }}
                            />
                        </SoftBox>
                    </Tooltip>
                </SoftBox>
            </SoftBox>
            <CalendarRoot p={2}>
                <FullCalendar
                    ref={calendarRef}
                    {...rest}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={events}
                    height="100%"
                    headerToolbar={false}
                    datesSet={handleDatesSet}
                />
            </CalendarRoot>
            <SoftBox
                px={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <SoftBox
                    onClick={() => {
                        calendarRef.current.getApi().prev();
                    }}
                    sx={{
                        p: 3,
                        cursor: "pointer",
                    }}
                >
                    <ArrowBackIosIcon fontSize="medium" color="black" />
                </SoftBox>
                <SoftBox
                    onClick={() => {
                        calendarRef.current.getApi().next();
                    }}
                    sx={{
                        p: 3,
                        cursor: "pointer",
                    }}
                >
                    <ArrowForwardIosIcon fontSize="medium" color="black" />
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default ScheduledMessagesCalendar;
