const { apiAxios } = require("common/services/httpService");

export const getContacts = (params) => {
    return apiAxios.get("contact", params);
};

export const getContact = (id) => {
    return apiAxios.get(`contact/${id}`);
};

export const updateCustomFields = (id, data) => {
    return apiAxios.patch(`contact/${id}/custom_fields`, data);
};
