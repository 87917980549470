import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        displayAvatar,
        displayName,
        displayColor,
        name,
        age,
        gender,
        ethnicity,
        emojiSkinTone,
        background,
        jobDescription,
        role,
        style,
        conversationStepGroups,
        defaultConversationStepGroup,
        followUpGroups,
        defaultFollowUpGroup,
        additional,
        teamNotes,
        business,
        readTime,
        assistantLinks,
        skills,
    },
} = checkout;

const validations = [
    // Basic Info
    Yup.object().shape({
        [displayName.name]: Yup.string().required(displayName.errorMsg),
        [displayColor.name]: Yup.string().required(displayColor.errorMsg),
        [name.name]: Yup.string().required(name.errorMsg),
        [age.name]: Yup.string().required(age.errorMsg),
        [gender.name]: Yup.string()
            .oneOf(["male", "female"], "Gender is required.")
            .required(gender.errorMsg),
        [ethnicity.name]: Yup.string().required(ethnicity.errorMsg),
        [emojiSkinTone.name]: Yup.string()
            .oneOf(
                [
                    "light_skin_tone",
                    "medium_light_skin_tone",
                    "medium_skin_tone",
                    "medium_dark_skin_tone",
                    "dark_skin_tone",
                ],
                "Emoji Skin Tone is required."
            )
            .required(emojiSkinTone.errorMsg),
    }),
    // Preferences
    Yup.object().shape({
        [business.name]: Yup.string().required(business.errorMsg),
        [readTime.name]: Yup.number()
            .min(0, "Number must be greater than or equal to 0")
            .required(readTime.errorMsg),
        // [readTime.name]: Yup.string().required(readTime.errorMsg),
        // [city.name]: Yup.string().required(city.errorMsg),
        // [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
    }),
    // Assistant Skills
    Yup.object().shape({}),
    // Conversation Steps
    Yup.object().shape({}),
    // Follow Up Groups
    Yup.object().shape({}),
    // Additional Details
    Yup.object().shape({
        [role.name]: Yup.string().required(role.errorMsg),
        [style.name]: Yup.string().required(style.errorMsg),
        [background.name]: Yup.string().required(background.errorMsg),
        [jobDescription.name]: Yup.string().required(jobDescription.errorMsg),
        // [name.name]: Yup.string().required(name.errorMsg),
        // [age.name]: Yup.number().required(age.errorMsg),
        // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
        // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
        // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
        // [repeatPassword.name]: Yup.string()
        //   .required(repeatPassword.errorMsg)
        //   .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    }),
];

export default validations;
