import AddIcon from "@mui/icons-material/Add";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LaptopIcon from "@mui/icons-material/Laptop";
import { Box, Modal, Stack, Tooltip, Typography } from "@mui/material";
import {
    adminCreatePhoneNumberDirectLink,
    adminDeletePhoneNumberDirectLink,
    adminUpdatePhoneNumberDirectLink,
} from "common/api/admin/phoneNumber";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const directLinksModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 99,
};

const directLinkModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 100,
};

function DirectLinksCell({ phoneNumber }) {
    const [directLinks, setDirectLinks] = useState(phoneNumber.directLinks);
    const [isDirectLinksModalOpen, setIsDirectLinksModalOpen] = useState(false);
    const [isDirectLinkModalOpen, setIsDirectLinkModalOpen] = useState(false);

    const [selectedDirectLink, setSelectedDirectLink] = useState(null);
    const [directLinkFormData, setDirectLinkFormData] = useState({
        externalLink: "",
        content: "",
    });

    const toggleDirectLinksModal = () => {
        setIsDirectLinksModalOpen(!isDirectLinksModalOpen);
    };

    const toggleDirectLinkModal = () => {
        setIsDirectLinkModalOpen(!isDirectLinkModalOpen);
    };

    const handleCreateDirectLink = () => {
        if (directLinkFormData.externalLink === "") {
            toast.error("External Link is required!");
            return;
        }

        if (
            !directLinkFormData.externalLink.startsWith("http://") &&
            !directLinkFormData.externalLink.startsWith("https://")
        ) {
            return toast.error(
                "External Link should start with http:// or https://"
            );
        }

        if (directLinkFormData.content === "") {
            toast.error("Content is required!");
            return;
        }

        adminCreatePhoneNumberDirectLink(phoneNumber._id, {
            externalLink: directLinkFormData.externalLink,
            content: directLinkFormData.content,
        })
            .then((response) => {
                setDirectLinks(response.data.payload);
                setDirectLinkFormData({
                    link: response.data.payload.at(-1).link,
                    externalLink: response.data.payload.at(-1).externalLink,
                    content: response.data.payload.at(-1).content,
                });
                toast.success("Direct Link created successfully.");
            })
            .catch((error) => {
                toast.error(
                    error.response?.data?.error?.message || error.message
                );
            });
    };

    const handleUpdateDirectLink = () => {
        if (directLinkFormData.externalLink === "") {
            toast.error("External Link is required!");
            return;
        }

        if (
            !directLinkFormData.externalLink.startsWith("http://") &&
            !directLinkFormData.externalLink.startsWith("https://")
        ) {
            return toast.error(
                "External Link should start with http:// or https://"
            );
        }

        if (directLinkFormData.content === "") {
            toast.error("Content is required!");
            return;
        }

        adminUpdatePhoneNumberDirectLink(phoneNumber._id, selectedDirectLink, {
            externalLink: directLinkFormData.externalLink,
            content: directLinkFormData.content,
        })
            .then((response) => {
                setDirectLinks(response.data.payload);
                toast.success("Direct Link updated successfully.");
                toggleDirectLinkModal();
            })
            .catch((error) => {
                toast.error(
                    error.response?.data?.error?.message || error.message
                );
            });
    };

    const handleDeleteDirectLink = (directLinkId) => {
        if (
            window.confirm(
                "This will delete the direct link immediately, link will no longer be usable. Proceed?"
            )
        ) {
            adminDeletePhoneNumberDirectLink(phoneNumber._id, directLinkId)
                .then((response) => {
                    setDirectLinks(response.data.payload);
                    toast.success("Direct Link deleted successfully.");
                })
                .catch((error) => {
                    toast.error(
                        error.response?.data?.error?.message || error.message
                    );
                });
        }
    };

    const copyLinkToClipboard = (link) => {
        navigator.clipboard.writeText(link);
        toast.success("Link copied to clipboard!");
    };

    useEffect(() => {
        setDirectLinks(phoneNumber.directLinks);
    }, [phoneNumber]);

    return (
        <>
            <SoftBox
                sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    userSelect: "none",
                    color: "#000000",
                }}
                onClick={toggleDirectLinksModal}
            >
                {directLinks?.length} Direct Link/s
            </SoftBox>
            <Modal
                open={isDirectLinksModalOpen}
                onClose={toggleDirectLinksModal}
            >
                <Box
                    sx={{
                        ...directLinksModalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Direct Links ({directLinks.length})
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Direct links allows you to generate short links
                                that takes contacts directly in your inbox.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    p: 1,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                    backgroundColor: "#1C4C73",
                                    color: "#fff",
                                }}
                                onClick={() => {
                                    setSelectedDirectLink(null);
                                    setDirectLinkFormData({
                                        externalLink: "",
                                        content: "",
                                    });
                                    toggleDirectLinkModal();
                                }}
                            >
                                <AddIcon fontSize="medium" />
                            </SoftBox>
                        </Stack>
                    </SoftBox>

                    <SoftBox lineHeight={0} mt={3}></SoftBox>

                    {directLinks.length === 0 && (
                        <SoftBox
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftTypography
                                variant="body2"
                                fontWeight="regular"
                                color="black"
                                sx={{
                                    lineHeight: 1.4,
                                }}
                            >
                                This number has no direct links yet. Click{" "}
                                <Typography
                                    component="span"
                                    onClick={() => {
                                        setSelectedDirectLink(null);
                                        setDirectLinkFormData({
                                            externalLink: "",
                                            content: "",
                                        });
                                        toggleDirectLinkModal();
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        fontSize: "inherit",
                                    }}
                                >
                                    here
                                </Typography>{" "}
                                to create one.
                            </SoftTypography>
                        </SoftBox>
                    )}

                    {directLinks.map((directLink, index) => (
                        <SoftBox
                            key={`phoneNumber-${phoneNumber._id}-directLinks-${index}`}
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: 1,
                                }}
                            >
                                <SoftTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="black"
                                    sx={{
                                        cursor: "pointer",
                                        lineHeight: 1.4,
                                    }}
                                    onClick={() => {
                                        copyLinkToClipboard(directLink.link);
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontSize: "inherit",
                                            color: "#67748e",
                                        }}
                                    >
                                        https://
                                    </Typography>
                                    <Tooltip title="Copy" placement="top">
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontSize: "inherit",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {directLink.link.replace(
                                                /^(http:\/\/|https:\/\/)/,
                                                ""
                                            )}
                                        </Typography>
                                    </Tooltip>
                                </SoftTypography>
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        gap: 2,
                                    }}
                                >
                                    <SoftTypography
                                        variant="caption"
                                        color="text"
                                        sx={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            gap: 1,
                                            lineHeight: 1.4,
                                        }}
                                    >
                                        <LaptopIcon />{" "}
                                        {directLink.externalLink.length > 30
                                            ? directLink.externalLink.slice(
                                                  0,
                                                  46
                                              ) + "..."
                                            : directLink.externalLink}
                                    </SoftTypography>
                                    <SoftTypography
                                        variant="caption"
                                        color="text"
                                        sx={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            gap: 1,
                                            lineHeight: 1.4,
                                        }}
                                    >
                                        <ChatBubbleOutlineIcon />
                                        {directLink.content}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                <Tooltip title="Copy" placement="top">
                                    <ContentCopyIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            copyLinkToClipboard(
                                                directLink.link
                                            );
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                    <EditIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setSelectedDirectLink(
                                                directLink.linkId
                                            );
                                            setDirectLinkFormData({
                                                externalLink:
                                                    directLink.externalLink,
                                                content: directLink.content,
                                            });
                                            toggleDirectLinkModal();
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <DeleteIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            handleDeleteDirectLink(
                                                directLink.linkId
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                    ))}
                </Box>
            </Modal>
            <Modal open={isDirectLinkModalOpen} onClose={toggleDirectLinkModal}>
                <Box
                    sx={{
                        ...directLinkModalStyle,
                    }}
                >
                    <SoftBox
                        mb={1}
                        lineHeight={0}
                        sx={{
                            color: "#000000",
                        }}
                    >
                        <SoftTypography variant="h5" fontWeight="bold">
                            {directLinkFormData.link
                                ? "🎉 Direct Link Created"
                                : selectedDirectLink
                                ? "Edit Direct Link"
                                : "Add Direct Link"}
                        </SoftTypography>
                    </SoftBox>

                    {directLinkFormData.link ? (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Here is your direct link
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Direct Link"
                                disabled={true}
                                value={directLinkFormData.link}
                                onClick={() => {
                                    copyLinkToClipboard(
                                        directLinkFormData.link
                                    );
                                }}
                            />
                            <Box
                                sx={{
                                    marginTop: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        borderRadius: 1,
                                        bgcolor: "#1C4C73",
                                        color: "#fff",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        copyLinkToClipboard(
                                            directLinkFormData.link
                                        );
                                        toggleDirectLinkModal();
                                    }}
                                >
                                    Copy Link
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#1C4C73",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={toggleDirectLinkModal}
                                >
                                    Finish & Close
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    External Link
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="External Link"
                                placeholder="Enter External Link"
                                value={directLinkFormData.externalLink}
                                onChange={(event) => {
                                    setDirectLinkFormData({
                                        ...directLinkFormData,
                                        externalLink: event.target.value,
                                    });
                                }}
                            />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Content
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Content"
                                placeholder="Enter Content"
                                value={directLinkFormData.content}
                                multiline
                                rows={4}
                                onChange={(event) => {
                                    setDirectLinkFormData({
                                        ...directLinkFormData,
                                        content: event.target.value,
                                    });
                                }}
                            />
                            <Box
                                sx={{
                                    marginTop: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        borderRadius: 1,
                                        bgcolor: "#1C4C73",
                                        color: "#fff",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        selectedDirectLink !== null
                                            ? handleUpdateDirectLink(
                                                  selectedDirectLink
                                              )
                                            : handleCreateDirectLink();
                                    }}
                                >
                                    {selectedDirectLink !== null
                                        ? "Update"
                                        : "Create"}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#1C4C73",
                                        px: 3,
                                        py: 0.6,
                                        cursor: "pointer",
                                    }}
                                    onClick={toggleDirectLinkModal}
                                >
                                    Close
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
}

export default DirectLinksCell;
