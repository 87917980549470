import { Grid, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import SelectField from "pages/Settings/components/SelectField";
import PropTypes from "prop-types";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import FormField from "../FormField";

function PersonalInfo({ formData }) {
    const { formField, errors, touched } = formData;
    const {
        firstName,
        lastName,
        userEmail,
        password,
        isAdministrator,
        preferredTimezone,
    } = formField;

    const { values, setFieldValue } = useFormikContext();

    const { options, parseTimezone } = useTimezoneSelect({
        labelStyle: "original",
        timezones: allTimezones,
    });

    const {
        firstName: firstNameValue,
        lastName: lastNameValue,
        userEmail: userEmailValue,
        password: passwordValue,
        isAdministrator: isAdministratorValue,
        preferredTimezone: preferredTimezoneValue,
    } = values;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Personal Info
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Fill in the personal information of the user.
                </SoftTypography>
            </SoftBox>
            <SoftBox py={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={firstName.type}
                            label={firstName.label}
                            name={firstName.name}
                            placeholder={firstName.placeholder}
                            error={errors?.firstName && touched?.firstName}
                            success={
                                firstNameValue.length > 0 && !errors?.firstName
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={lastName.type}
                            label={lastName.label}
                            name={lastName.name}
                            placeholder={lastName.placeholder}
                            error={errors?.lastName && touched?.lastName}
                            success={
                                lastNameValue.length > 0 && !errors?.lastName
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={userEmail.type}
                        label={userEmail.label}
                        name={userEmail.name}
                        placeholder={userEmail.placeholder}
                        error={errors?.userEmail && touched?.userEmail}
                        success={
                            userEmailValue.length > 0 && !errors?.userEmail
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} mb={1.5}>
                    <SelectField
                        label="Preferred Timezone"
                        value={
                            options.filter(
                                (item) => item.value === preferredTimezoneValue
                            )[0]
                        }
                        options={options}
                        onChange={({ value }) =>
                            setFieldValue(preferredTimezone.name, value)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={password.type}
                        label={password.label}
                        name={password.name}
                        placeholder={password.placeholder}
                        error={errors?.password && touched?.password}
                        success={passwordValue.length > 0 && !errors?.password}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <SoftBox
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            gap: "1rem",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setFieldValue(
                                isAdministrator.name,
                                !isAdministratorValue
                            );
                        }}
                    >
                        <Switch
                            checked={isAdministratorValue}
                            onChange={(e) => {
                                setFieldValue(
                                    isAdministrator.name,
                                    !isAdministratorValue
                                );
                            }}
                        />
                        <SoftTypography variant="body2">
                            Administrator User
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

PersonalInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default PersonalInfo;
