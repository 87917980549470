import AccountBox from "@mui/icons-material/AccountBox";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { Box, Tooltip, Typography, alpha, styled } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ContactInfoPanel } from "./ContactInfoPanel";
import { ContactInfoRow } from "./ContactInfoRow";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { AdsClick } from "@mui/icons-material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Card from "@mui/material/Card";
import { colors } from "assets/mui/theme";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApp from "../../../../redux/hooks/useApp";
import classes from "../Conversations.module.css";
import { Attachments } from "./Attachments";

const StyledCalendar = styled(DateCalendar)((theme) => ({
    "& .MuiPickersDay-root.Mui-selected": {
        backgroundColor: colors.blue[200],
        "&:hover": {
            backgroundColor: colors.blue[200],
        },
    },
    "& .MuiPickersDay-root:hover": {
        background: alpha(colors.blue[200], 0.2),
    },
    "& .MuiPickersCalendarHeader-root": {
        color: colors.blue[200],
    },
    "& .MuiPickersArrowSwitcher-root button": {
        color: colors.blue[200],
    },
    "& .MuiDayCalendar-header span": {
        color: colors.blue[200],
    },
}));

/* eslint-disable react/prop-types */

export function ContactInfo() {
    const [followUp, setFollowUp] = useState(null);

    const {
        isSelectedConversationLoading,
        selectedConversation,
        selectedConversationMessages,
    } = useApp();

    const saveFollowUp = useCallback(
        (value) => {
            let followUps = localStorage.getItem("followsUps");
            followUps = followUps ? JSON.parse(followUps) : [];

            const updatedFollowUps = [
                ...followUps.filter(
                    (f) => f.contact !== selectedConversation?.contact?._id
                ),
                {
                    contact: selectedConversation?.contact?._id,
                    followUp: value.toISOString(),
                },
            ];
            localStorage.setItem(
                "followsUps",
                JSON.stringify(updatedFollowUps)
            );
            setFollowUp(value);
        },
        [selectedConversation]
    );

    useEffect(() => {
        if (!selectedConversation) return;

        let followUps = localStorage.getItem("followsUps");
        followUps = followUps ? JSON.parse(followUps) : [];

        const contactFollowUp = followUps.find(
            (c) => selectedConversation?.contact?._id === c.contact
        );
        setFollowUp(contactFollowUp ? dayjs(contactFollowUp.followUp) : null);
    }, [selectedConversation]);

    if (isSelectedConversationLoading) {
        return <></>;
    }

    return (
        selectedConversation && (
            <Box
                display={"flex"}
                flexDirection={"column"}
                className={classes.contactInfoContent}
            >
                <Box sx={{ marginBottom: "2.2rem" }}>
                    <ContactInfoPanel />
                </Box>

                <Box
                    sx={{
                        borderRadius: 4,
                    }}
                >
                    <Card>
                        <Box p={2.6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                }}
                            >
                                <AccountBox />
                                <Typography
                                    sx={{
                                        marginLeft: "10px",
                                        fontWeight: 500,
                                        fontSize: 16,
                                    }}
                                >
                                    Contact
                                </Typography>
                            </Box>

                            <Accordion>
                                <AccordionSummary
                                    className={classes.personalInfoPanel}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <Typography>Personal</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ContactInfoRow
                                        label="Name: "
                                        value={
                                            selectedConversation.contact
                                                ?.name || "N/A"
                                        }
                                        sx={{ marginBottom: "10px" }}
                                        icon={
                                            selectedConversation.contact
                                                ?.name ? (
                                                <VerifiedUser
                                                    sx={{ color: "#468df5" }}
                                                />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    ></ContactInfoRow>
                                    <ContactInfoRow
                                        label="Number:"
                                        value={
                                            selectedConversation.contact?.phone
                                        }
                                        icon={
                                            <VerifiedUser
                                                sx={{ color: "#468df5" }}
                                            />
                                        }
                                    ></ContactInfoRow>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    className={classes.personalInfoPanel}
                                >
                                    <Typography>
                                        Custom Fields (
                                        {
                                            selectedConversation.contact
                                                ?.customFields?.length
                                        }
                                        )
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {selectedConversation.contact?.customFields
                                        ?.length > 0 ? (
                                        selectedConversation.contact?.customFields?.map(
                                            (field) => (
                                                <ContactInfoRow
                                                    label={field.key}
                                                    value={field.value}
                                                />
                                            )
                                        )
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >
                                            No custom fields found.{" "}
                                            <Link
                                                to={`/contacts/${selectedConversation.contact?._id}`}
                                            >
                                                Add custom fields.
                                            </Link>
                                        </Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Card>
                </Box>

                <Box
                    marginTop="25px"
                    sx={{
                        borderRadius: 4,
                    }}
                >
                    <Card>
                        <Box p={2.6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                }}
                            >
                                <QuestionAnswerIcon />
                                <Typography
                                    sx={{
                                        marginLeft: "10px",
                                        fontWeight: 500,
                                        fontSize: 16,
                                    }}
                                >
                                    Conversation
                                </Typography>
                            </Box>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    className={classes.personalInfoPanel}
                                >
                                    <Typography>Information</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ContactInfoRow
                                        label="Assistant: "
                                        value={
                                            selectedConversation
                                                ?.activeAssistant
                                                ?.displayName || "N/A"
                                        }
                                        sx={{ marginBottom: "10px" }}
                                    />
                                    <ContactInfoRow
                                        label="Started At: "
                                        value={
                                            selectedConversation.startedAt
                                                ? dayjs(
                                                      selectedConversation.startedAt
                                                  ).format("D MMM, YYYY")
                                                : "N/A"
                                        }
                                        sx={{ marginBottom: "10px" }}
                                    />
                                    <ContactInfoRow
                                        label="Created At: "
                                        value={dayjs(
                                            selectedConversation.createdAt
                                        ).format("D MMM, YYYY")}
                                        sx={{ marginBottom: "10px" }}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    className={classes.personalInfoPanel}
                                >
                                    <Typography>
                                        Attachments (
                                        {
                                            selectedConversationMessages.flatMap(
                                                (m) => m.attachments
                                            ).length
                                        }
                                        )
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {selectedConversationMessages.flatMap(
                                        (m) => m.attachments
                                    ).length === 0 && (
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >
                                            No attachments found.
                                        </Typography>
                                    )}
                                    <Attachments
                                        attachments={selectedConversationMessages.flatMap(
                                            (m) => m.attachments
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    className={classes.personalInfoPanel}
                                >
                                    <Typography>
                                        Links (
                                        {
                                            selectedConversation.trackedLinks
                                                .length
                                        }
                                        )
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "5px",
                                    }}
                                >
                                    {selectedConversation.trackedLinks
                                        .length === 0 && (
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >
                                            Once someone clicks on a link, it
                                            will appear here.
                                        </Typography>
                                    )}
                                    {selectedConversation.trackedLinks.map(
                                        (link) => (
                                            <SoftBox
                                                sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems:
                                                        "justify-between",
                                                }}
                                            >
                                                <Tooltip title={link.link}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {link.link.length > 30
                                                            ? link.link.substring(
                                                                  0,
                                                                  30
                                                              ) + "..."
                                                            : link.link}
                                                    </Typography>
                                                </Tooltip>
                                                <SoftBox
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {link.totalClicks}
                                                    </Typography>
                                                    <AdsClick fontSize="inherit" />
                                                </SoftBox>
                                            </SoftBox>
                                        )
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Card>
                </Box>

                <Box marginTop="25px">
                    <Card>
                        <Box
                            p={2.6}
                            sx={{ borderBottom: "1px solid lightgray" }}
                            display="flex"
                        >
                            <CalendarMonthIcon />
                            <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                                Follow Up
                            </Typography>
                        </Box>
                        <StyledCalendar
                            value={followUp}
                            onChange={(value) => saveFollowUp(value)}
                        />
                    </Card>
                </Box>
            </Box>
        )
    );
}
