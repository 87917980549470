import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";

export const businessesColumns = [
    { Header: "DISPLAY NAME", accessor: "displayName", width: "20%" },
    {
        Header: "NAME",
        accessor: "name",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <SoftBadge
                variant="gradient"
                size="md"
                color="light"
                badgeContent={name}
                container
                border
                circular
            />
        ),
    },
    { Header: "STATE", accessor: "state", width: "15%" },
    { Header: "PHONE NUMBERS", accessor: "phoneNumbers", width: "15%" },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    { Header: "ACTION", accessor: "action", width: "30%" },
];

export function createTableRows(businesses, actions) {
    return businesses.map((business) => ({
        displayName: business.displayName,
        name: [business.name],
        state: business.state,
        phoneNumbers: business.phoneNumbers?.length || 0,
        createdAt: [
            new Date(business.createdAt).getTime(),
            dayjs(business.createdAt).format("D MMM, YYYY"),
        ],
        action: actions(business),
    }));
}
