import RefreshIcon from "@mui/icons-material/Refresh";
import { Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appActions } from "../../redux/appSlice";
import useApp from "../../redux/hooks/useApp";
import { contactsColumns, createTableRows } from "./Contacts.helpers";
import SelectBusiness from "./components/SelectBusiness";

function ContactsPage() {
    const { contacts, selectedBusinessId, contactsError, isContactsLoading } =
        useApp();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(appActions.fetchContacts());
    }, [selectedBusinessId]);

    const tableData = useMemo(() => {
        return {
            columns: contactsColumns,
            rows: createTableRows(contacts),
        };
    }, [contacts]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3} pb={3}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mb: 1,
                    }}
                >
                    <Grid item xs={3} lg={3} xl={3}>
                        <DefaultCounterCard
                            count={contacts.length}
                            suffix=""
                            title="contacts"
                            description="People You Are Connected With."
                        />
                    </Grid>
                </Grid>
                <Card>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        p={3}
                    >
                        <SoftBox lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                All Contacts
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                List of all contacts.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                onClick={() => {
                                    if (isContactsLoading) return;

                                    dispatch(appActions.fetchContacts());
                                }}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 1,
                                    pr: 2,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                }}
                            >
                                <RefreshIcon fontSize="medium" />
                            </SoftBox>
                            <SelectBusiness />
                        </Stack>
                    </SoftBox>
                    <DataTable table={tableData} canSearch />
                </Card>
            </SoftBox>
            <Loader open={isContactsLoading} />
            <Footer />
        </DashboardLayout>
    );
}

export default ContactsPage;
