import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import SelectAssistantCell from "./components/SelectAssistantCell";
import SelectBusinessCell from "./components/SelectBusinessCell";

export const phoneNumberGroupsColumns = [
    { Header: "ID", accessor: "_id", width: "20%" },
    { Header: "NAME", accessor: "name", width: "20%" },
    {
        Header: "DEFAULT ASSISTANT",
        accessor: "defaultAssistant",
        width: "20%",
        Cell: ({ value: [name, { phoneNumberGroupId }] }) => (
            <SelectAssistantCell
                defaultValue={name}
                phoneNumberGroupId={phoneNumberGroupId}
            />
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "business",
        width: "20%",
        Cell: ({ value: [name, { phoneNumberGroupId }] }) => (
            <SelectBusinessCell
                defaultValue={name}
                phoneNumberGroupId={phoneNumberGroupId}
            />
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(phoneNumberGroups, actions) {
    return phoneNumberGroups.map((phoneNumberGroup) => ({
        _id: phoneNumberGroup._id,
        name: phoneNumberGroup.name || "N/A",
        defaultAssistant: [
            phoneNumberGroup.defaultAssistant
                ? phoneNumberGroup.defaultAssistant._id
                : null,
            {
                phoneNumberGroupId: phoneNumberGroup._id,
            },
        ],
        business: [
            phoneNumberGroup.business ? phoneNumberGroup.business._id : null,
            { phoneNumberGroupId: phoneNumberGroup._id },
        ],
        createdAt: [
            new Date(phoneNumberGroup.createdAt).getTime(),
            dayjs(phoneNumberGroup.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
