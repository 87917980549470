/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

// @mui material components

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

import { CircularProgress } from "@mui/material";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import { resetPassword } from "common/api/user";

const { gradients } = colors;

function ResetPasswordPage() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [resetPasswordData, setResetPasswordData] = useState({
        passwordResetToken: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const passwordResetToken = params.get("tok");
        const email = params.get("email");

        setResetPasswordData({
            ...resetPasswordData,
            passwordResetToken,
        });

        setEmail(email);

        if (!passwordResetToken || !email) {
            navigate("/sign-in");
            toast.error("Invalid reset password token!");
        }
    }, []);

    const handleInput = (e) => {
        setResetPasswordData({
            ...resetPasswordData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        console.log(
            "[AUTHENTICATION / RESET PASSWORD]: Submitted reset password data: ",
            resetPasswordData
        );

        if (!resetPasswordData.password) {
            setIsLoading(false);
            return toast.error("Password is required!");
        }

        resetPassword(resetPasswordData)
            .then((response) => {
                console.log(
                    "[AUTHENTICATION / RESET PASSWORD]: Response: ",
                    response
                );

                setIsLoading(false);

                toast.success(response.data.payload);

                navigate("/sign-in");
            })
            .catch((error) => {
                console.log(
                    "[AUTHENTICATION / FORGOT PASSWORD]: Error: ",
                    error?.response?.data
                );
                toast.error(
                    error?.response?.data?.error?.message ||
                        "Unexpected error happened!"
                );
                setIsLoading(false);
            });
    };

    return (
        <IllustrationLayout
            color={linearGradient(
                gradients.darkTeal.main,
                gradients.darkTeal.state
            )}
            title="Reset your password"
            description="Enter your new password to reset your password."
            illustration={{
                image: chat,
                title: "AI Powered marketing, sales, and support through ios",
                description:
                    'Unlock the true power of generative AI for your business with "conversational marketing" The highest converting sales process ever created.',
            }}
        >
            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                    <SoftInput
                        type="email"
                        placeholder="Email"
                        size="large"
                        value={email}
                        name="email"
                        disabled={true}
                    />
                </SoftBox>
                <SoftBox mb={2}>
                    <SoftInput
                        type="password"
                        placeholder="Password"
                        size="large"
                        value={resetPasswordData.password}
                        name="password"
                        onChange={handleInput}
                    />
                </SoftBox>
                <SoftBox mt={3} mb={2}>
                    <SoftButton
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        disabled={isLoading}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                thickness={6}
                                color="inherit"
                            />
                        )}

                        {!isLoading && "Reset Password"}
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </IllustrationLayout>
    );
}

export default ResetPasswordPage;
