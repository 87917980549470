import { CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { memo, useEffect, useRef } from "react";
import useApp from "../../../../redux/hooks/useApp";
import classes from "../Conversations.module.css";
import { AddMessagePanel } from "./AddMessagePanel";
import { ConversationHeader } from "./ConversationHeader";
import { Message } from "./Message";

/* eslint-disable react/prop-types */

export const Conversation = memo(() => {
    const {
        isSelectedConversationLoading,
        selectedConversation,
        selectedConversationMessages,
        selectedConversationOptimisticMessages,
    } = useApp();

    const scrollRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (!scrollRef.current) return;

            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }, 100);
    }, [selectedConversationMessages, selectedConversationOptimisticMessages]);

    if (!selectedConversation) {
        return (
            <Stack height="100%">
                <Box sx={{ marginBottom: "1rem" }}>
                    <ConversationHeader />
                </Box>

                <Card
                    px={2}
                    sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: "lightgray",
                        padding: "1rem",
                    }}
                >
                    <SoftTypography
                        variant="body2"
                        sx={{ textAlign: "center" }}
                    >
                        Please select a conversation.
                    </SoftTypography>
                </Card>
            </Stack>
        );
    }

    const messages = !isSelectedConversationLoading
        ? [
              ...selectedConversationMessages,
              ...selectedConversationOptimisticMessages,
          ]
              ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              ?.reduce((result, message, index, array) => {
                  const formatDate = (date) =>
                      dayjs(date).format("MMM DD, YYYY");

                  const currentMessageFormattedDate = formatDate(
                      message.createdAt
                  );
                  const currentService = message.service || "iMessage";

                  const renderFlag =
                      index === 0 ||
                      formatDate(array[index - 1].createdAt) !==
                          currentMessageFormattedDate ||
                      (currentService !== array[index - 1].service &&
                          array[index - 1].service &&
                          currentService);

                  const nextMessage = array[index + 1];
                  const isLastPersonMessage =
                      !nextMessage ||
                      nextMessage.role !== message.role ||
                      (nextMessage?.service && message?.service
                          ? nextMessage?.service !== message?.service
                          : false) ||
                      (nextMessage.role === "assistant" &&
                          message.role === "assistant" &&
                          nextMessage?.assistant?._id !==
                              message?.assistant?._id);
                  const isLastItem = array.length - 1 === index;

                  const MessageDetails = () => {
                      if (
                          !selectedConversationMessages.find(
                              (index) => index.content
                          ) ||
                          !isLastPersonMessage
                      )
                          return null;

                      const marginLeft =
                          message.role === "assistant" ||
                          message.role === "system" ||
                          message.role === "tool" ||
                          message.role === "team"
                              ? "auto"
                              : "20px";
                      const marginRight =
                          message.role === "assistant" ||
                          message.role === "system" ||
                          message.role === "tool" ||
                          message.role === "team"
                              ? "20px"
                              : "none";
                      const rolePrefix =
                          message.role === "assistant"
                              ? message?.assistant
                                  ? "Assistant "
                                  : "You "
                              : "";
                      const formattedDate = dayjs(message.createdAt).format(
                          "hh:mm a"
                      );

                      return (
                          <Typography
                              key={`message-details-${message._id}`}
                              sx={{
                                  marginLeft,
                                  marginRight,
                                  marginBottom: "20px",
                                  fontSize: 12,
                                  fontWeight: 500,
                              }}
                          >
                              {rolePrefix}
                              {message.role === "assistant"
                                  ? message?.assistant
                                      ? message?.assistant?.displayName
                                      : ""
                                  : message.role === "system"
                                  ? "System"
                                  : message.role === "tool"
                                  ? "System (Tool)"
                                  : message.role === "team"
                                  ? "System (Team)"
                                  : selectedConversation?.contact?.name ||
                                    selectedConversation?.contact?.phone}{" "}
                              | {formattedDate}
                          </Typography>
                      );
                  };

                  result.push(
                      <Box
                          key={`message-${message._id}`}
                          ref={isLastItem ? scrollRef : null}
                          display="flex"
                          flexDirection="column"
                      >
                          {renderFlag && (
                              <Typography
                                  sx={{
                                      textAlign: "center",
                                      marginBottom: "20px",
                                      fontSize: 14,
                                      fontWeight: 500,
                                  }}
                              >
                                  {currentService ? `${currentService} - ` : ""}
                                  {currentMessageFormattedDate}
                              </Typography>
                          )}
                          <Message message={message} />
                          <MessageDetails />
                      </Box>
                  );

                  return result;
              }, [])
        : [];

    return (
        <Stack height="100%">
            <Box sx={{ marginBottom: "1rem" }}>
                <ConversationHeader />
            </Box>

            <Card
                px={2}
                sx={{
                    height: "100%",
                    borderColor: "lightgray",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    marginTop: "1rem",
                    padding: "0 1rem",
                }}
            >
                {isSelectedConversationLoading && (
                    <Box
                        display="flex"
                        alignItems={"center"}
                        justifyItems={"center"}
                        height={"100%"}
                        mx={"auto"}
                    >
                        <CircularProgress
                            color="inherit"
                            disableShrink
                            style={{
                                color: "#1C4C73",
                            }}
                        />
                    </Box>
                )}

                {!isSelectedConversationLoading && (
                    <>
                        {[
                            ...selectedConversationMessages,
                            ...selectedConversationOptimisticMessages,
                        ].length === 0 && (
                            <Box
                                display="flex"
                                alignItems={"center"}
                                justifyItems={"center"}
                                height={"100%"}
                            >
                                <SoftTypography
                                    variant="body2"
                                    sx={{
                                        textAlign: "center",
                                        mx: "auto",
                                    }}
                                >
                                    No messages yet.
                                </SoftTypography>
                            </Box>
                        )}

                        {[
                            ...selectedConversationMessages,
                            ...selectedConversationOptimisticMessages,
                        ].length > 0 && (
                            <Box className={classes.conversation}>
                                {messages}
                            </Box>
                        )}
                    </>
                )}

                <AddMessagePanel />
            </Card>
        </Stack>
    );
});
