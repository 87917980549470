import { FollowTheSigns, NotificationsActive } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import EditIcon from "@mui/icons-material/Edit";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import MicIcon from "@mui/icons-material/Mic";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Menu, MenuItem, Modal, Typography } from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Draggable } from "react-smooth-dnd";
import { toast } from "react-toastify";
import RegularStep from "../RegularStep";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function ConditionalStep({
    index,
    step,
    onChange,
    updateStep,
    onDelete,
    voiceNotes = [],
}) {
    const [isStepModalOpen, setIsStepModelOpen] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);

    const [stepData, setStepData] = useState(null);

    const [openAddStepMenu, setOpenAddStepMenu] = useState(false);
    const handleOpenAddStepMenu = (event) =>
        openAddStepMenu
            ? setOpenAddStepMenu(false)
            : setOpenAddStepMenu(event.currentTarget);
    const handleCloseAddStepMenu = () => setOpenAddStepMenu(false);

    const { values, setFieldValue } = useFormikContext();

    const {
        skills: skillsValue,
        conversationStepGroups: conversationStepGroupsValue,
        followUpGroups: followUpGroups,
    } = values;

    const toggleStepModal = () => {
        setIsStepModelOpen(!isStepModalOpen);
    };

    const { assistantId } = useParams();

    return (
        <SoftBox
            sx={{
                mb: 1,
            }}
        >
            <SoftBox
                key={`conversation-step-${index}`}
                sx={{
                    border: "1px solid #E0E0E0",
                    px: 1.5,
                    py: 1.5,
                    borderRadius: "0.375rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 0.5,
                    background: "white",
                    cursor: "grab",
                }}
                className="drag-handle"
            >
                <SoftBox
                    sx={{
                        display: "inline-flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                    }}
                >
                    <SoftTypography variant="body2" color="black">
                        {index + 1}.
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text">
                        If {step?.content} do:
                    </SoftTypography>
                </SoftBox>
                <EditIcon
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        ml: 1.5,
                    }}
                    onClick={onChange}
                />
                <CloseIcon
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        ml: 1.5,
                    }}
                    onClick={onDelete}
                />
            </SoftBox>

            {step?.steps?.length > 0 && (
                <Container
                    dragHandleSelector=".children-drag-handle"
                    groupName={`children-${index}`}
                    getChildPayload={(index) => step?.steps[index]}
                    onDrop={({ removedIndex, addedIndex }) => {
                        const newSteps = arrayMoveImmutable(
                            step?.steps,
                            removedIndex,
                            addedIndex
                        );

                        updateStep({
                            ...step,
                            steps: newSteps,
                        });
                    }}
                >
                    {step?.steps?.map((subStep, subIndex) => (
                        <Draggable key={`${index}-${subIndex}`}>
                            <RegularStep
                                key={`conversation-step-${index}-${subIndex}`}
                                index={subIndex}
                                step={subStep}
                                onChange={() => {
                                    setSelectedStep(subIndex);
                                    setStepData(step?.steps[subIndex]);

                                    toggleStepModal();
                                }}
                                onDelete={() => {
                                    updateStep({
                                        ...step,
                                        steps: step?.steps.filter(
                                            (_, i) => i !== subIndex
                                        ),
                                    });
                                }}
                                voiceNotes={voiceNotes}
                                conversationStepGroups={
                                    conversationStepGroupsValue
                                }
                                followUpGroups={followUpGroups}
                                isNested={true}
                            />
                        </Draggable>
                    ))}
                </Container>
            )}

            {step?.steps.filter((_) => _.type === "turn_off_assistant")
                .length === 0 && (
                <SoftBox
                    sx={{
                        py: 1,
                        borderRadius: "0.375rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#1C4C73",
                        cursor: "pointer",
                        ml: 5,
                    }}
                    onClick={(e) => {
                        handleOpenAddStepMenu(e);
                    }}
                    MenuProps={{ style: { width: "100%" } }}
                >
                    <SoftTypography
                        variant="body2"
                        fontWeight="regular"
                        color="white"
                    >
                        Add Conditional Step
                    </SoftTypography>
                </SoftBox>
            )}

            <Menu
                anchorEl={openAddStepMenu}
                anchorReference={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(openAddStepMenu)}
                onClose={handleCloseAddStepMenu}
                sx={{ mt: "1rem", width: "100%" }}
            >
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);
                        setStepData({
                            type: "custom",
                            content: "",
                        });

                        toggleStepModal();
                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Add Custom Step</span>
                        <EditIcon fontSize="small" />
                    </SoftBox>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);
                        setStepData({
                            type: "save_data",
                            content: "",
                            keys: [],
                        });

                        toggleStepModal();
                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Add Save Data Step</span>
                        <SaveIcon fontSize="small" />
                    </SoftBox>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);
                        setStepData({
                            type: "change_conversation_step_group",
                            content: "",
                        });

                        toggleStepModal();
                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Change Conversation Group</span>
                        <FollowTheSigns fontSize="small" />
                    </SoftBox>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);
                        setStepData({
                            type: "change_follow_up_group",
                            content: "",
                        });

                        toggleStepModal();
                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Change Follow-Up Group</span>
                        <NotificationsActive fontSize="small" />
                    </SoftBox>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);

                        updateStep({
                            ...step,
                            steps: [
                                ...step?.steps,
                                {
                                    type: "cancel_follow_ups",
                                    content: "",
                                },
                            ],
                        });

                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Cancel Follow Ups</span>
                        <RemoveCircleOutlineIcon fontSize="small" />
                    </SoftBox>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSelectedStep(null);

                        updateStep({
                            ...step,
                            steps: [
                                ...step?.steps,
                                {
                                    type: "turn_off_assistant",
                                    content: "",
                                },
                            ],
                        });

                        handleCloseAddStepMenu();
                    }}
                    sx={{
                        width: "265px",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0",
                        }}
                    >
                        <span>Turn OFF Assistant</span>
                        <DoDisturbOnIcon fontSize="small" />
                    </SoftBox>
                </MenuItem>
                {skillsValue?.filter((_) => _.name !== "shopify").length >
                    0 && (
                    <SoftBox
                        component="div"
                        bgColor="secondary"
                        opacity={0.3}
                        width="100%"
                        height="1px"
                        my={1}
                    />
                )}
                {skillsValue?.find((_) => _.name === "voiceNote") && (
                    <MenuItem
                        onClick={() => {
                            if (!assistantId) {
                                return toast.error(
                                    "You can configure this after creating the assistant."
                                );
                            }

                            setSelectedStep(null);
                            setStepData({
                                type: "voice_note",
                                content: "",
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Send Voice Note</span>
                            <MicIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                )}
                {skillsValue?.find((_) => _.name === "calendly") && (
                    <MenuItem
                        onClick={() => {
                            setSelectedStep(null);

                            updateStep({
                                ...step,
                                steps: [
                                    ...step?.steps,
                                    {
                                        type: "book_appointment",
                                        content: "",
                                    },
                                ],
                            });

                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Confirm And Book Appointment</span>
                            <EditCalendarIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                )}
            </Menu>
            <Modal open={isStepModalOpen} onClose={toggleStepModal}>
                <Box
                    sx={{
                        ...style,
                    }}
                >
                    <SoftTypography variant="body1">
                        {stepData?.type !== "voice_note" &&
                            (selectedStep === null ? "Add" : "Edit")}{" "}
                        {stepData?.type === "voice_note"
                            ? "Send Voice Note"
                            : stepData?.type === "custom"
                            ? "Custom Step"
                            : stepData?.type === "save_data"
                            ? "Save Data Step"
                            : stepData?.type ===
                              "change_conversation_step_group"
                            ? "Change Conversation Group"
                            : stepData?.type === "change_follow_up_group"
                            ? "Change Follow Up Group"
                            : ""}{" "}
                    </SoftTypography>

                    {stepData?.type === "custom" && (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Content
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Content"
                                placeholder="Enter Content"
                                value={stepData?.content}
                                onChange={(event) => {
                                    setStepData((_) => ({
                                        ..._,
                                        content: event.target.value,
                                    }));
                                }}
                            />
                        </>
                    )}

                    {stepData?.type === "voice_note" && (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Content
                                </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                                options={skillsValue
                                    ?.find((_) => _.name === "voiceNote")
                                    ?.config?.voiceNotes?.map((_) => ({
                                        label: _.title,
                                        value: _.id,
                                    }))}
                                value={skillsValue
                                    ?.find((_) => _.name === "voiceNote")
                                    ?.config?.voiceNotes?.map((_) => ({
                                        label: _.title,
                                        value: _.id,
                                    }))
                                    ?.filter(
                                        (_) => _.value === stepData?.content
                                    )}
                                onChange={({ value }) => {
                                    setStepData((_) => ({
                                        ..._,
                                        content: value,
                                    }));
                                }}
                            />
                        </>
                    )}

                    {stepData?.type === "save_data" && (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Content
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                label="Content"
                                placeholder="Content"
                                value={stepData?.content}
                                onChange={(event) => {
                                    setStepData((_) => ({
                                        ..._,
                                        content: event.target.value,
                                    }));
                                }}
                            />

                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Keys ({stepData?.keys?.length})
                                </SoftTypography>
                            </SoftBox>
                            {stepData?.keys?.map((key, index) => (
                                <SoftBox
                                    key={`save-data-key-${index}`}
                                    sx={{
                                        border: "1px solid #E0E0E0",
                                        px: 1,
                                        py: 1,
                                        borderRadius: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mb: 0.5,
                                        background: "white",
                                    }}
                                    className="drag-handle"
                                >
                                    <SoftBox
                                        sx={{
                                            display: "inline-flex",
                                            justifyContent: "flex-start",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <SoftTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {key}
                                        </SoftTypography>
                                    </SoftBox>
                                    <CloseIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                            ml: 1.5,
                                        }}
                                        onClick={() => {
                                            setStepData((_) => ({
                                                ..._,
                                                keys: _.keys?.filter(
                                                    (__, i) => i !== index
                                                ),
                                            }));
                                        }}
                                    />
                                </SoftBox>
                            ))}
                            <SoftInput
                                placeholder="Enter Key and press Enter"
                                onKeyDown={(event) => {
                                    if (
                                        event.target.value &&
                                        event.key === "Enter"
                                    ) {
                                        let key = event.target.value;

                                        setStepData((_) => ({
                                            ..._,
                                            keys: [..._.keys, key],
                                        }));

                                        event.target.value = "";
                                    }
                                }}
                            />
                        </>
                    )}

                    {stepData?.type === "change_conversation_step_group" && (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Conversation Step Group
                                </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                                options={conversationStepGroupsValue?.map(
                                    (_) => ({
                                        label: _.name,
                                        value: _.identifier,
                                    })
                                )}
                                value={conversationStepGroupsValue
                                    ?.map((_) => ({
                                        label: _.name,
                                        value: _.identifier,
                                    }))
                                    ?.filter(
                                        (_) => _.value === stepData?.content
                                    )}
                                onChange={({ value }) => {
                                    setStepData((_) => ({
                                        ..._,
                                        content: value,
                                    }));
                                }}
                            />
                        </>
                    )}

                    {stepData?.type === "change_follow_up_group" && (
                        <>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Follow-Up Group
                                </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                                options={followUpGroups?.map((_) => ({
                                    label: _.name,
                                    value: _.identifier,
                                }))}
                                value={followUpGroups
                                    ?.map((_) => ({
                                        label: _.name,
                                        value: _.identifier,
                                    }))
                                    ?.filter(
                                        (_) => _.value === stepData?.content
                                    )}
                                onChange={({ value }) => {
                                    setStepData((_) => ({
                                        ..._,
                                        content: value,
                                    }));
                                }}
                            />
                        </>
                    )}

                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 400,
                                borderRadius: 1,
                                bgcolor: "#1C4C73",
                                color: "#fff",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                if (!stepData?.content) {
                                    return toast.error("Content is required.");
                                }

                                if (stepData?.type === "save_data") {
                                    if (stepData?.keys.length === 0) {
                                        return toast.error(
                                            "At least one key is required."
                                        );
                                    }
                                }

                                if (selectedStep === null) {
                                    updateStep({
                                        ...step,
                                        steps: [...step?.steps, stepData],
                                    });
                                    toggleStepModal();
                                } else {
                                    updateStep({
                                        ...step,
                                        steps: step?.steps.map((_, i) =>
                                            i === selectedStep ? stepData : _
                                        ),
                                    });
                                    toggleStepModal();
                                }
                            }}
                        >
                            {selectedStep === null ? "Create" : "Update"}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={toggleStepModal}
                        >
                            Close
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </SoftBox>
    );
}

export default ConditionalStep;
