import SaveIcon from "@mui/icons-material/Save";
import { Card, Grid, Icon } from "@mui/material";
import { updateUser } from "common/api/user";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { authActions } from "../../../redux/authSlice";
import useAuth from "../../../redux/hooks/useAuth";
import FormField from "./FormField";

function PersonalDetails() {
    const { user } = useAuth();

    const [formData, setFormData] = useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({
            firstName: user?.firstName,
            lastName: user?.lastName,
        });
    }, [user]);

    const saveChanges = async () => {
        try {
            const response = await updateUser(formData);

            dispatch(authActions.updateUser(response.data.payload));

            toast.success("Changes saved successfully.");
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);

            setFormData({
                firstName: user?.firstName,
                lastName: user?.lastName,
            });
        }
    };

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Personal Details
                </SoftTypography>
                <SoftBox mt={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <FormField
                                type="text"
                                label="First Name"
                                value={formData?.firstName}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormField
                                type="text"
                                label="Last Name"
                                value={formData?.lastName}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        lastName: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField
                                type="text"
                                label="Email Address"
                                value={user?.userEmail}
                                disabled
                            />
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    mt: 1,
                                }}
                            >
                                <Icon color="action">info_outline</Icon>
                                <SoftTypography
                                    variant="body2"
                                    sx={{ fontSize: ".9rem" }}
                                >
                                    You can't change your email address. If you
                                    need to, please contact support.
                                </SoftTypography>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={6} lg={6} mt={1}>
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    mt: 1,
                                }}
                            >
                                <Icon color="action">manage_history_icon</Icon>
                                <SoftTypography
                                    variant="body2"
                                    sx={{ fontSize: ".9rem" }}
                                >
                                    <ReactTimeago
                                        date={user?.updatedAt}
                                        formatter={(value, unit, suffix) => {
                                            const units = {
                                                year: "y",
                                                month: "mo",
                                                week: "w",
                                                day: "d",
                                                hour: "h",
                                                minute: "min",
                                                second: "sec",
                                            };

                                            return `${value}${units[unit]} ago`;
                                        }}
                                    />
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={6} lg={6} mt={1}>
                            <SoftTypography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 1,
                                    cursor: "pointer",
                                    width: "fit-content",
                                    ml: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                                onClick={saveChanges}
                            >
                                <SaveIcon fontSize="small" />
                                Save Changes
                            </SoftTypography>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default PersonalDetails;
