import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import DirectLinksCell from "./components/DirectLinksCell";
import SelectAssistantCell from "./components/SelectAssistantCell";
import SelectBusinessCell from "./components/SelectBusinessCell";

export const phoneNumbersColumns = [
    { Header: "PHONE NUMBER", accessor: "phoneNumber", width: "20%" },
    {
        Header: "DEFAULT ASSISTANT",
        accessor: "defaultAssistant",
        width: "20%",
        Cell: ({ value: [name, { phoneNumberId }] }) => (
            <SelectAssistantCell
                defaultValue={name}
                phoneNumberId={phoneNumberId}
            />
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "business",
        width: "20%",
        Cell: ({ value: [name, { phoneNumberId }] }) => (
            <SelectBusinessCell
                defaultValue={name}
                phoneNumberId={phoneNumberId}
            />
        ),
    },
    {
        Header: "DIRECT LINKS",
        accessor: "directLinks",
        width: "20%",
        Cell: ({ value: [phoneNumber] }) => (
            <>
                {phoneNumber.senderNumber ? (
                    <DirectLinksCell phoneNumber={phoneNumber} />
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    { Header: "ACTION", accessor: "action", width: "30%" },
];

export function createTableRows(phoneNumbers, actions) {
    return phoneNumbers.map((phoneNumber) => ({
        phoneNumber: phoneNumber.senderNumber || phoneNumber.senderName,
        defaultAssistant: [
            phoneNumber.defaultAssistant
                ? phoneNumber.defaultAssistant._id
                : null,
            {
                phoneNumberId: phoneNumber._id,
            },
        ],
        business: [
            phoneNumber.business ? phoneNumber.business._id : null,
            { phoneNumberId: phoneNumber._id },
        ],
        directLinks: [phoneNumber],
        createdAt: [
            new Date(phoneNumber.createdAt).getTime(),
            dayjs(phoneNumber.createdAt).format("D MMM, YYYY"),
        ],
        action: actions(phoneNumber),
    }));
}
