import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function FollowUpStep({ key, index, step, onChange, onDelete }) {
    return (
        <SoftBox
            key={key}
            sx={{
                border: "1px solid #E0E0E0",
                px: 1.5,
                py: 1.5,
                borderRadius: "0.375rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
                background: "white",
                cursor: "grab",
                ml: 0,
            }}
            className="drag-handle"
        >
            <SoftBox
                sx={{
                    display: "inline-flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                }}
            >
                <SoftTypography variant="body2" color="black">
                    {index + 1}.
                </SoftTypography>
                <SoftTypography variant="body2" color="text">
                    Instruction: "{step?.message}" after {step?.interval}{" "}
                    {step?.intervalUnit}
                    {step?.interval !== 1 ? "s" : ""}
                </SoftTypography>
            </SoftBox>
            <EditIcon
                fontSize="medium"
                sx={{
                    cursor: "pointer",
                    ml: 1.5,
                }}
                onClick={onChange}
            />
            <CloseIcon
                fontSize="medium"
                sx={{
                    cursor: "pointer",
                    ml: 1.5,
                }}
                onClick={onDelete}
            />
        </SoftBox>
    );
}

export default FollowUpStep;
