import {
    CheckCircle,
    Delete,
    FollowTheSigns,
    NotificationsActive,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CloseIcon from "@mui/icons-material/Close";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import EditIcon from "@mui/icons-material/Edit";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import MicIcon from "@mui/icons-material/Mic";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import {
    Box,
    Grid,
    Menu,
    MenuItem,
    Modal,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Draggable } from "react-smooth-dnd";
import { toast } from "react-toastify";
import ConditionalStep from "./component/ConditionalStep";
import RegularStep from "./component/RegularStep";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function ConversationSteps({ formData }) {
    const {
        formField: { conversationStepGroups, defaultConversationStepGroup },
    } = formData;

    const { values, setFieldValue } = useFormikContext();

    const {
        conversationStepGroups: conversationStepGroupsValue,
        defaultConversationStepGroup: defaultConversationStepGroupValue,
        skills: skillsValue,
        followUpGroups: followUpGroupsValue,
    } = values;

    const [isGroupModalOpen, setIsGroupModelOpen] = useState(false);
    const [isStepModalOpen, setIsStepModelOpen] = useState(false);
    const [openAddStepMenu, setOpenAddStepMenu] = useState(false);

    const [selectedGroupIdentifier, setSelectedGroupIdentifier] = useState(
        conversationStepGroupsValue[0]?.identifier || null
    );
    const [
        selectedGroupIdentifierForEditing,
        setSelectedGroupIdentifierForEditing,
    ] = useState(null);
    const [selectedStepIndex, setSelectedStepIndex] = useState(null);

    const [groupData, setGroupData] = useState(null);
    const [stepData, setStepData] = useState(null);

    const { assistantId } = useParams();

    const selectedGroup = useMemo(() => {
        return conversationStepGroupsValue.find(
            (_) => _.identifier === selectedGroupIdentifier
        );
    }, [selectedGroupIdentifier, conversationStepGroupsValue]);

    const sortedConversationStepGroups = useMemo(() => {
        return [...(conversationStepGroupsValue.slice() || [])].sort((a, b) => {
            if (a.category === "General" && b.category !== "General") return -1;
            if (b.category === "General" && a.category !== "General") return 1;

            return 0;
        });
    }, [conversationStepGroupsValue]);

    const toggleGroupModal = () => {
        setIsGroupModelOpen(!isGroupModalOpen);
    };

    const toggleStepModal = () => {
        setIsStepModelOpen(!isStepModalOpen);
    };

    const handleOpenAddStepMenu = (event) =>
        openAddStepMenu
            ? setOpenAddStepMenu(false)
            : setOpenAddStepMenu(event.currentTarget);

    const handleCloseAddStepMenu = () => setOpenAddStepMenu(false);

    useEffect(() => {
        if (!defaultConversationStepGroupValue) {
            setFieldValue(
                defaultConversationStepGroup.name,
                conversationStepGroupsValue[0]?.identifier || null
            );
        }
    }, [defaultConversationStepGroupValue]);

    const renderedConversationStepGroups = useMemo(() => {
        return sortedConversationStepGroups.map(
            (conversationStepGroup, index) => {
                const isSubjectForHeading =
                    (index - 1 >= 0 &&
                        conversationStepGroup.category !==
                            sortedConversationStepGroups[index - 1].category &&
                        conversationStepGroup.category !== "General") ||
                    (index === 0 &&
                        sortedConversationStepGroups.filter(
                            (_) => _.category === "General"
                        ).length === 0);

                const isLastGroupOfGeneralCategory =
                    index ===
                    sortedConversationStepGroups.findLastIndex(
                        (_) => _.category === "General"
                    );
                const isGroupActive =
                    selectedGroupIdentifier ===
                    conversationStepGroup.identifier;

                const isDefault =
                    defaultConversationStepGroupValue ===
                    conversationStepGroup.identifier;
                const isEditable = conversationStepGroup.category === "General";
                const isRemovable =
                    conversationStepGroup.category === "General" && !isDefault;

                return (
                    <>
                        {isSubjectForHeading && (
                            <SoftBox
                                mt={1}
                                mx={0.5}
                                lineHeight={0}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    {conversationStepGroup.category}
                                </SoftTypography>
                            </SoftBox>
                        )}

                        <SoftBox
                            key={`conversation-step-group-${conversationStepGroup.identifier}`}
                            sx={{
                                borderRadius: "0.375rem",
                                padding: "0.5rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                                backgroundColor: isGroupActive
                                    ? "#1C4C73"
                                    : "#E6EAF0",
                                color: isGroupActive ? "#fff" : "#000",
                            }}
                            onClick={() => {
                                setSelectedGroupIdentifier(
                                    conversationStepGroup.identifier
                                );
                            }}
                        >
                            <SoftTypography
                                variant="caption"
                                textTransform="capitalize"
                                color={isGroupActive ? "#fff" : "#000"}
                            >
                                {conversationStepGroup.name}{" "}
                                {isDefault ? "(Default)" : ""}
                            </SoftTypography>
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 0,
                                    gap: 1,
                                }}
                                color={isGroupActive ? "#fff" : "#000"}
                            >
                                {!isDefault && (
                                    <Tooltip
                                        title="Set As Default"
                                        placement="top"
                                    >
                                        <CheckCircle
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                setFieldValue(
                                                    defaultConversationStepGroup.name,
                                                    conversationStepGroup.identifier
                                                );

                                                toast.success(
                                                    "Conversation Step Group set as default."
                                                );

                                                setSelectedGroupIdentifier(
                                                    conversationStepGroup.identifier
                                                );
                                            }}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                )}
                                {isEditable ? (
                                    <Tooltip title="Edit" placement="top">
                                        <EditIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedGroupIdentifierForEditing(
                                                    conversationStepGroup.identifier
                                                );
                                                setGroupData(
                                                    conversationStepGroup
                                                );
                                                toggleGroupModal();
                                            }}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                                {isRemovable ? (
                                    <Tooltip title="Remove" placement="top">
                                        <Delete
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                for (const _ of conversationStepGroupsValue) {
                                                    if (
                                                        _.steps.filter(
                                                            (step) =>
                                                                step.type ===
                                                                    "change_conversation_step_group" &&
                                                                step.content ===
                                                                    conversationStepGroup.identifier
                                                        ).length > 0 ||
                                                        _.steps.filter(
                                                            (step) =>
                                                                step.type ===
                                                                    "conditional" &&
                                                                step.steps.filter(
                                                                    (step) =>
                                                                        step.type ===
                                                                            "change_conversation_step_group" &&
                                                                        step.content ===
                                                                            conversationStepGroup.identifier
                                                                ).length > 0
                                                        ).length > 0
                                                    ) {
                                                        return toast.error(
                                                            "This group is being used in some steps."
                                                        );
                                                    }
                                                }

                                                setFieldValue(
                                                    conversationStepGroups.name,
                                                    conversationStepGroupsValue.filter(
                                                        (_) =>
                                                            _.identifier !==
                                                            conversationStepGroup.identifier
                                                    )
                                                );

                                                setSelectedGroupIdentifier(
                                                    conversationStepGroupsValue[0]
                                                        ?.identifier || null
                                                );

                                                toast.success(
                                                    "Conversation Step Group removed."
                                                );
                                            }}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                            </SoftBox>
                        </SoftBox>

                        {isLastGroupOfGeneralCategory ? (
                            <SoftBox
                                sx={{
                                    borderRadius: "0.375rem",
                                    padding: "0.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    backgroundColor: "#1C4C73",
                                    color: "#fff",
                                }}
                                onClick={() => {
                                    setGroupData({
                                        name: "",
                                        category: "General",
                                        steps: [],
                                    });

                                    setSelectedGroupIdentifierForEditing(null);

                                    toggleGroupModal();
                                }}
                            >
                                <AddIcon fontSize="small" />
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize"
                                    color="#fff"
                                >
                                    Add Conversation Step Group
                                </SoftTypography>
                                <SoftBox
                                    sx={{
                                        width: "20px",
                                    }}
                                />
                            </SoftBox>
                        ) : null}
                    </>
                );
            }
        );
    }, [
        sortedConversationStepGroups,
        selectedGroupIdentifier,
        defaultConversationStepGroupValue,
    ]);

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Conversation Steps
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Add conversation step groups to your assistant. These groups
                    are collections of conversation steps that can be assigned
                    to your assistant. The assistant will follow these steps for
                    the active conversation step group.
                </SoftTypography>
            </SoftBox>

            <SoftBox mt={1.625}>
                <Grid mt={1.625} item xs={12}>
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Conversation Step Groups
                        </SoftTypography>
                    </SoftBox>

                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            }}
                        >
                            {sortedConversationStepGroups.filter(
                                (_) => _.category === "General"
                            ).length === 0 && (
                                <SoftBox
                                    sx={{
                                        borderRadius: "0.375rem",
                                        padding: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                        backgroundColor: "#1C4C73",
                                        color: "#fff",
                                    }}
                                    onClick={() => {
                                        setGroupData({
                                            name: "",
                                            category: "General",
                                            steps: [],
                                        });

                                        setSelectedGroupIdentifierForEditing(
                                            null
                                        );

                                        toggleGroupModal();
                                    }}
                                >
                                    <AddIcon fontSize="small" />
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize"
                                        color="#fff"
                                    >
                                        Add Conversation Step Group
                                    </SoftTypography>
                                    <SoftBox
                                        sx={{
                                            width: "20px",
                                        }}
                                    />
                                </SoftBox>
                            )}
                            {renderedConversationStepGroups}
                        </Grid>

                        <Grid item xs={9}>
                            {selectedGroup && (
                                <SoftBox mb={1} ml={0.5} lineHeight={0}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Conversation Steps
                                    </SoftTypography>
                                </SoftBox>
                            )}

                            {selectedGroup?.steps?.length > 0 && (
                                <Container
                                    dragHandleSelector=".drag-handle"
                                    groupName="parent"
                                    onDrop={({ removedIndex, addedIndex }) => {
                                        setFieldValue(
                                            conversationStepGroups.name,
                                            conversationStepGroupsValue.map(
                                                (group) => {
                                                    if (
                                                        group.identifier ===
                                                        selectedGroup.identifier
                                                    ) {
                                                        return {
                                                            ...group,
                                                            steps: arrayMoveImmutable(
                                                                group.steps,
                                                                removedIndex,
                                                                addedIndex
                                                            ),
                                                        };
                                                    } else {
                                                        return group;
                                                    }
                                                }
                                            )
                                        );
                                    }}
                                >
                                    {(selectedGroup?.steps || []).map(
                                        (step, index) => {
                                            if (step.type === "conditional") {
                                                return (
                                                    <Draggable key={index}>
                                                        <ConditionalStep
                                                            index={index}
                                                            step={step}
                                                            onChange={(_) => {
                                                                setSelectedStepIndex(
                                                                    index
                                                                );
                                                                setStepData(
                                                                    selectedGroup
                                                                        .steps[
                                                                        index
                                                                    ]
                                                                );

                                                                toggleStepModal();
                                                            }}
                                                            updateStep={(
                                                                step
                                                            ) => {
                                                                setFieldValue(
                                                                    conversationStepGroups.name,
                                                                    conversationStepGroupsValue.map(
                                                                        (
                                                                            group
                                                                        ) => {
                                                                            if (
                                                                                group.identifier ===
                                                                                selectedGroup.identifier
                                                                            ) {
                                                                                return {
                                                                                    ...group,
                                                                                    steps: group.steps.map(
                                                                                        (
                                                                                            s,
                                                                                            i
                                                                                        ) =>
                                                                                            i ===
                                                                                            index
                                                                                                ? step
                                                                                                : s
                                                                                    ),
                                                                                };
                                                                            } else {
                                                                                return group;
                                                                            }
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                            onDelete={(_) => {
                                                                setFieldValue(
                                                                    conversationStepGroups.name,
                                                                    conversationStepGroupsValue.map(
                                                                        (
                                                                            group
                                                                        ) => {
                                                                            if (
                                                                                group.identifier ===
                                                                                selectedGroup.identifier
                                                                            ) {
                                                                                return {
                                                                                    ...group,
                                                                                    steps: group.steps.filter(
                                                                                        (
                                                                                            _,
                                                                                            i
                                                                                        ) =>
                                                                                            i !==
                                                                                            index
                                                                                    ),
                                                                                };
                                                                            } else {
                                                                                return group;
                                                                            }
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                            conversationStepGroups={
                                                                conversationStepGroupsValue
                                                            }
                                                            voiceNotes={
                                                                skillsValue?.find(
                                                                    (_) =>
                                                                        _.name ===
                                                                        "voiceNote"
                                                                )?.config
                                                                    ?.voiceNotes
                                                            }
                                                        />
                                                    </Draggable>
                                                );
                                            } else {
                                                return (
                                                    <Draggable key={index}>
                                                        <RegularStep
                                                            key={`conversation-step-${index}`}
                                                            index={index}
                                                            step={step}
                                                            onChange={(_) => {
                                                                setSelectedStepIndex(
                                                                    index
                                                                );
                                                                setStepData(
                                                                    selectedGroup
                                                                        .steps[
                                                                        index
                                                                    ]
                                                                );

                                                                toggleStepModal();
                                                            }}
                                                            onDelete={(_) => {
                                                                setFieldValue(
                                                                    conversationStepGroups.name,
                                                                    conversationStepGroupsValue.map(
                                                                        (
                                                                            group
                                                                        ) => {
                                                                            if (
                                                                                group.identifier ===
                                                                                selectedGroup.identifier
                                                                            ) {
                                                                                return {
                                                                                    ...group,
                                                                                    steps: group.steps.filter(
                                                                                        (
                                                                                            _,
                                                                                            i
                                                                                        ) =>
                                                                                            i !==
                                                                                            index
                                                                                    ),
                                                                                };
                                                                            } else {
                                                                                return group;
                                                                            }
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                            voiceNotes={
                                                                skillsValue?.find(
                                                                    (_) =>
                                                                        _.name ===
                                                                        "voiceNote"
                                                                )?.config
                                                                    ?.voiceNotes
                                                            }
                                                            conversationStepGroups={
                                                                conversationStepGroupsValue
                                                            }
                                                            followUpGroups={
                                                                followUpGroupsValue
                                                            }
                                                        />
                                                    </Draggable>
                                                );
                                            }
                                        }
                                    )}
                                </Container>
                            )}

                            {selectedGroup?.steps.filter(
                                (_) => _.type === "turn_off_assistant"
                            ).length === 0 && (
                                <SoftBox
                                    sx={{
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1C4C73",
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                        handleOpenAddStepMenu(e);
                                    }}
                                    MenuProps={{ style: { width: "100%" } }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                    >
                                        Add Conversation Step
                                    </SoftTypography>
                                </SoftBox>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={openAddStepMenu}
                    anchorReference={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={Boolean(openAddStepMenu)}
                    onClose={handleCloseAddStepMenu}
                    sx={{ mt: "1rem", width: "100%" }}
                >
                    <MenuItem
                        onClick={() => {
                            setSelectedStepIndex(null);
                            setStepData({
                                type: "custom",
                                content: "",
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Add Custom Step</span>
                            <EditIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedStepIndex(null);
                            setStepData({
                                type: "conditional",
                                content: "",
                                steps: [],
                                skipRemainingSteps: false,
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Add Conditional Step</span>
                            <CallSplitIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedStepIndex(null);
                            setStepData({
                                type: "save_data",
                                content: "",
                                keys: [],
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Add Save Data Step</span>
                            <SaveIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedStepIndex(null);
                            setStepData({
                                type: "change_conversation_step_group",
                                content: "",
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Change Conversation Group</span>
                            <FollowTheSigns fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedStepIndex(null);
                            setStepData({
                                type: "change_follow_up_group",
                                content: "",
                            });

                            toggleStepModal();
                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Change Follow-Up Group</span>
                            <NotificationsActive fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setFieldValue(
                                conversationStepGroups.name,
                                conversationStepGroupsValue.map((group) => {
                                    if (
                                        group.identifier ===
                                        selectedGroup.identifier
                                    ) {
                                        return {
                                            ...group,
                                            steps: [
                                                ...group.steps,
                                                {
                                                    type: "cancel_follow_ups",
                                                    content: "",
                                                },
                                            ],
                                        };
                                    } else {
                                        return group;
                                    }
                                })
                            );

                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Cancel Follow Ups</span>
                            <RemoveCircleOutlineIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setFieldValue(
                                conversationStepGroups.name,
                                conversationStepGroupsValue.map((group) => {
                                    if (
                                        group.identifier ===
                                        selectedGroup.identifier
                                    ) {
                                        return {
                                            ...group,
                                            steps: [
                                                ...group.steps,
                                                {
                                                    type: "turn_off_assistant",
                                                    content: "",
                                                },
                                            ],
                                        };
                                    } else {
                                        return group;
                                    }
                                })
                            );

                            handleCloseAddStepMenu();
                        }}
                        sx={{
                            width: "265px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Turn OFF Assistant</span>
                            <DoDisturbOnIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                    {skillsValue?.filter((_) => _.name !== "shopify").length >
                        0 && (
                        <SoftBox
                            component="div"
                            bgColor="secondary"
                            opacity={0.3}
                            width="100%"
                            height="1px"
                            my={1}
                        />
                    )}
                    {skillsValue?.find((_) => _.name === "voiceNote") && (
                        <MenuItem
                            onClick={() => {
                                if (!assistantId) {
                                    return toast.error(
                                        "You can configure this after creating the assistant."
                                    );
                                }

                                setSelectedStepIndex(null);
                                setStepData({
                                    type: "voice_note",
                                    content: "",
                                });

                                toggleStepModal();
                                handleCloseAddStepMenu();
                            }}
                            sx={{
                                width: "265px",
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0",
                                }}
                            >
                                <span>Send Voice Note</span>
                                <MicIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                    )}
                    {skillsValue?.find((_) => _.name === "calendly") && (
                        <MenuItem
                            onClick={() => {
                                setSelectedStepIndex(null);

                                setFieldValue(
                                    conversationStepGroups.name,
                                    conversationStepGroupsValue.map((group) => {
                                        if (
                                            group.identifier ===
                                            selectedGroup.identifier
                                        ) {
                                            return {
                                                ...group,
                                                steps: [
                                                    ...group.steps,
                                                    {
                                                        type: "book_appointment",
                                                        content: "",
                                                    },
                                                ],
                                            };
                                        } else {
                                            return group;
                                        }
                                    })
                                );

                                handleCloseAddStepMenu();
                            }}
                            sx={{
                                width: "265px",
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0",
                                }}
                            >
                                <span>Confirm And Book Appointment</span>
                                <EditCalendarIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                    )}
                </Menu>

                <Modal open={isGroupModalOpen} onClose={toggleGroupModal}>
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftTypography variant="body1">
                            {selectedGroupIdentifierForEditing === null
                                ? "Add"
                                : "Edit"}{" "}
                            Conversation Step Group
                        </SoftTypography>

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Name
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            label="Name"
                            placeholder="Enter name"
                            value={groupData?.name}
                            onChange={(event) => {
                                setGroupData((_) => ({
                                    ..._,
                                    name: event.target.value,
                                }));
                            }}
                        />

                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (!groupData?.name) {
                                        return toast.error("Name is required.");
                                    }

                                    if (!groupData?.category) {
                                        return toast.error(
                                            "Category is required."
                                        );
                                    }

                                    if (groupData?.category !== "General") {
                                        return toast.error(
                                            "Category is invalid."
                                        );
                                    }

                                    if (!Array.isArray(groupData?.steps)) {
                                        return toast.error(
                                            "Steps must be an array."
                                        );
                                    }

                                    if (
                                        selectedGroupIdentifierForEditing ===
                                        null
                                    ) {
                                        const temporaryGroupIdentifier =
                                            crypto.randomUUID();

                                        setFieldValue(
                                            conversationStepGroups.name,
                                            [
                                                ...conversationStepGroupsValue,
                                                {
                                                    identifier:
                                                        temporaryGroupIdentifier,
                                                    ...groupData,
                                                },
                                            ]
                                        );

                                        setSelectedGroupIdentifier(
                                            temporaryGroupIdentifier
                                        );
                                    } else {
                                        setFieldValue(
                                            conversationStepGroups.name,
                                            conversationStepGroupsValue.map(
                                                (group) => {
                                                    if (
                                                        group.identifier ===
                                                        selectedGroupIdentifierForEditing
                                                    ) {
                                                        return groupData;
                                                    }

                                                    return group;
                                                }
                                            )
                                        );

                                        setSelectedGroupIdentifier(
                                            selectedGroupIdentifierForEditing
                                        );

                                        setSelectedGroupIdentifierForEditing(
                                            null
                                        );
                                    }

                                    toggleGroupModal();
                                }}
                            >
                                {selectedGroupIdentifierForEditing === null
                                    ? "Create"
                                    : "Update"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleGroupModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>

                <Modal open={isStepModalOpen} onClose={toggleStepModal}>
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftTypography variant="body1">
                            {stepData?.type !== "voice_note" &&
                                (selectedStepIndex === null
                                    ? "Add"
                                    : "Edit")}{" "}
                            {stepData?.type === "voice_note"
                                ? "Send Voice Note"
                                : stepData?.type === "custom"
                                ? "Custom Step"
                                : stepData?.type === "conditional"
                                ? "Conditional Step"
                                : stepData?.type === "save_data"
                                ? "Save Data Step"
                                : stepData?.type ===
                                  "change_conversation_step_group"
                                ? "Change Conversation Group"
                                : stepData?.type === "change_follow_up_group"
                                ? "Change Follow Up Group"
                                : ""}{" "}
                        </SoftTypography>

                        {stepData?.type === "custom" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Content
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Content"
                                    placeholder="Enter Content"
                                    value={stepData?.content}
                                    onChange={(event) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: event.target.value,
                                        }));
                                    }}
                                />
                            </>
                        )}

                        {stepData?.type === "conditional" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        If
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Content"
                                    placeholder="Enter If Condition"
                                    value={stepData?.content}
                                    onChange={(event) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: event.target.value,
                                        }));
                                    }}
                                />
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mt: 1,
                                    }}
                                >
                                    <Switch
                                        color="default"
                                        checked={stepData?.skipRemainingSteps}
                                        onChange={(e) =>
                                            setStepData({
                                                ...stepData,
                                                skipRemainingSteps:
                                                    e.target.checked,
                                            })
                                        }
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "green",
                                            },
                                            "&.Mui-checked + .MuiSwitch-track":
                                                {
                                                    backgroundColor: "green",
                                                },
                                        }}
                                    />

                                    <SoftTypography
                                        variant="body2"
                                        sx={{
                                            fontSize: ".9rem",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            setStepData({
                                                ...stepData,
                                                skipRemainingSteps:
                                                    !stepData?.skipRemainingSteps,
                                            })
                                        }
                                    >
                                        Skip Remaining Steps
                                    </SoftTypography>
                                </SoftBox>
                            </>
                        )}

                        {stepData?.type === "voice_note" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Content
                                    </SoftTypography>
                                </SoftBox>
                                <SoftSelect
                                    options={skillsValue
                                        ?.find((_) => _.name === "voiceNote")
                                        ?.config?.voiceNotes?.map((_) => ({
                                            label: _.title,
                                            value: _.id,
                                        }))}
                                    value={skillsValue
                                        ?.find((_) => _.name === "voiceNote")
                                        ?.config?.voiceNotes?.map((_) => ({
                                            label: _.title,
                                            value: _.id,
                                        }))
                                        ?.filter(
                                            (_) => _.value === stepData?.content
                                        )}
                                    onChange={({ value }) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: value,
                                        }));
                                    }}
                                />
                            </>
                        )}

                        {stepData?.type === "save_data" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Content
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Content"
                                    placeholder="Content"
                                    value={stepData?.content}
                                    onChange={(event) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: event.target.value,
                                        }));
                                    }}
                                />

                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Keys ({stepData?.keys?.length})
                                    </SoftTypography>
                                </SoftBox>
                                {stepData?.keys?.map((key, index) => (
                                    <SoftBox
                                        key={`save-data-key-${index}`}
                                        sx={{
                                            border: "1px solid #E0E0E0",
                                            px: 1,
                                            py: 1,
                                            borderRadius: "0.5rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            mb: 0.5,
                                            background: "white",
                                        }}
                                        className="drag-handle"
                                    >
                                        <SoftBox
                                            sx={{
                                                display: "inline-flex",
                                                justifyContent: "flex-start",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: 1,
                                                width: "100%",
                                            }}
                                        >
                                            <SoftTypography
                                                variant="body2"
                                                color="black"
                                            >
                                                {key}
                                            </SoftTypography>
                                        </SoftBox>
                                        <CloseIcon
                                            fontSize="medium"
                                            sx={{
                                                cursor: "pointer",
                                                ml: 1.5,
                                            }}
                                            onClick={() => {
                                                setStepData((_) => ({
                                                    ..._,
                                                    keys: _.keys?.filter(
                                                        (__, i) => i !== index
                                                    ),
                                                }));
                                            }}
                                        />
                                    </SoftBox>
                                ))}
                                <SoftInput
                                    placeholder="Enter Key and press Enter"
                                    onKeyDown={(event) => {
                                        if (
                                            event.target.value &&
                                            event.key === "Enter"
                                        ) {
                                            let key = event.target.value;

                                            setStepData((_) => ({
                                                ..._,
                                                keys: [..._.keys, key],
                                            }));

                                            event.target.value = "";
                                        }
                                    }}
                                />
                            </>
                        )}

                        {stepData?.type ===
                            "change_conversation_step_group" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Conversation Step Group
                                    </SoftTypography>
                                </SoftBox>
                                <SoftSelect
                                    options={conversationStepGroupsValue?.map(
                                        (_) => ({
                                            label: _.name,
                                            value: _.identifier,
                                        })
                                    )}
                                    value={conversationStepGroupsValue
                                        ?.map((_) => ({
                                            label: _.name,
                                            value: _.identifier,
                                        }))
                                        ?.filter(
                                            (_) => _.value === stepData?.content
                                        )}
                                    onChange={({ value }) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: value,
                                        }));
                                    }}
                                />
                            </>
                        )}

                        {stepData?.type === "change_follow_up_group" && (
                            <>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Follow-Up Group
                                    </SoftTypography>
                                </SoftBox>
                                <SoftSelect
                                    options={followUpGroupsValue?.map((_) => ({
                                        label: _.name,
                                        value: _.identifier,
                                    }))}
                                    value={followUpGroupsValue
                                        ?.map((_) => ({
                                            label: _.name,
                                            value: _.identifier,
                                        }))
                                        ?.filter(
                                            (_) => _.value === stepData?.content
                                        )}
                                    onChange={({ value }) => {
                                        setStepData((_) => ({
                                            ..._,
                                            content: value,
                                        }));
                                    }}
                                />
                            </>
                        )}

                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (!stepData?.content) {
                                        return toast.error(
                                            "Content is required."
                                        );
                                    }

                                    if (stepData?.type === "save_data") {
                                        if (stepData?.keys.length === 0) {
                                            return toast.error(
                                                "At least one key is required."
                                            );
                                        }
                                    }

                                    if (selectedStepIndex === null) {
                                        setFieldValue(
                                            conversationStepGroups.name,
                                            conversationStepGroupsValue.map(
                                                (group) => {
                                                    if (
                                                        group.identifier ===
                                                        selectedGroupIdentifier
                                                    ) {
                                                        return {
                                                            ...group,
                                                            steps: [
                                                                ...group.steps,
                                                                stepData,
                                                            ],
                                                        };
                                                    } else {
                                                        return group;
                                                    }
                                                }
                                            )
                                        );

                                        toggleStepModal();
                                    } else {
                                        setFieldValue(
                                            conversationStepGroups.name,
                                            conversationStepGroupsValue.map(
                                                (group) => {
                                                    if (
                                                        group.identifier ===
                                                        selectedGroupIdentifier
                                                    ) {
                                                        return {
                                                            ...group,
                                                            steps: group.steps.map(
                                                                (s, i) =>
                                                                    i ===
                                                                    selectedStepIndex
                                                                        ? stepData
                                                                        : s
                                                            ),
                                                        };
                                                    } else {
                                                        return group;
                                                    }
                                                }
                                            )
                                        );

                                        toggleStepModal();
                                    }
                                }}
                            >
                                {selectedStepIndex === null
                                    ? "Create"
                                    : "Update"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleStepModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </SoftBox>
        </SoftBox>
    );
}

ConversationSteps.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default ConversationSteps;
