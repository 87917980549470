import SoftBox from "components/SoftBox";

export const customFieldsColumns = [
    {
        Header: "KEY",
        accessor: "key",
        width: "50%",
        Cell: ({ value: [name, openModal] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                }}
                onClick={() => {
                    openModal(name);
                }}
            >
                {name}
            </SoftBox>
        ),
    },
    { Header: "VALUE", accessor: "value", width: "50%" },
];

export function createTableRows(customFields, openModal) {
    return customFields.map((customField) => ({
        key: [customField.key, openModal],
        value: customField.value,
    }));
}
