import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../../../redux/adminSlice";
import useAdmin from "../../../../../redux/hooks/useAdmin";

function SelectBusiness({
    withoutAny = false,
    defaultValue = undefined,
    withNoBusiness = false,
    callbackFn = () => {},
}) {
    const {
        businesses,
        selectedUserId,
        selectedBusinessId,
        businessesError,
        isBusinessesLoading,
    } = useAdmin();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.fetchBusinesses());

        if (
            withoutAny &&
            defaultValue === undefined &&
            selectedBusinessId === undefined
        ) {
            dispatch(adminActions.selectBusiness(businesses?.[0]?._id));

            if (businesses?.[0]?._id) {
                const user = businesses.find(
                    (business) => business._id === businesses?.[0]?._id
                )?.userSetting;
                dispatch(adminActions.selectUser(user?._id));
            }

            dispatch(adminActions.fetchAssistants());
        }

        if (defaultValue !== undefined) {
            dispatch(adminActions.selectBusiness(defaultValue));

            dispatch(adminActions.fetchAssistants());
        }
    }, []);

    useEffect(() => {
        callbackFn(selectedBusinessId);
    }, [selectedBusinessId]);

    const businessesValues = useMemo(() => {
        return [
            !withoutAny
                ? {
                      label: "Any Business",
                      value: undefined,
                  }
                : null,
            withNoBusiness
                ? {
                      label: "No Business",
                      value: null,
                  }
                : null,
            ...businesses
                ?.filter((_) => {
                    return selectedUserId
                        ? _.userSetting?._id === selectedUserId
                        : true;
                })
                .map((business) => ({
                    label: business.displayName,
                    value: business._id,
                })),
        ].filter(Boolean);
    }, [businesses]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={businessesValues}
                value={businessesValues.filter(
                    (_) => _.value === selectedBusinessId
                )}
                onChange={({ value }) => {
                    dispatch(adminActions.selectBusiness(value));

                    dispatch(adminActions.selectAssistant(undefined));

                    if (value) {
                        const user = businesses.find(
                            (business) => business._id === value
                        )?.userSetting;
                        dispatch(adminActions.selectUser(user?._id));
                    }

                    dispatch(adminActions.fetchAssistants());
                }}
            />
        </div>
    );
}

export default SelectBusiness;
