import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {
    adminGetPhoneNumberGroup,
    adminUpdatePhoneNumberGroup,
} from "common/api/admin/phoneNumberGroup";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import AdminLayout from "examples/LayoutContainers/AdminLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NumberGroupDetails from "./components/NumberGroupDetails";
import form from "./schemas/form";
import _initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

const { formId, formField } = form;

function getSteps() {
    return ["Number Group Details"];
}

function getStepContent(stepIndex, formData) {
    console.log(formData);
    switch (stepIndex) {
        case 0:
            return <NumberGroupDetails formData={formData} />;
        default:
            return null;
    }
}

function UpdateNumberGroupPage() {
    const [activeStep, setActiveStep] = useState(0);

    const { numberGroupId } = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const steps = getSteps();

    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const navigate = useNavigate();

    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        try {
            await adminUpdatePhoneNumberGroup(numberGroupId, {
                ...values,
            });
            actions.setSubmitting(false);
            actions.resetForm();

            toast.success("Phone Number Group updated successfully.");
            navigate("/admin/number-groups");
        } catch (error) {
            actions.setSubmitting(false);
            toast.error(error?.response?.data?.error?.message || error.message);
        }
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchPhoneNumberGroup();
    }, [numberGroupId]);

    const fetchPhoneNumberGroup = async () => {
        setIsLoading(true);

        try {
            const response = await adminGetPhoneNumberGroup(numberGroupId);

            setInitialValues({
                ..._initialValues,
                ...response.data.payload,
                business: response.data.payload.business?._id,
            });
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response?.data?.error?.message || error.message);
            setIsLoading(false);
        }
    };

    return (
        <AdminLayout>
            <DashboardNavbar />
            <SoftBox py={2} mb={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={10}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {!isLoading && (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                }) => (
                                    <Form id={formId} autoComplete="off">
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={2}>
                                                <SoftBox>
                                                    {getStepContent(
                                                        activeStep,
                                                        {
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                        }
                                                    )}
                                                    <SoftBox
                                                        mt={2}
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <SoftBox
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                gap: 2,
                                                            }}
                                                        >
                                                            <SoftButton
                                                                variant="gradient"
                                                                color="light"
                                                                onClick={
                                                                    handleBack
                                                                }
                                                            >
                                                                Back
                                                            </SoftButton>
                                                        </SoftBox>
                                                        <SoftButton
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            type="submit"
                                                            variant="gradient"
                                                            color="dark"
                                                        >
                                                            {isLastStep
                                                                ? "Update"
                                                                : "Next"}
                                                        </SoftButton>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </AdminLayout>
    );
}

export default UpdateNumberGroupPage;
