import axios from "axios";
import { toast } from "react-toastify";
import { createSliceWithThunks } from "./createSliceWithThunks";

const initialState = {
    accessToken: null,
    user: null,
    error: null,

    isAuthenticated: false,
    isLoading: true,
    isError: false,
};

const authSlice = createSliceWithThunks({
    name: "auth",
    initialState,
    reducers: (create) => ({
        clearError: (state) => {
            state.error = null;
            state.isError = false;
        },
        updateState: (state, action) => {
            const { accessToken, user, isAuthenticated, isLoading } =
                action.payload;

            localStorage.setItem("accessToken", accessToken);

            state.accessToken = accessToken;

            state.user = user;
            state.isAuthenticated = isAuthenticated || state.isAuthenticated;
            state.isLoading = isLoading || state.isLoading;
        },
        updateUser: (state, action) => {
            const user = action.payload;

            state.user = user;
        },
        logout: (state) => {
            localStorage.setItem("accessToken", "");

            state.accessToken = null;

            state.user = null;
            state.error = null;
            state.isAuthenticated = false;
            state.isError = false;
            state.isLoading = false;
        },
        populate: create.asyncThunk(
            async (thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/user`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating user:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.isLoading = true;
                },
                rejected: (state, action) => {
                    localStorage.setItem("accessToken", "");

                    state.accessToken = null;

                    state.user = null;
                    state.isAuthenticated = false;
                    state.isLoading = false;
                },
                fulfilled: (state, action) => {
                    state.accessToken = localStorage.getItem("accessToken");

                    state.user = action.payload;
                    state.isAuthenticated = true;
                    state.isLoading = false;
                },
            }
        ),
        login: create.asyncThunk(
            async ({ email, password }, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_URL}/user/login`,
                            {
                                email,
                                password,
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error logging in:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.error = null;
                    state.isLoading = true;
                    state.isError = false;
                },
                rejected: (state, action) => {
                    localStorage.setItem("accessToken", "");

                    state.accessToken = null;

                    state.user = null;
                    state.isAuthenticated = false;
                    state.isLoading = false;
                    state.isError = true;
                    state.error = action.error;
                },
                fulfilled: (state, action) => {
                    const { user, accessToken } = action.payload;

                    localStorage.setItem("accessToken", accessToken);

                    state.accessToken = accessToken;

                    state.user = user;
                    state.error = null;
                    state.isAuthenticated = true;
                    state.isError = false;
                    state.isLoading = false;
                },
            }
        ),
        subscribeToPushService: create.asyncThunk(
            async (pushSubscription, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_URL}/user/push/subscribe`,
                            { pushSubscription },
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error subscribing to Push Service:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: () => {
                    localStorage.setItem("pushSubscription", "");
                },
                rejected: () => {
                    toast.error("Error subscribing to push service!");
                },
                fulfilled: (state, action) => {
                    console.log("Subscribed to Push Service:", action.payload);

                    localStorage.setItem(
                        "pushSubscription",
                        JSON.stringify(action.payload)
                    );
                },
            }
        ),
        unsubscribeFromPushService: create.asyncThunk(
            async (pushSubscription, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_URL}/user/push/unsubscribe`,
                            { pushSubscription },
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error unsubscribing to Push Service:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: () => {},
                rejected: () => {},
                fulfilled: (state, action) => {
                    localStorage.setItem("pushSubscription", "");
                },
            }
        ),
    }),
});

export const authActions = {
    ...authSlice.actions,
};

export default authSlice.reducer;
