/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React context
import { setLayout, useSoftUIController } from "context";
import useAuth from "../../../redux/hooks/useAuth";

function AdminLayout({ children }) {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, layout } = controller;
    const { pathname } = useLocation();

    const { user, isLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setLayout(dispatch, "admin");
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (!user.isAdministrator) {
                setLayout(dispatch, "dashboard");
                navigate("/sign-in");
            }
        }
    }, [isLoading]);

    return (
        <SoftBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: 3,
                position: "relative",

                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(
                        ["margin-left", "margin-right"],
                        {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }
                    ),
                },
            })}
        >
            {children}
        </SoftBox>
    );
}

// Typechecking props for the AdminLayout
AdminLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AdminLayout;
