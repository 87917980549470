import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Stack } from "@mui/material";
import { getFollowUp } from "common/api/followUp";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";
import { attemptsColumns, createTableRows } from "./Attempts.helpers";

const attemptsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 99,
};

const attemptModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 100,
};

function AttemptsCell({ followUp }) {
    const [attempts, setAttempts] = useState([]);
    const [isAttemptsModalOpen, setIsAttemptsModalOpen] = useState(false);
    const [isAttemptsLoading, setIsAttemptsLoading] = useState(false);
    // const [isAttemptModalOpen, setIsAttemptModalOpen] = useState(false);

    // const [selectedAttempt, setSelectedAttempt] = useState(null);

    const toggleAttemptsModal = () => {
        setIsAttemptsModalOpen(!isAttemptsModalOpen);
    };

    // const toggleAttemptModal = () => {
    //     setIsAttemptModalOpen(!isAttemptModalOpen);
    // };

    useEffect(() => {
        if (isAttemptsModalOpen) {
            (async () => {
                setIsAttemptsLoading(true);

                const response = await getFollowUp(followUp._id);

                setAttempts(response.data.payload.attempts);

                setIsAttemptsLoading(false);
            })();
        }
    }, [isAttemptsModalOpen]);

    const tableData = useMemo(() => {
        return {
            columns: attemptsColumns,
            rows: createTableRows(attempts),
        };
    }, [attempts]);

    return (
        <>
            <SoftBox
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textDecoration: "underline",
                    cursor: "pointer",
                }}
                onClick={toggleAttemptsModal}
            >
                {followUp?.totalAttempts} Attempt/s
            </SoftBox>
            <Modal open={isAttemptsModalOpen} onClose={toggleAttemptsModal}>
                <Box
                    sx={{
                        ...attemptsModalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Attempts ({followUp?.totalAttempts})
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Here is the list of all attempts made for this
                                follow-up.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    p: 1,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                }}
                                onClick={() => {
                                    toggleAttemptsModal();
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </SoftBox>
                        </Stack>
                    </SoftBox>

                    <DataTable
                        table={tableData}
                        entriesPerPage={{
                            defaultValue: 5,
                            entries: [],
                        }}
                    />

                    <Loader open={isAttemptsLoading} />

                    {/* {attempts.length === 0 && (
                        <SoftBox
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftTypography
                                variant="body2"
                                fontWeight="regular"
                                color="black"
                                sx={{
                                    lineHeight: 1.4,
                                }}
                            >
                                No attempts made yet.
                            </SoftTypography>
                        </SoftBox>
                    )}

                    {attempts.map((attempt, index) => (
                        <SoftBox
                            key={`followUp-${followUp._id}-attempts-${index}`}
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <SoftTypography
                                    variant="body1"
                                    fontWeight="regular"
                                    color="black"
                                    sx={{
                                        lineHeight: 1.4,
                                    }}
                                >
                                    {index + 1}.
                                </SoftTypography>

                                <SoftTypography
                                    variant="body1"
                                    fontWeight="regular"
                                    color="black"
                                    sx={{
                                        lineHeight: 1.4,
                                    }}
                                >
                                    {attempt.message}
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                <Tooltip title="View Details" placement="top">
                                    <VisibilityIcon
                                        fontSize="medium"
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setSelectedAttempt(attempt);

                                            toggleAttemptModal();
                                        }}
                                    />
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                    ))} */}
                </Box>
            </Modal>
            {/* <Modal open={isAttemptModalOpen} onClose={toggleAttemptModal}>
                <Box
                    sx={{
                        ...attemptModalStyle,
                    }}
                >
                    <SoftBox
                        mb={1}
                        lineHeight={0}
                        sx={{
                            color: "#000000",
                        }}
                    >
                        <SoftTypography variant="h5" fontWeight="bold">
                            Attempt Details
                        </SoftTypography>
                    </SoftBox>

                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={toggleAttemptModal}
                        >
                            Close
                        </Typography>
                    </Box>
                </Box>
            </Modal> */}
        </>
    );
}

export default AttemptsCell;
