import { Switch } from "@mui/material";
import { adminUpdateUser } from "common/api/admin/user";
import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const OutboundDisabledCell = ({
    isOutboundDisabled: _isOutboundDisabled,
    userId,
}) => {
    const [isOutboundDisabled, setIsOutboundDisabled] =
        useState(_isOutboundDisabled);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsOutboundDisabled(_isOutboundDisabled);
    }, [_isOutboundDisabled]);

    useEffect(() => {
        if (isOutboundDisabled === _isOutboundDisabled) return;

        setIsLoading(true);
        adminUpdateUser(userId, {
            isOutboundDisabled,
        })
            .then(() => {
                toast.success("User updated successfully.");
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.error?.message || error.message
                );
                setIsOutboundDisabled(_isOutboundDisabled);
                setIsLoading(false);
            });
    }, [isOutboundDisabled]);

    return (
        <SoftBox>
            <Switch
                checked={isOutboundDisabled}
                disabled={isLoading}
                onChange={() => {
                    setIsOutboundDisabled(!isOutboundDisabled);
                }}
            />
        </SoftBox>
    );
};

export default OutboundDisabledCell;
