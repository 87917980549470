import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import SelectBusiness from "pages/admin/Businesses/components/SelectBusiness";
import PropTypes from "prop-types";
import SelectUser from "../../../Users/components/SelectUser";
import FormField from "../FormField";
import SelectAssistant from "../SelectAssistant";

function NumberGroupDetails({ formData }) {
    const { formField, errors, touched } = formData;
    const { relayServerPoolId, name, business, defaultAssistant, userSetting } =
        formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        relayServerPoolId: relayServerPoolIdValue,
        name: nameValue,
        business: businessValue,
        defaultAssistant: defaultAssistantValue,
        userSetting: userSettingValue,
    } = values;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Phone Number Group
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Enter the phone number group details.
                </SoftTypography>
            </SoftBox>
            <SoftBox py={6}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                            <FormField
                                type={name.type}
                                label={name.label}
                                name={name.name}
                                placeholder={name.placeholder}
                                error={errors?.name && touched?.name}
                                success={nameValue.length > 0 && !errors?.name}
                            />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        User
                                    </SoftTypography>
                                </SoftBox>
                                <SelectUser
                                    withoutAny
                                    defaultValue={userSettingValue}
                                    callbackFn={(_) => {
                                        setFieldValue(userSetting.name, _);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Business
                                    </SoftTypography>
                                </SoftBox>
                                <SelectBusiness
                                    withoutAny
                                    defaultValue={businessValue}
                                    withNoBusiness={true}
                                    callbackFn={(_) => {
                                        setFieldValue(business.name, _);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Default Assistant
                                    </SoftTypography>
                                </SoftBox>
                                <SelectAssistant
                                    withoutAny
                                    defaultValue={defaultAssistantValue}
                                    withNoAssistant={true}
                                    callbackFn={(_) => {
                                        setFieldValue(defaultAssistant.name, _);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={relayServerPoolId.type}
                                    label={relayServerPoolId.label}
                                    name={relayServerPoolId.name}
                                    placeholder={relayServerPoolId.placeholder}
                                    error={false}
                                    success={
                                        !relayServerPoolIdValue ||
                                        (relayServerPoolIdValue.length > 0 &&
                                            !errors?.relayServerPoolId)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

NumberGroupDetails.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default NumberGroupDetails;
