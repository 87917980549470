import TollIcon from "@mui/icons-material/Toll";
import { Avatar } from "@mui/material";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const adminAssistantsColumns = [
    { Header: "DISPLAY AVATAR", accessor: "displayAvatar", width: "10%" },
    {
        Header: "DISPLAY NAME",
        accessor: "displayName",
        width: "30%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/assistants/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "businessName",
        width: "25%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/business/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "TOKEN LIMIT",
        accessor: "tokenLimit",
        width: "15%",
        Cell: ({ value }) => (
            <SoftBox
                sx={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                }}
            >
                <TollIcon fontSize="medium" />
                {value.toLocaleString()}
            </SoftBox>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(assistants) {
    return assistants.map((assistant) => ({
        displayAvatar: (
            <Avatar
                src={
                    assistant.displayAvatar
                        ? assistant.displayAvatar
                        : undefined
                }
                sx={{
                    bgcolor: assistant.displayColor,
                    width: 48,
                    height: 48,
                    objectFit: "cover",
                    mx: "auto",
                }}
            >
                <span
                    style={{
                        color: "white",
                        mixBlendMode: "difference",
                    }}
                >
                    {assistant.displayName
                        ?.split(" ")
                        .map((word) => word[0]?.toUpperCase())
                        .slice(0, 2)
                        .join("")}
                </span>
            </Avatar>
        ),
        displayName: [assistant.displayName, assistant],
        businessName: [assistant.business.name, assistant.business],
        tokenLimit: 30000,
        createdAt: [
            new Date(assistant.createdAt).getTime(),
            dayjs(assistant.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
