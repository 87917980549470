import { apiAxios } from "common/services/httpService";

export const getNewContactsStatistics = ({
    period,
    businessId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `dashboard/contacts?period=${period}&startDate=${startDate}&endDate=${endDate}&businessId=${businessId}`
    );
};

export const getSentMessagesStatistics = ({
    period,
    businessId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `dashboard/messages/sent?period=${period}&startDate=${startDate}&endDate=${endDate}&businessId=${businessId}`
    );
};

export const getReceivedMessagesStatistics = ({
    period,
    businessId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `dashboard/messages/received?period=${period}&startDate=${startDate}&endDate=${endDate}&businessId=${businessId}`
    );
};

export const getStartedConversationsStatistics = ({
    period,
    businessId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `dashboard/conversations/started?period=${period}&startDate=${startDate}&endDate=${endDate}&businessId=${businessId}`
    );
};

export const getScheduledMessagesStatistics = (params, { businessId = "" }) => {
    return apiAxios.get(
        `dashboard/messages/scheduled/?businessId=${businessId}`,
        params
    );
};
