import { Box } from "@mui/material";

export function ContactInfoPanel() {
    return (
        <Box
            sx={{ display: "flex", justifyContent: "right", marginTop: "42px" }}
        >
            {/* <FaEdit /> */}
        </Box>
    );
}
