import { apiAxios } from "common/services/httpService";

export const updatePhoneNumber = (phoneNumberId, data) => {
    return apiAxios.patch(`phone_number/${phoneNumberId}`, data);
};

export const getAllPhoneNumberDirectLinks = (phoneNumberId) => {
    return apiAxios.get(`phone_number/${phoneNumberId}/direct-link`);
};

export const createPhoneNumberDirectLink = (phoneNumberId, data) => {
    return apiAxios.post(`phone_number/${phoneNumberId}/direct-link`, data);
};

export const updatePhoneNumberDirectLink = (
    phoneNumberId,
    directLinkId,
    data
) => {
    return apiAxios.patch(
        `phone_number/${phoneNumberId}/direct-link/${directLinkId}`,
        data
    );
};

export const deletePhoneNumberDirectLink = (phoneNumberId, directLinkId) => {
    return apiAxios.delete(
        `phone_number/${phoneNumberId}/direct-link/${directLinkId}`
    );
};
