const { apiAxios } = require("common/services/httpService");

export const updateUser = (params) => {
    return apiAxios.patch("user", params);
};

export const forgotPassword = (params) => {
    return apiAxios.post("user/password/forgot", params);
};

export const resetPassword = (params) => {
    return apiAxios.post("user/password/reset", params);
};
