import EditNoteIcon from "@mui/icons-material/EditNote";
import { Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import UpdateScheduledMessage from "./components/UpdateScheduledMessageCell";

export const scheduledMessagesColumns = [
    {
        Header: "RECIPIENT",
        accessor: "recipient",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/contacts/${data.contact._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "businessName",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBadge
                variant="gradient"
                size="md"
                color="light"
                badgeContent={name}
                container
                border
                circular
            />
        ),
    },
    {
        Header: "MESSAGE",
        accessor: "message",
        Cell: ({ value: [scheduledMessage] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                }}
            >
                {scheduledMessage?.instruction && (
                    <Tooltip title="This is an instruction message. It will not be sent to the recipient.">
                        <EditNoteIcon
                            fontSize="medium"
                            sx={{
                                cursor: "pointer",
                            }}
                        />
                    </Tooltip>
                )}

                <SoftBox
                    sx={{
                        width: "500px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {scheduledMessage?.instruction ||
                        scheduledMessage?.message ||
                        "--"}
                </SoftBox>
            </SoftBox>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    {
        Header: "SCHEDULED AT",
        accessor: "scheduledAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    {
        Header: "STATUS",
        accessor: "status",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <SoftBadge
                    variant="gradient"
                    size="md"
                    color={
                        name === "sent"
                            ? "success"
                            : name === "failed"
                            ? "error"
                            : "warning"
                    }
                    badgeContent={name.toUpperCase()}
                    container
                    border
                    circular
                />
                {data.status !== "sent" && data.status !== "failed" && (
                    <UpdateScheduledMessage scheduledMessage={data} />
                )}
            </SoftBox>
        ),
    },
];

export function createTableRows(scheduledMessages) {
    const formattedPhoneNumber = (phone) => {
        try {
            return parsePhoneNumber(phone).formatNational();
        } catch (error) {
            return phone;
        }
    };

    return scheduledMessages.map((scheduledMessage) => ({
        recipient: [
            scheduledMessage.contact.name ||
                formattedPhoneNumber(scheduledMessage.contact.phone),
            scheduledMessage,
        ],
        businessName: [scheduledMessage.business.name || "--"],
        message: [scheduledMessage],
        status: [scheduledMessage.status, scheduledMessage],
        createdAt: [
            new Date(scheduledMessage.createdAt).getTime(),
            dayjs(scheduledMessage.createdAt).format("D MMM, YYYY"),
        ],
        scheduledAt: [
            new Date(scheduledMessage.scheduledAt).getTime(),
            dayjs(scheduledMessage.scheduledAt).format("D MMM, YYYY, h:mm A"),
        ],
    }));
}
