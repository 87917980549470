import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";

export const contactsColumns = [
    {
        Header: "NAME",
        accessor: "name",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/contacts/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    { Header: "PHONE", accessor: "phone", width: "15%" },
    {
        Header: "BUSINESS",
        accessor: "businessName",
        width: "15%",
        Cell: ({ value: [name, data] }) => (
            <SoftBadge
                variant="gradient"
                size="md"
                color="light"
                badgeContent={name}
                container
                border
                circular
            />
        ),
    },
    {
        Header: "LAST MESSAGE",
        accessor: "lastMessageDate",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "10%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(contacts) {
    const formattedPhoneNumber = (phone) => {
        try {
            return parsePhoneNumber(phone).formatNational();
        } catch (error) {
            return phone;
        }
    };

    return contacts.map((contact) => ({
        name: [contact.name || formattedPhoneNumber(contact.phone), contact],
        phone: contact.phone,
        businessName: [contact.business?.name || "--"],
        createdAt: [
            new Date(contact.createdAt).getTime(),
            dayjs(contact.createdAt).format("D MMM, YYYY"),
        ],
        lastMessageDate: [
            new Date(contact.lastMessageDate).getTime(),
            contact.lastMessageDate
                ? dayjs(contact.lastMessageDate).format("D MMM, YYYY, h:mm A")
                : "--",
        ],
    }));
}
