import { Box } from "@mui/material";
import classes from "../Conversations.module.css";

export function ContactInfoRow({ label, value, icon, sx = {} }) {
    return (
        <Box className={classes.contactInfoRow} sx={sx}>
            <span className={classes.contactInfoLabel}>{label}</span>
            <span className={classes.contactInfoValue}>
                <span>{value}</span>
                {icon && <span>{icon}</span>}
            </span>
        </Box>
    );
}
