const { apiAxios } = require("common/services/httpService");

export const adminGetPhoneNumber = (phoneNumberId, params) => {
    return apiAxios.get(`/admin/phone_number/${phoneNumberId}`, { params });
};

export const adminUpdatePhoneNumber = (phoneNumberId, params) => {
    return apiAxios.patch(`/admin/phone_number/${phoneNumberId}`, params);
};

export const adminGetAllPhoneNumberDirectLinks = (phoneNumberId) => {
    return apiAxios.get(`/admin/phone_number/${phoneNumberId}/direct-link`);
};

export const adminCreatePhoneNumberDirectLink = (phoneNumberId, data) => {
    return apiAxios.post(
        `/admin/phone_number/${phoneNumberId}/direct-link`,
        data
    );
};

export const adminUpdatePhoneNumberDirectLink = (
    phoneNumberId,
    directLinkId,
    data
) => {
    return apiAxios.patch(
        `/admin/phone_number/${phoneNumberId}/direct-link/${directLinkId}`,
        data
    );
};

export const adminDeletePhoneNumberDirectLink = (
    phoneNumberId,
    directLinkId
) => {
    return apiAxios.delete(
        `/admin/phone_number/${phoneNumberId}/direct-link/${directLinkId}`
    );
};
