import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Modal, Typography, alpha, styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { colors } from "assets/mui/theme";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useDebounce } from "core/hooks/useDebounce";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

const SearchInput = styled(({ toggleFilterModal, ...props }) => (
    <TextField
        InputLabelProps={{
            color: `${colors.blue[100]} !important`,
            marginBottom: "10px !important",
            width: "100%",
        }}
        sx={{
            "& .MuiInputBase-input": { flex: 1, fontSize: "17px" },
        }}
        InputProps={{
            disableUnderline: true,
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon
                        sx={{
                            color: "action.active",
                            mr: 1,
                            my: 0.5,
                            fontSize: "26px !important",
                        }}
                    />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment
                    position="end"
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={toggleFilterModal}
                >
                    <FilterAltIcon
                        sx={{
                            color: "black",
                            borderLeft: "1px solid lightgray",
                            paddingLeft: "10px",
                            fontSize: "37px !important",
                        }}
                    />
                </InputAdornment>
            ),
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiFilledInput-root": {
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: `${alpha(colors.blue[100], 0.25)} 0 0 0 2px`,
            borderColor: colors.blue[100],
        },
    },
    "& .MuiInputBase-root": {
        marginTop: "20px",
        paddingRight: "0.5rem !important",
    },
}));

export function ConversationSearch() {
    const [searchQueryInput, setSearchQueryInput] = useState("");
    const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
    const searchQueryDebounced = useDebounce(searchQueryInput, 500);

    const [initialAssistant, setInitialAssistant] = useState();
    const [activeAssistant, setActiveAssistant] = useState();

    const dispatch = useDispatch();
    const { searchQuery, selectedBusinessId, assistants, filters } = useApp();

    useEffect(() => {
        if (searchQuery !== searchQueryDebounced.replace(/ /g, "")) {
            dispatch(
                appActions.setSearchQuery(
                    searchQueryDebounced.replace(/ /g, "")
                )
            );
            dispatch(appActions.fetchConversations());
        }
    }, [searchQueryDebounced, dispatch, searchQuery]);

    const toggleFilterModal = () => {
        setIsFilterModalOpened(!isFilterModalOpened);
    };

    useEffect(() => {
        dispatch(
            appActions.setFilters({
                initialAssistant: "",
                activeAssistant: "",
            })
        );
    }, [selectedBusinessId]);

    const options = [
        {
            value: "",
            label: "Any",
        },
        ...(assistants.map((a) => ({
            value: a._id.toString(),
            label: a.displayName,
        })) || []),
    ];

    return (
        <>
            <SearchInput
                placeholder="Search conversations"
                variant="standard"
                sx={{ width: "100%" }}
                value={searchQueryInput}
                onChange={(e) => {
                    setSearchQueryInput(e.target.value);
                }}
                toggleFilterModal={toggleFilterModal}
            />

            <Modal open={isFilterModalOpened} onClose={toggleFilterModal}>
                <Box
                    sx={{
                        ...style,
                    }}
                >
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Initial Assistant:
                        </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                        placeholder="Initial assistant"
                        defaultValue={options.filter(
                            (option) =>
                                option.value === filters.initialAssistant
                        )}
                        options={options}
                        onChange={({ value }) => {
                            setInitialAssistant(value);
                        }}
                    />

                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Active Assistant:
                        </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                        placeholder="Active assistant"
                        defaultValue={options.filter(
                            (option) => option.value === filters.activeAssistant
                        )}
                        options={options}
                        onChange={({ value }) => {
                            setActiveAssistant(value);
                        }}
                    />

                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 400,
                                borderRadius: 1,
                                bgcolor: "#1C4C73",
                                color: "#fff",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                dispatch(
                                    appActions.setFilters({
                                        ...filters,
                                        initialAssistant:
                                            initialAssistant || "",
                                        activeAssistant: activeAssistant || "",
                                    })
                                );
                                dispatch(appActions.fetchConversations());
                                toggleFilterModal();
                            }}
                        >
                            Apply
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={toggleFilterModal}
                        >
                            Close
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
