const { apiAxios } = require("common/services/httpService");

export const adminGetUser = (userId, params) => {
    return apiAxios.get(`/admin/user/${userId}`, { params });
};

export const adminUpdateUser = (userId, params) => {
    return apiAxios.patch(`/admin/user/${userId}`, params);
};

export const adminSendPushNotification = (userId, params) => {
    return apiAxios.post(`/admin/user/${userId}/push/send`, params);
};
