import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import SelectBusiness from "pages/admin/Businesses/components/SelectBusiness";
import PropTypes from "prop-types";
import SelectUser from "../../../Users/components/SelectUser";
import FormField from "../FormField";
import SelectAssistant from "../SelectAssistant";

function NumberDetails({ formData }) {
    const { formField, errors, touched } = formData;
    const {
        relayServerId,
        senderName,
        senderNumber,
        userSetting,
        defaultAssistant,
        business,
    } = formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        relayServerId: relayServerIdValue,
        senderName: senderNameValue,
        senderNumber: senderNumberValue,
        userSetting: userSettingValue,
        defaultAssistant: defaultAssistantValue,
        business: businessValue,
    } = values;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Phone Number
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Enter the phone number details.
                </SoftTypography>
            </SoftBox>
            <SoftBox py={6}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    User
                                </SoftTypography>
                            </SoftBox>
                            <SelectUser
                                withoutAny
                                defaultValue={userSettingValue}
                                callbackFn={(_) => {
                                    setFieldValue(userSetting.name, _);
                                }}
                            />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Business
                                    </SoftTypography>
                                </SoftBox>
                                <SelectBusiness
                                    withoutAny
                                    defaultValue={businessValue}
                                    withNoBusiness={true}
                                    callbackFn={(_) => {
                                        setFieldValue(business.name, _);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Default Assistant
                                    </SoftTypography>
                                </SoftBox>
                                <SelectAssistant
                                    withoutAny
                                    defaultValue={defaultAssistantValue}
                                    withNoAssistant={true}
                                    callbackFn={(_) => {
                                        setFieldValue(defaultAssistant.name, _);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={-1.2}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={senderName.type}
                                    label={senderName.label}
                                    name={senderName.name}
                                    placeholder={senderName.placeholder}
                                    error={
                                        errors?.senderName &&
                                        touched?.senderName
                                    }
                                    success={
                                        senderNameValue.length > 0 &&
                                        !errors?.senderName
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    type={senderNumber.type}
                                    label={senderNumber.label}
                                    name={senderNumber.name}
                                    placeholder={senderNumber.placeholder}
                                    error={
                                        errors?.senderNumber &&
                                        touched?.senderNumber
                                    }
                                    success={
                                        senderNumberValue.length > 0 &&
                                        !errors?.senderNumber
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormField
                                type={relayServerId.type}
                                label={relayServerId.label}
                                name={relayServerId.name}
                                placeholder={relayServerId.placeholder}
                                error={false}
                                success={
                                    !relayServerIdValue ||
                                    (relayServerIdValue.length > 0 &&
                                        !errors?.relayServerId)
                                }
                            />
                            <SoftTypography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                    display: "block",
                                    mt: -1,
                                }}
                            >
                                Note: Relay Server ID is optional. You can leave
                                it blank for LoopMessage.
                            </SoftTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

NumberDetails.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default NumberDetails;
