/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

// @mui material components

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

import { CircularProgress } from "@mui/material";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import SoftTypography from "components/SoftTypography";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/authSlice";
import useAuth from "../../redux/hooks/useAuth";

const { gradients } = colors;

function SignInPage() {
    const navigate = useNavigate();
    // const [rememberMe, setRememberMe] = useState(false);

    const {
        user,
        error,

        isAuthenticated,
        isLoading,
        isError,
    } = useAuth();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            navigate("/dashboard");
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        if (!isLoading && isError) {
            console.log("[AUTHENTICATION / SIGN IN]: Error: ", error);
            toast.error(error.message);
        }
    }, [isLoading, isError]);

    let [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    const handleInput = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    // const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const handleSubmit = async () => {
        console.log(
            "[AUTHENTICATION / SIGN IN]: Submitted login data: ",
            loginData
        );

        if (!loginData.email) {
            return toast.error("Email is required!");
        }

        if (!loginData.password) {
            return toast.error("Password is required!");
        }

        dispatch(
            authActions.login({
                email: loginData.email,
                password: loginData.password,
            })
        );
    };

    return (
        <IllustrationLayout
            color={linearGradient(
                gradients.darkTeal.main,
                gradients.darkTeal.state
            )}
            title="Sign In"
            description="Enter your email and password to continue."
            illustration={{
                image: chat,
                title: "AI Powered marketing, sales, and support through ios",
                description:
                    'Unlock the true power of generative AI for your business with "conversational marketing" The highest converting sales process ever created.',
            }}
        >
            <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                    <SoftInput
                        type="email"
                        placeholder="Email"
                        size="large"
                        value={loginData.email}
                        name="email"
                        onChange={handleInput}
                    />
                </SoftBox>
                <SoftBox mb={2}>
                    <SoftInput
                        type="password"
                        placeholder="Password"
                        size="large"
                        value={loginData.password}
                        name="password"
                        onChange={handleInput}
                    />
                </SoftBox>
                {/* <SoftBox display="flex" alignItems="center">
                    <Switch
                        checked={rememberMe}
                        onChange={handleSetRememberMe}
                    />
                    <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                        &nbsp;&nbsp;Remember me
                    </SoftTypography>
                </SoftBox> */}
                <SoftBox mt={3} mb={2}>
                    <SoftButton
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={isLoading}
                        type="submit"
                    >
                        {isLoading && ( // If loading, show spinner
                            <CircularProgress
                                size={24}
                                thickness={6}
                                color="inherit"
                            />
                        )}

                        {!isLoading && "SIGN IN"}
                    </SoftButton>
                </SoftBox>
                <SoftBox mb={2}>
                    <SoftTypography variant="body2">
                        Forgot password?{" "}
                        <SoftTypography
                            component={Link}
                            to="/forgot-password/"
                            variant="body2"
                            color="info"
                        >
                            Click here to reset it
                        </SoftTypography>
                    </SoftTypography>
                </SoftBox>

                {/* <SoftBox mt={3} textAlign="center">
                    <SoftTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                    >
                        Don&apos;t have an account?{" "}
                        <SoftTypography
                            component={Link}
                            to="/authentication/sign-up/illustration"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                            Sign up
                        </SoftTypography>
                    </SoftTypography>
                </SoftBox> */}
            </SoftBox>
        </IllustrationLayout>
    );
}

export default SignInPage;
