import checkout from "./form";

const {
    formField: {
        name,
        displayName,
        state,
        city,
        managerName,
        managerNumber,
        description,
        businessHoursEnabled,
        businessHoursStartTime,
        businessHoursEndTime,
        teamMembers,
        phoneNumbers,
    },
} = checkout;

const initialValues = {
    // 1st Step
    [name.name]: "",
    [displayName.name]: "",
    [state.name]: "",
    [city.name]: "",
    [description.name]: "",
    [businessHoursEnabled.name]: false,
    [businessHoursStartTime.name]: "",
    [businessHoursEndTime.name]: "",

    // 2nd Step
    [teamMembers.name]: [],

    // 3rd Step
    [phoneNumbers.name]: [],
};

export default initialValues;
