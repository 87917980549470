import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { apiAxios } from "common/services/httpService";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

const { formId, formField } = form;

function getSteps() {
    return ["Business Details", "Team Members", "Assign Phone Numbers"];
}

function getStepContent(stepIndex, formData) {
    console.log("Form Data changed: ", formData);

    switch (stepIndex) {
        case 0:
            return <FirstStep formData={formData} />;
        case 1:
            return <SecondStep formData={formData} />;
        case 2:
            return <ThirdStep formData={formData} />;
        default:
            return null;
    }
}

function CreateBusinessPage() {
    const [activeStep, setActiveStep] = useState(0);

    const steps = getSteps();

    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const navigate = useNavigate();

    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        try {
            const {
                businessHoursEnabled,
                businessHoursStartTime,
                businessHoursEndTime,
                ...rest
            } = values;

            await apiAxios.post(`business`, {
                ...rest,
                businessHours: {
                    isEnabled: businessHoursEnabled,
                    startTime:
                        businessHoursStartTime &&
                        parseInt(businessHoursStartTime),
                    endTime:
                        businessHoursEndTime && parseInt(businessHoursEndTime),
                },
            });
            actions.setSubmitting(false);
            actions.resetForm();

            toast.success("Business created successfully.");
            navigate("/businesses");
        } catch (error) {
            actions.setSubmitting(false);
            toast.error(error?.response?.data?.error?.message || error.message);
        }
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            // actions.setTouched();
            actions.setSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={2} mb={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                {getStepContent(activeStep, {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                })}
                                                <SoftBox
                                                    mt={2}
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    {activeStep === 0 ? (
                                                        <SoftBox />
                                                    ) : (
                                                        <SoftButton
                                                            variant="gradient"
                                                            color="light"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </SoftButton>
                                                    )}
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        {isLastStep
                                                            ? "Create"
                                                            : "Next"}
                                                    </SoftButton>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CreateBusinessPage;
