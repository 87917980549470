import { combineReducers, configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminSlice";
import appReducer from "./appSlice";
import authReducer from "./authSlice";

const combinedReducer = combineReducers({
    admin: adminReducer,
    auth: authReducer,
    app: appReducer,
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
});
