import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import styled from "styled-components";
import WaveSurfer from "wavesurfer.js";

const Waveform = ({ audio }) => {
    const containerRef = useRef();
    const waveSurferRef = useRef({
        isPlaying: () => false,
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [totalTime, setTotalTime] = useState(0);

    useEffect(() => {
        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            responsive: true,
            height: 40,
            barWidth: 2,
            barheight: 5,
            cursorWidth: 0,
            waveColor: "#abcdef",
            progressColor: "#ffffff",
        });

        waveSurfer.load(audio);
        waveSurfer.on("ready", () => {
            waveSurferRef.current = waveSurfer;
            setTotalTime(waveSurfer.getDuration() / 100);
        });

        waveSurfer.on("finish", () => {
            setIsPlaying(false);
        });

        return () => {
            waveSurfer.destroy();
        };
    }, [audio]);

    return (
        <WaveSurferWrap>
            <button
                onClick={() => {
                    waveSurferRef.current.playPause();
                    setIsPlaying(!isPlaying);
                }}
                type="button"
            >
                {isPlaying ? (
                    <FaPauseCircle size="2.7em" />
                ) : (
                    <FaPlayCircle size="2.7em" />
                )}
            </button>
            <div ref={containerRef} />
            <Typography color={"#ffffff"} fontSize={18}>
                {totalTime.toFixed(2)}
            </Typography>
        </WaveSurferWrap>
    );
};

Waveform.propTypes = {
    audio: PropTypes.string.isRequired,
};

const WaveSurferWrap = styled.div`
    display: grid;
    grid-template-columns: 45px 4fr 1fr;
    align-items: center;
    button {
        width: 35px;
        height: 35px;
        border: none;
        background-color: transparent;
        color: white;
        cursor: pointer;
    }
`;

export default Waveform;
