export const colors = {
  blue: {
    100: '#468df5',
    200: '#00abee',
    800: '#033864',
  },
  gray: {
    50: '#ececec',
    100: 'lightgray',
    200: 'lightslategray',
    300: '#737373'
  },
  greenBlue: {
    50: '#D4DFEC',
    100: '#1C4C73'
  },
  pink: {
    100: '#714ECC'
  }
}