import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { apiAxios } from "common/services/httpService";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdditionalDetails from "./components/AdditionalDetails";
import Skills from "./components/AssistantSkills";
import BasicInfo from "./components/BasicInfo";
import ConversationSteps from "./components/ConversationSteps";
import FollowUpSteps from "./components/FollowUpSteps";
import Preferences from "./components/Preferences";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

const { formId, formField } = form;

function getSteps() {
    return [
        "Personalization & Basic Info",
        "Preferences, Business & Links",
        "Assistant Skills",
        "Conversation Steps",
        "Follow-Up Steps",
        "Personal & Professional Details",
    ];
}

function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
        case 0:
            return <BasicInfo formData={formData} />;
        case 1:
            return <Preferences formData={formData} />;
        case 2:
            return <Skills formData={formData} />;
        case 3:
            return <ConversationSteps formData={formData} />;
        case 4:
            return <FollowUpSteps formData={formData} />;
        case 5:
            return <AdditionalDetails formData={formData} />;
        default:
            return null;
    }
}

function CreateAssistantPage() {
    const [activeStep, setActiveStep] = useState(0);

    const steps = getSteps();

    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const navigate = useNavigate();

    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        try {
            await apiAxios.post(`/assistant`, values);
            actions.setSubmitting(false);
            actions.resetForm();

            toast.success("Assistant created successfully.");
            navigate("/assistants");
        } catch (error) {
            actions.setSubmitting(false);
            toast.error(error.response?.data?.error?.message || error.message);
        }
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            // actions.setTouched();
            actions.setSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={2} mb={3}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={10}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                {getStepContent(activeStep, {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                })}
                                                <SoftBox
                                                    mt={2}
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    {activeStep === 0 ? (
                                                        <SoftBox />
                                                    ) : (
                                                        <SoftButton
                                                            variant="gradient"
                                                            color="light"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </SoftButton>
                                                    )}
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        {isLastStep
                                                            ? "Create"
                                                            : "Next"}
                                                    </SoftButton>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CreateAssistantPage;
