// Soft UI Dashboard PRO React icons
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PhoneIcon from "@mui/icons-material/Phone";
import Shop from "examples/Icons/Shop";
import SpaceShip from "examples/Icons/SpaceShip";
import AdminAssistants from "pages/admin/Assistants";
import CreateAssistantPage from "pages/admin/Assistants/CreateAssistant";
import UpdateAssistantPage from "pages/admin/Assistants/UpdateAssistant";
import AdminBusinesses from "pages/admin/Businesses";
import CreateBusinessPage from "pages/admin/Businesses/CreateBusiness";
import UpdateBusinessPage from "pages/admin/Businesses/UpdateBusiness";

// Pages
import AdminDashboard from "pages/admin/Dashboard";
import AdminNumberGroups from "pages/admin/NumberGroups";
import CreateNumberGroupPage from "pages/admin/NumberGroups/CreateNumberGroup";
import UpdateNumberGroupPage from "pages/admin/NumberGroups/UpdateNumberGroup";
import AdminNumbers from "pages/admin/Numbers";
import CreateNumberPage from "pages/admin/Numbers/CreateNumber";
import UpdateNumberPage from "pages/admin/Numbers/UpdateNumber";
import AdminUsers from "pages/admin/Users";
import CreateUserPage from "pages/admin/Users/CreateUser";
import UpdateUserPage from "pages/admin/Users/UpdateUser";

const adminRoutes = [
    { type: "title", title: "ADMIN OVERVIEW", key: "title-pages" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "/admin/dashboard",
        icon: <Shop />,
        noCollapse: true,
        route: "/admin/dashboard",
        component: <AdminDashboard />,
    },
    {
        type: "collapse",
        name: "Users",
        key: "/admin/users",
        icon: <PeopleIcon />,
        noCollapse: true,
        route: "/admin/users",
        component: <AdminUsers />,
    },
    {
        name: "Create User",
        key: "/admin/users/create",
        noCollapse: true,
        route: "/admin/users/create",
        component: <CreateUserPage />,
    },
    {
        name: "Update User",
        key: "/admin/users/:userId",
        noCollapse: true,
        route: "/admin/users/:userId",
        component: <UpdateUserPage />,
    },
    {
        type: "collapse",
        name: "Businesses",
        key: "/admin/businesses",
        icon: <BusinessIcon />,
        noCollapse: true,
        route: "/admin/businesses",
        component: <AdminBusinesses />,
    },
    {
        name: "Create Business",
        key: "/admin/businesses/create",
        noCollapse: true,
        route: "/admin/businesses/create",
        component: <CreateBusinessPage />,
    },
    {
        name: "Update Business",
        key: "/admin/businesses/:businessId",
        noCollapse: true,
        route: "/admin/businesses/:businessId",
        component: <UpdateBusinessPage />,
    },
    {
        type: "collapse",
        name: "Numbers",
        key: "/admin/numbers",
        icon: <PhoneIcon />,
        noCollapse: true,
        route: "/admin/numbers",
        component: <AdminNumbers />,
    },
    {
        name: "Create Number",
        key: "/admin/numbers/create",
        noCollapse: true,
        route: "/admin/numbers/create",
        component: <CreateNumberPage />,
    },
    {
        name: "Update Number",
        key: "/admin/numbers/:numberId",
        noCollapse: true,
        route: "/admin/numbers/:numberId",
        component: <UpdateNumberPage />,
    },
    {
        type: "collapse",
        name: "Number Groups",
        key: "/admin/number-groups",
        icon: <PhoneIcon />,
        noCollapse: true,
        route: "/admin/number-groups",
        component: <AdminNumberGroups />,
    },
    {
        name: "Create Number Group",
        key: "/admin/number-groups/create",
        noCollapse: true,
        route: "/admin/number-groups/create",
        component: <CreateNumberGroupPage />,
    },
    {
        name: "Update Number Group",
        key: "/admin/number-groups/:numberGroupId",
        noCollapse: true,
        route: "/admin/number-groups/:numberGroupId",
        component: <UpdateNumberGroupPage />,
    },
    {
        type: "collapse",
        name: "Assistants",
        key: "/admin/assistants",
        icon: <SpaceShip />,
        noCollapse: true,
        route: "/admin/assistants",
        component: <AdminAssistants />,
    },
    {
        name: "Create Assistant",
        key: "/admin/assistants/create",
        noCollapse: true,
        route: "/admin/assistants/create",
        component: <CreateAssistantPage />,
    },
    {
        name: "Update Assistant",
        key: "/admin/assistants/:assistantId",
        noCollapse: true,
        route: "/admin/assistants/:assistantId",
        component: <UpdateAssistantPage />,
    },
];

export default adminRoutes;
