import { updatePhoneNumberGroup } from "common/api/numberGroups";
import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function SelectBusinessCell({ phoneNumberGroupId, defaultValue = undefined }) {
    const {
        businesses,
        phoneNumberGroups,
        businessesError,
        isBusinessesLoading,
    } = useApp();
    const [selectedBusinessId, setSelectedBusinessId] = useState(defaultValue);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appActions.fetchBusinesses());
    }, []);

    const phoneNumberGroup = useMemo(() => {
        return phoneNumberGroups?.find((_) => _._id === phoneNumberGroupId);
    }, [phoneNumberGroups]);

    const businessesValues = useMemo(() => {
        return [
            {
                label: "No Business",
                value: null,
            },
            ...businesses?.map((business) => ({
                label: business.name,
                value: business._id,
            })),
        ].filter(Boolean);
    }, [businesses]);

    useEffect(() => {
        setSelectedBusinessId(
            phoneNumberGroup?.business !== null
                ? phoneNumberGroup?.business?._id
                : phoneNumberGroup?.business
        );
    }, [phoneNumberGroup]);

    useEffect(() => {
        if (selectedBusinessId === undefined) return;

        if (
            phoneNumberGroup?.business !== null &&
            phoneNumberGroup?.business?._id === undefined
        )
            return;

        if (
            phoneNumberGroup.business === null
                ? selectedBusinessId === phoneNumberGroup?.business
                : selectedBusinessId === phoneNumberGroup?.business?._id
        )
            return;

        updatePhoneNumberGroup(phoneNumberGroupId, {
            business: selectedBusinessId,
        })
            .then((res) => {
                toast.success("Phone number group updated successfully.");
                dispatch(appActions.fetchPhoneNumberGroups());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err.message);
                dispatch(appActions.fetchPhoneNumberGroups());
            });
    }, [selectedBusinessId]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={businessesValues}
                value={businessesValues.filter(
                    (_) => _.value === selectedBusinessId
                )}
                onChange={({ value }) => {
                    if (value === selectedBusinessId) return;

                    setSelectedBusinessId(value);
                }}
            />
        </div>
    );
}

export default SelectBusinessCell;
