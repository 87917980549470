import axios from "axios";
import { createSliceWithThunks } from "./createSliceWithThunks";

const initialState = {
    users: [],
    usersError: null,
    isUsersLoading: false,

    selectedUserId: undefined,
    selectedBusinessId: undefined,
    selectedAssistantId: undefined,

    businesses: [],
    businessesError: null,
    isBusinessesLoading: false,

    phoneNumbers: [],
    phoneNumbersError: null,
    isPhoneNumbersLoading: false,

    phoneNumberGroups: [],
    phoneNumberGroupsError: null,
    isPhoneNumberGroupsLoading: false,

    assistants: [],
    assistantsError: null,
    isAssistantsLoading: false,

    usedModels: [],
    usedModelsError: null,
    isUsedModelsLoading: false,
};

const adminSlice = createSliceWithThunks({
    name: "admin",
    initialState,
    reducers: (create) => ({
        selectUser: (state, action) => {
            state.selectedUserId = action.payload;
        },
        selectBusiness: (state, action) => {
            state.selectedBusinessId = action.payload;
        },
        selectAssistant: (state, action) => {
            state.selectedAssistantId = action.payload;
        },
        fetchUsers: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/admin/user`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating users:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.usersError = null;
                    state.isUsersLoading = true;
                },
                rejected: (state, action) => {
                    state.usersError = action.error;
                    state.isUsersLoading = false;
                },
                fulfilled: (state, action) => {
                    state.users = action.payload;
                    state.isUsersLoading = false;
                },
            }
        ),
        fetchBusinesses: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/admin/business${
                                thunkApi.getState().admin.selectedUserId
                                    ? `?userId=${
                                          thunkApi.getState().admin
                                              .selectedUserId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating businesses:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.businessesError = null;
                    state.isBusinessesLoading = true;
                },
                rejected: (state, action) => {
                    state.businessesError = action.error;
                    state.isBusinessesLoading = false;
                },
                fulfilled: (state, action) => {
                    state.businesses = action.payload;
                    state.isBusinessesLoading = false;
                },
            }
        ),
        fetchPhoneNumbers: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/admin/phone_number${
                                thunkApi.getState().admin.selectedUserId
                                    ? `?userId=${
                                          thunkApi.getState().admin
                                              .selectedUserId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating phone numbers:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.phoneNumbersError = null;
                    state.isPhoneNumbersLoading = true;
                },
                rejected: (state, action) => {
                    state.phoneNumbersError = action.error;
                    state.isPhoneNumbersLoading = false;
                },
                fulfilled: (state, action) => {
                    state.phoneNumbers = action.payload;
                    state.isPhoneNumbersLoading = false;
                },
            }
        ),
        fetchPhoneNumberGroups: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${
                                process.env.REACT_APP_API_URL
                            }/admin/phone_number_group${
                                thunkApi.getState().admin.selectedUserId
                                    ? `?userId=${
                                          thunkApi.getState().admin
                                              .selectedUserId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log(
                            "Error populating phone number groups:",
                            error
                        );
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.phoneNumberGroupsError = null;
                    state.isPhoneNumberGroupsLoading = true;
                },
                rejected: (state, action) => {
                    state.phoneNumberGroupsError = action.error;
                    state.isPhoneNumberGroupsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.phoneNumberGroups = action.payload;
                    state.isPhoneNumberGroupsLoading = false;
                },
            }
        ),
        fetchAssistants: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/admin/assistant${
                                thunkApi.getState().admin.selectedBusinessId
                                    ? `?businessId=${
                                          thunkApi.getState().admin
                                              .selectedBusinessId
                                      }`
                                    : ""
                            }`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating assistants:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.assistantsError = null;
                    state.isAssistantsLoading = true;
                },
                rejected: (state, action) => {
                    state.assistantsError = action.error;
                    state.isAssistantsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.assistants = action.payload;
                    state.isAssistantsLoading = false;
                },
            }
        ),
        fetchUsedModels: create.asyncThunk(
            async (_, thunkApi) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/admin/dashboard/models`,
                            {
                                headers: {
                                    authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        );

                        const data = await response.data;

                        resolve(data.payload);
                    } catch (error) {
                        console.log("Error populating used models:", error);
                        reject(error.response?.data?.error);
                    }
                }),
            {
                pending: (state) => {
                    state.usedModelsError = null;
                    state.isUsedModelsLoading = true;
                },
                rejected: (state, action) => {
                    state.usedModelsError = action.error;
                    state.isUsedModelsLoading = false;
                },
                fulfilled: (state, action) => {
                    state.usedModels = action.payload;
                    state.isUsedModelsLoading = false;
                },
            }
        ),
    }),
});

export const adminActions = {
    ...adminSlice.actions,
};

export default adminSlice.reducer;
