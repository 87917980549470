import { updatePhoneNumber } from "common/api/numbers";
import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function SelectBusinessCell({ phoneNumberId, defaultValue = undefined }) {
    const { businesses, phoneNumbers, businessesError, isBusinessesLoading } =
        useApp();
    const [selectedBusinessId, setSelectedBusinessId] = useState(defaultValue);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appActions.fetchBusinesses());
    }, []);

    const phoneNumber = useMemo(() => {
        return phoneNumbers?.find((_) => _._id === phoneNumberId);
    }, [phoneNumbers]);

    const businessesValues = useMemo(() => {
        return [
            {
                label: "No Business",
                value: null,
            },
            ...businesses?.map((business) => ({
                label: business.name,
                value: business._id,
            })),
        ].filter(Boolean);
    }, [businesses]);

    useEffect(() => {
        setSelectedBusinessId(
            phoneNumber?.business !== null
                ? phoneNumber?.business?._id
                : phoneNumber?.business
        );
    }, [phoneNumber]);

    useEffect(() => {
        if (selectedBusinessId === undefined) return;

        if (
            phoneNumber?.business !== null &&
            phoneNumber?.business?._id === undefined
        )
            return;

        if (
            phoneNumber.business === null
                ? selectedBusinessId === phoneNumber?.business
                : selectedBusinessId === phoneNumber?.business?._id
        )
            return;

        updatePhoneNumber(phoneNumberId, {
            business: selectedBusinessId,
        })
            .then((res) => {
                toast.success("Phone number updated successfully.");
                dispatch(appActions.fetchPhoneNumbers());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err.message);
                dispatch(appActions.fetchPhoneNumbers());
            });
    }, [selectedBusinessId]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={businessesValues}
                value={businessesValues.filter(
                    (_) => _.value === selectedBusinessId
                )}
                onChange={({ value }) => {
                    if (value === selectedBusinessId) return;

                    setSelectedBusinessId(value);
                }}
            />
        </div>
    );
}

export default SelectBusinessCell;
