/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

function FormField({ label, ...rest }) {
    return (
        <>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}
                </SoftTypography>
            </SoftBox>
            <SoftInput {...rest} />
        </>
    );
}

FormField.propTypes = {
    label: PropTypes.string.isRequired,
};

export default FormField;
