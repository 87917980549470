import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Card, Stack } from "@mui/material";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import AdminLayout from "examples/LayoutContainers/AdminLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminActions } from "../../../redux/adminSlice";
import useAdmin from "../../../redux/hooks/useAdmin";
import SelectBusiness from "../Businesses/components/SelectBusiness";
import SelectUser from "../Users/components/SelectUser";
import {
    adminAssistantsColumns,
    createTableRows,
} from "./AdminAssistants.helpers";

const AdminAssistants = () => {
    const {
        assistants,
        selectedBusinessId,
        assistantsError,
        isAssistantsLoading,
    } = useAdmin();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(adminActions.fetchAssistants());
    }, [selectedBusinessId]);

    const tableData = useMemo(() => {
        return {
            columns: adminAssistantsColumns,
            rows: createTableRows(assistants),
        };
    }, [assistants]);

    return (
        <AdminLayout>
            <DashboardNavbar />
            <SoftBox pt={3} pb={3}>
                <Card>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        p={3}
                    >
                        <SoftBox lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                All Assistants
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                List of all assistants.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                onClick={() => {
                                    if (isAssistantsLoading) return;

                                    dispatch(adminActions.fetchAssistants());
                                }}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 1,
                                    pr: 2,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                }}
                            >
                                <RefreshIcon fontSize="medium" />
                            </SoftBox>
                            <SelectUser />
                            <SelectBusiness />
                            <SoftBox
                                onClick={() => {
                                    navigate("/admin/assistants/create");
                                }}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 1,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                    backgroundColor: "#1C4C73",
                                    color: "#fff",
                                }}
                            >
                                <AddIcon fontSize="medium" />
                            </SoftBox>
                        </Stack>
                    </SoftBox>
                    <DataTable table={tableData} canSearch />
                </Card>
            </SoftBox>
            <Loader open={isAssistantsLoading} />
            <Footer />
        </AdminLayout>
    );
};

export default AdminAssistants;
