import { colors } from "assets/mui/theme";
import { useMemo, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import Waveform from "./WaveForm";

const { styled, Box, CircularProgress } = require("@mui/material");

/* eslint-disable react/prop-types */

const rightSideRoles = ["assistant", "system", "tool", "team"];

const MessageBox = styled(Box)((params) => ({
    color: params.message.role === "assistant" ? "white" : "black",
    marginBottom: 5,
    marginLeft:
        params.message.role === "tool" || params.message.role === "team"
            ? "35%"
            : rightSideRoles.includes(params.message.role)
            ? "20%"
            : "15px",
    marginRight:
        params.message.role === "tool" || params.message.role === "team"
            ? "15px"
            : rightSideRoles.includes(params.message.role)
            ? "15px"
            : "20%",
    position: "relative",
    fontSize: params.message.role === "tool" || params.message.role === "team" ? "85%" : "inherit",
    wordWrap: "break-word",
    padding: "12px 16px",
    marginTop:
        params.message.reactions && params.message.reactions.length > 0
            ? 10
            : 0,
    background: params.message.isOptimistic
        ? "#74a9f9"
        : params.message.role === "assistant"
        ? "#468df5"
        : params.message.role === "system"
        ? "#e9e9eb"
        : params.message.role === "tool" || params.message.role === "team"
        ? "#eaf2ff"
        : "#e9e9eb",
    borderRadius: 24,

    ...(params.message.content !== "" && {
        whiteSpace: "break-spaces",
    }),

    "&:after": {
        content: '""',
        position: "absolute",
        bottom: "10px",
        right: rightSideRoles.includes(params.message.role) ? 0 : "initial",
        left: !rightSideRoles.includes(params.message.role) ? 0 : "initial",
        width: 0,
        height: 0,

        border: "24px solid transparent",

        ...(params.message.role === "assistant"
            ? {
                  borderLeftColor: params.message.isOptimistic
                      ? "#74a9f9"
                      : "#468df5",
              }
            : params.message.role === "system"
            ? {
                  borderLeftColor: "#e9e9eb",
              }
            : params.message.role === "tool" || params.message.role === "team"
            ? {
                  borderLeftColor: "#eaf2ff",
              }
            : {}),
        ...(!rightSideRoles.includes(params.message.role) && {
            borderRightColor: "#e9e9eb",
        }),

        ...(rightSideRoles.includes(params.message.role)
            ? {
                  borderRight: 0,
              }
            : {
                  borderLeft: 0,
              }),
        borderBottom: 0,

        ...(rightSideRoles.includes(params.message.role) && {
            marginRight: "-12px",
        }),
        ...(!rightSideRoles.includes(params.message.role) && {
            marginLeft: "-12px",
        }),
    },

    ...(params.message?.attachments?.length && {
        "& img,video": {
            width: "100%",
            height: "auto",
            borderRadius: 10,

            "&:hover": {
                filter: "opacity(0.5)",
                cursor: "pointer",
            },
        },
    }),
}));

const ReactionBox = styled(SoftBox)((params) => ({
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
    borderRadius: "100%",
    backgroundColor:
        params.message.role === "assistant" ? "#e9e9eb" : "#468DF5",
    pointerEvents: "none",
    userSelect: "none",

    position: "relative",
    aspectRatio: "1",

    "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        ...(!rightSideRoles.includes(params.message.role) && {
            right: -4,
        }),
        ...(rightSideRoles.includes(params.message.role) && {
            left: -4,
        }),
        padding: "8px",
        borderRadius: "100%",
        backgroundColor:
            params.message.role === "assistant" ? "#e9e9eb" : "#468DF5",
    },

    "&:before": {
        content: '""',
        position: "absolute",
        bottom: -8,
        ...(!rightSideRoles.includes(params.message.role) && {
            right: -15,
        }),
        ...(rightSideRoles.includes(params.message.role) && {
            left: -15,
        }),
        padding: "5px",
        borderRadius: "100%",
        backgroundColor:
            params.message.role === "assistant" ? "#e9e9eb" : "#468DF5",
    },
}));

const VoiceMessageNote = styled(Box)((params) => ({
    display: "block",
    color: params.message.role === "assistant" ? "white" : "black",
    marginLeft: rightSideRoles.includes(params.message.role) ? "20%" : "15px",
    marginRight: rightSideRoles.includes(params.message.role) ? "15px" : "20%",
    position: "relative",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",

    ...(params.message.content && {
        padding: "12px 16px",
        background: params.message.isOptimistic
            ? "#74a9f9"
            : params.message.role === "assistant"
            ? "#468df5"
            : params.message.role === "system"
            ? "#e9e9eb"
            : params.message.role === "tool" || params.message.role === "team"
            ? "#e9e9eb"
            : "#e9e9eb",

        borderRadius: "24px 24px 0 0",
    }),

    ...(params.message?.attachments?.length && {
        boxShadow: `inset 0px 0px 2px 1px ${colors.gray[200]}`,
        borderRadius: 10,

        "& img": {
            width: "100%",
            height: "auto",
            padding: 10,

            "&:hover": {
                filter: "opacity(0.3)",
                cursor: "pointer",
            },
        },
    }),
}));

const VoiceMessageBox = styled(Box)((params) => ({
    textAlign: "right",
    marginBottom: 5,
    color: "#555555",
    marginLeft: rightSideRoles.includes(params.message.role) ? "20%" : "15px",
    marginRight: rightSideRoles.includes(params.message.role) ? "15px" : "20%",
    position: "relative",

    ...(params.message.content && {
        background:
            params.message.role === "assistant"
                ? "#468df5"
                : params.message.role === "system"
                ? "#e9e9eb"
                : params.message.role === "tool" || params.message.role === "team"
                ? "#e9e9eb"
                : "#e9e9eb",
        padding: "10px 16px",

        ...(params.message.content.length > 1
            ? {
                  borderRadius: "0 0 24px 24px",
              }
            : {
                  borderRadius: 24,
              }),

        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "10px",
            right: rightSideRoles.includes(params.message.role) ? 0 : "initial",
            left: !rightSideRoles.includes(params.message.role) ? 0 : "initial",
            width: 0,
            height: 0,

            border: "24px solid transparent",

            ...(params.message.role === "assistant"
                ? {
                      borderLeftColor: params.message.isOptimistic
                          ? "#74a9f9"
                          : "#468df5",
                  }
                : params.message.role === "system"
                ? {
                      borderLeftColor: "#e9e9eb",
                  }
                : params.message.role === "tool" || params.message.role === "team"
                ? {}
                : {}),
            ...(!rightSideRoles.includes(params.message.role) && {
                borderRightColor: "#e9e9eb",
            }),

            ...(rightSideRoles.includes(params.message.role)
                ? {
                      borderRight: 0,
                  }
                : {
                      borderLeft: 0,
                  }),
            borderBottom: 0,

            ...(rightSideRoles.includes(params.message.role) && {
                marginRight: "-12px",
            }),
            ...(!rightSideRoles.includes(params.message.role) && {
                marginLeft: "-12px",
            }),
        },
    }),
}));

export function Message({ message }) {
    const [lightBoxImageIndex, setLightBoxImageIndex] = useState(-1);

    const attachments = useMemo(() => {
        return (message?.attachments ?? []).map((a, i) => {
            if (a.type === "image") {
                return (
                    <div key={`a.slice(0, 5)_${i}`}>
                        <img
                            src={a.url}
                            alt="file"
                            loading="lazy"
                            onClick={(e) => setLightBoxImageIndex(i)}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                    "https://placehold.co/600x300/FFFFFF/000?text=Image+Not+Available";
                            }}
                        />
                    </div>
                );
            }

            if (a.type === "video") {
                return (
                    <SoftBox
                        sx={{
                            position: "relative",
                            width: "100%",
                            color: "white",
                        }}
                        key={`a.slice(0, 5)_${i}`}
                    >
                        <video
                            src={a.url}
                            controls={false}
                            onClick={(e) => setLightBoxImageIndex(i)}
                        />
                        <PlayArrowIcon
                            style={{
                                color: "#e9e9eb",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "5px",
                            }}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                            }}
                            fontSize="large"
                        />
                    </SoftBox>
                );
            }

            return (
                <SoftBox
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    key={`a.slice(0, 5)_${i}`}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1.5,
                        }}
                    >
                        <AttachFileIcon fontSize="medium" />
                        <SoftBox
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <SoftTypography fontWeight="bold">
                                {a.fileName}
                            </SoftTypography>
                            <SoftTypography variant="subtitle2">
                                {a.fileSize > 1000000
                                    ? `${(a.fileSize / 1000000).toFixed(2)} MB`
                                    : `${(a.fileSize / 1000).toFixed(2)} KB`}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>

                    <DownloadIcon
                        sx={{
                            cursor: "pointer",
                        }}
                        fontSize="medium"
                        onClick={() => {
                            window.open(a.url, "_blank");
                        }}
                    />
                </SoftBox>
            );
        });
    }, [message]);

    const lightBoxData = useMemo(() => {
        return (message.attachments || []).map((attachment) => {
            if (attachment.type === "image") {
                return { type: "image", src: attachment.url };
            } else if (attachment.type === "video") {
                return {
                    type: "video",
                    sources: [
                        {
                            src: attachment.url,
                            type: attachment.mimeType,
                        },
                    ],
                };
            } else {
                return {
                    type: "binary",
                    src: attachment.url,
                };
            }
        });
    }, [message]);

    return (
        <>
            {!message.voicenote ? (
                <MessageBox message={message}>
                    {message.role === "system" && (
                        <SoftBox
                            sx={{
                                display: "inline-flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#468DF5",
                                pr: 1,
                            }}
                        >
                            <CircularProgress color="inherit" size={17} />
                        </SoftBox>
                    )}
                    {message.reactions && message.reactions.length > 0 && (
                        <SoftBox
                            sx={{
                                position: "absolute",
                                top: -20,
                                ...(rightSideRoles.includes(message.role) && {
                                    left: -20,
                                }),
                                ...(!rightSideRoles.includes(message.role) && {
                                    right: -20,
                                }),
                            }}
                        >
                            {message.reactions.map((reaction, index) => (
                                <ReactionBox message={message} key={index}>
                                    {reaction === "love" && "❤️"}
                                    {reaction === "like" && "👍"}
                                    {reaction === "dislike" && "👎"}
                                    {reaction === "laugh" && "😂"}
                                    {reaction === "emphasize" && "❗"}
                                    {reaction === "question" && "❓"}
                                </ReactionBox>
                            ))}
                        </SoftBox>
                    )}
                    {message?.content !== "" && message.content}
                    {!!message?.attachments?.length && attachments}
                </MessageBox>
            ) : (
                <SoftBox>
                    {message.content.length > 1 && (
                        <VoiceMessageNote message={message}>
                            {message.content && message.content}
                            {!!message?.attachments?.length && attachments}
                        </VoiceMessageNote>
                    )}
                    <VoiceMessageBox message={message}>
                        <Waveform audio={message.voicenote} />
                    </VoiceMessageBox>
                </SoftBox>
            )}
            {!!message.attachments?.length && (
                <Lightbox
                    plugins={[Video]}
                    index={lightBoxImageIndex}
                    open={lightBoxImageIndex > -1}
                    render={{
                        buttonPrev: lightBoxData.length > 1 ? null : () => null,
                        buttonNext: lightBoxData.length > 1 ? null : () => null,
                    }}
                    controller={{ closeOnBackdropClick: true }}
                    close={() => setLightBoxImageIndex(-1)}
                    slides={lightBoxData}
                    carousel={false}
                />
            )}
        </>
    );
}
