import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { Loader } from "components/Loader/Loader";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SelectBusiness from "pages/Contacts/components/SelectBusiness";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appActions } from "../../redux/appSlice";
import useApp from "../../redux/hooks/useApp";
import {
    createTableRows,
    phoneNumberGroupsColumns,
} from "./NumberGroups.helpers";

function NumberGroupsPage() {
    const {
        phoneNumberGroups,
        selectedBusinessId,
        phoneNumberGroupsError,
        isPhoneNumberGroupsLoading,
    } = useApp();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(appActions.fetchPhoneNumberGroups());
    }, [selectedBusinessId]);

    const tableData = useMemo(() => {
        return {
            columns: phoneNumberGroupsColumns,
            rows: createTableRows(phoneNumberGroups, (phoneNumberGroup) => (
                <SoftBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <EditIcon
                        fontSize="medium"
                        sx={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate(
                                `/phoneNumberGroups/${phoneNumberGroup._id}`
                            );
                        }}
                    />
                </SoftBox>
            )),
        };
    }, [phoneNumberGroups]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3} pb={3}>
                <Card>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        p={3}
                    >
                        <SoftBox lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                All Number Groups
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                List of all number groups.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                onClick={() => {
                                    if (isPhoneNumberGroupsLoading) return;

                                    dispatch(
                                        appActions.fetchPhoneNumberGroups()
                                    );
                                }}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 1,
                                    pr: 2,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                }}
                            >
                                <RefreshIcon fontSize="medium" />
                            </SoftBox>
                            <SelectBusiness />
                        </Stack>
                    </SoftBox>
                    <DataTable table={tableData} canSearch extendHeight />
                </Card>
            </SoftBox>
            <Loader open={isPhoneNumberGroupsLoading} />
            <Footer />
        </DashboardLayout>
    );
}

export default NumberGroupsPage;
