import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import { CardMedia, alpha, styled } from "@mui/material";
import { colors } from "assets/mui/theme";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useMemo, useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

const ImageList = styled("div")(({ theme }) => ({
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    mt: -1,
    paddingBottom: 5,

    gap: 20,

    "&::-webkit-scrollbar": {
        height: 7,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
        background: colors.greenBlue[50],
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
        background: alpha(colors.greenBlue[100], 0.9),
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
        background: colors.greenBlue[100],
    },
}));

export function Attachments({ attachments }) {
    const [showLightBoxIndex, setIndex] = useState(-1);
    const containerRef = useRef();

    const list = useMemo(() => {
        if (!attachments) return [];
        return attachments.map((attachment, i) => {
            if (attachment.type === "image") {
                return (
                    <CardMedia
                        onClick={() => setIndex(i)}
                        key={i}
                        src={attachment.url}
                        component="img"
                        title="title"
                        sx={{
                            m: 0,
                            width: "100px",
                            height: "100px",
                            borderRadius: "0",
                            cursor: "pointer",
                            objectFit: "scale-down",
                        }}
                    />
                );
            } else if (attachment.type === "video") {
                return (
                    <CardMedia
                        onClick={() => setIndex(i)}
                        key={i}
                        src={attachment.url}
                        component="video"
                        title="title"
                        sx={{
                            m: 0,
                            width: "100px",
                            height: "100px",
                            borderRadius: "0",
                            cursor: "pointer",
                            objectFit: "scale-down",
                        }}
                    />
                );
            } else {
                return (
                    <SoftBox
                        onClick={() => setIndex(i)}
                        key={i}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100px",
                            height: "100px",
                            borderRadius: "0",
                            cursor: "pointer",
                            objectFit: "scale-down",
                        }}
                    >
                        <AttachFileIcon fontSize="medium" />
                        <SoftTypography variant="subtitle2">
                            {attachment.fileName}
                        </SoftTypography>
                    </SoftBox>
                );
            }
        });
    }, [attachments]);

    const lightBoxData = useMemo(() => {
        return (attachments || []).map((attachment) => {
            if (attachment.type === "image") {
                return { type: "image", src: attachment.url };
            } else if (attachment.type === "video") {
                return {
                    type: "video",
                    sources: [
                        {
                            src: attachment.url,
                            type: attachment.mimeType,
                        },
                    ],
                };
            } else {
                return {
                    type: "binary",
                    src: attachment.url,
                    fileName: attachment.fileName,
                    fileSize: attachment.fileSize,
                };
            }
        });
    }, [attachments]);

    const onWheel = useMemo((event) => {
        //TODO
        if (containerRef.current && event) {
            event.preventDefault();
            event.scrollLeft += event.deltaY;
        }
    }, []);

    return (
        <>
            <ImageList ref={containerRef} onWheel={onWheel}>
                {list}
            </ImageList>
            <Lightbox
                plugins={[Video]}
                index={showLightBoxIndex}
                open={showLightBoxIndex > -1}
                controller={{ closeOnBackdropClick: true }}
                close={() => setIndex(-1)}
                slides={lightBoxData}
                render={{
                    slide: ({ slide }) =>
                        slide.type === "binary" ? (
                            <SoftBox
                                sx={{
                                    width: "30%",
                                    background: "#fff",
                                    px: 4,
                                    py: 2,
                                    borderRadius: 10,
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1.5,
                                        }}
                                    >
                                        <AttachFileIcon fontSize="medium" />
                                        <SoftBox
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <SoftTypography fontWeight="bold">
                                                {slide.fileName}
                                            </SoftTypography>
                                            <SoftTypography variant="subtitle2">
                                                {slide.fileSize > 1000000
                                                    ? `${(
                                                          slide.fileSize /
                                                          1000000
                                                      ).toFixed(2)} MB`
                                                    : `${(
                                                          slide.fileSize / 1000
                                                      ).toFixed(2)} KB`}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>

                                    <DownloadIcon
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        fontSize="medium"
                                        onClick={() => {
                                            window.open(slide.src, "_blank");
                                        }}
                                    />
                                </SoftBox>
                            </SoftBox>
                        ) : undefined,
                    buttonPrev: lightBoxData.length > 1 ? null : () => null,
                    buttonNext: lightBoxData.length > 1 ? null : () => null,
                }}
                carousel={false}
            />
        </>
    );
}
