import checkout from "./form";

const {
    formField: {
        // Basic Info
        displayAvatar,
        displayName,
        displayColor,
        name,
        age,
        ethnicity,
        emojiSkinTone,
        gender,
        modelName,

        // Preferences
        userId,
        business,
        tokenLimit,
        readTime,
        assistantLinks,

        // Skills
        skills,

        // Conversation Steps
        conversationStepGroups,
        defaultConversationStepGroup,

        // Follow-Up Groups
        followUpGroups,
        defaultFollowUpGroup,

        // Additional Details
        role,
        style,
        background,
        jobDescription,
        additional,
        teamNotes,
    },
} = checkout;

const initialValues = {
    // Basic Info
    [displayAvatar.name]: "",
    [displayName.name]: "",
    [displayColor.name]: "#1C4C73",
    [name.name]: "",
    [age.name]: "",
    [ethnicity.name]: "",
    [emojiSkinTone.name]: "",
    [gender.name]: "male",
    [modelName.name]: "",

    // Preferences
    [userId.name]: undefined,
    [business.name]: undefined,
    [tokenLimit.name]: 30000,
    [readTime.name]: 1000,
    [assistantLinks.name]: [],

    // Skills
    [skills.name]: [],

    // Conversation Steps
    [conversationStepGroups.name]: [],
    [defaultConversationStepGroup.name]: null,

    // Follow Up Groups
    [followUpGroups.name]: [],
    [defaultFollowUpGroup.name]: null,

    // Additional Details
    [role.name]: "assistant",
    [style.name]:
        "You are very calm and casual. You are not an in a rush. You care more about learning about them, you are curious about their goals and after you learn a decent amount only then do you casually suggest the offer",
    [background.name]: "",
    [jobDescription.name]: "",
    [additional.name]: "",
    [teamNotes.name]: "",
};

export default initialValues;
