import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../../../redux/adminSlice";
import useAdmin from "../../../../../redux/hooks/useAdmin";

function SelectUser({
    withoutAny = false,
    defaultValue = undefined,
    callbackFn = () => {},
}) {
    const { users, selectedUserId, usersError, isUsersLoading } = useAdmin();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.fetchUsers());

        if (
            withoutAny &&
            defaultValue !== undefined &&
            selectedUserId === undefined
        ) {
            dispatch(adminActions.selectUser(users?.[0]?._id));
            dispatch(adminActions.selectUser(defaultValue));
            dispatch(adminActions.fetchBusinesses());
            dispatch(adminActions.fetchPhoneNumbers());
        }

        if (defaultValue !== undefined) {
            dispatch(adminActions.selectUser(defaultValue));
            dispatch(adminActions.fetchBusinesses());
            dispatch(adminActions.fetchPhoneNumbers());
        }
    }, []);

    useEffect(() => {
        callbackFn(selectedUserId);
    }, [selectedUserId]);

    const usersValues = useMemo(() => {
        return [
            !withoutAny
                ? {
                      label: "Any User",
                      value: undefined,
                  }
                : null,
            ...users?.map((user) => ({
                label: user.userEmail,
                value: user._id,
            })),
        ].filter(Boolean);
    }, [users]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={usersValues}
                value={usersValues.filter((_) => _.value === selectedUserId)}
                onChange={({ value }) => {
                    dispatch(adminActions.selectUser(value));

                    dispatch(adminActions.selectBusiness(undefined));

                    dispatch(adminActions.fetchBusinesses());

                    dispatch(adminActions.fetchPhoneNumbers());
                }}
            />
        </div>
    );
}

export default SelectUser;
