const { apiAxios } = require("common/services/httpService");

export const getFollowUps = (params) => {
    return apiAxios.get(`follow_up/`, params);
};

export const getFollowUp = (followUpId) => {
    return apiAxios.get(`follow_up/${followUpId}`);
};

export const updateFollowUp = (followUpId, data) => {
    return apiAxios.patch(`follow_up/${followUpId}`, data);
};
