import EditIcon from "@mui/icons-material/Edit";
import { Box, Modal, Typography } from "@mui/material";
import {
    deleteScheduledMessage,
    updateScheduledMessage,
} from "common/api/scheduledMessage";
import SoftBox from "components/SoftBox";
import SoftDatePicker from "components/SoftDatePicker";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function UpdateScheduledMessage({ scheduledMessage, ...rest }) {
    const [
        isUpdateScheduledMessageModalOpen,
        setIsUpdateScheduledMessageModalOpen,
    ] = useState(false);

    const [message, setMessage] = useState(scheduledMessage.message);
    const [scheduledAt, setScheduledAt] = useState(
        new Date(scheduledMessage.scheduledAt)
    );

    const dispatch = useDispatch();

    const toggleUpdateScheduledMessageModal = () => {
        setIsUpdateScheduledMessageModalOpen((_) => !_);
    };

    const _updateScheduledMessage = () => {
        updateScheduledMessage(scheduledMessage._id, {
            message,
            scheduledAt,
        })
            .then((res) => {
                setIsUpdateScheduledMessageModalOpen(false);
                toast.success("Scheduled Message updated successfully.");
                dispatch(appActions.fetchScheduledMessages());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    };

    const _deleteScheduledMessage = () => {
        deleteScheduledMessage(scheduledMessage._id)
            .then(() => {
                setIsUpdateScheduledMessageModalOpen(false);
                toast.success("Scheduled Message deleted successfully.");
                dispatch(appActions.fetchScheduledMessages());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    };

    return (
        <>
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                {...rest}
            >
                <EditIcon
                    onClick={toggleUpdateScheduledMessageModal}
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                    }}
                />
            </SoftBox>

            <Modal
                open={isUpdateScheduledMessageModalOpen}
                onClose={toggleUpdateScheduledMessageModal}
            >
                <Box
                    sx={{
                        ...style,
                    }}
                >
                    <SoftBox mb={1} ml={0.5} lineHeight={0}>
                        <SoftTypography
                            component="label"
                            variant="h4"
                            textTransform="capitalize"
                        >
                            Update Scheduled Message
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Message
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        multiline
                        rows={4}
                        sx={{
                            mb: 1,
                        }}
                        placeholder="Enter recipient"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Scheduled At
                        </SoftTypography>
                    </SoftBox>
                    <SoftDatePicker
                        input={{ placeholder: "Select a date" }}
                        options={{
                            minDate: new Date(),
                            defaultDate: scheduledAt,
                            onChange: (date) => {
                                setScheduledAt(date);
                            },
                            enableTime: true,
                        }}
                    />

                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={_updateScheduledMessage}
                            >
                                Update
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#ff6b6b",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={_deleteScheduledMessage}
                            >
                                Delete
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={toggleUpdateScheduledMessageModal}
                        >
                            Close
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default UpdateScheduledMessage;
