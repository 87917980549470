import { Card } from "@mui/material";
import { createConversation } from "common/api/conversation";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function MessageContact({ contact }) {
    const { phoneNumbers } = useApp();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sendMessage = (number) => {
        createConversation({
            recipient: contact.phone,
            phoneNumberId: number._id,
        })
            .then((res) => {
                dispatch(appActions.fetchConversations());

                dispatch(appActions.selectConversation(res.data.payload._id));

                navigate(`/conversations/${contact.business}`);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    };

    return (
        <Card>
            <SoftBox pt={2} px={2}>
                <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                >
                    Send Message
                </SoftTypography>
            </SoftBox>
            <SoftBox p={2}>
                <SoftBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                >
                    {phoneNumbers?.map((number) => (
                        <SoftBox
                            key={number._id}
                            component="li"
                            display="flex"
                            alignItems="center"
                            py={1}
                            mb={1}
                            gap={2}
                        >
                            {number.defaultAssistant && (
                                <SoftBox>
                                    {number.defaultAssistant.displayAvatar ? (
                                        <SoftAvatar
                                            src={
                                                number.defaultAssistant
                                                    ?.displayAvatar
                                            }
                                            variant="rounded"
                                            shadow="md"
                                        />
                                    ) : (
                                        <SoftAvatar
                                            src={undefined}
                                            sx={{
                                                bgcolor:
                                                    number.defaultAssistant
                                                        .displayColor,
                                                objectFit: "cover",
                                            }}
                                            variant="rounded"
                                            shadow="md"
                                        >
                                            <span
                                                style={{
                                                    color: "white",
                                                    mixBlendMode: "difference",
                                                }}
                                            >
                                                {(
                                                    number.defaultAssistant
                                                        .displayName || "A I"
                                                )
                                                    ?.split(" ")
                                                    .map((word) =>
                                                        word[0]?.toUpperCase()
                                                    )
                                                    .slice(0, 2)
                                                    .join("")}
                                            </span>
                                        </SoftAvatar>
                                    )}
                                </SoftBox>
                            )}
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="center"
                            >
                                <SoftTypography
                                    variant="button"
                                    fontWeight="medium"
                                >
                                    {number.senderName || number.senderNumber}{" "}
                                    {number.defaultAssistant &&
                                        `(${number.defaultAssistant?.displayName})`}
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text">
                                    {number.defaultAssistant
                                        ? `Start a chat, ${number.defaultAssistant?.displayName} will handle the rest.`
                                        : `Start a chat, configure assistant to handle the rest.`}
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox ml="auto">
                                <SoftButton
                                    component={Link}
                                    onClick={() => sendMessage(number)}
                                    variant="text"
                                    color="info"
                                >
                                    Message
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    ))}
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default MessageContact;
