import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import { useState } from "react";
import { toast } from "react-toastify";

function AssistantOptions({ assistant }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <SoftBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <MoreVertIcon
                        fontSize="medium"
                        sx={{
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            setIsMenuOpen(
                                isMenuOpen ? false : event.currentTarget
                            );
                        }}
                    />
                </SoftBox>
                <Menu
                    anchorEl={isMenuOpen}
                    anchorReference={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(isMenuOpen)}
                    onClose={() => {
                        setIsMenuOpen(false);
                    }}
                    sx={{ mt: "1rem", width: "100%" }}
                >
                    <MenuItem
                        onClick={() => {
                            navigator.clipboard.writeText(assistant?._id);

                            toast.success("Assistant ID copied to clipboard.");

                            setIsMenuOpen(false);
                        }}
                        sx={{
                            width: "170px",
                        }}
                    >
                        <SoftBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "0",
                            }}
                        >
                            <span>Copy ID</span>
                            <ContentCopyIcon fontSize="small" />
                        </SoftBox>
                    </MenuItem>
                </Menu>
            </SoftBox>
        </>
    );
}

export default AssistantOptions;
