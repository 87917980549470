import { updatePhoneNumberGroup } from "common/api/numberGroups";
import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function SelectAssistantCell({ phoneNumberGroupId, defaultValue = undefined }) {
    const {
        assistants,
        phoneNumberGroups,
        assistantsError,
        isAssistantsLoading,
    } = useApp();
    const [selectedAssistantId, setSelectedAssistantId] =
        useState(defaultValue);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appActions.fetchAssistants());
    }, []);

    const phoneNumberGroup = useMemo(() => {
        return phoneNumberGroups?.find((_) => _._id === phoneNumberGroupId);
    }, [phoneNumberGroups]);

    const assistantsValues = useMemo(() => {
        return [
            {
                label: "No Assistant",
                value: null,
            },
            ...assistants
                ?.filter((assistant) =>
                    phoneNumberGroup.business === null
                        ? assistant.business._id === phoneNumberGroup.business
                        : assistant.business._id ===
                          phoneNumberGroup.business?._id
                )
                ?.map((assistant) => ({
                    label: assistant.displayName,
                    value: assistant._id,
                })),
        ];
    }, [assistants, phoneNumberGroup]);

    useEffect(() => {
        setSelectedAssistantId(
            phoneNumberGroup?.defaultAssistant !== null
                ? phoneNumberGroup?.defaultAssistant?._id
                : phoneNumberGroup?.defaultAssistant
        );
    }, [phoneNumberGroup]);

    useEffect(() => {
        if (selectedAssistantId === undefined) return;

        if (
            phoneNumberGroup?.defaultAssistant !== null &&
            phoneNumberGroup?.defaultAssistant?._id === undefined
        )
            return;

        if (
            phoneNumberGroup.defaultAssistant === null
                ? selectedAssistantId === phoneNumberGroup?.defaultAssistant
                : selectedAssistantId ===
                  phoneNumberGroup?.defaultAssistant?._id
        )
            return;

        updatePhoneNumberGroup(phoneNumberGroupId, {
            defaultAssistant: selectedAssistantId,
        })
            .then((res) => {
                toast.success("Phone number group updated successfully.");
                dispatch(appActions.fetchPhoneNumberGroups());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err.message);
                dispatch(appActions.fetchPhoneNumberGroups());
            });
    }, [selectedAssistantId]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={assistantsValues}
                value={assistantsValues.filter(
                    (_) => _.value === selectedAssistantId
                )}
                onChange={({ value }) => {
                    if (value === selectedAssistantId) return;

                    setSelectedAssistantId(value);
                }}
            />
        </div>
    );
}

export default SelectAssistantCell;
