import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import CalendlySkill from "./components/CalendlySkill";
import ShopifySkill from "./components/ShopifySkill";
import VoiceNoteSkill from "./components/VoiceNoteSkill";

function Skills({ formData }) {
    const { formField } = formData;
    const { skills, conversationStepGroups, followUpGroups } = formField;

    const { values } = useFormikContext();

    const { skills: skillsValue } = values;

    const skillOrder = ["voiceNote", "calendly", "shopify"];

    const orderedSkills = [
        ...skillsValue,
        ...skillOrder
            .filter((skill) => !skillsValue.some((s) => s.name === skill))
            .map((skill) => ({ name: skill })),
    ];

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Assistant Skills
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Add the skills that your assistant has to help you with your
                    conversations.
                </SoftTypography>
            </SoftBox>

            <Grid
                container
                spacing={3}
                sx={{
                    mt: 0,
                }}
            >
                {orderedSkills.map((skill) => {
                    switch (skill.name) {
                        case "voiceNote": {
                            return (
                                <Grid item xs={12} md={6} lg={4}>
                                    <VoiceNoteSkill formField={skills.name} />
                                </Grid>
                            );
                        }

                        case "calendly": {
                            return (
                                <Grid item xs={12} md={6} lg={4}>
                                    <CalendlySkill formField={skills.name} />
                                </Grid>
                            );
                        }

                        case "shopify": {
                            return (
                                <Grid item xs={12} md={6} lg={4}>
                                    <ShopifySkill
                                        formField={skills.name}
                                        conversationStepGroupsFormField={
                                            conversationStepGroups.name
                                        }
                                        followUpGroupsFormField={
                                            followUpGroups.name
                                        }
                                    />
                                </Grid>
                            );
                        }
                        default:
                            return "";
                    }
                })}
            </Grid>
        </SoftBox>
    );
}

Skills.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default Skills;
