import ChatIcon from "@mui/icons-material/Chat";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PauseIcon from "@mui/icons-material/Pause";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
    Avatar,
    AvatarGroup,
    Badge,
    Chip,
    CircularProgress,
    ListItem,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import parsePhoneNumber from "libphonenumber-js";
import MuiPhoneNumber from "material-ui-phone-number";
import { memo, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { createConversation } from "../../../../common/api/conversation";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";
import classes from "../Conversations.module.css";
import { ConversationSearch } from "./ConversationSearch";

/* eslint-disable react/prop-types */

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

export const ConversationList = memo(() => {
    const dispatch = useDispatch();
    const {
        phoneNumbers,
        conversations,
        selectedBusinessId,
        totalConversations,
        isConversationsLoading,
        selectedConversation,
    } = useApp();

    const [recipient, setRecipient] = useState("");
    const [phoneNumberId, setPhoneNumberId] = useState(undefined);

    const [isMoreActionsMenuOpened, setIsMoreActionsMenuOpened] =
        useState(false);
    const [isCreateConversationModalOpen, setIsCreateConversationModalOpen] =
        useState(false);

    const toggleMoreActionsMenu = (moreActionsMenuOpened) => {
        setIsMoreActionsMenuOpened((_) => (!_ ? moreActionsMenuOpened : false));
    };
    const toggleCreateConversationModal = () => {
        setIsCreateConversationModalOpen((_) => !_);
    };

    useEffect(() => {
        setRecipient("");
        setPhoneNumberId(undefined);
    }, [isCreateConversationModalOpen]);

    useEffect(() => {
        console.log("Recipient: ", recipient);
    }, [recipient]);

    const startConversation = async () => {
        try {
            if (!recipient) {
                toast.error("Recipient is required.");
                return;
            }

            if (!phoneNumberId) {
                toast.error("Phone number is required.");
                return;
            }

            const response = await createConversation({
                recipient,
                phoneNumberId,
            });

            console.log("Conversation created successfully: ", response);

            toggleCreateConversationModal();

            dispatch(appActions.fetchConversations());

            dispatch(appActions.selectConversation(response.data.payload._id));
        } catch (error) {
            console.error("Error creating conversation: ", error);
            toast.error(
                error?.response?.data?.error?.message ||
                    "Error creating conversation..."
            );
        }
    };

    const selectConversation = (conversationId) => {
        dispatch(appActions.selectConversation(conversationId));
    };

    const defaultAssistant = useMemo(() => {
        return phoneNumbers?.filter(
            (phoneNumber) => phoneNumber._id === phoneNumberId
        )[0]?.defaultAssistant;
    }, [phoneNumbers, phoneNumberId]);

    const conversationList = useMemo(
        () => (
            <>
                {isConversationsLoading && conversations.length === 0 && (
                    <CircularProgress
                        color="inherit"
                        disableShrink
                        style={{
                            color: "#1C4C73",
                        }}
                    />
                )}

                {!isConversationsLoading && conversations.length === 0 && (
                    <SoftTypography
                        variant="body2"
                        sx={{ textAlign: "center" }}
                    >
                        No conversations found.
                    </SoftTypography>
                )}

                <InfiniteScroll
                    dataLength={conversations.length}
                    next={() => {
                        dispatch(appActions.fetchNextPageConversations());
                    }}
                    hasMore={conversations.length < totalConversations}
                    scrollableTarget="scrollableDiv"
                >
                    {conversations.map((conversation) => (
                        <div
                            key={`conversation-${selectedBusinessId}-${conversation._id}`}
                            onClick={() => {
                                selectedConversation?._id.toString() !==
                                    conversation._id.toString() &&
                                    selectConversation(conversation._id);
                            }}
                        >
                            <Box
                                sx={{
                                    borderBottom: "1px solid lightgray",
                                    py: ".5rem",
                                    minHeight: "4.2rem",
                                    display: "flex",
                                    borderLeft:
                                        selectedConversation?._id.toString() ===
                                        conversation._id.toString()
                                            ? "3px solid #1C4C73"
                                            : "3px solid transparent",
                                    alignItems: "center",
                                }}
                                px={2}
                            >
                                <ListItem>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            cursor: "pointer",
                                            width: "100%",
                                        }}
                                    >
                                        <AvatarGroup spacing={"small"}>
                                            {conversation.initialAssistant && (
                                                <Avatar
                                                    src={
                                                        conversation
                                                            .initialAssistant
                                                            .displayAvatar
                                                            ? conversation
                                                                  .initialAssistant
                                                                  .displayAvatar
                                                            : undefined
                                                    }
                                                    sx={{
                                                        bgcolor:
                                                            conversation
                                                                .initialAssistant
                                                                .displayColor,
                                                        objectFit: "cover",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "white",
                                                            mixBlendMode:
                                                                "difference",
                                                        }}
                                                    >
                                                        {(
                                                            conversation
                                                                .initialAssistant
                                                                .displayName ||
                                                            "A I"
                                                        )
                                                            ?.split(" ")
                                                            .map((word) =>
                                                                word[0]?.toUpperCase()
                                                            )
                                                            .slice(0, 2)
                                                            .join("")}
                                                    </span>
                                                </Avatar>
                                            )}

                                            {!conversation.initialAssistant && (
                                                <SoftBox
                                                    sx={{
                                                        width: "1.5rem",
                                                    }}
                                                />
                                            )}

                                            {conversation.activeAssistant ? (
                                                <Avatar
                                                    sx={{
                                                        bgcolor: "#1C4C73",
                                                        color: "white",
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    {conversation.contact.name
                                                        ? conversation.contact
                                                              .name[0]
                                                        : "#"}
                                                </Avatar>
                                            ) : (
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    badgeContent={
                                                        <PauseIcon
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        ".MuiBadge-badge": {
                                                            backgroundColor:
                                                                "red",
                                                            color: "white",
                                                            width: "15px",
                                                            zIndex: 2,
                                                        },
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: "#1C4C73",
                                                            color: "white",
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        {conversation.contact
                                                            .name
                                                            ? conversation
                                                                  .contact
                                                                  .name[0]
                                                            : "#"}
                                                    </Avatar>
                                                </Badge>
                                            )}
                                        </AvatarGroup>

                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            justifyContent={"center"}
                                            sx={{
                                                width: "100%",
                                                pl: "1rem",
                                            }}
                                        >
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                sx={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    color={"#000000"}
                                                    fontWeight={700}
                                                    variant="h6"
                                                >
                                                    {conversation.contact
                                                        .name ||
                                                        parsePhoneNumber(
                                                            conversation.contact
                                                                .phone
                                                        )?.formatNational() ||
                                                        conversation.contact
                                                            .phone}
                                                </Typography>

                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                >
                                                    <Typography variant="caption">
                                                        <ReactTimeago
                                                            date={
                                                                conversation
                                                                    ?.lastMessage
                                                                    ?.lastMessageAt
                                                            }
                                                            formatter={(
                                                                value,
                                                                unit,
                                                                suffix
                                                            ) => {
                                                                const units = {
                                                                    year: "y",
                                                                    month: "mo",
                                                                    week: "w",
                                                                    day: "d",
                                                                    hour: "h",
                                                                    minute: "min",
                                                                    second: "sec",
                                                                };

                                                                return `${value}${units[unit]}`;
                                                            }}
                                                        />
                                                    </Typography>

                                                    <Icon
                                                        sx={{
                                                            transform:
                                                                "rotate(90deg)",
                                                        }}
                                                        fontSize="small"
                                                    >
                                                        expand_less
                                                    </Icon>
                                                </Box>
                                            </Box>

                                            {conversation?.lastMessage
                                                ?.message && (
                                                <SoftBox
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <SoftBox
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {conversation
                                                            .lastMessage
                                                            ?.message?.role ===
                                                        "assistant" ? (
                                                            conversation
                                                                .lastMessage
                                                                ?.message
                                                                ?.assistant ? (
                                                                <Chip
                                                                    size="small"
                                                                    sx={{
                                                                        fontSize:
                                                                            ".8rem",
                                                                        bgcolor:
                                                                            conversation
                                                                                .lastMessage
                                                                                ?.message
                                                                                ?.assistant
                                                                                ?.displayColor,
                                                                    }}
                                                                    style={{
                                                                        color: "white",
                                                                    }}
                                                                    label={
                                                                        conversation
                                                                            .lastMessage
                                                                            ?.message
                                                                            ?.assistant
                                                                            ?.name
                                                                    }
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    size="small"
                                                                    sx={{
                                                                        fontSize:
                                                                            ".8rem",
                                                                    }}
                                                                    label={`You`}
                                                                />
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </SoftBox>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            display:
                                                                "-webkit-box",
                                                            WebkitLineClamp: 1,
                                                            WebkitBoxOrient:
                                                                "vertical",
                                                            width: "100%",
                                                            wordBreak:
                                                                "break-all",
                                                        }}
                                                    >
                                                        {conversation
                                                            .lastMessage.message
                                                            .voicenote
                                                            ? conversation
                                                                  .lastMessage
                                                                  .message
                                                                  .content
                                                                  .length > 1
                                                                ? conversation
                                                                      .lastMessage
                                                                      .message
                                                                      .content
                                                                : "Voice Note"
                                                            : conversation
                                                                  .lastMessage
                                                                  .message
                                                                  .content}
                                                    </Typography>
                                                </SoftBox>
                                            )}
                                        </Box>
                                    </Box>
                                </ListItem>
                            </Box>
                        </div>
                    ))}
                </InfiniteScroll>
            </>
        ),
        [
            conversations,
            selectedConversation,
            isConversationsLoading,
            totalConversations,
        ]
    );

    return (
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
            <Box sx={{ marginBottom: "1rem" }}>
                <ConversationSearch />
            </Box>
            <Card
                sx={{
                    height: "100%",
                    borderRight: 1,
                    borderColor: "lightgray",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                }}
            >
                <Box
                    flex={1}
                    flexDirection={"column"}
                    display={"flex"}
                    id="scrollableDiv"
                    className={classes.contactListContent}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        color="lightslategray"
                        px={2}
                    >
                        <MoreHorizIcon
                            style={{
                                color: "#1C4C73",
                                cursor: "pointer",
                            }}
                            fontSize="medium"
                            onClick={(e) => {
                                toggleMoreActionsMenu(e.currentTarget);
                            }}
                        />
                        <Menu
                            anchorEl={isMoreActionsMenuOpened}
                            anchorReference={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(isMoreActionsMenuOpened)}
                            onClose={toggleMoreActionsMenu}
                            sx={{
                                mt: "1rem",
                                ml: "-1rem",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    toggleCreateConversationModal();
                                    toggleMoreActionsMenu();
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0",
                                        width: "180px",
                                    }}
                                >
                                    <span>Start Conversation</span>
                                    <ChatIcon fontSize="small" />
                                </SoftBox>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    dispatch(appActions.fetchConversations());
                                    toggleMoreActionsMenu();
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0",
                                        width: "180px",
                                    }}
                                >
                                    <span>Refresh Conversation</span>
                                    <RefreshIcon fontSize="small" />
                                </SoftBox>
                            </MenuItem>
                        </Menu>
                        <Typography
                            fontSize={12}
                            component={"i"}
                            py={2}
                            px={0.5}
                        >
                            Sorted by response time
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            borderBottom: "1px solid lightgray",
                        }}
                    />

                    <List
                        sx={
                            conversations.length === 0
                                ? {
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      px: "2",
                                  }
                                : {}
                        }
                    >
                        {conversationList}
                    </List>
                </Box>
            </Card>
            <Modal
                open={isCreateConversationModalOpen}
                onClose={toggleCreateConversationModal}
            >
                <Box
                    sx={{
                        ...style,
                    }}
                >
                    <MuiPhoneNumber
                        sx={{
                            mb: 2,
                            width: "100%",
                            outline: "none",
                            border: "none",
                            "& .MuiInputBase-root::before": {
                                display: "none",
                            },
                            "& .MuiInputBase-root::after": {
                                display: "none",
                            },
                        }}
                        defaultCountry={"us"}
                        onChange={(value) => {
                            setRecipient(value.replace(/[^0-9+]|(?!^)\+/g, ""));
                        }}
                    />
                    <SoftSelect
                        placeholder="Choose phone number"
                        options={[
                            ...(phoneNumbers
                                .filter(
                                    (phoneNumber) =>
                                        phoneNumber.business?._id ===
                                        selectedBusinessId
                                )
                                .map((phoneNumber) => ({
                                    value: phoneNumber._id.toString(),
                                    label:
                                        phoneNumber.senderNumber ||
                                        phoneNumber.senderName,
                                })) || []),
                        ]}
                        onChange={({ value }) => setPhoneNumberId(value)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: 1,
                        }}
                    >
                        <Icon color="action">error_outline</Icon>
                        {phoneNumberId ? (
                            <SoftTypography
                                variant="body2"
                                sx={{ fontSize: ".9rem" }}
                            >
                                {defaultAssistant
                                    ? `${defaultAssistant.displayName} will be used as the default assistant. This can be changed per number on the "Numbers" page.`
                                    : `No default assistant has been set for this phone number. This can be changed per number on the "Numbers" page.`}
                            </SoftTypography>
                        ) : (
                            <SoftTypography
                                variant="body2"
                                sx={{ fontSize: ".9rem" }}
                            >
                                Please select a phone number.
                            </SoftTypography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 400,
                                borderRadius: 1,
                                bgcolor: "#1C4C73",
                                color: "#fff",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={() => startConversation()}
                        >
                            Start Conversation
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={toggleCreateConversationModal}
                        >
                            Close
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
});
