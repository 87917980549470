import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import SelectBusiness from "pages/admin/Businesses/components/SelectBusiness";
import SelectUser from "pages/admin/Users/components/SelectUser";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormField from "../FormField";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function Preferences({ formData }) {
    const [isCreateLinkModalOpen, setIsCreateLinkModalOpen] = useState(false);

    const [linkFormData, setLinkFormData] = useState({
        url: "",
        description: "",
    });

    const { formField, errors, touched } = formData;
    const {
        userId,
        business,
        tokenLimit,
        readTime,
        assistantLinks,
        voiceNotes,
    } = formField;

    const { values, setFieldValue } = useFormikContext();

    const {
        userId: userIdValue,
        business: businessValue,
        tokenLimit: tokenLimitValue,
        readTime: readTimeValue,
        assistantLinks: assistantLinksValue,
    } = values;

    useEffect(() => {
        setLinkFormData({
            url: "",
            description: "",
        });
    }, [isCreateLinkModalOpen]);

    const toggleCreateLinkModal = () => {
        setIsCreateLinkModalOpen(!isCreateLinkModalOpen);
    };

    const handleCreateLink = () => {
        if (!linkFormData.url || !linkFormData.description) {
            return toast.error("URL / Description cannot be empty.");
        }

        if (
            !linkFormData.url.startsWith("http://") &&
            !linkFormData.url.startsWith("https://")
        ) {
            return toast.error("URL should start with http:// or https://");
        }

        setFieldValue(assistantLinks.name, [
            ...assistantLinksValue,
            {
                url: linkFormData.url,
                description: linkFormData.description,
            },
        ]);

        setIsCreateLinkModalOpen(false);
    };

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Preferences, Business & Assistant Links
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Set read time preferences, share business details, and
                    provide links for your AI assistant to navigate seamlessly.
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid mb={1} container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                User
                            </SoftTypography>
                        </SoftBox>
                        <SelectUser
                            withoutAny
                            defaultValue={userIdValue}
                            callbackFn={(_) => {
                                setFieldValue(userId.name, _);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Business
                            </SoftTypography>
                        </SoftBox>
                        <SelectBusiness
                            withoutAny
                            defaultValue={businessValue}
                            callbackFn={(_) => {
                                setFieldValue(business.name, _);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={tokenLimit.type}
                            label={tokenLimit.label}
                            name={tokenLimit.name}
                            placeholder={tokenLimit.placeholder}
                            error={errors?.tokenLimit && touched?.tokenLimit}
                            success={
                                typeof tokenLimitValue === "number" &&
                                !errors?.tokenLimit
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={readTime.type}
                            label={readTime.label}
                            name={readTime.name}
                            placeholder={readTime.placeholder}
                            error={errors?.readTime && touched?.readTime}
                            success={
                                typeof readTimeValue === "number" &&
                                !errors?.readTime
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Assistant Links
                        </SoftTypography>
                    </SoftBox>
                    {assistantLinksValue.map((link, index) => (
                        <SoftBox
                            key={`assistantLinks-${index}`}
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <SoftTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="black"
                                >
                                    {link.url}
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text">
                                    {link.description}
                                </SoftTypography>
                            </SoftBox>
                            <CloseIcon
                                fontSize="medium"
                                sx={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setFieldValue(assistantLinks.name, [
                                        ...assistantLinksValue.slice(0, index),
                                        ...assistantLinksValue.slice(index + 1),
                                    ]);
                                }}
                            />
                        </SoftBox>
                    ))}
                    <SoftBox
                        sx={{
                            py: 1,
                            borderRadius: "0.375rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#1C4C73",
                            cursor: "pointer",
                        }}
                        onClick={toggleCreateLinkModal}
                    >
                        <SoftTypography
                            variant="body2"
                            fontWeight="regular"
                            color="white"
                        >
                            Create Assistant Link
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Modal
                    open={isCreateLinkModalOpen}
                    onClose={toggleCreateLinkModal}
                >
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                URL
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            type="url"
                            label="URL"
                            placeholder="Enter URL"
                            value={linkFormData.url}
                            onChange={(event) => {
                                setLinkFormData({
                                    ...linkFormData,
                                    url: event.target.value,
                                });
                            }}
                        />

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Description
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            type="description"
                            label="Description"
                            placeholder="Enter Description"
                            value={linkFormData.description}
                            multiline
                            rows={4}
                            onChange={(event) => {
                                setLinkFormData({
                                    ...linkFormData,
                                    description: event.target.value,
                                });
                            }}
                        />
                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={handleCreateLink}
                            >
                                Add
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleCreateLinkModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </SoftBox>
        </SoftBox>
    );
}

Preferences.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default Preferences;
