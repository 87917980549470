export async function imageFileToBase64(file) {
  return new Promise((res, rej) => {
    var reader = new FileReader();
    reader.onloadend = function() {
      console.log('RESULT', reader.result)
      res(reader.result)
    }
    reader.onerror = function(e) {
      rej(e)
    }

    reader.readAsDataURL(file);
  })
}