import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import UsageDetails from "./components/UsageDetails";

export const adminBusinessColumns = [
    {
        Header: "DISPLAY NAME",
        accessor: "displayName",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/businesses/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "USER EMAIL",
        accessor: "userEmail",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/users/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    { Header: "PHONE NUMBERS", accessor: "phoneNumbers", width: "15%" },
    { Header: "ASSISTANTS", accessor: "assistants", width: "15%" },
    {
        Header: "BUSINESS HOURS",
        accessor: "businessHours",
        width: "13%",
        Cell: ({ value: [name, data] }) =>
            name.enabled ? (
                <SoftBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                        fontSize: "0.9rem",
                    }}
                >
                    <AlarmOnIcon fontSize="medium" />{" "}
                    {name.startTime.toString().padStart(2, "0")} -{" "}
                    {name.endTime.toString().padStart(2, "0")}
                </SoftBox>
            ) : (
                <SoftBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                        fontSize: "0.9rem",
                    }}
                >
                    <AlarmOffIcon fontSize="medium" />
                </SoftBox>
            ),
    },
    {
        Header: "USAGE",
        accessor: "usage",
        width: "10%",
        Cell: ({ value }) => <UsageDetails usage={value} />,
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(businesses) {
    return businesses.map((business) => ({
        displayName: [business.displayName, business],
        userEmail: [business.userSetting.userEmail, business.userSetting],
        state: business.state,
        phoneNumbers: business.phoneNumberCount || 0,
        assistants: business.assistantCount || 0,
        businessHours: [business.businessHours, business],
        usage: business.totalUsage || 0,
        createdAt: [
            new Date(business.createdAt).getTime(),
            dayjs(business.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
