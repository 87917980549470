/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useMemo, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Soft UI Dashboard PRO React components

// Soft UI Dashboard PRO React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { setMiniSidenav, useSoftUIController } from "context";

// Images
import adminRoutes from "admin.routes";
import brand from "assets/images/sblogo2.png";
import { useDispatch } from "react-redux";
import { authActions } from "./redux/authSlice";
import useAuth from "./redux/hooks/useAuth";

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
        controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const { pathname } = useLocation();

    const { user, isAuthenticated } = useAuth();
    const reduxDispatcher = useDispatch();

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    useEffect(() => {
        reduxDispatcher(authActions.populate());
    }, []);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    useEffect(() => {
        let scriptElement;

        if (isAuthenticated) {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.ready
                    .then(async (registration) => {
                        const pushSubscription =
                            await registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey:
                                    process.env.REACT_APP_VAPID_PUBLIC_KEY,
                            });

                        reduxDispatcher(
                            authActions.subscribeToPushService(pushSubscription)
                        );
                    })
                    .catch((err) => {
                        console.log(
                            "Service Worker registration failed: ",
                            err
                        );
                    });
            }

            scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            scriptElement.defer = true; // or use scriptElement.setAttribute("defer", "true");

            // Add user information to the script content
            scriptElement.innerHTML = `
                var beamer_config = {
                    product_id: 'yHoyTCmJ64807',
                };
            `;

            // Append the script element to the document
            document.head.appendChild(scriptElement);

            // Create another script element for the Beamer embed script
            const beamerScriptElement = document.createElement("script");
            beamerScriptElement.type = "text/javascript";
            beamerScriptElement.src =
                "https://app.getbeamer.com/js/beamer-embed.js";
            beamerScriptElement.defer = true;

            document.head.appendChild(beamerScriptElement);
        }

        return () => {
            if (scriptElement) {
                document.head.removeChild(scriptElement);
            }
        };
    }, [user]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                );
            }

            return null;
        });

    return direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={themeRTL}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    transition={Flip}
                    limit={2}
                />
                <CssBaseline />
                {layout === "dashboard" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={brand}
                            brandName="SellBlue"
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                    </>
                )}
                {layout === "admin" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={brand}
                            brandName="SellBlue Admin"
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                    </>
                )}
                {layout === "vr" && <Configurator />}
                <Routes>
                    {getRoutes(routes)}
                    {getRoutes(adminRoutes)}
                    <Route path="*" element={<Navigate to="/sign-in" />} />
                </Routes>
            </ThemeProvider>
        </CacheProvider>
    ) : (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    transition={Flip}
                    limit={2}
                />
                <CssBaseline />
                {layout === "dashboard" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={brand}
                            brandName="SellBlue"
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                    </>
                )}
                {layout === "admin" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={brand}
                            brandName="SellBlue Admin"
                            routes={adminRoutes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                    </>
                )}
                {layout === "vr" && <Configurator />}
                <Routes>
                    {getRoutes(routes)}
                    {getRoutes(adminRoutes)}
                    <Route path="*" element={<Navigate to="/sign-in" />} />
                </Routes>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
