import { updatePhoneNumber } from "common/api/numbers";
import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../../redux/appSlice";
import useApp from "../../../../redux/hooks/useApp";

function SelectAssistantCell({ phoneNumberId, defaultValue = undefined }) {
    const { assistants, phoneNumbers, assistantsError, isAssistantsLoading } =
        useApp();
    const [selectedAssistantId, setSelectedAssistantId] =
        useState(defaultValue);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appActions.fetchAssistants());
    }, []);

    const phoneNumber = useMemo(() => {
        return phoneNumbers?.find((_) => _._id === phoneNumberId);
    }, [phoneNumbers]);

    const assistantsValues = useMemo(() => {
        return [
            {
                label: "No Assistant",
                value: null,
            },
            ...assistants
                ?.filter((assistant) =>
                    phoneNumber.business === null
                        ? assistant.business._id === phoneNumber.business
                        : assistant.business._id === phoneNumber.business?._id
                )
                ?.map((assistant) => ({
                    label: assistant.displayName,
                    value: assistant._id,
                })),
        ];
    }, [assistants, phoneNumber]);

    useEffect(() => {
        setSelectedAssistantId(
            phoneNumber?.defaultAssistant !== null
                ? phoneNumber?.defaultAssistant?._id
                : phoneNumber?.defaultAssistant
        );
    }, [phoneNumber]);

    useEffect(() => {
        if (selectedAssistantId === undefined) return;

        if (
            phoneNumber?.defaultAssistant !== null &&
            phoneNumber?.defaultAssistant?._id === undefined
        )
            return;

        if (
            phoneNumber.defaultAssistant === null
                ? selectedAssistantId === phoneNumber?.defaultAssistant
                : selectedAssistantId === phoneNumber?.defaultAssistant?._id
        )
            return;

        updatePhoneNumber(phoneNumberId, {
            defaultAssistant: selectedAssistantId,
        })
            .then((res) => {
                toast.success("Phone number updated successfully.");
                dispatch(appActions.fetchPhoneNumbers());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err.message);
                dispatch(appActions.fetchPhoneNumbers());
            });
    }, [selectedAssistantId]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={assistantsValues}
                value={assistantsValues.filter(
                    (_) => _.value === selectedAssistantId
                )}
                onChange={({ value }) => {
                    if (value === selectedAssistantId) return;

                    setSelectedAssistantId(value);
                }}
            />
        </div>
    );
}

export default SelectAssistantCell;
