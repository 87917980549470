import SoftSelect from "components/SoftSelect";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../../../redux/adminSlice";
import useAdmin from "../../../../../redux/hooks/useAdmin";

function SelectAssistant({
    withoutAny = false,
    withNoAssistant = false,
    defaultValue = undefined,
    callbackFn = () => {},
}) {
    const {
        businesses,
        assistants,
        selectedUserId,
        selectedBusinessId,
        selectedAssistantId,
        assistantsError,
        isAssistantsLoading,
    } = useAdmin();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.fetchAssistants());

        if (
            withoutAny &&
            defaultValue === undefined &&
            selectedAssistantId === undefined
        ) {
            dispatch(adminActions.selectAssistant(assistants?.[0]?._id));

            if (assistants?.[0]?._id) {
                const business = assistants.find(
                    (assistant) => assistant._id === assistants?.[0]?._id
                )?.business;
                dispatch(adminActions.selectBusiness(business?._id));
            } else {
                dispatch(adminActions.selectBusiness(null));
            }
        }

        if (defaultValue !== undefined) {
            dispatch(adminActions.selectAssistant(defaultValue));
        }
    }, []);

    useEffect(() => {
        callbackFn(selectedAssistantId);
    }, [selectedAssistantId]);

    const assistantValues = useMemo(() => {
        return [
            !withoutAny
                ? {
                      label: "Any Assistant",
                      value: undefined,
                  }
                : null,
            withNoAssistant
                ? {
                      label: "No Assistant",
                      value: null,
                  }
                : null,
            ...assistants
                ?.filter((_) => {
                    return !selectedBusinessId
                        ? false
                        : selectedBusinessId
                        ? _.business?._id === selectedBusinessId
                        : true;
                })
                .map((assistant) => ({
                    label: assistant.displayName,
                    value: assistant._id,
                })),
        ].filter(Boolean);
    }, [assistants, selectedBusinessId]);

    return (
        <div style={{ minWidth: "250px" }}>
            <SoftSelect
                options={assistantValues}
                value={assistantValues.filter(
                    (_) => _.value === selectedAssistantId
                )}
                onChange={({ value }) => {
                    dispatch(adminActions.selectAssistant(value));
                }}
            />
        </div>
    );
}

export default SelectAssistant;
