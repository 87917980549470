import SoftBox from "components/SoftBox";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const adminPhoneNumberGroupsColumns = [
    {
        Header: "NAME",
        accessor: "name",
        width: "30%",
        Cell: ({ value: [name, data] }) => (
            <SoftBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                }}
            >
                <Link
                    to={`/admin/number-groups/${data._id}`}
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                    {name}
                </Link>
            </SoftBox>
        ),
    },
    {
        Header: "USER EMAIL",
        accessor: "userEmail",
        width: "20%",
        Cell: ({ value: [name, data] }) => (
            <>
                <SoftBox
                    sx={{
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                    }}
                >
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/users/${data._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {name}
                        </Link>
                    </SoftBox>
                </SoftBox>
            </>
        ),
    },
    {
        Header: "BUSINESS",
        accessor: "business",
        width: "15%",
        Cell: ({ value: [phoneNumberGroup] }) => (
            <>
                {phoneNumberGroup.business ? (
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/businesses/${phoneNumberGroup.business._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {phoneNumberGroup.business.displayName}
                        </Link>
                    </SoftBox>
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "DEFAULT ASSISTANT",
        accessor: "defaultAssistant",
        width: "15%",
        Cell: ({ value: [phoneNumberGroup] }) => (
            <>
                {phoneNumberGroup.defaultAssistant ? (
                    <SoftBox
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                        }}
                    >
                        <Link
                            to={`/admin/assistants/${phoneNumberGroup.defaultAssistant._id}`}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit",
                            }}
                        >
                            {phoneNumberGroup.defaultAssistant.displayName}
                        </Link>
                    </SoftBox>
                ) : (
                    <>--</>
                )}
            </>
        ),
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ value: [date, formattedDate] }) => (
            <SoftBox>{formattedDate}</SoftBox>
        ),
    },
];

export function createTableRows(phoneNumberGroups) {
    return phoneNumberGroups.map((phoneNumberGroup) => ({
        name: [phoneNumberGroup.name, phoneNumberGroup],
        userEmail: [
            phoneNumberGroup.userSetting.userEmail,
            phoneNumberGroup.userSetting,
        ],
        business: [phoneNumberGroup],
        defaultAssistant: [phoneNumberGroup],
        createdAt: [
            new Date(phoneNumberGroup.createdAt).getTime(),
            dayjs(phoneNumberGroup.createdAt).format("D MMM, YYYY"),
        ],
    }));
}
