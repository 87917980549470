import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {
        relayServerId,
        senderName,
        senderNumber,
        userSetting,
        defaultAssistant,
        business,
    },
} = checkout;

const validations = [
    Yup.object().shape({
        [relayServerId.name]: Yup.string(),
        [senderName.name]: Yup.string().required(senderName.errorMsg),
        [senderNumber.name]: Yup.string().required(senderNumber.errorMsg),
        [userSetting.name]: Yup.string().required(userSetting.errorMsg),
    }),
];

export default validations;
