/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

// react-router components
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";
import PublicIcon from "@mui/icons-material/Public";
import SettingsIcon from "@mui/icons-material/Settings";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Breadcrumbs from "examples/Breadcrumbs";
import { useNavigate } from "react-router-dom";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
    setMiniSidenav,
    setOpenConfigurator,
    setTransparentNavbar,
    useSoftUIController,
} from "context";

// Images

//Icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MenuItem } from "@mui/material";
import SoftSnackbar from "components/SoftSnackbar";
import SoftTypography from "components/SoftTypography";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { authActions } from "../../../redux/authSlice";
import useApp from "../../../redux/hooks/useApp";
import useAuth from "../../../redux/hooks/useAuth";

function DashboardNavbar({ absolute, light, isMini }) {
    const navigate = useNavigate();
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const {
        miniSidenav,
        transparentNavbar,
        fixedNavbar,
        openConfigurator,
        layout,
    } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const [showNotificationBar, setShowNotificationBar] = useState(false);

    useEffect(() => {
        if ("PushManager" in window) {
            navigator.serviceWorker.ready.then(function (registration) {
                registration.pushManager
                    .getSubscription()
                    .then(function (subscription) {
                        if (subscription) {
                            setShowNotificationBar(false);
                        } else {
                            setShowNotificationBar(true);
                        }
                    })
                    .catch(function (error) {
                        console.error(
                            "Error occurred while checking for subscription: ",
                            error
                        );
                    });
            });
        }
    }, []);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(
                dispatch,
                (fixedNavbar && window.scrollY === 0) || !fixedNavbar
            );
        }

        /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () =>
            window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () =>
        setOpenConfigurator(dispatch, !openConfigurator);

    const handleOpenMenu = (event) =>
        openMenu ? setOpenMenu(false) : setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const { isLoading, isAuthenticated, user } = useAuth();
    const { businesses, selectedBusiness } = useApp();
    const reduxDespatch = useDispatch();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate("/sign-in");
        }
    }, [isLoading, isAuthenticated]);

    const handleLogout = () => {
        if (localStorage.getItem("pushSubscription")) {
            reduxDespatch(
                authActions.unsubscribeFromPushService(
                    JSON.parse(localStorage.getItem("pushSubscription"))
                )
            );
        }

        reduxDespatch(authActions.logout());
    };

    const fromRouteToTitle = useMemo(() => {
        switch (layout) {
            case "dashboard": {
                if (route.length === 1) {
                    return `${route[0].charAt(0).toUpperCase()}${route[0].slice(
                        1
                    )}`;
                }

                if (route[0] === "conversations") {
                    return "Conversations";
                }

                if (route[0] === "businesses") {
                    if (route[1] === "create") {
                        return "Create Business";
                    }

                    return "Update Business";
                }

                if (route[0] === "assistants") {
                    if (route[1] === "create") {
                        return "Create Assistant";
                    }

                    return "Update Assistant";
                }

                if (route[0] === "contacts") {
                    if (route[1]) {
                        return "Contact Profile";
                    }

                    return "Contacts";
                }

                break;
            }
            case "admin": {
                if (route[1] === "dashboard") {
                    return "Dashboard";
                }

                if (route[1] === "users") {
                    if (route[2] === "create") {
                        return "Create User";
                    } else if (route[2]) {
                        return "Update User";
                    }

                    return "Users";
                }

                if (route[1] === "businesses") {
                    return "Businesses";
                }

                if (route[1] === "assistants") {
                    return "Assistants";
                }

                if (route[1] === "numbers") {
                    return "Numbers";
                }

                if (route[1] === "contacts") {
                    return "Contacts";
                }

                break;
            }
        }

        return "";
    }, [route]);

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) =>
                navbar(theme, { transparentNavbar, absolute, light })
            }
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent",
                        borderRadius: "100%",
                        width: "2rem",
                        height: "2rem",
                        border: "1px dashed lightgray",
                        position: "absolute",
                        left:
                            route[route.length - 2] === "profile-overview"
                                ? "-5rem"
                                : "-4rem",
                    }}
                >
                    <Icon fontSize="0.5rem" onClick={handleMiniSidenav}>
                        {miniSidenav
                            ? "keyboard_arrow_right_icon"
                            : "keyboard_arrow_left_icon "}
                    </Icon>
                </SoftBox>
                <SoftBox
                    color="inherit"
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme) => navbarRow(theme, { isMini })}
                >
                    <Breadcrumbs
                        icon="home"
                        title={fromRouteToTitle}
                        route={route}
                        light={light}
                    />
                </SoftBox>
                <SoftBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        ml: "auto",
                    }}
                >
                    {/* <CardMedia
                        src={US}
                        component="img"
                        title="title"
                        sx={navbarDesktopMenu}
                    />
                    <Icon
                        sx={[
                            navbarDesktopMenu,
                            light ? navbarDesktopMenuProfile : "",
                        ]}
                        color="action"
                    >
                        notifications
                    </Icon>
                    <Icon
                        sx={[
                            navbarDesktopMenu,
                            light ? navbarDesktopMenuProfile : "",
                        ]}
                    >
                        people_alt
                    </Icon>
                    <Icon
                        sx={[
                            navbarDesktopMenu,
                            light ? navbarDesktopMenuProfile : "",
                        ]}
                        color="action"
                    >
                        settings
                    </Icon> */}

                    <MenuItem
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: "#FFF",
                            px: "1rem",
                            py: "0.7rem",
                            border: "1px solid #EDEFF2",
                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.03)",
                            gap: "0.5rem",
                        }}
                        onClick={handleOpenMenu}
                        MenuProps={{ style: { width: "100%" } }}
                    >
                        <SoftTypography variant="body2" color="dark">
                            {user?.firstName} {user?.lastName}
                            {!user?.firstName ? user?.userEmail : ""}
                        </SoftTypography>
                        <AccountCircleIcon
                            sx={{
                                color: "#1C4C73",
                            }}
                            fontSize="medium"
                        />
                    </MenuItem>

                    <SoftBox
                        onClick={handleMiniSidenav}
                        sx={{
                            px: "1rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <MenuIcon fontSize={"medium"} />
                    </SoftBox>

                    <Menu
                        anchorEl={openMenu}
                        anchorReference={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(openMenu)}
                        onClose={handleCloseMenu}
                        sx={{ mt: "1rem", width: "100%" }}
                    >
                        {user?.isAdministrator && (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        navigate(
                                            layout === "admin"
                                                ? "/dashboard"
                                                : "/admin/dashboard"
                                        );
                                    }}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                            padding: "0",
                                        }}
                                    >
                                        <SwapVertIcon fontSize="small" />

                                        <span>
                                            {layout === "admin"
                                                ? "User"
                                                : "Admin"}
                                        </span>

                                        {layout === "admin" ? (
                                            <PublicIcon fontSize="small" />
                                        ) : (
                                            <LockIcon fontSize="small" />
                                        )}
                                    </SoftBox>
                                </MenuItem>
                                <SoftBox
                                    component="div"
                                    bgColor="secondary"
                                    opacity={0.3}
                                    width="100%"
                                    height="1px"
                                    my={1}
                                />
                            </>
                        )}
                        <MenuItem
                            onClick={() => {
                                navigator.clipboard.writeText(user?._id);

                                toast.success("User ID copied to clipboard.");

                                handleCloseMenu();
                            }}
                            sx={{
                                width: "170px",
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0",
                                }}
                            >
                                <span>Copy ID</span>
                                <ContentCopyIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate("/settings");
                                handleCloseMenu();
                            }}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0",
                                }}
                            >
                                <span>Settings</span>
                                <SettingsIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                        <SoftBox
                            component="div"
                            bgColor="secondary"
                            opacity={0.3}
                            width="100%"
                            height="1px"
                            my={1}
                        />
                        <MenuItem
                            onClick={handleLogout}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0",
                                    color: "red",
                                }}
                            >
                                <span>Logout</span>
                                <ExitToAppIcon fontSize="small" />
                            </SoftBox>
                        </MenuItem>
                    </Menu>

                    {layout === "dashboard" && showNotificationBar && (
                        <SoftSnackbar
                            color="error"
                            icon="notifications"
                            title="Notifications are blocked."
                            content="Please allow notifications in your browser."
                            open={showNotificationBar}
                            close={() => setShowNotificationBar(false)}
                        />
                    )}
                </SoftBox>
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
