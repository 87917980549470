import AddIcon from "@mui/icons-material/Add";
import { Box, Card, Modal, Stack, Typography } from "@mui/material";
import { updateCustomFields } from "common/api/contacts";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { createTableRows, customFieldsColumns } from "./CustomFields.helpers";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function CustomFields({ contact, refresh = () => {} }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomField, setSelectedCustomField] = useState(null);

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const toggleModalOpen = (keyName = undefined) => {
        setIsModalOpen((_) => !_);

        if (keyName) {
            const customField = contact.customFields.find(
                (field) => field.key === keyName
            );
            setSelectedCustomField(customField);
            setKey(customField.key);
            setValue(customField.value);
        }
    };

    const tableData = useMemo(() => {
        return {
            columns: customFieldsColumns,
            rows: createTableRows(contact?.customFields || [], toggleModalOpen),
        };
    }, [contact]);

    async function addCustomField() {
        if (!key || !value) {
            return;
        }

        if (contact.customFields.some((field) => field.key === key)) {
            toast.error("Key already exists.");
            return;
        }

        updateCustomFields(contact._id, {
            customFields: [
                ...contact.customFields,
                {
                    key,
                    value,
                },
            ],
        })
            .then((res) => {
                setIsModalOpen(false);
                toast.success("Custom Field added successfully.");
                refresh();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    }

    async function updateCustomField() {
        if (!key || !value) {
            return;
        }

        if (
            key !== selectedCustomField.key &&
            contact.customFields.some((field) => field.key === key)
        ) {
            toast.error("Key already exists.");
            return;
        }

        updateCustomFields(contact._id, {
            customFields: contact.customFields.map((field) => {
                if (field.key === selectedCustomField.key) {
                    return {
                        key,
                        value,
                    };
                }
                return field;
            }),
        })
            .then((res) => {
                setIsModalOpen(false);
                toast.success("Custom Field updated successfully.");
                refresh();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    }

    return (
        <>
            <Card>
                <SoftBox pt={2} px={2}>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={1}>
                            <SoftTypography
                                variant="h6"
                                fontWeight="medium"
                                textTransform="capitalize"
                            >
                                Custom Fields
                            </SoftTypography>
                            <SoftTypography
                                variant="subtitle2"
                                fontWeight="light"
                            >
                                Here you can view and edit custom fields for
                                this contact.
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftBox
                                onClick={() => {
                                    setSelectedCustomField(null);
                                    setKey("");
                                    setValue("");
                                    toggleModalOpen();
                                }}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    p: 1,
                                    border: "1px solid #fff",
                                    borderRadius: "8px",
                                    backgroundColor: "#1C4C73",
                                    color: "#fff",
                                }}
                            >
                                <AddIcon fontSize="medium" />
                            </SoftBox>
                        </Stack>
                    </SoftBox>

                    <DataTable
                        table={tableData}
                        entriesPerPage={{
                            defaultValue: 5,
                            entries: [5],
                        }}
                        wideSearch={true}
                        canSearch
                    />
                </SoftBox>
            </Card>
            <Modal
                open={isModalOpen}
                onClose={() => {
                    toggleModalOpen();
                }}
            >
                <Box
                    sx={{
                        ...style,
                    }}
                >
                    <SoftBox mb={1} ml={0.5} lineHeight={0}>
                        <SoftTypography
                            component="label"
                            variant="h4"
                            textTransform="capitalize"
                        >
                            {selectedCustomField ? "Update" : "Add"} Custom
                            Field
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Key
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        sx={{
                            mb: 1,
                        }}
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />

                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Value
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        sx={{
                            mb: 1,
                        }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />

                    <Box
                        sx={{
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    toggleModalOpen();
                                    setSelectedCustomField(null);

                                    if (selectedCustomField) {
                                        updateCustomField();
                                    } else {
                                        addCustomField();
                                    }
                                }}
                            >
                                {selectedCustomField ? "Update" : "Add"}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1C4C73",
                                px: 3,
                                py: 0.6,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                toggleModalOpen();
                            }}
                        >
                            Cancel
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default CustomFields;
