import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Card, Divider, Grid, Modal } from "@mui/material";
import calendlyLogo from "assets/images/assistant-skills/calendly.png";
import axios from "axios";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../../../../redux/hooks/useAuth";

const extensionSettingsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function CalendlySkill({ formField }) {
    const { values, setFieldValue } = useFormikContext();

    const { skills: skillsValue } = values;

    const [extension, setExtension] = useState(
        skillsValue.filter((_) => _.name === "calendly")[0]
            ? skillsValue.filter((_) => _.name === "calendly")[0]
            : undefined
    );
    const [calendlyEvents, setCalendlyEvents] = useState({
        organization: "",
        events: [],
    });

    const { user } = useAuth();

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const toggleSettingsModal = () => {
        setIsSettingsModalOpen(!isSettingsModalOpen);
    };

    useEffect(() => {
        setCalendlyEvents({
            organization: "",
            events: [],
        });

        setExtension(
            skillsValue.filter((_) => _.name === "calendly")[0]
                ? skillsValue.filter((_) => _.name === "calendly")[0]
                : {
                      name: "calendly",
                      config: {
                          accessToken: "",
                          eventTypeId: "",
                          schedulingUrl: "",
                      },
                  }
        );

        if (skillsValue.filter((_) => _.name === "calendly")[0]) {
            fetchCalendlyOrganization();
        }
    }, [isSettingsModalOpen]);

    useEffect(() => {
        if (skillsValue) {
            setExtension(
                skillsValue.filter((_) => _.name === "calendly")[0]
                    ? skillsValue.filter((_) => _.name === "calendly")[0]
                    : undefined
            );
        }
    }, [skillsValue]);

    const fetchCalendlyOrganization = async () => {
        if (!extension.config.accessToken) {
            return toast.error(
                "Please fill in the Personal Access Token field."
            );
        }

        try {
            const response = await axios.get(
                `https://api.calendly.com/users/me`,
                {
                    headers: {
                        Authorization: `Bearer ${extension.config.accessToken}`,
                    },
                }
            );

            const currentOrganization =
                response.data.resource.current_organization;

                                // Update the extension config to include the current organization
        setExtension((prevExtension) => ({
            ...prevExtension,
            config: {
                ...prevExtension.config,
                organization: currentOrganization,
            },
        }));

            setCalendlyEvents((_) => ({
                ..._,
                organization: currentOrganization,
            }));
        } catch (error) {
            console.error(error);
            toast.error("Invalid Personal Access Token.");
        }
    };

    const fetchCalendlyEvents = async () => {
        if (!extension.config.accessToken) {
            return toast.error(
                "Please fill in the Personal Access Token field."
            );
        }

        if (!calendlyEvents.organization) {
            return toast.error("Calendly Organization not found.");
        }

        try {
            const response = await axios.get(
                `https://api.calendly.com/event_types?organization=${calendlyEvents.organization}&count=100`,
                {
                    headers: {
                        Authorization: `Bearer ${extension.config.accessToken}`,
                    },
                }
            );

            const events = response.data.collection;

            setCalendlyEvents((_) => ({
                ..._,
                events: events.filter((_) => _.active),
            }));
        } catch (error) {
            console.error(error);
            toast.error("Invalid Personal Access Token.");
        }
    };

    useEffect(() => {
        if (calendlyEvents.organization) {
            fetchCalendlyEvents();
        }
    }, [calendlyEvents.organization]);

    return (
        <>
            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "0.375rem",
                    border: "1px solid #E0E0E0",
                    px: 1.5,
                    py: 1.5,
                    p: 0,
                }}
            >
                <SoftBox p={2}>
                    <SoftBox display="flex" alignItems="center">
                        <SoftAvatar
                            src={calendlyLogo}
                            alt={`Calendly Logo`}
                            size="lg"
                            variant="rounded"
                        />
                        <SoftBox ml={2} lineHeight={0}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography
                                    variant="h5"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                >
                                    Calendly
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox my={2} lineHeight={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            With Calendly, your assistant will be able to
                            schedule meetings for you.
                        </SoftTypography>
                    </SoftBox>
                    <Divider />
                    <SoftBox
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {skillsValue?.filter(
                            (_) => _.name === "calendly"
                        )[0] === undefined && (
                            <SoftBox
                                lineHeight={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    px: 2,
                                    py: 1,
                                    cursor: "pointer",
                                    backgroundColor: !user.extensions.filter(
                                        (_) => _.name === "calendly"
                                    )[0]
                                        ? "#787878"
                                        : "#1c4c73",
                                    borderRadius: "4px",
                                }}
                                onClick={() => {
                                    if (
                                        !user.extensions.filter(
                                            (_) => _.name === "calendly"
                                        )[0]
                                    ) {
                                        toast.error(
                                            "Please upgrade your account to use this feature."
                                        );
                                    } else {
                                        toggleSettingsModal();
                                    }
                                }}
                            >
                                <SoftTypography
                                    variant="button"
                                    fontWeight="medium"
                                    color="white"
                                >
                                    {!user.extensions.filter(
                                        (_) => _.name === "calendly"
                                    )[0]
                                        ? "Locked"
                                        : "Activate"}
                                </SoftTypography>
                                {!user.extensions.filter(
                                    (_) => _.name === "calendly"
                                )[0] ? (
                                    <LockIcon color="white" />
                                ) : (
                                    <AddIcon color="white" />
                                )}
                            </SoftBox>
                        )}

                        {skillsValue?.filter(
                            (_) => _.name === "calendly"
                        )[0] && (
                            <SoftBox
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "red",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        setFieldValue(formField, [
                                            ...skillsValue.filter(
                                                (_) => _.name !== "calendly"
                                            ),
                                        ]);

                                        toast.success(
                                            "Calendly skill removed."
                                        );
                                    }}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Remove
                                    </SoftTypography>
                                    <DeleteIcon color="white" />
                                </SoftBox>
                                <SoftBox
                                    lineHeight={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        px: 2,
                                        py: 1,
                                        cursor: "pointer",
                                        backgroundColor: "#1c4c73",
                                        borderRadius: "4px",
                                    }}
                                    onClick={toggleSettingsModal}
                                >
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                    <SettingsIcon color="white" />
                                </SoftBox>
                            </SoftBox>
                        )}
                    </SoftBox>
                </SoftBox>
            </Card>
            <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
                <Box
                    sx={{
                        ...extensionSettingsModalStyle,
                    }}
                >
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SoftBox lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                Configure Calendly
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                Configure your Calendly settings.
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox mt={1.625}>
                        <Grid mt={1.625} item xs={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Personal Access Token
                                </SoftTypography>
                                <a href="https://developer.calendly.com/how-to-authenticate-with-personal-access-tokens">
                                    <HelpIcon fontSize="small" color="black" />
                                </a>
                            </SoftBox>
                            <SoftInput
                                label="Personal Access Token"
                                placeholder="Enter Personal Access Token"
                                type="password"
                                value={extension?.config?.accessToken}
                                onChange={(event) => {
                                    setExtension((_) => ({
                                        ..._,
                                        config: {
                                            ..._.config,
                                            accessToken: event.target.value,
                                        },
                                    }));
                                }}
                            />

                            <SoftBox
                                sx={{
                                    mt: 0.7,
                                    py: 1,
                                    borderRadius: "0.375rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#1C4C73",
                                    cursor: "pointer",
                                }}
                                onClick={fetchCalendlyOrganization}
                            >
                                <SoftTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="white"
                                >
                                    {calendlyEvents.organization
                                        ? "Refresh"
                                        : "List"}{" "}
                                    All Events
                                </SoftTypography>
                            </SoftBox>
                            {calendlyEvents.events.length > 0 && (
                                <>
                                    <SoftBox
                                        mb={1}
                                        ml={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                    >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Event
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        options={calendlyEvents.events.map(
                                            (_) => ({
                                                label: _.name,
                                                value: _.uri.split("/").pop(),
                                            })
                                        )}
                                        value={calendlyEvents.events
                                            .map((_) => ({
                                                label: _.name,
                                                value: _.uri.split("/").pop(),
                                            }))
                                            .filter(
                                                (_) =>
                                                    _.value ===
                                                    extension?.config
                                                        ?.eventTypeId
                                            )}
                                        onChange={({ value }) => {
                                            const selectedEvent =
                                                calendlyEvents.events.filter(
                                                    (_) =>
                                                        _.uri
                                                            .split("/")
                                                            .pop() === value
                                                )[0];

                                            setExtension((_) => ({
                                                ..._,
                                                config: {
                                                    ..._.config,
                                                    eventTypeId:
                                                        selectedEvent.uri
                                                            .split("/")
                                                            .pop(),
                                                    schedulingUrl:
                                                        selectedEvent.scheduling_url,
                                                },
                                            }));
                                        }}
                                    />
                                </>
                            )}

                            <SoftBox
                                sx={{
                                    mt: 3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        toggleSettingsModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="black"
                                    >
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        width: "100%",
                                        py: 1,
                                        borderRadius: "0.375rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1C4C73",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (!calendlyEvents.organization) {
                                            return toast.error(
                                                "Please provide a valid Personal Access Token."
                                            );
                                        }

                                        if (
                                            !extension.config.eventTypeId ||
                                            !extension.config.schedulingUrl
                                        ) {
                                            return toast.error(
                                                "Please select an event."
                                            );
                                        }

                                        if (!extension.config.accessToken) {
                                            return toast.error(
                                                "Please provide a valid Personal Access Token."
                                            );
                                        }

                                        setFieldValue(formField, [
                                            ...skillsValue.filter(
                                                (_) => _.name !== "calendly"
                                            ),
                                            extension,
                                        ]);
                                        toast.success(
                                            "Calendly skill configured."
                                        );
                                        toggleSettingsModal();
                                    }}
                                >
                                    <SoftTypography
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                    >
                                        Configure
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </SoftBox>
                </Box>
            </Modal>
        </>
    );
}

export default CalendlySkill;
