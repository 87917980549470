const form = {
    formId: "create-assistant",
    formField: {
        firstName: {
            name: "firstName",
            label: "First Name",
            type: "text",
            placeholder: "Enter First Name",
            errorMsg: "First Name is required.",
        },
        lastName: {
            name: "lastName",
            label: "Last Name",
            type: "text",
            placeholder: "Enter Last Name",
            errorMsg: "Last Name is required.",
        },
        userEmail: {
            name: "userEmail",
            label: "Email",
            type: "email",
            placeholder: "Enter Email",
            errorMsg: "Email is required.",
        },
        password: {
            name: "password",
            label: "Password",
            type: "password",
            placeholder: "Enter Password",
            errorMsg: "Password is required.",
        },
        extensions: {
            name: "extensions",
            label: "Extensions",
            type: "text",
            placeholder: "Enter Extensions",
            errorMsg: "Extensions is required.",
        },
        isAdministrator: {
            name: "isAdministrator",
            label: "Is Administrator",
            type: "checkbox",
            errorMsg: "Is Administrator is required.",
        },
        preferredTimezone: {
            name: "preferredTimezone",
            label: "Preferred Timezone",
            type: "text",
            placeholder: "Enter Preferred Timezone",
            errorMsg: "Preferred Timezone is required.",
        },
    },
};

export default form;
