import { useEffect } from "react";
import socketIOClient from "socket.io-client";
import useApp from "../../redux/hooks/useApp";

export const useMessageReceived = (
    appendSelectedConversationMessage,
    updateSelectedConversationStatus
) => {
    const { selectedConversation } = useApp();

    useEffect(() => {
        let socket;

        if (!selectedConversation) {
            return;
        }

        const connectSocket = () => {
            socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
                path: "/socket.io",
            });

            socket.on("connect", () => {
                console.log("Successfully connected to WebSocket server.");

                socket.emit("register", {
                    clientUuid: selectedConversation._id,
                });
            });

            socket.on("newMessage", (data) => {
                const newMessage = data;

                console.log("New message received:", newMessage);

                appendSelectedConversationMessage(newMessage);
            });

            socket.on("updatedMessage", (data) => {
                const updatedMessage = data;

                console.log("Updated message received:", updatedMessage);

                appendSelectedConversationMessage(updatedMessage);
            });

            socket.on("conversationStatus", (data) => {
                const updatedConversationStatus = data;

                console.log(
                    "Conversation status received:",
                    updatedConversationStatus
                );

                updateSelectedConversationStatus(updatedConversationStatus);
            });

            socket.on("clientNotFound", (data) => {});

            socket.on("disconnect", () => {
                console.log(`Disconnected from WebSocket server.`);
            });
        };

        connectSocket();

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [selectedConversation]);
};
