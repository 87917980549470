import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { updateFollowUp } from "common/api/followUp";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { appActions } from "../../../redux/appSlice";

function StatusCell({ followUp }) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const changeFollowUpStatus = () => {
        updateFollowUp(followUp._id, {
            status: "closed",
        })
            .then((res) => {
                toast.success("Follow Up closed successfully.");

                dispatch(appActions.fetchFollowUps());
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error.message || err.message);
            });
    };

    return (
        <SoftBox
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
            }}
        >
            <SoftBadge
                variant="gradient"
                size="md"
                color={
                    followUp.status === "completed"
                        ? "success"
                        : followUp.status === "closed"
                        ? "error"
                        : "info"
                }
                badgeContent={followUp.status?.toUpperCase()}
                container
                border
                circular
            />
            {followUp.status === "active" && (
                <Tooltip title="Close Follow-Up">
                    <CloseIcon
                        fontSize="medium"
                        sx={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (isLoading) {
                                toast.error(
                                    "Please wait for the current action to complete."
                                );
                            }

                            setIsLoading(true);

                            changeFollowUpStatus();
                        }}
                    />
                </Tooltip>
            )}
        </SoftBox>
    );
}

export default StatusCell;
