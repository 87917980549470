import { apiAxios } from "common/services/httpService";
import SoftBox from "components/SoftBox";
import { useEffect } from "react";
const {
    default: DashboardLayout,
} = require("examples/LayoutContainers/DashboardLayout");
const {
    default: DashboardNavbar,
} = require("examples/Navbars/DashboardNavbar");

function ShopifyCallbackPage() {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const shop = params.get("shop");
        const code = params.get("code");

        if (shop && code) {
            apiAxios
                .post("/shopify/callback", {
                    code,
                    shop,
                })
                .then(async (data) => {
                    const accessToken = data.data.accessToken;
                    const shopDetails = data.data.shopDetails;

                    console.log(
                        "Access token stored and shop details fetched successfully"
                    );
                    window.opener.postMessage(
                        { accessToken, shopDetails },
                        process.env.REACT_APP_HOSTNAME
                    );
                    window.close();
                })
                .catch((error) => {
                    console.error(
                        "Error storing access token and fetching shop details",
                        error
                    );
                });
        } else {
            console.error("Shop and code not found in the URL");

            window.location.href = "/";
        }
    }, [window.location]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}></SoftBox>
        </DashboardLayout>
    );
}

export default ShopifyCallbackPage;
