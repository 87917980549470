import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Modal,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";

const usageDetailsModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    outline: "none",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
    zIndex: 100,
};

function UsageDetails({ usage }) {
    const [isUsageModalOpen, setIsUsageModalOpen] = useState(false);

    const toggleUsageModal = () => {
        setIsUsageModalOpen(!isUsageModalOpen);
    };

    return (
        <>
            <SoftBox
                sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    userSelect: "none",
                    color: "#000000",
                }}
                onClick={toggleUsageModal}
            >
                View Usage
            </SoftBox>
            <Modal open={isUsageModalOpen} onClose={toggleUsageModal}>
                <Box
                    sx={{
                        ...usageDetailsModalStyle,
                    }}
                >
                    <SoftBox lineHeight={0}>
                        <SoftTypography variant="h5" fontWeight="bold">
                            Usage Details
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {usage.length} models are used by this business.
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox lineHeight={0} mt={1}></SoftBox>

                    {usage.length === 0 && (
                        <SoftBox
                            sx={{
                                border: "1px solid #E0E0E0",
                                px: 1.5,
                                py: 1.5,
                                borderRadius: "0.375rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                            }}
                        >
                            <SoftTypography
                                variant="body2"
                                fontWeight="regular"
                                color="black"
                                sx={{
                                    lineHeight: 1.4,
                                }}
                            >
                                No usage available yet. Please check back later.
                            </SoftTypography>
                        </SoftBox>
                    )}

                    {usage.map(
                        (
                            {
                                model,
                                completionTokens,
                                promptTokens,
                                totalTokens,
                            },
                            index
                        ) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    <SoftTypography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: "medium",
                                        }}
                                    >
                                        {model.toUpperCase()} ({totalTokens})
                                    </SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".9rem",
                                            }}
                                        >
                                            Completion
                                        </SoftTypography>
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".8rem",
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {completionTokens} tokens
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".9rem",
                                            }}
                                        >
                                            Prompt
                                        </SoftTypography>
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".8rem",
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {promptTokens} tokens
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".9rem",
                                            }}
                                        >
                                            Total
                                        </SoftTypography>
                                        <SoftTypography
                                            sx={{
                                                fontSize: ".8rem",
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {totalTokens} tokens
                                        </SoftTypography>
                                    </SoftBox>
                                </AccordionDetails>
                            </Accordion>
                        )
                    )}
                </Box>
            </Modal>
        </>
    );
}

export default UsageDetails;
