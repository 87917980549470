import { apiAxios } from "common/services/httpService";

export const adminGetNewContactsStatistics = ({
    period,
    businessId = "",
    userId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `/admin/dashboard/contacts?period=${period}&startDate=${startDate}&endDate=${endDate}&userId=${userId}&businessId=${businessId}`
    );
};

export const adminGetSentMessagesStatistics = ({
    period,
    businessId = "",
    userId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `/admin/dashboard/messages/sent?period=${period}&startDate=${startDate}&endDate=${endDate}&userId=${userId}&businessId=${businessId}`
    );
};

export const adminGetStartedConversationsStatistics = ({
    period,
    businessId = "",
    userId = "",
    startDate,
    endDate,
}) => {
    return apiAxios.get(
        `/admin/dashboard/conversations/started?period=${period}&startDate=${startDate}&endDate=${endDate}&userId=${userId}&businessId=${businessId}`
    );
};

export const adminGetUsedModels = () => {
    return apiAxios.get(`/admin/dashboard/models`);
};

export const adminGetUsersByUsage = ({ model = "" }) => {
    return apiAxios.get(`/admin/dashboard/users-by-usage?model=${model}`);
};
